import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const CampaignDescription = ({
  content
}) => {
  return (
    <div className='description-container'>
      <p className='description-text'>
        {content}
      </p>
    </div>
  );
};

CampaignDescription.propTypes = {
  content: PropTypes.string
};

export default CampaignDescription;
