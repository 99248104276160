import React, { Component } from 'react';
import ForgotPasswordForm from 'containers/ForgotPassword/ForgotPasswordForm';
import { sendForgotPassEmail } from './action';
import { connect } from 'react-redux';

import './style.scss';
class ForgotPassword extends Component {
  handleSubmit = (value) => {
    const data = value.toJS ? value.toJS() : value;
    this.props.sendForgotPassEmail(data.email);
  }
  render() {

    return (
      <div id='forgot-pass-email-container'>
        <div className='left-col-container'>

          <div onClick={this.props.history.goBack} className="back-icon">
            <img alt="" src="images/back.svg" /><span>戻る</span>
          </div>

          <ForgotPasswordForm onSubmit={this.handleSubmit} />
        </div>
        <div className='right-col-container'>
          <img alt='' className='meshi-icon' src='/images/logo-login.svg' />
          <div className='meshi-slogan'>就活の場を</div>
          <div className='meshi-slogan'>「デスク」から「テーブル」へ</div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendForgotPassEmail: (email) => dispatch(sendForgotPassEmail(email))
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);