export const OPEN_CUSTOM_MODAL = 'OPEN_CUSTOM_MODAL';
export const CLOSE_CUSTOM_MODAL = 'CLOSE_CUSTOM_MODAL';

export const openModal = ({ key }) => ({
  type: OPEN_CUSTOM_MODAL,
  payload: { key }
});

export const closeModal = () => ({
  type: CLOSE_CUSTOM_MODAL
});
