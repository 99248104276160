import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import {
  receiveEventDetail,
  resetEventDetail,
  FETCH_EVENT_DETAIL_REQUEST
} from './actions';
import { closeRequestEventReasonPopUp } from './../../containers/RequestEventRessonPopUp/action';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { setCommonPopup } from 'containers/Popup/actions';
import { get } from 'lodash';

function* getEventDetail(action) {
  yield put(showLoading());
  try {
    const { eventId, actionType } = action;
    const data = yield call(
      request,
      'functions/getEventDetail',
      { eventId },
      'POST'
    );
    yield put(receiveEventDetail(data.result.eventInfo));
    if (actionType === 'join_refresh') {
      yield put(closeRequestEventReasonPopUp());
      yield put(
        setCommonPopup('success', {
          message: '応募を受け付けました！\n参加確定の連絡をお待ち下さい'
        })
      );
    }
  } catch (e) {
    yield put(resetEventDetail());
    if (get(e, 'error') === 'REQUIRED_LOGIN_STUDENT' || get(e, 'error') === 'この操作を行う権限がありません') {
      yield put(setCommonPopup('success', { message: 'この操作を行う権限がありません' }));
      yield put(replace(e.error === 'この操作を行う権限がありません' ? '/waiting-student' : '/'));
    } else {
      yield put(replace('/404-not-found'));
    }
  }
  yield put(hideLoading());
}

export default function* watchEventDetail() {
  yield takeEvery(FETCH_EVENT_DETAIL_REQUEST, getEventDetail);
}
