import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

const serviceURL = 'https://maps.googleapis.com/maps/api/staticmap?';
const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const defLatitude = 35.6471564;
const defLongitude = 139.7097394;

class Map extends Component {

  // <!> restricted by google, static map allow 25Kreq/day, digital signature is reqired prop for more request
  staticMap = (props = {}) => {
    const lat = props.lat || defLatitude;
    const long = props.lng || defLongitude;
    props = {
      center: `${lat},${long}`,
      ...props, 
      key
    };
    const query = queryString.stringify(props);

    return (
      <a href={`http://maps.google.co.jp/maps?q=${lat},${long}`} target="_blank" rel="noopener noreferrer">
        <div className="map-img" style={{backgroundImage: `url("${serviceURL + query}"`}}/>
      </a>
    );
  };

  render() {
    const { eventInfo } = this.props;

    return (
      <Fragment>
        {this.staticMap({
          zoom: 16,
          lat: eventInfo.locationPoint && eventInfo.locationPoint.latitude,
          lng: eventInfo.locationPoint && eventInfo.locationPoint.longitude,
          size: process.env.REACT_APP_GOOGLE_MAP_SIZE || "600x400",
          maptype: "roadmap",
          language: "ja"
        })}
        <Marker
            lat={(eventInfo.locationPoint && eventInfo.locationPoint.latitude) || 35.6471564}
            lng={(eventInfo.locationPoint && eventInfo.locationPoint.longitude) || 139.7097394}
            text={eventInfo.location}
          />
      </Fragment>
    );
  }
}

Map.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
  location: PropTypes.string
};

const Marker = ({ text }) => {
  return (
    <div className="marker-location" ><div className="info">{text}</div><img alt="" src={'./images/marker.png'} /></div>
  );
};

export default Map;
