
export const getUserCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(position => {
      return resolve(position.coords);
    }, err => {
      return reject(err);
    });
  });
};

export const getPage = (pathName, prePathName) => {
  if (pathName) {
    // check if this is home page
    let matches = pathName.match(/^(\/|\/home)$/)
    if (matches) {
      return {
        page_name: 'home'
      }
    }

    // check if this is event list order by date page
    matches = pathName.match(/^\/event$/)
    if (matches) {
      return {
        page_name: 'event_list_by_date'
      }
    }

    // check if this is president list page
    matches = pathName.match(/^\/presidents$/)
    if (matches) {
      return {
        page_name: 'president_list'
      }
    }
    // check if this is president detail page
    matches = pathName.match(/^\/presidentDetail\/([a-zA-Z0-9]+)$/)
    if (matches && matches.length === 2) {
      const prePage = getPage(prePathName)
      return {
        page_name: 'president_detail',
        president_id: matches[1],
        previous_page: prePage.page_name ? `${prePage.page_name}_page` : ''
      }
    }

    // check if this is event list of a president page
    matches = pathName.match(/^\/event-list\/([a-zA-Z0-9]+)$/)
    if (matches && matches.length === 2) {
      return {
        page_name: 'president_events',
        president_id: matches[1]
      }
    }

    // check if this is showing events by tag page
    matches = pathName.match(/^\/tags\/(.+)$/)
    if (matches && matches.length === 2) {
      return {
        page_name: 'search_by_tag',
        tang_name: matches[1]
      }
    }

    // check if this is event detail page
    matches = pathName.match(/^\/event-detail\/([a-zA-Z0-9]+)$/)
    if (matches && matches.length === 2) {
      const prePage = getPage(prePathName)
      return {
        page_name: 'event_detail',
        event_id: matches[1],
        previous_page: prePage.page_name ? `${prePage.page_name}_page` : ''
      }
    }

    // check if this is message page
    matches = pathName.match(/^\/messages.*$/)
    if (matches) {
      return {
        page_name: 'message'
      }
    }

    // check if this is notifications page
    matches = pathName.match(/^\/notifications$/)
    if (matches) {
      return {
        page_name: 'notifications'
      }
    }

    // check if this is management-event page
    matches = pathName.match(/^\/management-event$/)
    if (matches) {
      return {
        page_name: 'president_management_event'
      }
    }

    // check if this is management-student page
    matches = pathName.match(/^\/management-student$/)
    if (matches) {
      return {
        page_name: 'president_management_student'
      }
    }

    // check if this is event history page
    matches = pathName.match(/^\/event-history$/)
    if (matches) {
      return {
        page_name: 'student_management_event'
      }
    }

    // check if this is mypresidents page
    matches = pathName.match(/^\/mypresidents$/)
    if (matches) {
      return {
        page_name: 'student_followed_president'
      }
    }
    

  }
  return {
    page_name: ''
  }

}

export const getEventAndParams = (pathName, prePathName) => {
  const page = getPage(pathName, prePathName)
  const { page_name, ...params } = page
  if(page_name){
    return {
      eventName: `view_${page_name}_page`,
      params
    }
  }
}