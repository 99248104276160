export const FETCH_PRESIDENT_REQUEST = 'FETCH_PRESIDENT_REQUEST';
export const FETCH_PRESIDENT_SUCCESS = 'FETCH_PRESIDENT_SUCCESS';
export const FETCH_PRESIDENT_FAILURE = 'FETCH_PRESIDENT_FAILURE';
export const RESET_PRESIDENT_LIST = 'RESET_PRESIDENT_LIST';
export const TOGGLE_FITLER = 'TOGGLE_FILTER';

export function requestPresident({ page = 1, tags = [] }) {
  return {type: FETCH_PRESIDENT_REQUEST, payload: { page: page, tags: tags }};
}

export function requestMorePresident() {
  return {type: FETCH_PRESIDENT_REQUEST};
}

export function receivePresidents(results, page) {
  return {type: FETCH_PRESIDENT_SUCCESS, results: results, page: page};
}

export function requestPresidentFailure() {
  return {type: FETCH_PRESIDENT_FAILURE};
}

export function resetPresidentList(paload) {
  return {type: RESET_PRESIDENT_LIST, paload};
}

export function toggleFilter({ tag, type }) {
  return {type: TOGGLE_FITLER, payload: { tag, type }};
}