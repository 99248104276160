import { fromJS } from 'immutable';
import {
  FETCH_PRESIDENT_REQUEST,
  FETCH_PRESIDENT_SUCCESS,
  FETCH_PRESIDENT_FAILURE,
  RESET_PRESIDENT_LIST,
  TOGGLE_FITLER
} from './action';
import _ from 'lodash';

const initState = fromJS({
  selectedTags: [],
  list: [],
  page: 1,
  hasMore: true,
  isFirstPageLoading: false
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function getPresidentList(state = initState, action) {
  switch (action.type) {
    case FETCH_PRESIDENT_REQUEST: {
      const { payload } = action;
      if (payload.page === 1) {
        return state.set('isFirstPageLoading', true);
      }
      return state;
    }
    case FETCH_PRESIDENT_SUCCESS: {
      let newState = state.update('list', v => {
        if (action.page > 1) {
          return v.concat(fromJS(action.results));
        } else {
          return fromJS(action.results);
        }
      });
      if (_.isEmpty(action.results)) {
        newState = newState.set('hasMore', false);
      }
      return newState.update('page', v => v + 1).set('isFirstPageLoading', false);
    }
    case FETCH_PRESIDENT_FAILURE: 
      return state.set('isFirstPageLoading', false);
    case RESET_PRESIDENT_LIST: {
      return state.set('list', []).set('page', 1).set('hasMore', true).set('isFirstPageLoading', false);
    }
    case TOGGLE_FITLER: {
      const { payload = {} } = action;
      const selectedTags = state.get('selectedTags');
      if (payload.type === 'add') {
        return state.set('selectedTags', [...selectedTags, payload.tag]);
      }
      if (payload.type === 'remove') {
        return state.set('selectedTags', _.filter(selectedTags, (tag) => tag !== payload.tag));
      }
      return state.set('selectedTags', []);
    }
    default:
      return state;
  }
}
