import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getCurrentNationSelect } from 'utilities/handleValues';
import NumberPhoneVerification from './NumberPhoneVerification';
import OptVerification from './OptVerification';

import './styles.scss';

const Component = ({
  history,
  onCheckPhoneNumber,
  onSubmitOtp,
  isCheckedPhone,
  onSubmitConfirmOtp,
  ...props
}) => {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const handleSubmitPhoneNumber = (value) => {
    setPhoneNumber(value);
    onCheckPhoneNumber && onCheckPhoneNumber(value);
  };

  const currentPhoneNumber = useMemo(() => {
    if (phoneNumber) {
      return `+${getCurrentNationSelect()}${phoneNumber}`;
    }

    return phoneNumber;
  }, [phoneNumber]);

  

  return <div id="sms-page-container">
    <div className="row">
      <div className="back-icon" onClick={history.goBack}>
        <img alt="" src="images/back.svg" /> <span>戻る</span>
      </div>
    </div>

    <section id="left-col-container" className="col-lg-7 col-md-12  col-sm-12 col-xs-12">
      <div id="left-col">
        {
          isCheckedPhone ? 
            <OptVerification 
              currentPhoneNumber={currentPhoneNumber}
              onSubmitOtp={onSubmitOtp}
              onSubmitConfirmOtp={onSubmitConfirmOtp}
              onSubmitPhoneNumber={onCheckPhoneNumber}
            /> : 
            <NumberPhoneVerification 
              {...props}
              onSubmitPhoneNumber={handleSubmitPhoneNumber}
            />
        }
      </div>
      <div className="footer-label">
        <a href="https://careertrip.jp/shachomeshi-agreement/" target="_blank" rel="noopener noreferrer">
            利用規約
        </a>、
        <a href="https://www.unitedwill.jp/privacy/" target="_blank" rel="noopener noreferrer">
            プライバシーポリシー及び個人情報の取り扱い
        </a>
      </div>
    </section>

    <section 
      id="right-col-container" 
      style={{ backgroundImage: "url('images/background-login.png')" }} 
      className="col-lg-5 hidden-md hidden-sm hidden-xs"
    >
      <div id="right-col" >
        <img alt="" src="images/logo-login.svg" className="logo" />
        <p className="meshi-slogan">
              就活の場を<br />「デスク」から「テーブル」へ
        </p>
      </div>
    </section>
  </div>;
};

Component.propTypes = {
  history: PropTypes.object,
  valid: PropTypes.bool,
  onCheckPhoneNumber: PropTypes.func,
  onSubmitOtp: PropTypes.func,
  isCheckedPhone: PropTypes.bool,
  onSubmitConfirmOtp: PropTypes.func
};

export default Component;
