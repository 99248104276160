import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';

import { request } from 'utilities/Api';
import {
  FETCH_NOTIFICATION_SETTING_REQUEST,
  fetchNotificationSettingSuccess,
  UPDATE_NOTIFICATION_SETTING_REQUEST,
  updateNotificationSettingSuccess,
  fetchNotificationSettingRequest
} from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get } from 'lodash';
import { toast } from 'react-toastify';

function* fetchNotificationSetting({ meta }) {
  try {
    yield put(showLoading());
    const { result } = yield call(request, 'functions/notificationSettingOfStudent', {}, 'POST');
    yield put(fetchNotificationSettingSuccess(result, meta));
  } catch (e) {
    const errorMessage = get(e, 'error');
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }
  yield put(hideLoading());
}

function* updateNotificationSetting({ payload, meta }) {
  try {
    yield put(showLoading());
    const { result } = yield call(request, 'functions/studentUpdateNotificationSetting', payload, 'POST');
    yield put(updateNotificationSettingSuccess(result, meta));
    yield put(fetchNotificationSettingRequest());
  } catch (e) {
    const errorMessage = get(e, 'error');
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }
  yield put(hideLoading());
}

export default function* watchNotificationSetting() {
  yield takeEvery(FETCH_NOTIFICATION_SETTING_REQUEST, fetchNotificationSetting);
  yield takeLatest(UPDATE_NOTIFICATION_SETTING_REQUEST, updateNotificationSetting);

}
