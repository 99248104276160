import React, { Component } from 'react';
import './style.css';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class MenuRight extends Component {
  targetElement = null;

  componentDidMount() {
    this.targetElement = document.querySelector(`.menu-right#${this.props.id}`);
  }

  showTargetElement = () => {
    disableBodyScroll(this.targetElement);
  };

  hideTargetElement = () => {
    enableBodyScroll(this.targetElement);
  };

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  shouldComponentUpdate = (nextProps) => {
    return true;
  }

  componentDidUpdate(prevProps) {
    const selector = this.props.scrollableArea ? `.menu-right ${this.props.scrollableArea}` : `.menu-right#${this.props.id}`;
    this.targetElement = document.querySelector(selector);

    if (prevProps.active !== this.props.active) {
      if (this.props.active) {
        this.showTargetElement();
      } else {
        this.hideTargetElement();
      }
    }
  }

  render() {
    const children = React.Children.map(this.props.children, (child => {
      return React.cloneElement(child, {action: this.props.action});
    }));
    return (
      <div className={this.props.active === 1 ? 'menu-right active-menu' : 'menu-right'} id={this.props.id}>
        <div className="menu-right-content">
          {children}
        </div>
        <div className="menu-overlay" onClick={() => this.props.action(0)}></div>
      </div>
    );
  }
}

MenuRight.propTypes = {
  active: PropTypes.number,
  action: PropTypes.func,
  children: PropTypes.any,
  id: PropTypes.string.isRequired,
  scrollableArea: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    storeWatch: state.get(ownProps.storeWatch)
  };
};

const mapDispatchToProps = () => ({
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(MenuRight);