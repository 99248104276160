import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setCommonPopup } from './../Popup/actions';

class PrivateLink extends Component {

  constructor(props) {
    super(props);
    this.handleAction = this.handleAction.bind(this);
  }

  handleAction() {
    const currentUser = this.props.currentUser;
    if (
      currentUser &&
      currentUser.role === 'PRESIDENT' &&
      (!currentUser.approvalStatus || currentUser.approvalStatus !== 'ACCEPTED')) {
      this.props.setPopupNotice(true);
    } else {
      if (this.props.to) {
        this.props.history.push(this.props.to);
      }
      if (this.props.link) {
        this.props.history.push(this.props.link);
      }
      this.props.handleAction();
    }
  }

  render() {
    if (this.props.displayBlock) {
      return (
        <div id={this.props.id} className={this.props.className} onClick={this.handleAction}>
          {this.props.children}
        </div>
      );
    }

    return (
      <span id={this.props.id} className={this.props.className} onClick={this.handleAction}>
        {this.props.children}
      </span>
    );
  }
}

PrivateLink.propTypes = {
  handleAction: PropTypes.func,
  currentUser: PropTypes.any,
  children: PropTypes.any,
  to: PropTypes.any,
  link: PropTypes.any,
  history: PropTypes.object,
  setPopupNotice: PropTypes.func,
  displayBlock: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string

};

PrivateLink.defaultProps = {
  link: '',
  to: '',
  handleAction: () => null,
  displayBlock: false
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data')
  };
};
const mapDispatchToProps = dispatch => ({
  setPopupNotice: (open) => dispatch(setCommonPopup('notice', open))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(PrivateLink);
