import { fromJS } from 'immutable';
import _ from 'lodash';
import { OPEN_CUSTOM_MODAL, CLOSE_CUSTOM_MODAL } from './actions';

const initialState = fromJS({
  activeModal: ''
});

export default function customModal(state = initialState, action) {
  switch (action.type) {
    case OPEN_CUSTOM_MODAL:
      return state.set('activeModal', _.get(action, 'payload.key'));
    case CLOSE_CUSTOM_MODAL:
      return state.set('activeModal', '');
    default:
      return state;
  }
}