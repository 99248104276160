import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { setCommonPopup } from './../Popup/actions';
import { getValueByKey } from './../Popup/selectors';
import { getValueByKey as getValueByKeyResource } from '../Resources/selectors';
import { getResources } from '../Resources/actions';
import { studentSurvey } from './actions';
//Component
import Answer from '../../components/Inputs/Answer';
import { Input } from 'components/Form';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';
//Style
import './style.scss';
import validate from './validate';
import _ from 'lodash';
import { getVerifyPopupPayload } from 'utilities/handleValues';

const selector = formValueSelector('StudentSurvey');

class StudentSurveyForm extends Component {

  constructor(props) {
    super(props);
    this.showExtraField = this.showExtraField.bind(this);
    this.state = { extraField: false };
  }

  showExtraField(value) {
    this.setState({
      extraField: value
    });
    if (!value) {
      this.props.change('extraAnswer', '');
    }
  }
  
  render() {
    const { answerList, answer, currentCampaign, showVerifyPopup, currentUser } = this.props;
    const { extraField } = this.state;
    const campaignId = _.get(currentCampaign, 'objectId');

    return (
      <Modal
        backdrop="static"
        id="student-survey-container"
        show={this.props.show}
        onHide={() => this.props.setPopupWelcome(false)}
      >
        <Modal.Header>
          <Modal.Title >
            おめでとうございます。<br />
            登録が完了しました。
            <img alt="" src="./images/Quit.svg" onClick={() => {
              this.props.setPopupWelcome(false);
              showVerifyPopup({ ...getVerifyPopupPayload({ email: currentUser.email })});
            }} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.props.handleSubmit}>
            <div className="field-container">
              {campaignId && <Field
                name="inviteCode"
                type="text"
                label="お友達紹介コードをお持ちの方"
                maxLength={6}
                customForm={true}
                component={Input}
                normalize={(value) => value && value.toUpperCase()}
              />}
            </div>
            <div className="field-container">
              <Field
                component={Answer}
                name="answer"
                label="社長メシをどこで知りましたか？"
                showExtraField={this.showExtraField}
                getResources={this.props.getResources}
                answerList={answerList}
                customForm={true}
                answer={answer}
              />
            </div>
            {extraField ? (
              <div className="field-container">
                <Field
                  name="extraAnswer"
                  type="text"
                  label={answerList.find((ans) => ans.objectId === answer).referenceFieldLabel}
                  maxLength={15}
                  customForm={true}
                  component={Input}
                />
              </div>
            ) : null}
            
            <div className="action-container">
              <Button type="submit" className="btn-submit btn-active"> OK </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

StudentSurveyForm.propTypes = {
  show: PropTypes.bool,
  setPopupWelcome: PropTypes.func,
  getResources: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  answerList: PropTypes.array,
  answer: PropTypes.string,
  currentCampaign: PropTypes.object,
  showVerifyPopup: PropTypes.func,
  currentUser: PropTypes.object
};

StudentSurveyForm.defaultProps = {
  setPopupWelcome: () => null,
  show: false
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'studentSurvey'),
    answerList: getValueByKeyResource(state, 'answersforstudent'),
    answer: selector(state, 'answer'),
    currentCampaign: state.get('campaign').get('data'),
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  setPopupWelcome: (open) => dispatch(setCommonPopup('studentSurvey', open)),
  showVerifyPopup: (payload) => dispatch(setCommonPopup('confirm', payload)),
  getResources: () => dispatch(getResources(['AnswersForStudent'])),
  submitSurvey: (values) => dispatch(studentSurvey(values))
});


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'StudentSurvey', validate }),
  withImmutablePropsToJS,
)(StudentSurveyForm);


