import { request } from './../../utilities/Api';
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { 
  requestMessageListSuccess, 
  requestMessageListFail,
  FETCH_MESSAGE_LIST_REQUEST, 
  receiveUnreadMessageNumber,
  FETCH_UNREAD_MESSAGE_NUMBER_REQUEST,
  receiveEventDetailForNewConversation,
  FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_REQUEST
} from './actions';
import get from 'lodash/get';
import { takeFirst } from 'utilities/supportSaga';
import { replace } from 'connected-react-router';

function* getMessageList(action) {

  let { page, meta } = action;
  try {
    const hasMore = yield select(state => state.get('message').get('hasMore'));
    if (!hasMore) {
      return;
    }
    if (!page) {
      page = yield select(state => state.get('message').get('page'));
    }
    const data = yield call(request, 'functions/getNotificationForMessageTab', {
      limit: 10,
      page: page
    }, 'POST');
    yield put(requestMessageListSuccess(data.result, page, meta));
  } catch (error) {
    yield put(requestMessageListFail({}, meta));
    if (error.code === 9001) {
      yield put(replace('/'));
    }
    
  }
}

export function* watchMessageList() {
  yield takeFirst(FETCH_MESSAGE_LIST_REQUEST, getMessageList);
}

function* getUnreadMessageNumber() {
  try {
    const data = yield call(request, 'functions/countUnreadNotificationOfMessageTab', {}, 'POST');
    yield put(receiveUnreadMessageNumber(data.result));
  } catch (error) {
    console.log(error);
  }
}

export function* watchUnreadMessageNumber() {
  yield takeEvery(FETCH_UNREAD_MESSAGE_NUMBER_REQUEST, getUnreadMessageNumber);
}

function* getEventDetailForNewConversation(action) {
  try {
    const data = yield call(request, 'functions/getEventDetailForMessage', { eventId: action.eventId }, 'POST');
    const { result = {} } = data;
    if (!get(result, 'offerEvent', false)) {
      result.jImage = result.jImage || result.image;
    }
    result.eventTitle = result.eventTitle || result.name;
    yield put(receiveEventDetailForNewConversation(result));
  } catch (error) {
    if (error.code === 104) {
      yield put(replace('/'));
    }
  }
}

export function* watchEventDetailForNewConversation() {
  yield takeLatest(FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_REQUEST, getEventDetailForNewConversation);
}

export function* watchIconMessageForNewMessageCome() {
  yield takeLatest('NEW_MESSAGE_COME', getUnreadMessageNumber);
}