import './styles.scss';
import React from 'react';
import { Grid, Row, Col, Glyphicon } from 'react-bootstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const Plan = ({ plans }) => (
  <div id="plans-container">
    <Grid>
      <Row>
        {plans.map((item) => {
          if (item.status !== 'ACTIVE') return null;
          return (
            <Col xs={12} md={6} key={item._id} className="normal-plan">
              <p className="title-plan">{item.name}</p>
              <p>
                <Glyphicon glyph="ok" />
                {item.used}/{item.total}
              </p>
              <p>
                {moment(item.startDate.iso).format('YYYY.MM.DD')}~
                {moment(item.endDate.iso).format('YYYY.MM.DD')}
              </p>
            </Col>
          );
        })}
      </Row>
    </Grid>
  </div>
);

Plan.propTypes = {
  plans: PropTypes.array
};

export default Plan;