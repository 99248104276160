import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import TagsField from 'components/Form/TagsField';
import { Input, Textarea } from 'components/Form';
import ProfilePhoto from 'components/Form/ProfilePhoto';
import ImageCropForm from 'components/ImageCropForm';
import { readURL, dataURItoBlob, uploadAvatar } from 'utilities/file';
import { request } from 'utilities/Api';
import validate from './validate';
import './style.scss';
import PopupMedia from './PopupMedia';
import SocialMedia from '../SocialMedia';

class EditMyPagePresident extends Component {
  constructor(props) {
    super(props);
    const companyLogo = props.companyLogo && props.companyLogo.thumbSmall;
    this.state = {
      opacity: 1,
      startDate: new Date(props.initialValues.get('birthday')),
      imageHeight: 0,
      imageWidth: 0,
      backgroundImage: companyLogo || '',
      companyLogo: '',
      isOpen: false,
      cropImage: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    let newState = {};
    if (nextProps.initialValues.get('birthday')) {
      newState.startDate = new Date(nextProps.initialValues.get('birthday'));
    }
    this.setState(newState);
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  }

  handleCompleted = async(imageUrl) => {
    // close modal crop
    this.handleClose();
    // commit file to parent
    const { uploadFile } = this.props;
    const blob = dataURItoBlob(imageUrl);
    const imgSize = blob.size / 1024 / 1024;
    if (imgSize > parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE_MB || 20)) {
      this.props.handleOpenAlertPopup();
      return;
    }
    // Hide avatar
    this.setState({ opacity: 0 });
    const companyLogo = await uploadFile(blob);
    // set companyLogo to input
    // set backgroundImage
    this.setState({ backgroundImage: `${imageUrl}`, companyLogo }, () => {
      setTimeout(() => this.setState({ opacity: 1 }), 650);
    });
    this.props.change('presidentProfile.companyLogo', companyLogo);
  }

  handleSelectedFile = async(e) => {
    const files = e.target.files;
    if (files && files[0]) {
      if (!files[0].type.startsWith('image')) {
        return;
      }
      
      try {
        // read file as dataURL
        const imageDataURL = await readURL(files[0]);
        this.setState({ cropImage: `${imageDataURL}` });
        // open crop image popup
        this.handleOpen();
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  handleDeleteSocialMedia = index => {
    let socialMedias = [...this.props.mediaArticles];
    socialMedias.splice(index, 1);
    this.props.change('mediaArticles', socialMedias);
  }

  handleDeleteImage = id => {
    let mediaImages = this.props.mediaImages.filter(img => img.objectId !== id);
    this.props.change('mediaImages', mediaImages);
  }

  handleSelectedImage = async(e) => {
    const files = e.target.files;
    if (files && files[0]) {
      if (!files[0].type.startsWith('image')) {
        return;
      }
      try {
        this.props.showLoading();
        const imageDataURL = await readURL(files[0]);
        const blob = dataURItoBlob(imageDataURL);
        const imgSize = blob.size / 1024 / 1024;
        if (imgSize > parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE_MB || 20)) this.props.handleOpenAlertPopup();
        if (imgSize <= parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE_MB || 20)) {
          const img = await this.handleUploadImage(blob);
          let mediaImages = [...this.props.mediaImages];
          mediaImages.push(img);
          this.props.change('mediaImages', mediaImages);
        }
        this.props.hideLoading();
      } catch (e) {
        console.log(e.message);
        this.props.hideLoading();
      }
    }
  }

  async handleUploadImage(file) {
    try {
      const image = await uploadAvatar(file);
      const img = await request('classes/Image', {
        file: { ...image, '__type': 'File' },
        type: 'MEDIAIMAGE',
        name: image.name
      }, 'POST');
      return img || null;
    } catch (e) {
      return null;
    }
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    const companyLogo = this.state.companyLogo || (this.props.companyLogo && this.props.companyLogo.objectId) || '';
    const socialMediaModifier = this.props.mediaArticles.length < 5 ? '' : '--disabled';
    const imageModifier = this.props.mediaImages.length < 5 ? '' : '--disabled';

    return (
      <React.Fragment>
        <Modal backdrop='static' keyboard={false} show={this.state.isOpen} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Crop Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <ImageCropForm
                onCompleted={this.handleCompleted}
                src={this.state.cropImage} />
            </div>
          </Modal.Body>
        </Modal>
        <PopupMedia />
        <form onSubmit={handleSubmit} className="form-horizontal edit-profile edit-my-page-president">
          <div style={{maxWidth: '570px', marginLeft: 'auto', marginRight: 'auto'}}>
            <Field
              name="photos"
              component={ProfilePhoto} />
          </div>

          <div className="field-wrapper mt-2">
            <Field
              name="presidentProfile[slogan]"
              type="text"
              label="社長キャッチコピー"
              maxLength={100}
              placeholder="社長キャッチコピー"
              component={Input}
              customForm={true}
            />
          </div>

          <div className="field-wrapper">
            <Field
              name="tags"
              type="text"
              label="タグ"
              placeholder="タグ"
              component={TagsField}
              tag={this.props.presidentTag}
              maxSelectable={6}
              customForm={true}
              showNote={true}
            />
          </div>

          <div className="field-wrapper h-144">
            <Field
              name="presidentProfile[introduction]"
              type="text"
              label="自己紹介"
              placeholder="自己紹介"
              component={Textarea}
              customForm={true}
            />
          </div>

          <div className="field-wrapper mt-2">
            {/* <!-- Email --> */}
            <div className="field-container">
              <Field
                disabled={!!this.props.currentUser.email}
                name="email"
                type="email"
                label="メールアドレス"
                placeholder="メールアドレス"
                component={Input}
                customForm={true}
                required={true}
                withEditEmail
              />
            </div>
          </div>

          <div className="preview-social-media-container">
            <div>Web記事/Youtube動画</div>
            <div className="preview-social-media-description">Web記事やYoutube動画を追加することができます</div>
            <div 
              className={`preview-social-media-add-btn${socialMediaModifier}`} 
              onClick={() => socialMediaModifier === '' && this.props.openMediaPopup(-1)}
            >
              <img 
                alt="" 
                src={`/images/${socialMediaModifier === '' ? 'add-upload-image.svg' : 'add-btn-disabled.png'}`} 
              />
              <span>リンクを追加</span>
            </div>
            {
              this.props.mediaArticles.map((socialMedia, i) => (
                <SocialMedia 
                  key={i} 
                  index={i} 
                  socialMedia={socialMedia} 
                  openMediaPopup={this.props.openMediaPopup} 
                  handleDeleteSocialMedia={this.handleDeleteSocialMedia} 
                  preview={true}
                />
              ))
            }
          </div>

          <div className="preview-img-container">
            <div>画像（雑誌記事など）</div>
            <label htmlFor="img" className={`preview-img-label${imageModifier}`}>
              <img 
                alt="" 
                src={`/images/${imageModifier === '' ? 'add-upload-image.svg' : 'add-btn-disabled.png'}`} 
              />
              <span>画像を追加</span>
            </label>
            {imageModifier === '' && (
              <input id="img" type='file' accept=".png, .jpg, .jpeg" onChange={this.handleSelectedImage} />
            )}
            <div className="preview-img-list row">
              {
                this.props.mediaImages.map(img => (
                  <div key={img.objectId} className="preview-img-cell col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <img alt="" src={img.file ? img.file.url : img.thumbSmall} />
                    <div className="preview-img-delete-btn" onClick={() => this.handleDeleteImage(img.objectId)}>
                      <img alt="" src="/images/remove-upload-image.svg" />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="field-wrapper">
            <Field
              name="presidentProfile[otherUrl]"
              type="text"
              label="ブログ"
              maxLength={100}
              placeholder="ブログ"
              component={Input}
              customForm={true}
            />
          </div>

          <div className="field-wrapper">
            <Field
              name="presidentProfile[companyName]"
              type="text"
              label="会社名"
              maxLength={30}
              placeholder="会社名"Input
              component={Input}
              customForm={true}
            />
          </div>

          <div className="company-logo">
            <div className="company-logo__title">企業ロゴ</div>
            <div className="avatar-wrapper">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type='file'
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={e => this.handleSelectedFile(e)} />
                  <label htmlFor="imageUpload"></label>
                </div>
                <div className="avatar-preview">
                  <input type="hidden" name="presidentProfile[companyLogo]" value={companyLogo} />
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: `url(${this.state.backgroundImage})`,
                      opacity: this.state.opacity, transition: 'opacity 1s'
                    }}>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field-wrapper">
            <Field
              name="presidentProfile[companyUrl]"
              type="text"
              label="会社URL"
              placeholder="会社URL"
              component={Input}
              customForm={true}
            />
          </div>
          
          <div className="field-wrapper h-144">
            <Field
              name="presidentProfile[companyIntroduction]"
              type="text"
              label="会社紹介"
              placeholder="会社紹介"
              component={Textarea}
              customForm={true}
            />
          </div>

          {/* Error */}
          {/* <Field
            name=""
            error={error}
            component={Errors} /> */}

          {/* <!-- Button --> */}
          <div className="btn-submit-wrapper">
            <Button className="btn-submit" disabled={submitting} name="submit" type="submit">
              保存
            </Button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

EditMyPagePresident.propTypes = {
  currentUser: PropTypes.object,
  initialValues: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  uploadFile: PropTypes.func,
  openMediaPopup: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  handleOpenAlertPopup: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.any,
  companyLogo: PropTypes.any,
  tagsResource: PropTypes.array,
  presidentTag: PropTypes.array,
  mediaImages: PropTypes.array,
  mediaArticles: PropTypes.array
};

export default reduxForm({
  form: 'StudentProfileForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(EditMyPagePresident);
