import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { getValueByKey } from 'containers/Popup/selectors';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setCommonPopup } from 'containers/Popup/actions';
import './style.scss';
import { chooseStudent } from './actions';

class PopupChooseStudent extends Component {
  render() {
    const { eventId, userId, type } = this.props.show;

    return (
      <Modal show={!!this.props.show} onHide={this.props.closePopupChooseStudent} className="confirm-popup">
        <div className="text-center message">
          {
            type === 'accept' && 'この学生の応募リクエストを承認しますか？'
          }
          {
            type === 'deny' && 'この学生の応募リクエストを拒否しますか？'
          }
        </div>
        <div>
          <button onClick={this.props.closePopupChooseStudent}>いいえ</button>
          <button onClick={() => {
            this.props.chooseStudent(eventId, userId, type);
            this.props.closePopupChooseStudent();
          }}>はい</button>
        </div>
      </Modal>
    );
  }
}

PopupChooseStudent.propTypes = {
  show: PropTypes.any,
  closePopupChooseStudent: PropTypes.func,
  chooseStudent: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    show: getValueByKey(state, 'chooseStudent')
  };
};

const mapDispatchToProps = (dispatch) => ({
  chooseStudent: (eventId, userId, actionType) => dispatch(chooseStudent(eventId, userId, actionType)),
  closePopupChooseStudent: () => dispatch(setCommonPopup('chooseStudent', false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupChooseStudent);
