import { fromJS } from 'immutable';
import { CHANGE_EMAIL_SUCCESS, RESET_EMAIL_CHANGE, CHANGE_EMAIL_FAILED, IN_CHANGE_EMAIL_REQUEST } from './action';

const initialState = fromJS({
  changeEmailSuccess: false,
  inChangeEmailRequest: false
});

const EmailChange = (state = initialState, { type }) => {
  switch (type) {
    case CHANGE_EMAIL_SUCCESS:
      return state.set('changeEmailSuccess', true).set('inChangeEmailRequest', false);
    case CHANGE_EMAIL_FAILED:
      return state.set('changeEmailSuccess', false).set('inChangeEmailRequest', false);  
    case IN_CHANGE_EMAIL_REQUEST:
      return state.set('inChangeEmailRequest', true);  
    case RESET_EMAIL_CHANGE:
      return initialState;
    default:
      return state;
  }
};

export default EmailChange;