import { fromJS } from 'immutable';
import { FETCH_NOTIFICATION_SETTING_REQUEST, FETCH_NOTIFICATION_SETTING_SUCCESS } from './actions';

const initState = fromJS({
  isLoading: true,
  data: {}
});

export default function notificationSetting(state = initState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATION_SETTING_REQUEST:
      return state.set('isLoading', true);
    case FETCH_NOTIFICATION_SETTING_SUCCESS:
      return state.set('isLoading', false).set('data', action.payload);
    default:
      return state;
  }
}