import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { requestUserInfo } from '../../../PresidentDetail2/action';
import StudentProfile from '../../../MyPage/StudentProfile';
import _ from 'lodash';

class PopupViewStudentProfileContent extends Component {
  componentDidMount() {
    const { index, list } = this.props;
    const studentId = list[index];

    if (!this.props.data[studentId]) {
      this.props.getStudentProfile(studentId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { list = [], index, data } = this.props;
    const nextIndex = nextProps.index;
    const studentId = list[index];
    const nextStudentId = list[nextIndex];

    if (!_.isEqual(this.props.eventInfo, nextProps.eventInfo)) {
      const requestedList = nextProps.eventInfo.requestedUsers.map(requestedUser => requestedUser.objectId);
      const length = requestedList.length;

      if (length === 0) {
        this.props.closePopupViewStudentProfile();
      } else {
        const requestedIndex = index === length ? 0 : index;
        const requestedUserId = requestedList[requestedIndex];

        if (!data[requestedUserId]) {
          this.props.getStudentProfile(requestedUserId);
        }

        this.props.openPopupViewStudentProfile(requestedIndex, requestedList, this.props.status);
      }
    }

    if (!data[nextStudentId] && studentId !== nextStudentId && !_.isEmpty(nextProps.list)) {
      this.props.getStudentProfile(nextStudentId);
    }
  }

  render() {
    const { data, eventInfo, index, list = [] } = this.props;
    const studentId = list[index];

    if (!data[studentId]) return null;

    return (
      /**
       * @property {string} eventId Avoid condition in StudentProfile component
       * @property {bool} isInPopup Move reason next to avatar in popup toward Requested Student & Denied Student
       */
      <StudentProfile 
        studentDetail={data[studentId]} 
        objectId={studentId} 
        eventId={eventInfo.objectId} 
        eventInfo={eventInfo} 
        isInPopup={true}
      />
    );
  }
}

PopupViewStudentProfileContent.propTypes = {
  index: PropTypes.number,
  status: PropTypes.string,
  list: PropTypes.array,
  data: PropTypes.object,
  eventInfo: PropTypes.object,
  getStudentProfile: PropTypes.func,
  openPopupViewStudentProfile: PropTypes.func,
  closePopupViewStudentProfile: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    eventInfo: state.get('eventDetail').get('eventInfo')
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentProfile: studentId => dispatch(requestUserInfo(studentId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PopupViewStudentProfileContent));