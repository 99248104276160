const msChange = {
  msPerMin: 60000,
  msPerHour: 3600000,
  msPerDay: 86400000,
  msPerWeek: 604800000
}; //millisecond to others time format

const monthFormat = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]; //array to map MM=>MMM

//const japAgo = { mins: '分前', hours: '時間前', days: '日前' };

//time=Math(..) => return mins or hours or days between posted time and current time
export const timeDisplay = (time = new Date()) => {
  const timePost = new Date(time);
  const timeDif = Date.now() - timePost.getTime();
  if (timeDif < msChange.msPerMin) {
    return 'Now';
  }
  
  if (msChange.msPerMin <= timeDif && timeDif < msChange.msPerHour) {
    const time = Math.floor(timeDif / msChange.msPerMin); //calculate minutes away from posted time
    return `${time} ${time <= 1 ? 'min' : 'mins'}`;
  }
  
  if (msChange.msPerHour <= timeDif && timeDif < msChange.msPerDay) {
    const time = Math.floor(timeDif / msChange.msPerHour); //calculate hours away from posted time
    return `${time} ${time <= 1 ? 'hour' : 'hours'}`;
  }
  
  if (msChange.msPerDay <= timeDif && timeDif < msChange.msPerWeek) {
    const time = Math.floor(timeDif / msChange.msPerDay); //calculate days away from posted time
    return `${time} ${time <= 1 ? 'day' : 'days'}`;
  }
  
  if (timeDif >= msChange.msPerWeek) {
    const timePostDate = timePost.getDate();
    return `${monthFormat[timePost.getMonth()]} ${
      timePostDate > 9 ? timePostDate : `0${timePostDate}`
    }`;
  }
};
