import { request } from './Api';
import EXIF from 'exif-js';
import _ from 'lodash';

export function readURL(file) {
  return new Promise((resolve, reject) => {
    EXIF.getData(file, function() {
      try {
        var orientation = EXIF.getTag(this, 'Orientation');
        var can = document.createElement('canvas');
        var ctx = can.getContext('2d');
        var thisImage = new Image();

        thisImage.onload = function() {
          can.width = thisImage.width;
          can.height = thisImage.height;
          ctx.save();
          var width = can.width; var styleWidth = can.style.width;
          var height = can.height; var styleHeight = can.style.height;
          if (orientation) {
            if (orientation > 4) {
              can.width = height; can.style.width = styleHeight;
              can.height = width; can.style.height = styleWidth;
            }
            switch (orientation) {
              case 2: ctx.translate(width, 0); ctx.scale(-1, 1); break;
              case 3: ctx.translate(width, height); ctx.rotate(Math.PI); break;
              case 4: ctx.translate(0, height); ctx.scale(1, -1); break;
              case 5: ctx.rotate(0.5 * Math.PI); ctx.scale(1, -1); break;
              case 6: ctx.rotate(0.5 * Math.PI); ctx.translate(0, -height); break;
              case 7: ctx.rotate(0.5 * Math.PI); ctx.translate(width, -height); ctx.scale(-1, 1); break;
              case 8: ctx.rotate(-0.5 * Math.PI); ctx.translate(-width, 0); break;
              default: break;
            }
          }
    
          ctx.drawImage(thisImage, 0, 0);
          ctx.restore();
          var dataURL = can.toDataURL();

          resolve(dataURL);
        };
      } catch (err) {
        reject(err);
      }

      thisImage.src = URL.createObjectURL(file);
    });
  });
}

export async function blobUrlToBlob(blobUrl) {
  return fetch(blobUrl)
    .then((res) => res.blob());
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export async function uploadAvatar(file) {
  if (file) {
    try {
      return await request('files/ar.jpeg', file, 'POST', {
        headers: { 'Content-Type': file.type }
      });
    } catch (e) {
      console.log(e.message);
      return null;
    }
  }
  return null;
}

export function generateCSVStringFromJSON(jsonDatas = [], structure = {}) {
  let header = '';
  for (let key in structure) {
    header += `"${key}",`;
  }
  header += '\r\n';

  let csv = jsonDatas.map((row) => {
    let rowString = '';
    for (let key in structure) {
      let dataPath = structure[key];
      let data = null;

      if (typeof dataPath === 'object') {
        let path = dataPath.path;
        data = _.get(row, path);
        data = dataPath.format ? dataPath.format(data) : data;
      } else { //is string
        data = _.get(row, dataPath);
      }

      rowString += `"${data || ''}",`;
    }
    return rowString;
  });
  csv = csv.join('\r\n');

  return header + csv;
}