/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class TextField extends Component {
  state = {
    showPass: false
  }
  toggleEye = () => {
    this.setState({showPass: !this.state.showPass});
  }
  showEye() {
    if (this.props.showEye && this.props.type === 'password') {
      if (this.state.showPass) {
        return (
          <img src="images/show-password.svg" alt="" style={{position: 'absolute', right: 16, top: 16}} onClick={this.toggleEye}/>
        );
      } else {
        return (
          <img src="images/hide-password.svg" alt="" style={{position: 'absolute', right: 16, top: 16}} onClick={this.toggleEye}/>
        );
      }
      
    }
  } 
  render() {
    let { input, placeholder, type, meta: {touched, error}, maxLength, className, autocomplete, showEye, onChange } = this.props;
    if (showEye && this.state.showPass && type === 'password') {
      type = 'text';
    }
    return (
      <div style={{position: 'relative'}} className={`textfield-form-container ${!this.props.label && 'custom-input-no-label'} ${touched && error && 'error-form'}`}>
        { this.props.label ? (<span className="subtitle">{this.props.label}</span>) : null }
        {this.showEye()}
        <input 
          {...input} 
          placeholder={placeholder}
          type={type}
          className={className}
          maxLength={maxLength}
          autoComplete={autocomplete}
          {...(onChange && {
            onChange: onChange
          })}
        />
        {/*       {touched && ((error && <span className="text-danger">{error}</span>)) } */}
      </div>
    );
  }
}

TextField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.any,
  input: PropTypes.any,
  maxLength: PropTypes.any,
  onChange: PropTypes.func
};
 
export default TextField;



