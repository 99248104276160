import React, { Component, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';

import ElementRenderer from './ElementRenderer';
import { isValidDate, formatDate } from 'utilities/date';

import moment from 'moment';

class Datepicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: props.selected
    }
  }
  handleChange = (date) => {
    date = isValidDate(date) ? formatDate(date, this.props.dateFormat || 'YYYY-MM-DD') : '';
    this.props.input.onChange(date);
  }
  componentWillReceiveProps(newProps) {
    let newState = {};
    if (newProps.input && newProps.input.value) {
      newState.startDate = moment(newProps.input.value, this.props.dateFormat).toDate();
    }
    this.setState(newState);
  }
  componentDidMount() {
    const a = document.querySelectorAll(".react-datepicker__input-container input.date-picker");
    a.forEach(element => {
      //element.setAttribute('onKeyDown', 'return false');
      element.setAttribute('readOnly', '');
    });
  }
  
  render() {
    // console.log(this.props.meta.error, this.props.meta.touched);
    return (
      <Fragment>
        {!this.props.customForm ? (
          <ElementRenderer
            {...this.props}
            render={({ input, dateFormat }) => (
              <DatePicker
                className="form-control input-md"
                selected={this.state.startDate}
                dateFormat={this.props.dateFormat || "YYYY-MM-DD"}
                onChange={this.handleChange}
                style={{ fontSize: '5em' }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            )}
          />
        ) : (
          <div className={`custom-datepicker-container ${!this.props.label && 'custom-input-no-label'} ${this.props.meta.error && 'error-form'} `}>
              {/* {console.log(this.props)} */}
              { this.props.label ? (<span className="subtitle">{this.props.label}</span>) : null }
              <DatePicker
                className="form-control input-form input-lg date-picker"
                selected={this.state.startDate}
                closeOnSelect={true}
                disabledNavigation={true}
                dateFormat={this.props.dateFormat || "YYYY-MM-DD"}
                onChange={this.handleChange}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                popperPlacement="bottom-start"
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"] // don't allow it to flip to be above
                  }
                }}
                value={this.props.input && this.props.input.value}
                {...this.props}
                filterDate={(date) => {
                  if (this.props.birthdayType)
                    return moment() > date;
                  else
                    return date;
              }}
              />
              
              {this.props.meta.error &&
                <p className="help-block text-danger">
                  {this.props.meta.error}
                </p>
              }
            </div>
          )}
      </Fragment>
    );
  }
}

Datepicker.propTypes = {
  input: PropTypes.object.isRequired,
  dateFormat: PropTypes.string,
  onChanged: PropTypes.func,
  selected: PropTypes.object,
  defaultForm: PropTypes.bool
};

export default Datepicker;