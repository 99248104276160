import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import './style.scss';

const BannerImage = ({
  banner,
  type
}) => {
  return isEmpty(banner[type]) ? <></> : (
    <div className='banner-image' style={{
      background: `url(${banner[type]})`
    }} />
  );
};

BannerImage.propTypes = {
  banner: PropTypes.object,
  type: PropTypes.string
};

const CampaignBanner = ({
  list,
  type,
  imageCustomClass
}) => {
  if (isEmpty(list)) return <></>;
  return (
    <div className={`campaign-banner-container${imageCustomClass ? ` ${imageCustomClass}` : ''}`}>
      {list.map((banner) => {
        return (
          <BannerImage key={banner.objectId} banner={banner} type={type} />
        );
      })}
    </div>
  );
};

CampaignBanner.propTypes = {
  list: PropTypes.array,
  type: PropTypes.string,
  imageCustomClass: PropTypes.string
};

export default CampaignBanner;
