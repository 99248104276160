import React, {Component} from 'react';
import {connect} from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { setCommonPopup } from './../Popup/actions';
import { getValueByKey } from './../Popup/selectors';

class WelcomePresident extends Component {

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.props.setPopupWelcome(false)}>
        <Modal.Header closeButton>
          <Modal.Title >
            アカウント申請完了
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          仮アカウントが発行されました。3営業日以内にご登録のメールアドレスまたは電話番号へご連絡いたします。
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.setPopupWelcome(false)} className="btn btn-warning"> ok </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

WelcomePresident.propTypes = {
  show: PropTypes.bool,
  setPopupWelcome: PropTypes.func
};

WelcomePresident.defaultProps = {
  setPopupWelcome: () => null,
  show: false
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'welcome')
  };
};

const mapDispatchToProps = dispatch => ({
  setPopupWelcome: (open) => dispatch(setCommonPopup('welcome', open))
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withImmutablePropsToJS(WelcomePresident));
