export const __INIT_LIVE_ADAPTER_SUCCESS = '__INIT_LIVE_ADAPTER_SUCCESS';
export const __INIT_LIVE_ADAPTER_FAIL = '__INIT_LIVE_ADAPTER_FAIL';
export function initLiveAdapterSuccess() {
  return { type: __INIT_LIVE_ADAPTER_SUCCESS };
}
export function initLiveAdapterFail(err) {
  return { type: __INIT_LIVE_ADAPTER_FAIL, err };
}

export const __DESTROY_LIVE_ADAPTER_SUCCESS = '__DESTROY_LIVE_ADAPTER_SUCCESS';
export const __DESTROY_LIVE_ADAPTER_FAIL = '__DESTROY_LIVE_ADAPTER_FAIL';
export function destroyLiveAdapterSuccess() {
  return { type: __DESTROY_LIVE_ADAPTER_SUCCESS };
}
export function destroyLiveAdapterFail(err) {
  return { type: __DESTROY_LIVE_ADAPTER_FAIL, err };
}