import { request } from 'utilities/Api';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { STUDENT_SURVEY_REQUEST } from './actions';
import { setCommonPopup } from 'containers/Popup/actions';
import { stopSubmit } from 'redux-form';
import { get } from 'lodash';
import { getVerifyPopupPayload } from 'utilities/handleValues';

function* handleStudentSurvey(action) {
  try {
    yield call(request, 'functions/studentAnswerHowQuestion', action.values, 'POST');
    yield put(setCommonPopup('studentSurvey', false));
    try {
      const currentUser = yield select((state) =>
        state
          .get('account')
          .toJS()
      );
      yield put(setCommonPopup('confirm', getVerifyPopupPayload({ email: get(currentUser, 'data.email') })));
    } catch (error) {
      console.error('error', error);
    }

    try {
      let newSignUpStudent = JSON.parse(window.localStorage.getItem('newSignUpStudent')) || [];
      const currentUser = window.localStorage.getItem('account').data;

      newSignUpStudent = newSignUpStudent.filter((element) => element !== currentUser.objectId);
  
      window.localStorage.setItem('newSignUpStudent', JSON.stringify(newSignUpStudent));
    } catch (err) {
      window.localStorage.removeItem('newSignUpStudent');
    }

  } catch (e) {
    const errorMessage = get(e, 'error');
    if (errorMessage) {
      toast.error(errorMessage);
    }
    yield put(stopSubmit('StudentSurvey', { _error: e.error }));
  }
}

export function* watchStudentSurvey() {
  yield takeEvery(STUDENT_SURVEY_REQUEST, handleStudentSurvey);
}

