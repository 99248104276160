export const FETCH_USER_INFO_REQUEST = 'FETCH_USER_INFO_REQUEST';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAILURE = 'FETCH_USER_INFO_FAILURE';
export const REMOVE_USER_INFO = 'REMOVE_USER_INFO';
export const UPDATE_FOLLOW_STATUS = 'UPDATE_FOLLOW_STATUS';

export function requestUserInfo(userId, fromInternalLink, currentPage) {
  return {type: FETCH_USER_INFO_REQUEST, userId, fromInternalLink, currentPage};
}

export function receiveUserInfo(infoUser) {
  return {type: FETCH_USER_INFO_SUCCESS, infoUser: infoUser};
}

export function removeUserInfo() {
  return {type: REMOVE_USER_INFO};
}