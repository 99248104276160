import { fromJS } from 'immutable';

const initState = fromJS({
  count: {value: 0},
  dependence: {value: 1}
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function increment(state = initState, action) {
  switch (action.type) {
    case 'INCREMENT':
      return state.updateIn(['count', 'value'], v => v + 1);
    default:
      return state;
  }
}
