export const COMMON_LOGIN_REQUEST = 'CommonAuth/CCOMMON_LOGIN_REQUEST';
export const COMMON_LOGIN_SUCCESS = 'CommonAuth/CCOMMON_LOGIN_SUCCESS';
export const COMMON_LOGIN_FAILURE = 'CommonAuth/CCOMMON_LOGIN_FAILURE';

export const commonLoginRequest = (payload) => ({
  type: COMMON_LOGIN_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const commonLoginSuccess = (payload, meta) => ({
  type: COMMON_LOGIN_SUCCESS,
  payload,
  meta
});
export const commonLoginFailure = (payload, meta) => ({
  type: COMMON_LOGIN_FAILURE,
  payload,
  meta,
  error: true
});
