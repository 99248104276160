import React, {Component} from 'react';
import {connect} from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { getValueByKey } from './../Popup/selectors';
import { setCommonPopup } from './../Popup/actions';
import { setMenu } from './../Header/actions';

class WelcomePresident extends Component {

  constructor(props) {
    super(props);
    this.handleAction = this.handleAction.bind(this);
  }

  handleAction() {
    this.props.setPopupNotice(false);
    this.props.setPopupLogout(true);
    this.props.setMenu(false);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.props.setPopupNotice(false)}>
        <Modal.Header closeButton>
          <Modal.Title >
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          この機能はアカウント申請受付完了後に利用可能となります。
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.setPopupNotice(false)} className="btn btn-warning"> ok </Button>
          <Button onClick={this.handleAction} className="btn btn-warning"> ログアウト </Button>
          <a className="btn btn-default" href="https://shachomeshi.com/company/form/">運営へ連絡する</a>
        </Modal.Footer>
      </Modal>
    );
  }
}

WelcomePresident.propTypes = {
  show: PropTypes.bool,
  setPopupNotice: PropTypes.func,
  setPopupLogout: PropTypes.func,
  setMenu: PropTypes.func
};

WelcomePresident.defaultProps = {
  setPopupNotice: () => null,
  show: false
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'notice')
  };
};

const mapDispatchToProps = dispatch => ({
  setPopupNotice: (open) => dispatch(setCommonPopup('notice', open)),
  setPopupLogout: (open) => dispatch(setCommonPopup('logout', open)),
  setMenu: (open) => dispatch(setMenu('mainMenu', open))
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withImmutablePropsToJS(WelcomePresident));
