import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { LOCK_EVENT_REQUEST } from './actions';
import { resetEventDetail, requestEventDetail } from 'containers/EventDetail/actions';

function* lockRequest(action) {
  try {
    const { eventId } = action.payload;
    yield call(request, 'functions/lockEvent', { eventId }, 'POST');
    yield put(requestEventDetail(eventId));
  } catch (e) {
    console.log(e.message);
    yield put(resetEventDetail());
  }
}

export default function* watchLockEvent() {
  yield takeEvery(LOCK_EVENT_REQUEST, lockRequest);
}