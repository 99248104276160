import { fromJS } from 'immutable';
import {
  FETCH_OFFER_TEMPLATE_REQUEST,
  FETCH_OFFER_TEMPLATE_SUCCESS,
  FETCH_OFFER_TEMPLATE_FAILURE,
  REMOVE_OFFER_TEMPLATE_REQUEST,
  REMOVE_OFFER_TEMPLATE_SUCCESS,
  REMOVE_OFFER_TEMPLATE_FAILURE
} from './actions';

const initState = fromJS({
  templateList: []
});

export default function offerTemplate(state = initState, action) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_OFFER_TEMPLATE_REQUEST:
      return state;
    case FETCH_OFFER_TEMPLATE_SUCCESS:
      return state.set('templateList', payload.data, []);
    case FETCH_OFFER_TEMPLATE_FAILURE:
      return initState;
    case REMOVE_OFFER_TEMPLATE_REQUEST:
      return state;
    case REMOVE_OFFER_TEMPLATE_SUCCESS:
      return state;
    case REMOVE_OFFER_TEMPLATE_FAILURE:
      return initState;
    default:
      return state;
  }
}