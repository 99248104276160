import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_EVENT_REQUEST, updateEventFailure
} from './action';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { replace } from 'connected-react-router';

function* handleUpdateEvent(action) {
  try {
    yield put(showLoading());
    const eventInfoData = action.eventData || {};
    const eventId = action.eventId || '';
    eventInfoData.graduateYear = eventInfoData.graduateYear && eventInfoData.graduateYear.objectId;
    eventInfoData.image = eventInfoData.image && eventInfoData.image.objectId;
    eventInfoData.prefecture = eventInfoData.prefecture && eventInfoData.prefecture.objectId;
    const data = yield call(
      request,
      'functions/updateEvent',
      {...eventInfoData, eventId},
      'POST'
    );
    if (data.statusCode === 200) {
      yield put(replace(`/event-detail/${eventId}`));
    }
    yield put(hideLoading());
  } catch (e) {
    console.log('fail');
    if (e) {
      yield put(updateEventFailure(e));
      yield put(hideLoading());
    }
  }
}

export function* watchUpdateEvent() {
  yield takeEvery(UPDATE_EVENT_REQUEST, handleUpdateEvent);
}
