import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import { readURL, dataURItoBlob, uploadAvatar } from 'utilities/file';
import { request } from 'utilities/Api';
import PropTypes from 'prop-types';
import { setCommonPopup } from 'containers/Popup/actions';

class MessageFileInput extends Component {
  handleSelectedFile = async(e) => {
    const files = e.target.files;
    if (files && files[0]) {
      if (!files[0].type.startsWith('image')) {
        return;
      }
      try {
        const imageDataURL = await readURL(files[0]);
        const blob = dataURItoBlob(imageDataURL);
        const imgSize = blob.size / 1024 / 1024;
        if (imgSize > parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE_MB || 20)) {
          this.handleOpenAlertPopup();
          return;
        }
        const avatar = await this.handleUploadFile(blob);
        this.props.sendImage(avatar.file);
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  async handleUploadFile(file) {
    try {
      const image = await uploadAvatar(file);
      const avatar = await request('classes/Image', {
        file: { ...image, '__type': 'File' },
        type: 'MESSAGE',
        name: image.name
      }, 'POST');
      return avatar || null;
    } catch (e) {
      return null;
    }
  }

  handleOpenAlertPopup = () => {
    this.props.openAlertPopup({
      message: 'ファイルが大きすぎます。', 
      OKcallback: () => this.props.closeAlertPopup(),
      onHideCallback: () => this.props.closeAlertPopup()
    });
  }

  render() {
    return (
      <div id="msg-file-inp">
        <label htmlFor="img"><img alt="" src="/images/image.svg" /></label>
        <input id="img" type='file' accept=".png, .jpg, .jpeg" onChange={e => this.handleSelectedFile(e)} />
      </div>
    );
  }
}

MessageFileInput.propTypes = {
  openAlertPopup: PropTypes.func,
  closeAlertPopup: PropTypes.func,
  sendImage: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  openAlertPopup: show => dispatch(setCommonPopup('success', show)),
  closeAlertPopup: () => dispatch(setCommonPopup('success', false))
});

export default connect(
  null,
  mapDispatchToProps
)(MessageFileInput);