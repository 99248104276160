export const STUDENT_COMPLETE_PROFILE = 'STUDENT_COMPLETE_PROFILE';
export const STUDENT_COMPLETE_PROFILE_SUCCESS = 'STUDENT_COMPLETE_PROFILE_SUCCESS';
export const STUDENT_COMPLETE_PROFILE_FAIL = 'STUDENT_COMPLETE_PROFILE_FAIL';
export const STUDENT_VERIFY_EMAIL = 'STUDENT_VERIFY_EMAIL';
export const STUDENT_VERIFY_EMAIL_SUCCESS = 'STUDENT_VERIFY_EMAIL_SUCCESS';
export const STUDENT_VERIFY_EMAIL_FAIL = 'STUDENT_VERIFY_EMAIL_FAIL';
export const STUDENT_RESEND_VERIFY_EMAIL = 'STUDENT_RESEND_VERIFY_EMAIL';
export const STUDENT_RESEND_VERIFY_EMAIL_SUCCESS = 'STUDENT_RESEND_VERIFY_EMAIL_SUCCESS';
export const STUDENT_RESEND_VERIFY_EMAIL_FAIL = 'STUDENT_RESEND_VERIFY_EMAIL_FAIL';

export function studentCompleteProfile(values, sessionToken, message) {
  return {
    type: STUDENT_COMPLETE_PROFILE,
    values,
    sessionToken,
    message,
    meta: {
      thunk: true
    }
  };
}

export function studentCompleteProfileSuccess(meta, data = {}) {
  return {
    type: STUDENT_COMPLETE_PROFILE_SUCCESS,
    data,
    meta
  };
}

export function studentCompleteProfileFail(meta, data = {}) {
  return {
    type: STUDENT_COMPLETE_PROFILE_FAIL,
    data,
    meta,
    error: true
  };
}

export function studentVerifyEmail(payload) {
  return {
    type: STUDENT_VERIFY_EMAIL,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function studentVerifyEmailSuccess(payload, meta) {
  return {
    type: STUDENT_VERIFY_EMAIL_SUCCESS,
    payload,
    meta
  };
}


export function studentVerifyEmailFail(error, meta) {
  return {
    type: STUDENT_VERIFY_EMAIL_FAIL,
    error,
    meta
  };
}

export function studentResendEmail(payload) {
  return {
    type: STUDENT_RESEND_VERIFY_EMAIL,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function studentResendEmailSuccess(payload, meta) {
  return {
    type: STUDENT_RESEND_VERIFY_EMAIL_SUCCESS,
    payload,
    meta
  };
}

export function studentResendEmailFail(error, meta) {
  return {
    type: STUDENT_RESEND_VERIFY_EMAIL_FAIL,
    error: true,
    meta,
    payload: error
  };
}