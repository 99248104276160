import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
/**
 * useCountDown: Countdown time
 * @param {*} value: string => seconds
 * @returns string =>  format '00:00:00', '00:00', '00'
 */
const useCountDown = (value) => {
  const rootValue = cloneDeep(value);
  const hRoot = rootValue >= 3600,
    mRoot = rootValue >= 60,
    sRoot = rootValue;
  const [timeLeft, setTimeLeft] = useState(value);
  const [pause, setPause] = useState(false);

  const secondsToTime = (e) => {
    if (e) {
      const h = Math.floor(e / 3600).toString().padStart(2, '0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(e % 60).toString().padStart(2, '0');
        
      return [
        hRoot && h,
        mRoot && m,
        sRoot && s
      ].filter(Boolean).join(':');
    } else {
      return [
        hRoot && '00',
        mRoot && '00',
        sRoot && '00'
      ].filter(Boolean).join(':');
    }
  };

  // Handle Re-start again with init countdown time
  const handleReStart = () => {
    if (!timeLeft && value) setTimeLeft(value);
  };

  // Handle Pause countdown time
  const handlePause = () => setPause(true);

  // Handle Play countdown time
  const handlePlay = () => setPause(false);

  useEffect(() => {
    if (!pause) {
      if (timeLeft === 0) {
        setTimeLeft(null);
      }
  
      if (!timeLeft) return;
      
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    
      return () => clearInterval(intervalId);
    }
  }, [timeLeft, pause]);

  return {
    value: secondsToTime(timeLeft),
    reStart: handleReStart,
    pause: handlePause,
    play: handlePlay
  };
};
export default useCountDown;