import { fromJS } from 'immutable';
import { GET_PLANS_REQUEST_SUCCESS } from './actions';

const initState = fromJS({
  list: [],
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function commonPopup(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_PLANS_REQUEST_SUCCESS:
      return state.set('list', payload);
    default:
      return state;
  }
}
