import axios from 'axios';
import { unsetCurrentAccount } from 'containers/Account/actions';
import { setCommonPopup } from 'containers/Popup/actions';
/**
 *
 * @param params
 * @param prefix
 */
function serializeQuery(params, prefix) {
  const query = Object.keys(params).map((key) => {
    const value = params[key];

    if (params.constructor === Array)
      key = `${prefix}[]`;
    else if (params.constructor === Object)
      key = (prefix ? `${prefix}[${key}]` : key);

    if (typeof value === 'object')
      return serializeQuery(value, key);
    else
      return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join('&');
}

/**
 *
 * @returns {AxiosInstance}
 */
function getApi() {
  let token = localStorage.getItem('account');
  token = JSON.parse(token);
  token = token ? token.token || '' : '';

  let headers = {};

  if (process.env.REACT_APP_API_APPLICATION_ID) {
    headers['X-Parse-Application-Id'] = process.env.REACT_APP_API_APPLICATION_ID;
  }

  if (process.env.REACT_APP_API_REST_KEY) {
    headers['X-Parse-REST-API-Key'] = process.env.REACT_APP_API_REST_KEY;
  }

  if (token) {
    headers['X-Parse-Session-Token'] = token;
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
    headers: headers
  });
  // Add a response interceptor
  instance.interceptors.response.use(function(response) {
    // Do something with response data
    // console.log('brefore response:', response);
    if ([200, 201].includes(response.status)) {
      const result = response.data;
      result.statusCode = response.status;
      return response.data;
    }
    return Promise.reject(response);
  }, function(error) {
    // Do something with response error
    console.log('brefore response error:', error.response.statusText);
    if (error.response.data.code) {
      if (error.response.data.code === 209) {
        window.store.dispatch(setCommonPopup('success', {
          message: 'セッションが切れました', 
          OKcallback: () => window.store.dispatch(unsetCurrentAccount()),
          onHideCallback: () => window.store.dispatch(unsetCurrentAccount()),
          btnLabel: 'はい'
        }));
      }

      return Promise.reject(error.response.data);
    } else {
      return Promise.reject({ code: 1, error: error.response.statusText });
    }
  });

  return instance;
}


export async function request(url, data = {}, method = 'GET', config = {}) {
  try {
    const api = getApi();
    switch (method) {
      case 'POST':
        return await api.post(url, data, config);
      case 'PUT':
        return await api.put(url, data, config);
      default:
        return await api.get(url + '?' + serializeQuery(data), config);
    }
  } catch (error) {
    return Promise.reject(error);
  }

}
