export const OPEN_REQUIRE_PHONE_MODAL = 'OPEN_REQUIRE_PHONE_MODAL';
export const CLOSE_REQUIRE_PHONE_MODAL = 'CLOSE_REQUIRE_PHONE_MODAL';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';


export const openRequirePhoneModal = () => ({
  type: OPEN_REQUIRE_PHONE_MODAL
});

export const closeRequirePhoneModal = () => ({
  type: CLOSE_REQUIRE_PHONE_MODAL
});

export const updateProfileSuccess = () => ({
  type: UPDATE_PROFILE_SUCCESS
});
