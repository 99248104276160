import { request } from 'utilities/Api';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { STUDENT_COMPLETE_PROFILE,
  studentCompleteProfileSuccess,
  studentCompleteProfileFail,
  STUDENT_RESEND_VERIFY_EMAIL,
  STUDENT_VERIFY_EMAIL,
  studentResendEmailFail,
  studentResendEmailSuccess,
  studentVerifyEmailFail,
  studentVerifyEmailSuccess} from './actions';
import { getCurrentAccount } from './../Account/actions';
import { stopSubmit } from 'redux-form';
import { toast } from 'react-toastify';
import { takeFirst } from 'utilities/supportSaga';
import { setCommonPopup } from 'containers/Popup/actions';

function* handleStudentCompleteProfile(action) {
  const { meta, message } = action; 
  try {
    if (action.isRequestJoinEvent) {
      yield call(request, 'functions/updateUserInformation', action.values, 'POST', {
        headers: { 'X-Parse-Session-Token': action.sessionToken }
      });
      //yield put(getCurrentAccount(action.sessionToken, 'STUDENT_COMPLETE_PROFILE'));
    } else {
      yield call(request, 'functions/updateUserInformation', action.values, 'POST', {
        headers: { 'X-Parse-Session-Token': action.sessionToken }
      });
      yield put(getCurrentAccount(action.sessionToken, message || 'STUDENT_COMPLETE_PROFILE'));
      yield put(studentCompleteProfileSuccess(meta));

    }
  } catch (e) {
    yield put(stopSubmit('StudentProfileForm', { _error: e.error }));
    yield put(studentCompleteProfileFail(meta));
  }
}

function* handleResendVerifyEmail(action) {
  const { meta, payload } = action;
  try {
    const data = yield call(request, 'functions/resendVerifyEmail', null, 'POST');
    if (payload && payload.showSuccessToast) {
      toast.success('メールが正常に送信されました。');
    }
    yield put(studentResendEmailSuccess(data.result, meta));
  } catch (e) {
    if (e && e.error) {
      yield put(setCommonPopup('success', { message: e.error, btnLabel: '確認' }));
    }
    if (e && e.code && e.code === 9005) {
      const currentUser = yield select((state) =>
        state
          .get('account')
          .toJS()
      );
      if (currentUser) {
        yield put(getCurrentAccount(currentUser.token));
      }
    }
    yield put(studentResendEmailFail(e, meta));
  }
}

function* handleVerifyEmail(action) {
  const { meta, payload } = action;
  try {
    const data = yield call(request, 'functions/verifyEmail', payload, 'POST');
    yield put(studentVerifyEmailSuccess(data, meta));
    yield put(setCommonPopup('success', { message: 'メールアドレス認証が完了しました', btnLabel: '確認' }));
    const currentUser = yield select((state) =>
      state
        .get('account')
        .toJS()
    );
    if (currentUser) {
      yield put(getCurrentAccount(currentUser.token));
    }
  } catch (error) {
    if (error && error.error) {
      yield put(setCommonPopup('success', { 
        message: error.error, 
        btnLabel: '確認', 
        title: error && error.code && error.code === 9004 ? 'メールアドレスが未認証です' : undefined }));
    }
    yield put(studentVerifyEmailFail(error, meta));
  }
  
}

export default function* watchStudentCompleteProfile() {
  yield takeEvery(STUDENT_COMPLETE_PROFILE, handleStudentCompleteProfile);
  yield takeFirst(STUDENT_RESEND_VERIFY_EMAIL, handleResendVerifyEmail);
  yield takeEvery(STUDENT_VERIFY_EMAIL, handleVerifyEmail);
}

 