import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import listensToClickOutside from 'react-onclickoutside';

class Popover extends Component {
  handleClickOutside = (event) => {
    this.props.clickOutSide(event);
  }

  render() {
    return (
      <div className="custom-popover">
        <div className="arrow" />
        <div className="cover-box" />
        <div className="header">{this.props.header}</div>
        <hr />
        {this.props.children}
      </div>
    );
  }
}

Popover.propTypes = {
  children: PropTypes.any,
  header: PropTypes.string,
  clickOutSide: PropTypes.func
};

Popover.defaultProps = {
  clickOutSide: () => {}
}

export default listensToClickOutside(Popover);