export const REQUEST_GET_EVENTS_BY_STATUS = 'REQUEST_GET_EVENTS_BY_STATUS';
export const REQUEST_GET_EVENTS_BY_STATUS_SUCCESS = 'REQUEST_GET_EVENTS_BY_STATUS_SUCCESS';
export const RESET_EVENTS_BY_STATUS = 'RESET_EVENTS_BY_STATUS';
export const requestGetEventsByStatus = (status) => {
  return {type: REQUEST_GET_EVENTS_BY_STATUS, status};
};
export function receiveGetEventsByStatus(payload, status) {
  return {type: REQUEST_GET_EVENTS_BY_STATUS_SUCCESS, payload, status};
}
export function resetEventsByStatus() {
  return {type: RESET_EVENTS_BY_STATUS};
}

export const GET_PRESIDENT_EVENTS_REQUEST = 'GET_PRESIDENT_EVENTS_REQUEST';
export const GET_PRESIDENT_EVENTS_SUCCESS = 'GET_PRESIDENT_EVENTS_SUCCESS';
export const GET_PRESIDENT_EVENTS_FAILURE = 'GET_PRESIDENT_EVENTS_FAILURE';

export const getPresidentEventsRequest = (payload) => ({
  type: GET_PRESIDENT_EVENTS_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});

export const getPresidentEventsSuccess = (payload, meta) => ({
  type: GET_PRESIDENT_EVENTS_SUCCESS,
  payload,
  meta
});


export const getPresidentEventsFailure = (payload, meta) => ({
  type: GET_PRESIDENT_EVENTS_FAILURE,
  payload,
  meta,
  error: true
});