import { request } from './../../utilities/Api';
import { put, call } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { 
  FETCH_WAITING_STUDENT_OFFER_EVENT_REQUEST,
  RESET_WAITING_STUDENT_OFFER_EVENT,
  failToGetWaitingStudentOfferEvents,
  resetWaitingStudentOfferEventList,
  receiveWaitingStudentOfferEvents
} from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* getWaitingStudentOfferEvents(action) {
  try {
    yield put(showLoading());
    const data = yield call(request, 'functions/getEventList', action.param, 'POST');
    yield put(receiveWaitingStudentOfferEvents(data.result.eventList));
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    yield put(failToGetWaitingStudentOfferEvents(error));
  }
}

export default function* watchWaitingStudentEvents() {
  yield takeFirst(RESET_WAITING_STUDENT_OFFER_EVENT, resetWaitingStudentOfferEventList);
  yield takeFirst(FETCH_WAITING_STUDENT_OFFER_EVENT_REQUEST, getWaitingStudentOfferEvents);
}