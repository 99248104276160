import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImageCropForm from 'components/ImageCropForm';
import { readURL, dataURItoBlob, uploadAvatar } from 'utilities/file';
import { } from 'utilities/file';
import { request } from 'utilities/Api';
import { setCommonPopup } from 'containers/Popup/actions';
import { Modal } from 'react-bootstrap';
import './style.css';

const loaderURL = 'images/loader.gif';
class PhotoSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      imageForCrop: null,
      loading: false
    };
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }
  handleOpen = () => {
    this.setState({ isOpen: true });
  }

  handleSelectedFile = async(e) => {
    const files = e.target.files;
    if (files && files[0]) {
      if (!files[0].type.startsWith('image')) {
        return;
      }
      try {
        const imageDataURL = await readURL(files[0]);
        this.setState({ imageForCrop: `${imageDataURL}` });
        this.handleOpen();
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  handleCompleted = async(imageUrl) => {
    this.handleClose();
    this.setState({ loading: true });
    const blob = dataURItoBlob(imageUrl);
    const imgSize = blob.size / 1024 / 1024;
    if (imgSize > parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE_MB || 20)) {
      this.handleOpenAlertPopup();
      this.setState({ loading: false });
      return;
    }
    try {
      const avatar = await this.handleUploadFile(blob);
      this.props.addItem({ imageUrl: avatar.file.url, imageId: avatar.objectId });
      this.setState({ loading: false });
    } catch (e) {
      console.log(e.message);
    }
  }

  async handleUploadFile(file) {
    try {
      const image = await uploadAvatar(file);
      const avatar = await request('classes/Image', {
        file: { ...image, '__type': 'File' },
        type: 'AVATAR',
        name: image.name
      }, 'POST');
      return avatar || null;
    } catch (e) {
      return null;
    }
  }

  handleOpenAlertPopup = () => {
    this.props.openAlertPopup({
      message: 'ファイルが大きすぎます。',
      OKcallback: () => this.props.closeAlertPopup(),
      onHideCallback: () => this.props.closeAlertPopup()
    });
  }

  render() {
    let fileTrigger = null;
    let cornerButton = null;
    let cornerBtnAction = null;
    let background = 'none';

    let inputId = 'imageUploadMyPage';

    if (this.props.imageUrl && this.props.imageId) {
      // remove mode
      cornerBtnAction = this.props.removeItem;
      cornerButton =
        <div className="corner-btn" onClick={cornerBtnAction}>
          <img alt="" src="/images/remove-upload-image.svg" />
        </div>;
      background = "url('" + this.props.imageUrl + "')";
    } else {
      // upload mode
      cornerButton =
        <div className="corner-btn" onClick={cornerBtnAction}>
          <img alt="" src="/images/add-upload-image.svg" />
        </div>;
      fileTrigger = <label htmlFor={inputId}></label>;
      background = this.state.loading ? "url('" + loaderURL + "')" : 'none';
    }

    return (
      <div className="photo-selector square" style={{ backgroundImage: background }}>
        {cornerButton}
        {fileTrigger}
        <input
          type='file'
          id={inputId}
          accept=".png, .jpg, .jpeg"
          onChange={e => this.handleSelectedFile(e)} />


        <Modal backdrop='static' keyboard={false} show={this.state.isOpen} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Crop Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {
                this.state.imageForCrop ?
                  <ImageCropForm
                    onCompleted={this.handleCompleted}
                    src={this.state.imageForCrop}
                    freeSelect={true}
                  />
                  :
                  <div></div>
              }
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

PhotoSelector.propTypes = {
  imageId: PropTypes.any,
  imageUrl: PropTypes.string,
  addItem: PropTypes.func,
  openAlertPopup: PropTypes.func,
  closeAlertPopup: PropTypes.func,
  removeItem: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  openAlertPopup: show => dispatch(setCommonPopup('success', show)),
  closeAlertPopup: () => dispatch(setCommonPopup('success', false))
});

export default connect(
  null,
  mapDispatchToProps
)(PhotoSelector);