import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import StarRating from 'components/Form/StarRating';

import './styles.scss';

const renderStar = numberOfStars => {
  let starArr = [];
  for (let i = 0; i < numberOfStars; i++) {
    starArr.push(
      <div 
        key={i} 
        className='star' />
    );
  }
  return starArr;
};

const PresidentCell = props => {
  const { presidentInfo, votePresident, isPreview } = props;
  return (
    <div className='president-cell'>
      <div className='avatar-container'>
        <img 
          src={presidentInfo.avatarImage ? presidentInfo.avatarImage.thumbSmall : '/images/default-avatar.png'} 
          alt='avatar'
          draggable='false'
        />
      </div>
      <div className='president-content'>
        <div className='name'>{presidentInfo.displayName}</div>
        <div className='star-container'>
          { isPreview ? 
            renderStar(votePresident) :
            <Field 
              name='votePresident' 
              component={StarRating} 
              isPresidentReview={true}
              size='small'
            />
          }
          <div className='rating'>{votePresident > 0 ? votePresident : 0}</div>
        </div>
        { !isPreview && <div className='rating-note mt-2'>★をタップ</div> }
      </div> 
    </div>
  );
};

PresidentCell.propTypes = {
  presidentInfo: PropTypes.object,
  votePresident: PropTypes.number,
  isPreview: PropTypes.bool
};

export default PresidentCell;
