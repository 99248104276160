import { fromJS } from 'immutable';
import {
  FETCH_EVENT_HISTORY_SUCCESS,
  FETCH_EVENT_HISTORY_BY_STATUS_SUCCESS,
  REMOVE_EVENT_HISTORY,
  GET_OFFER_EVENTS_BY_STATUS_SUCCESS
} from './actions';

const initState = fromJS({
  activeEvents: fromJS({
    list: [],
    page: 1,
    hasMore: true
  }),
  lockedEvents: fromJS({
    list: [],
    page: 1,
    hasMore: true
  }),
  requestedEvents: fromJS({
    list: [],
    page: 1,
    hasMore: true
  }),
  finishedEvents: fromJS({
    list: [],
    page: 1,
    hasMore: true
  }),
  canceledEvents: fromJS({
    list: [],
    page: 1,
    hasMore: true
  }),
  pendingOffers: [],
  acceptOffers: [],
  rejectOffers: []
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function eventsByDate(state = initState, action) {
  switch (action.type) {
    case FETCH_EVENT_HISTORY_SUCCESS: {
      const eventGroups = action.eventGroups || {};
      let newState = state.set('activeEvents', eventGroups.activeEvents);
      newState = newState.set('lockedEvents', eventGroups.lockedEvents);
      newState = newState.set('requestedEvents', eventGroups.requestedEvents);
      newState = newState.set('finishedEvents', eventGroups.finishedEvents);
      newState = newState.set('canceledEvents', eventGroups.canceledEvents);
      return newState;
    }
    case FETCH_EVENT_HISTORY_BY_STATUS_SUCCESS: {
      const { status } = action;
      const eventList = action.eventList || [];
      const hasMore =
        eventList.length < (process.env.REACT_APP_EVENT_HISTORY_PERPAGE || 5)
          ? false
          : true;
      let newState = state;
      if (status === 'ACTIVE') {
        const page = state.get('activeEvents').get('page');
        const newList =
          page <= 1
            ? eventList
            : state
              .get('activeEvents')
              .get('list')
              .concat(eventList);
        let jsState = {
          page: page + 1,
          list: newList,
          hasMore
        };
        newState = newState.set('activeEvents', fromJS(jsState));
      }
      if (status === 'LOCKED') {
        const page = state.get('lockedEvents').get('page');
        const newList =
          page <= 1
            ? eventList
            : state
              .get('lockedEvents')
              .get('list')
              .concat(eventList);
        let jsState = {
          page: page + 1,
          list: newList,
          hasMore
        };
        newState = newState.set('lockedEvents', fromJS(jsState));
      }
      if (status === 'FINISHED') {
        const page = state.get('finishedEvents').get('page');
        const newList =
          page <= 1
            ? eventList
            : state
              .get('finishedEvents')
              .get('list')
              .concat(eventList);
        let jsState = {
          page: page + 1,
          list: newList,
          hasMore
        };
        newState = newState.set('finishedEvents', fromJS(jsState));
      }
      if (status === 'CANCELED') {
        const page = state.get('canceledEvents').get('page');
        const newList =
          page <= 1
            ? eventList
            : state
              .get('canceledEvents')
              .get('list')
              .concat(eventList);
        let jsState = {
          page: page + 1,
          list: newList,
          hasMore
        };
        newState = newState.set('canceledEvents', fromJS(jsState));
      }
      if (status === 'REQUESTED') {
        const page = state.get('requestedEvents').get('page');
        const newList =
          page <= 1
            ? eventList
            : state
              .get('requestedEvents')
              .get('list')
              .concat(eventList);
        let jsState = {
          page: page + 1,
          list: newList,
          hasMore
        };
        newState = newState.set('requestedEvents', fromJS(jsState));
      }
      return newState;
    }
    case GET_OFFER_EVENTS_BY_STATUS_SUCCESS: {
      const { list = [], status } = action.payload;
      if (status === 'PENDING') {
        return state.set('pendingOffers', list);
      } 
      if (status === 'ACCEPT') {
        return state.set('acceptOffers', list);
      }
      if (status === 'REJECT') {
        return state.set('rejectOffers', list);
      }
      return state;
    }
    case REMOVE_EVENT_HISTORY:
      return initState;
    default:
      return state;
  }
}
