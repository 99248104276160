import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import _get from 'lodash/get';
import { ROLE, OFFER_EVENT_STATUS_KEYS } from '../../utilities/constants';
import { useDispatch } from 'react-redux';
import { getOfferEventDetailRequest } from 'containers/OfferEventRequestModal/actions';

const OfferEventItem = ({ role, data, location }) => {
  const dispatch = useDispatch();
  const bgImg = _.get(data, 'jCreatedBy.avatarImage.thumbSmall', '');
  const background = {
    backgroundImage: `linear-gradient(to top, #000000, rgba(50, 50, 50, 0.3) 40%, rgba(0, 0, 0, 0)),  url(${bgImg})`
  };
  const avatarStudent = _.get(
    data,
    'studentRequests[0].avatarImage.thumbSmall',
    'images/default-profile.png'
  );
  const avatar = _.get(
    data,
    'jCreatedBy.avatarImage.thumbSmall',
    'images/default-profile.png'
  );
  const url =
    role === ROLE.PRESIDENT
      ? `/studentDetail/${_.get(data, 'studentRequests[0].objectId', '')}`
      : `/presidentDetail/${_.get(data, 'jCreatedBy.objectId', '')}`;
  const isAccepedRequest = [
    OFFER_EVENT_STATUS_KEYS.ACCEPTED_REQUEST,
    OFFER_EVENT_STATUS_KEYS.EXAMINING_CANDIDATE,
    OFFER_EVENT_STATUS_KEYS.REJECTED_CANDIDATE,
    OFFER_EVENT_STATUS_KEYS.APPOINTED
  ].includes(data.offerEventStatus);

  const handleOnClick = (e) => {
    if (data.offerEventStatus === OFFER_EVENT_STATUS_KEYS.WAITTING_FOR_ANSWER) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(getOfferEventDetailRequest({ 
        eventId: data.objectId, reference: role === ROLE.STUDENT ? 'offer_management' : undefined
      }));
    }
  };

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 detail-col">
      <div className="event-detail">
        <Link
          to={
            isAccepedRequest
              ? {
                pathname: `/messages/${data.objectId}`,
                state: {
                  eventId: data.objectId
                }
              }
              : `/event-detail/${data.objectId}`
          }
          onClick={handleOnClick}
          className="event-banner"
        >
          <div className="event-background" style={background} />
          <div className="graduate-title-container">
            {!location.pathname.includes('waiting-student') && (
              <p className="graduate-title">
                {_get(data, 'studentRequests[0].graduationYear', '')}
              </p>
            )}
          </div>
          <div className="info-container">
            <div className="info">
              <span className="day"></span>
              <div style={{ clear: 'both' }}></div>
              <span className="time"></span>
            </div>
          </div>
        </Link>
        <div className="description">
          <Link
            to={
              isAccepedRequest
                ? {
                  pathname: `/messages/${data.objectId}`,
                  state: {
                    eventId: data.objectId
                  }
                }
                : `/event-detail/${data.objectId}`
            }
            title="Show more"
            onClick={handleOnClick}
          >
            <img alt="" src="images/next-icon.svg" className="detail-icon" />
            <div className="ellipsises" style={{ color: '#333' }}>
              <div>{_get(data, 'name', '')}</div>
            </div>
          </Link>
          <hr />
          <Link to={url} className="user-info">
            <img
              alt="社長メシ"
              src={role === ROLE.PRESIDENT ? avatarStudent : avatar}
            />
            <div className="user-col">
              <p className="username auto-trim">
                {role === ROLE.STUDENT
                  ? _get(data, 'jCreatedBy.displayName', '')
                  : data.offerEventStatus === 'ACCEPTED_REQUEST'
                    ? _get(data, 'studentRequests[0].displayName', '')
                    : ''}
              </p>
              <p className="status auto-trim">
                {role === ROLE.STUDENT
                  ? _get(data, 'jCreatedBy.presidentProfile.companyName', '')
                  : _get(
                    data,
                    'studentRequests[0].studentProfile.university',
                    ''
                  )}
              </p>
            </div>
          </Link>
          {!!location.pathname.includes('waiting-student') &&
            role === ROLE.PRESIDENT && (
            <p className="graduate-title">
              {_get(data, 'studentRequests[0].graduationYear', '')} 年卒
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

OfferEventItem.propTypes = {
  role: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withRouter(OfferEventItem);
