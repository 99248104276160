import { fromJS } from 'immutable';
import { FETCH_EVENT_BY_DATE_SUCCESS, RESET_EVENT_BY_DATE } from './actions';
import _ from 'lodash';

const initState = fromJS({
  events: [],
  page: 1,
  hasMore: true
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function eventsByDate(state = initState, action) {
  switch (action.type) {
    case FETCH_EVENT_BY_DATE_SUCCESS: {
      let newState = state.update('events', (v) => {
        if (action.page > 1) {
          return fromJS(_.unionBy(v.toJS(), action.events, 'objectId'));
        } else {
          return fromJS(action.events);
        }
      });

      if (_.isEmpty(action.events)) {
        newState = newState.set('hasMore', false);
      }
      return newState.set('page', action.page);
    }
    case RESET_EVENT_BY_DATE: {
      return initState;
    }
    default:
      return state;
  }
}
