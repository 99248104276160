import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { resetMenu } from '../../../containers/Header/actions';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import './style.scss';

class CloseMenuButton extends PureComponent {
  render() {
    return (
      <div className="nav-menu-close" onClick={this.props.resetMenu}></div>
    );
  }
}

CloseMenuButton.propTypes = {
  resetMenu: PropTypes.any
};

const mapDispatchToProps = dispatch => ({
  resetMenu: () => dispatch(resetMenu())
});


export default withRouter(connect(
  null, mapDispatchToProps
)(withImmutablePropsToJS(CloseMenuButton)));