import React, { Component, Fragment } from 'react';
import ManagementMenuCell from './ManagementMenuCell';
import { Dropdown, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './style.scss';

class ManagementMenu extends Component {
  constructor(params) {
    super(params);
    this.state = {
      isOpenMenu: false
    };
  }

  selectItem = () => {
    const selectItem = this.props.ManagementMenu.find((item) => {
      if (item.path === this.props.location.pathname && !item.isPC) {
        return item;
      } else {
        return null;
      }
    });
    return selectItem;
  };

  renderMenu = () => {
    const selectItem = this.selectItem();
    if (selectItem) {
      return (
        <div className="events-management-menu-tittle">
          <span className="events-management-menu-tittle-img">
            <img src={selectItem.icon} alt="management" />
          </span>
          <span className="events-management-menu-tittle-text">
            {selectItem.title}
          </span>
        </div>
      );
    }
  };

  render() {
    const { history, location } = this.props;
    const selectItem = this.selectItem();
    return (
      <React.Fragment>
        <div
          className="events-management-menu"
          style={this.props.hasCreateEventBtn ? { top: 168 } : {}}
        >
          {this.props.ManagementMenu.map((item) => (!item.isMobile ? (
            <ManagementMenuCell
              item={item}
              key={item.path}
              currentPath={location.pathname}
            />
          ) : <Fragment key={`${item.path}-sp`} />))}
        </div>
        <div className="events-management-menu-small">
          <Dropdown
            id="events-management-menu-drop"
            className={`${
              this.state.isOpenMenu
                ? 'events-management-menu-open'
                : 'events-management-menu-close'
            }`}
            onToggle={(isOpenMenu) => this.setState({ isOpenMenu })}
          >
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {this.renderMenu()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props.ManagementMenu.map((item) => (!item.isPC ? (
                <MenuItem
                  key={item.path}
                  onClick={() => history.replace(item.path)}
                  active={item.path === selectItem && selectItem.path ? true : false}
                >
                  <span className="events-management-menu-drop-img">
                    <img src={item.icon} alt="management" />
                  </span>
                  {item.title}
                </MenuItem>
              ) : <Fragment key={`${item.path}-pc`} />))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </React.Fragment>
    );
  }
}

ManagementMenu.propTypes = {
  hasCreateEventBtn: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  ManagementMenu: PropTypes.array
};

export default ManagementMenu;