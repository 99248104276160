import { fromJS } from 'immutable';

import {
  GET_ACTIVE_EVENTS_SUCCESS
} from './actions';

const initState = fromJS({
  events: []
});

export default function inviteToEvent(state = initState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ACTIVE_EVENTS_SUCCESS:
      return state.set('events', payload, []);

    default:
      return state;
  }
}