import React, {
  useEffect,
  useState
} from 'react';
import { Field } from 'redux-form/immutable';
import TextField from '../Inputs/TextField';
import Select from '../Form/Select';
import get from 'lodash/get';

const NATION_FLAG = {
  84: './images/icon-vn-flag.svg',
  81: './images/icon-jp-flag.svg',
  82: './images/icon-kr-flag.svg'
};

const InputPhoneNumber = ({
  ...props
}) => {
  const [selectedNation, setSelectedNation] = useState(process.env.REACT_APP_NATION_CODE);
  const input = {
    ...get(props, 'input', {}),
    ...(get(props, 'id') && {
      id: get(props, 'id')
    })
  };
  const isSelectNation = (process.env.REACT_APP_SWITCH_NATION_CODE || '').includes('true');

  useEffect(() => {
    window.sessionStorage.setItem('select_nation_code', process.env.REACT_APP_NATION_CODE);
  }, []);
  
  return (
    <div className={`number-phone-input${isSelectNation ? ' have-select-nation' : ''}`}>
      <div className='nation-code'>
        {
          isSelectNation ? (
            <>
              <img src={NATION_FLAG[selectedNation]} alt='' className='select-nation-img' />
              <Field
                component={Select}
                customForm={true}
                required={true}
                input={{
                  value: selectedNation,
                  onChange: (e) => {
                    setSelectedNation(e.target.value);
                    window.sessionStorage.setItem('select_nation_code', e.target.value);
                  }
                }}
                value={selectedNation}
              >
                <option value="84" label="+84" />
                <option value="81" label="+81" />
                <option value="82" label="+82" />
              </Field>
            </>
          ) : (
            <>
              {/* <img src={NATION_FLAG[process.env.REACT_APP_NATION_CODE]} alt='' />
              <span>+{process.env.REACT_APP_NATION_CODE}</span> */}
            </>
          )
        }
      </div>
      <TextField 
        {...({
          ...props,
          input
        })}
        onChange={(event) => {
          const val = event.target.value;
          const convertedValue = val.replace(/[^0-9.]/g, '').replace('.', '').replace(',', '');
          // Update the input field value only if it has changed
          input.onChange(convertedValue);
        }}
      />
    </div>
  );
};
export default InputPhoneNumber;
