import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { reduxForm, Field } from 'redux-form/immutable';
import { Input, Textarea } from '../../components/Form';
import Button from 'components/Button';

const CreateTemplate = ({
  handleSubmit,
  pristine,
  valid,
  changeValue,
  formValues
}) => {
  const handleOnchangeValue = (value) => {
    changeValue('description', `${formValues.description}`.concat(value));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="label-text">テンプレート名</div>
      <Field
        name="templateName"
        type="text"
        component={Input}
        customForm={true}
        maxLength={100}
      />
      <div className="label-text">タイトル</div>
      <Field
        name="name"
        type="text"
        component={Input}
        customForm={true}
        maxLength={100}
      />
      <div className="user-templete-name">
        テキスト
        <div
          onClick={() => handleOnchangeValue('{student_name}さん')}
          className="add-template-name-btn"
        >
          学生名
        </div>
        <div
          onClick={() => handleOnchangeValue('{president_name}')}
          className="add-template-name-btn"
        >
          社長名
        </div>
      </div>
      <Field
        name="description"
        component={Textarea}
        customForm={true}
        required={true}
        maxLength={1000}
        counter={1000}
        rows={5}
      />
      <Button
        type="submit"
        disabled={pristine || !valid}
        className="create-event-btn"
      >
        保存
      </Button>
    </form>
  );
};

CreateTemplate.propTypes = {
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  changeValue: PropTypes.func,
  formValues: PropTypes.object
};

export default compose(
  reduxForm({
    form: 'create-template',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      if (!values.get('name')) errors.name = 'Please enter the name';
      else if (values.get('name') && !values.get('name').trim()) {
        errors.name = 'Please enter the name';
      }
      if (!values.get('description'))
        errors.description = 'Please enter the description';
      else if (values.get('description') && !values.get('description').trim()) {
        errors.description = 'Please enter the description';
      }
      return errors;
    }
  }),
  withImmutablePropsToJS
)(CreateTemplate);
