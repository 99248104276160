import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { FETCH_MEDIA_INFO_REQUEST, getMediaInfoByUrlSuccess, getMediaInfoByUrlFailure } from './actions';

function* getMediaInfoByUrl(action) {
  try {
    const { url, json, meta } = action;
    const data = yield call(request, 'functions/getMediaInfoByUrl', { url: url, json: json }, 'POST');
    const result = data.result.meta[0];
    if (!result.error) yield put(getMediaInfoByUrlSuccess(result, meta));
    if (result.error) yield put(getMediaInfoByUrlFailure(result, meta));
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetMediaInfoByUrl() {
  yield takeEvery(FETCH_MEDIA_INFO_REQUEST, getMediaInfoByUrl);
}