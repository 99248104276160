import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';


class Textarea extends Component {
  countChars = (event) => {
    let content = event.target.value;
    this.props.input.onChange(content);
    if (content.length > this.props.maxLength) {
      this.props.input.onChange(content.slice(0, this.props.maxLength));
    }
  }

  render() {
    return (
      <Fragment>
        <div className={`custom-textarea-container ${this.props.className && this.props.className} ${this.props.meta.error && 'error-form'}`}>
          { this.props.label ? (<span className="subtitle">{this.props.label}</span>) : null }
          <textarea
            {...this.props.input}
            type={this.props.type}
            placeholder={this.props.placeholder}
            className="form-control input-md input-form"
            disabled={this.props.disabled}
            onChange={this.countChars}
            onBlur={this.props._onBlur}
            rows={this.props.rows ? this.props.rows : 3}
          />
        </div>
        { this.props.counter > 0 &&
          <div className='text-counter'>
            {`${_.get(this.props.input, 'value.length')}/${this.props.counter}`}
          </div>
        }
      </Fragment>
    );
  }
}

Textarea.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  label: PropTypes.string,
  counter: PropTypes.number,
  rows: PropTypes.number
};

export default Textarea;