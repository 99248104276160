export const GET_OFFER_EVENT_DETAIL_REQUEST = 'GET_OFFER_EVENT_DETAIL_REQUEST';
export const GET_OFFER_EVENT_DETAIL_SUCCESS = 'GET_OFFER_EVENT_DETAIL_SUCCESS';
export const GET_OFFER_EVENT_DETAIL_FAILURE = 'GET_OFFER_EVENT_DETAIL_FAILURE';

export const getOfferEventDetailRequest = (payload) => ({
  type: GET_OFFER_EVENT_DETAIL_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const getOfferEventDetailSuccess = (payload, meta) => ({
  type: GET_OFFER_EVENT_DETAIL_SUCCESS,
  payload,
  meta
});
export const getOfferEventDetailFailure = (payload, meta) => ({
  type: GET_OFFER_EVENT_DETAIL_FAILURE,
  payload,
  meta,
  error: true
});

export const RESPONSE_OFFER_EVENT_REQUEST = 'RESPONSE_OFFER_EVENT_REQUEST';
export const RESPONSE_OFFER_EVENT_SUCCESS = 'RESPONSE_OFFER_EVENT_SUCCESS';
export const RESPONSE_OFFER_EVENT_FAILURE = 'RESPONSE_OFFER_EVENT_FAILURE';

export const responseOfferEventRequest = (payload) => ({
  type: RESPONSE_OFFER_EVENT_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const responseOfferEventSuccess = (payload, meta) => ({
  type: RESPONSE_OFFER_EVENT_SUCCESS,
  payload,
  meta
});
export const responseOfferEventFailure = (payload, meta) => ({
  type: RESPONSE_OFFER_EVENT_FAILURE,
  payload,
  meta,
  error: true
});

export const SHOWN_FIRST_OFFER_EVENT_REQUEST = 'SHOWN_FIRST_OFFER_EVENT_REQUEST';
export const SHOWN_FIRST_OFFER_EVENT_SUCCESS = 'SHOWN_FIRST_OFFER_EVENT_SUCCESS';
export const SHOWN_FIRST_OFFER_EVENT_FAILURE = 'SHOWN_FIRST_OFFER_EVENT_FAILURE';

export const shownFirstOfferEventRequest = (payload) => ({
  type: SHOWN_FIRST_OFFER_EVENT_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const shownFirstOfferEventSuccess = (payload, meta) => ({
  type: SHOWN_FIRST_OFFER_EVENT_SUCCESS,
  payload,
  meta
});
export const shownFirstOfferEventFailure = (payload, meta) => ({
  type: SHOWN_FIRST_OFFER_EVENT_FAILURE,
  payload,
  meta,
  error: true
});
