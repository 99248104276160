import { request } from 'utilities/Api';
import { put, call, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  REQUEST_PRESIDENT_REVIEWS,
  requestPresidentReviewsSuccess
} from './actions';

function* requestPresidentReviewsList(action) {
  try {
    
    yield put(showLoading());
    const page = yield select(state => state.get('presidentReviews').get('page'));
    const data = yield call(
      request, 
      'functions/getPresidentReviews', 
      { limit: 10, page: page, presidentId: action.presidentId }, 
      'POST'
    );
    yield put(requestPresidentReviewsSuccess(data, page));
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}

export default function* watchPresidentReviews() {
  yield takeFirst(REQUEST_PRESIDENT_REVIEWS, requestPresidentReviewsList);
}