import { request } from 'utilities/Api';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  GET_CURRENT_CAMPAIGN_REQUEST, getCurrentCampaignFailure, getCurrentCampaignSuccess,
  GET_POINT_HISTORY_REQUEST, getPointHistoryFailure, getPointHistorySuccess,
  CHANGE_POINT_REQUEST,
  changePointSuccess,
  changePointFailure,
  getPointHistoryRequest
} from './action';
import { takeFirst } from 'utilities/supportSaga';

function* handleGetCurrentCampaign() {
  try {
    const role = yield select(state => 
      state.get('account').get('data').get('role')
    );
    if (role === 'STUDENT') {
      const { result } = yield call(
        request,
        'functions/getCurrentCampaign',
        {},
        'POST'
      );
      yield put(getCurrentCampaignSuccess(result));
    }
    
  } catch (e) {
    if (e) {
      yield put(getCurrentCampaignFailure(e));
    }
  }
}

function* handleGetPointHistory(data) {
  const { params, meta } = data;
  try {
    const role = yield select(state => 
      state.get('account').get('data').get('role')
    );
    if (role === 'STUDENT') {
      const { result } = yield call(
        request,
        'functions/getPointHistory',
        params,
        'POST'
      );
      yield put(getPointHistorySuccess(result, meta));
    }
    
  } catch (e) {
    if (e) {
      yield put(getPointHistoryFailure(e, meta));
    }
  }
}


function* handleChangePoint(action) {
  const { meta } = action;
  try {
    const { result } = yield call(
      request,
      'functions/usePoint',
      {},
      'POST'
    );
    yield put(changePointSuccess(result, meta));
    yield put(getPointHistoryRequest());
  } catch (e) {
    yield put(changePointFailure(e, meta));
  }
}


export default function* watchCampaign() {
  yield takeFirst(GET_CURRENT_CAMPAIGN_REQUEST, handleGetCurrentCampaign);
  yield takeFirst(CHANGE_POINT_REQUEST, handleChangePoint);
  yield takeEvery(GET_POINT_HISTORY_REQUEST, handleGetPointHistory);
}
