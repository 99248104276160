import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { getValueByKey } from 'containers/Popup/selectors';
import { setCommonPopup } from 'containers/Popup/actions';
import { Modal } from 'react-bootstrap';
import PopupViewStudentProfileContent from './PopupViewStudentProfileContent';
import StudentInfo from '../../../components/EventDetail/StudentInfo';
import './style.scss';

class PopupViewStudentProfile extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isScroll: false
    };
  }

  /**
   * @param {Number} relation 1: next / -1: prev
   */

  handleClickPopupViewStudentProfileControl = relation => {
    const { show } = this.props;
    let { index, list, status } = show;

    if (index === 0 && relation < 0) {
      index = list.length - 1;
    } else if (index === list.length - 1 && relation > 0) {
      index = 0;
    } else {
      index += relation;
    }
    
    this.props.openPopupViewStudentProfile(index, list, status);
  }
  
  scrollStudentProfile = e => {
    if (e.target.scrollTop > 0 && !this.state.isScroll)
      this.setState({ isScroll: true });
    else if (e.target.scrollTop === 0)
      this.setState({ isScroll: false });  
  }

  resetPopupScroll = () => {
    this.setState({ isScroll: false });
  }

  render() {
    const { show, openPopupChooseStudent, eventId, data } = this.props;
    const { index = -1, list = [], status } = show;
    const studentId = list[index];

    return (
      <Modal 
        show={!!show} 
        onHide={this.props.closePopupViewStudentProfile} 
        id="popup-student-profile"
        onScroll={this.scrollStudentProfile}
      >
        <div className={`${this.state.isScroll ? 'popup-student-profile-scroll' : ''} popup-student-profile-header`}>
          <img 
            alt="" 
            src="/images/Quit.svg" 
            className="popup-student-profile-close" 
            onClick={this.props.closePopupViewStudentProfile} 
          />

          <div className="popup-student-profile-title">
            学生プロフィール ({index + 1}/{list.length})
          </div>
        </div>

        <PopupViewStudentProfileContent 
          index={index} 
          list={list} 
          data={data} 
          status={status} 
          openPopupViewStudentProfile={this.props.openPopupViewStudentProfile}
          closePopupViewStudentProfile={this.props.closePopupViewStudentProfile}
        />

        {status === 'requested' && (<div className="action-sheet">
          <StudentInfo student={data[studentId]} size="large" />
          <div 
            className="deny-btn"
            onClick={() => openPopupChooseStudent('deny', eventId, studentId)}
          >
            <img alt="" src="/images/requested-user-deny-icon.svg" />
          </div>
          <div 
            className="accept-btn" 
            onClick={() => openPopupChooseStudent('accept', eventId, studentId)}
          >
            <img alt="" src="/images/requested-user-accept-icon.svg" />
          </div>
        </div>)}
        
        {list.length > 1 && <div className="popup-student-profile-control">
          <img 
            alt="" 
            src="/images/left-control.svg" 
            className="popup-student-profile-control--left" 
            onClick={() => {
              this.resetPopupScroll();
              this.handleClickPopupViewStudentProfileControl(-1);
            }} 
          />
          <img 
            alt="" 
            src="/images/right-control.svg" 
            className="popup-student-profile-control--right" 
            onClick={() => {
              this.resetPopupScroll();
              this.handleClickPopupViewStudentProfileControl(1);
            }} 
          />
        </div>}
      </Modal>
    );
  }
}

PopupViewStudentProfile.propTypes = {
  show: PropTypes.any,
  openPopupViewStudentProfile: PropTypes.func,
  closePopupViewStudentProfile: PropTypes.func,
  openPopupChooseStudent: PropTypes.func,
  eventId: PropTypes.string,
  data: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    show: getValueByKey(state, 'studentProfile'),
    data: state.get('studentProfile').get('data')
  };
};

const mapDispatchToProps = (dispatch) => ({
  closePopupViewStudentProfile: () => dispatch(setCommonPopup('studentProfile', false)),
  openPopupViewStudentProfile: (index, list, status) => 
    dispatch(setCommonPopup('studentProfile', {index, list, status}))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PopupViewStudentProfile));