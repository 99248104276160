/* eslint-disable no-prototype-builtins */
import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import queryString from 'query-string';
import Header from './../Header';
import Footer from './../Footer';
import Home from './../../components/Home';
import EventByTag from './../../containers/EventByTag/Loadable';
//import PresidentDetail from './../PresidentDetail/Loadable';
import PresidentList from './../PresidentList/Loadable';
import EventDetail from './../EventDetail/Loadable';
import Auth from './../Auth';
import StudentUpdateProfile from './../StudentUpdateProfile/Loadable';
import LoadingBar from './../../components/Loading/CustomLoadingBar';
import { getCurrentAccount } from './../Account/actions';
import PropTypes from 'prop-types';
import RouteForStudent from '../RouteForStudent';
import TriggerSaveAccount from './../Account/TriggerSaveAccount';
import { setCommonPopup } from './../Popup/actions';
import EditMyPage from './../../containers/EditMyPage';
import EventHistory from './../../containers/EventHistory';
// import FollowList from './../../containers/FollowList';
import MyPage from './../../containers/MyPage';
import EventListOfPresident from 'containers/EventListOfPresident';
import { StudentLogin, PresidentLogin } from './../../containers/Login';
import LoginSMSVerification from '../../containers/LoginSMSVerification';
import SignUpSMSVerification from '../../containers/SignUpSMSVerification';
import { StudentSignUp, PresidentSignUp } from '../../containers/SignUp';
import { useRedirectURN } from '../../containers/LinkWithRedirect/action';
import CreateEvent from '../../containers/CreateEvent/Loadable';
import EventPreview from '../../containers/EventPreview/Loadable';
import TriggerLogout from '../../containers/Account/TriggerLogout';
import { destroyAllFormRequest } from '../../containers/Form/action';
import PresidentDetail from 'containers/PresidentDetail2';
import NoticeList from 'containers/Notification/NotificationPage/Loadable';
import { removeEventHistory } from '../../containers/EventHistory/actions';
import { removeRelateNoticeList } from 'containers/Notification/actions';
import _ from 'lodash';
import 'containers/EventPreview/style.scss';
import 'containers/PopupChooseStudent/style.scss';
import MessagePage from '../Message/MessagePage';
import CampaignPoint from '../Campaign';
import ForgotPassword from 'containers/ForgotPassword';
import { removeMessageList } from 'containers/Message/actions';
import PopupSuccess from '../../components/PopupSuccess';
import { removeFollowList } from '../../containers/FollowList/action';
import NotFound from '../../components/NotFound';
import EventByBanner from '../EventByBanner';
import Lightbox from '../../components/LightboxGallery/Lightbox';
import LightboxControl from '../../components/LightboxGallery/LightboxControl';
import Offline from '../../components/Offline';
import EventsManagement from '../../containers/Management/EventsManagement';
import StudentsManagement from '../../containers/Management/StudentsManagement';
import WelcomePresident from '../SignUp/PresidentTutorial/Welcome';
import PresidentTutorial from '../SignUp/PresidentTutorial/Tutorial';
import { closeRequirePhoneModal, openRequirePhoneModal } from '../RequirePhonePopup/action';
import EmailChange from '../EmailChange';
import { initFirebase } from '../../utilities/firebase';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { getEventAndParams } from '../../utilities/locationHelpers';
import PopupLogin from 'containers/PopupLogin';
import PopupPolicy from 'containers/PopupPolicy';
import { Helmet } from 'react-helmet';
import { getSeoDataHomePage } from '../Seo/actions';
import AbsenceWarning from '../AbsenceWarning';
import BlockStudentPopup from '../BlockStudentPopup';
import ConfirmPopup from '../PopupConfirm';
// Review Event
import EventReview from '../Review';
import { getEventNeedReview } from 'containers/Review/action';
import OfferEventRequestModal from 'containers/OfferEventRequestModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditEvent from '../EditEvent';
import OfferManagement from './../OfferManagement/Loadable';
import { commonLoginRequest } from '../CommonAuth/actions';
import { studentResendEmail } from 'containers/StudentUpdateProfile/actions';
import VerifyEmailPage from 'containers/VerifyEmail/Loadable';
import { getCurrentCampaignRequest } from '../Campaign/action';
import NotificationSetting from 'containers/NotificationSetting';

class App extends Component {

  constructor(props) {
    super(props);
    this.afterSaveAccount = this.afterSaveAccount.bind(this);
    initFirebase();
    this.currentLocation = props.location;
    this.state = {
      seo: {

      }
    };
  }

  handleChangeStorage = async e => {
    const { currentUser } = this.props;
    if (e.newValue && currentUser) {
      const { data = {} } = JSON.parse(localStorage.getItem('account'));
      const { objectId = null } = data;
      if (objectId && !_.isEmpty(currentUser)) {
        if (currentUser.objectId !== objectId) {
          window.location.replace('/');
        }
      }
    }
  }

  componentDidMount() {
    this.props.getEventNeedReview();
    window.addEventListener('storage', this.handleChangeStorage);
    const event = getEventAndParams(this.props.history.location.pathname);
    if (event) {
      firebase.analytics().logEvent(event.eventName, event.params);
    }
    this.unlisten = this.props.history.listen((location) => {
      const prevLocation = this.currentLocation;
      this.currentLocation = location;
      const event = getEventAndParams(location.pathname, prevLocation ? prevLocation.pathname : '');
      if (event) {
        firebase.analytics().logEvent(event.eventName, event.params);
      }
    });
    this.props.getSeoDataHomePage().then(data => {
      this.setState({ seo: data});
    });

    // verify common token
    const query = queryString.parse(this.props.location.search);
    if (query.t) {
      this.props.commonLogin({
        token: query.t
      }).finally(() => {
        this.props.history.replace({
          search: ''
        });
        window.location.reload();
      });
    } else {
      this.props.getCurrentAccount(this.props.token);
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  componentDidUpdate(e) {
    if (_.isEmpty(this.props.currentCampaign)) {
      this.props.getCurrentCampaign();
    }
    if (e.history.action === 'PUSH') {
      window.scrollTo(0, 0);
    }

    // Only fetch event need review when in home page
    if (!_.includes(['/', '/event', '/presidents', '/reviewEvent'], e.location.pathname)) {
      if (_.includes(['/', '/event', '/presidents'], this.props.location.pathname)) {
        if (_.get(this.props, 'currentUser.role')) {
          this.props.getEventNeedReview();
        }
      }
    }
  }

  afterSaveAccount(message) {
    const { setPopupSurvey } = this.props;
    if (message === 'UNAPPROVED_PRESIDENT_LOGIN') {
      this.props.setPopupWelcome(true);
    }
    if (message === 'STUDENT_COMPLETE_PROFILE') {
      try {
        const newSignUpStudent = JSON.parse(window.localStorage.getItem('newSignUpStudent')) || [];
        newSignUpStudent.push(this.props.currentUser.objectId);
        window.localStorage.setItem('newSignUpStudent', JSON.stringify(_.uniq(newSignUpStudent)));
        setPopupSurvey(true);
      } catch (err) {
        window.localStorage.removeItem('newSignUpStudent');
      }
    }
    if (message === 'NEW_STUDENT_LOGIN') {
      this.props.setPopupSurvey(true);
    }

    if (['STUDENT_COMPLETE_PROFILE',
      'LOGIN', 'NEW_STUDENT_LOGIN', 'LINE_LOGIN'].includes(message)) {
      this.props.useRedirectURN();
    }
  }

  routes() {
    const user = this.props.currentUser;

    if (_.has(user, 'role') && this.props.hasReviewingEvent) {
      return (
        <Switch>
          <Route exact path="/reviewEvent" component={EventReview} />
          <Redirect to="/reviewEvent" />
        </Switch>
      );
    }

    if (user && user.role === 'STUDENT' && !user.isCompletedProfile) {
      return (
        <Switch>
          <RouteForStudent path="/complete-profile" component={StudentUpdateProfile} />
          <Redirect to="/complete-profile" />
        </Switch>
      );
    }

    if (
      user &&
      user.role === 'PRESIDENT' &&
      (!user.hasOwnProperty('approvalStatus') || user.approvalStatus !== 'ACCEPTED')) {
      return (
        <Switch>
          <Route key="welcome" path="/welcome" component={WelcomePresident} />
          <Route
            render={props => <EventByTag key={props.match.params.tag + String(props.location.key)} {...props} />}
            path="/tags/:tag"
          />
          <Route
            render={props => <EventByBanner key={props.match.params.tag + String(props.location.key)} {...props} />}
            path="/banner/:banner"
          />
          <Route path="/event-detail/:eventId" component={EventDetail} />
          <Route path="/PresidentDetail/:objectId" component={PresidentDetail} />
          <Route path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      );
    }
    let myProfileRoute = [];
    if (user && user.role === 'PRESIDENT') {
      myProfileRoute = [
        <Route key="mypage" path="/my-page" component={PresidentDetail} />,
        <Route key="notifications" path="/notifications" component={NoticeList} />,
        <Route key="email-change" path="/edit-my-page/email-change" component={EmailChange} />,
        <Route key="edit" path="/edit-my-page" component={EditMyPage} />,
        <Route key="createEvent" path="/createEvent" component={CreateEvent} />,
        <Route key="editEvent" path="/editEvent/:id" component={EditEvent} />,
        <Route key="previewCreatingEvent" path="/previewCreatingEvent" component={EventPreview} />,
        <Route key="studentDetail" path="/studentDetail/:objectId" component={MyPage} />,
        <Route key="messages" path="/messages/:eventId?" component={MessagePage} />,
        <Route key="management-event" path="/management-event" component={EventsManagement} />,
        <Route key="management-student" path="/management-student" component={StudentsManagement} />
      ];
    }
    if (user && user.role === 'STUDENT') {
      myProfileRoute = [
        <Route key="notifications" path="/notifications" component={NoticeList} />,
        <Route key="email-change" path="/edit-my-page/email-change" component={EmailChange} />,
        <Route key="edit" path="/edit-my-page" component={EditMyPage} />,
        <Route key="history" path="/event-history" component={EventHistory} />,
        // <Route key="history" path="/offer-history" component={EventHistory} />,
        <Route key="offer-management" path="/offer-management" component={OfferManagement} />,
        <Route key="gochi-management" path="/gochi-management" render={() => <OfferManagement isGochi={true}/>} />,
        // <Route key="myprez" path="/mypresidents" component={FollowList} />,
        <Route key="mypage" path="/my-page" component={MyPage} />,
        <Route key="messages" path="/messages/:eventId?" component={MessagePage} />,
        <Route key="verify-email" path="/verify-email" component={VerifyEmailPage} />,
        <Route key="campaign" path="/campaign" component={CampaignPoint} />,
        <Route key="notification-settings" path="/notification-settings" component={NotificationSetting} />
      ];
    }

    let publicOnlyRoute = [];
    if (!user) {
      publicOnlyRoute = [
        <Route key="auth" path="/auth/" component={Auth} />
      ];
    }

    return (
      <Switch>
        <Route
          render={props => <EventByTag key={props.match.params.tag + String(props.location.key)} {...props} />}
          path="/tags/:tag"
        />
        <Route
          render={props => <EventByBanner key={props.match.params.tag + String(props.location.key)} {...props} />}
          path="/banner/:banner"
        />
        {myProfileRoute}
        <Route path="/presidentList" component={PresidentList} />
        <Route path="/PresidentDetail/:objectId" component={PresidentDetail} />
        <Route path="/event-detail/:eventId" component={EventDetail} />
        <Route path="/event-list/:presidentId" component={EventListOfPresident} />
        <Route path="/login" component={StudentLogin} />
        <Route path="/verify-email" component={VerifyEmailPage} />
        {publicOnlyRoute}
        <Route path="/loginPresident" component={PresidentLogin} />
        <Route path="/loginStudent" component={StudentLogin} />
        <Route path="/login-sms-verification" component={LoginSMSVerification} />
        <Route path="/signup-sms-verification" component={SignUpSMSVerification} />
        <Route path="/presidentTutorial" component={PresidentTutorial} />
        <Route path="/signUpPresident" component={PresidentSignUp} />
        <Route path="/signUpStudent" component={StudentSignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />

        <Route path='/404-not-found' component={NotFound} />
        <Route path='/unsubscribe' component={NotFound} />
        <Route path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
    );
  }

  myHeader = () => {
    if (['/login', '/loginPresident', '/loginStudent', '/signUpPresident', '/signUpStudent', '/forgotPass',
      '/complete-profile', '/forgot-password', '/404-not-found', '/offline',
      '/presidentTutorial', '/welcome', '/unsubscribe', '/login-sms-verification', '/signup-sms-verification'
    ].includes(this.props.location.pathname)) {
      return null;
    }
    if (['/reviewEvent'].includes(this.props.location.pathname)) return <Header isLogoOnly />;
    return <Header></Header>;
  }

  myFooter = () => {
    const show = ['/', '/event', '/presidents'].includes(this.props.location.pathname);
    return <Footer show={show} />;
  }

  render() {
    const { seo } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>{seo.title}</title>
          <meta name="title" content={seo.title} />
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keyword} />
          <meta name="twitter:image" content={seo.image} />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
        </Helmet>
        <LoadingBar className="loading-bar"></LoadingBar>
        <PopupSuccess />
        <ConfirmPopup />
        <Offline />
        <Lightbox />
        <LightboxControl />
        <PopupLogin />
        <PopupPolicy />
        <OfferEventRequestModal />
        <ToastContainer position="bottom-right"/>
        {this.myHeader()}
        <div id="main-content">
          {this.routes()}
        </div>
        {this.myFooter()}
        <TriggerSaveAccount endSaveAccount={this.afterSaveAccount} />
        <TriggerLogout afterLogout={(res) => {
          let userRole = _.get(res, 'data.role', '').toLowerCase();

          this.props.destroyAllForm();
          this.props.removeEventHistory();
          this.props.removeMessageList();
          this.props.removeRelateNoticeList();
          this.props.removeFollowList();
          
          // Direct to login form
          userRole = userRole && `${userRole.charAt(0).toUpperCase()}${userRole.slice(1)}`;
          if (userRole) {
            this.props.history.replace(`/login${userRole}`);
          }
        }} />
        <AbsenceWarning />
        <BlockStudentPopup />
      </Fragment>
    );
  }
}

App.propTypes = {
  getCurrentAccount: PropTypes.func,
  currentUser: PropTypes.any,
  setPopupWelcome: PropTypes.func,
  useRedirectURN: PropTypes.func,
  removeEventHistory: PropTypes.func,
  destroyAllForm: PropTypes.func,
  removeMessageList: PropTypes.func,
  removeRelateNoticeList: PropTypes.func,
  removeFollowList: PropTypes.func,
  setPopupSurvey: PropTypes.func,
  token: PropTypes.any,
  location: PropTypes.any,
  history: PropTypes.any,
  openRequirePhonePopup: PropTypes.func,
  isStudentSurveyOn: PropTypes.bool,
  closeRequirePhonePopup: PropTypes.func,
  updateProfileSuccess: PropTypes.bool,
  getEventNeedReview: PropTypes.func,
  hasReviewingEvent: PropTypes.bool,
  getSeoDataHomePage: PropTypes.func,
  commonLogin: PropTypes.func,
  resendEmail: PropTypes.func,
  showVerifyPopup: PropTypes.func,
  getCurrentCampaign: PropTypes.func,
  currentCampaign: PropTypes.object
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data'),
    token: state.get('account').get('token'),
    isStudentSurveyOn: state.get('commonPopup').get('studentSurvey'),
    updateProfileSuccess: state.get('requirePhoneModal').get('updateProfileSuccess'),
    hasReviewingEvent: state.get('review').get('hasReviewingEvent'),
    currentCampaign: state.get('campaign').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  getCurrentAccount: (token) => dispatch(getCurrentAccount(token, 'app')),
  setPopupWelcome: (open) => dispatch(setCommonPopup('welcome', open)),
  setPopupSurvey: (open) => dispatch(setCommonPopup('studentSurvey', open)),
  useRedirectURN: () => dispatch(useRedirectURN()),
  removeEventHistory: () => dispatch(removeEventHistory()),
  destroyAllForm: () => dispatch(destroyAllFormRequest()),
  removeMessageList: () => dispatch(removeMessageList()),
  removeRelateNoticeList: () => dispatch(removeRelateNoticeList()),
  removeFollowList: () => dispatch(removeFollowList()),
  openRequirePhonePopup: () => dispatch(openRequirePhoneModal()),
  closeRequirePhonePopup: () => dispatch(closeRequirePhoneModal()),
  getSeoDataHomePage: () => dispatch(getSeoDataHomePage()),
  getEventNeedReview: () => dispatch(getEventNeedReview()),
  commonLogin: (params) => dispatch(commonLoginRequest(params)),
  showVerifyPopup: (payload) => dispatch(setCommonPopup('confirm', payload)),
  resendEmail: (payload) => dispatch(studentResendEmail(payload)),
  getCurrentCampaign: () => dispatch(getCurrentCampaignRequest())
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(App)));
