import { fromJS } from 'immutable';
import _ from 'lodash';
import { FETCH_RELATE_EVENT_LIST_SUCCESS, REMOVE_RELATE_EVENT_LIST } from './actions';

const initState = fromJS({
  page: 1,
  list: [],
  hasMore: false
});

export default function eventListOfPresident(state = initState, action) {
  switch (action.type) {
    case FETCH_RELATE_EVENT_LIST_SUCCESS: {
      const { eventList = [] } = action;
      if (_.isEmpty(eventList)) {
        return state.set('hasMore', false);
      }
      return state
        .set('list', state.get('list').concat(eventList))
        .set('page', state.get('page') + 1)
        .set('hasMore', true);
    }
    case REMOVE_RELATE_EVENT_LIST:
      return initState;
    default:
      return state;
  }
}