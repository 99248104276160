import { request } from 'utilities/Api';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { 
  LOGIN_REQUEST,
  IS_CHECK_LOGIN_REQUEST,
  IS_CHECK_LOGIN_3RD_PARTY_REQUEST
} from './actions';
import { getLoginState } from './selectors';
import get from 'lodash/get';
import { getCurrentAccount } from './../Account/actions';
import { stopSubmit } from 'redux-form';
import { unsetCurrentAccount } from 'containers/Account/actions';
import { setCommonPopup } from 'containers/Popup/actions';
import { loginRequest } from './actions';
import { push } from 'connected-react-router';
import webPush from '../../utilities/webPush';

function* handleLogin(action) {
  try {
    const role = action.currentRole;
    const values = JSON.parse(JSON.stringify(action.values).replace(/"\s+|\s+"/g, '"'));
    const data = yield call(request, 'login', values, 'GET');

    if (data.role === role) {
      let message = 'LOGIN';
      if (data.approvalStatus === 'WAITING') {
        message = 'UNAPPROVED_PRESIDENT_LOGIN';
      }

      try {
        const newSignUpStudent = JSON.parse(window.localStorage.getItem('newSignUpStudent')) || [];
        if (newSignUpStudent && newSignUpStudent.includes(data.objectId)) {
          message = 'NEW_STUDENT_LOGIN';
        }
      } catch (err) {
        window.localStorage.removeItem('newSignUpStudent');
      }
      if (data.status === 'ACTIVE' || (data.status === 'INACTIVE' && data.role === 'STUDENT')) {
        yield put(getCurrentAccount(data.sessionToken, message));
      }
      if (data.status === 'INACTIVE' && data.role === 'PRESIDENT') {
        yield put(setCommonPopup('success', {
          message: 'アカウントが停止されています。運営に連絡してください', 
          OKcallback: () => window.store.dispatch(unsetCurrentAccount()),
          onHideCallback: () => window.store.dispatch(unsetCurrentAccount()),
          btnLabel: 'はい'
        }));
      }
      if (data.status === 'DELETE_PROCESS') {
        yield put(stopSubmit('loginForm', { _error: '正しいメールアドレスを入力してください' }));
      }

      webPush.requestPermission();
    } else {
      yield put(stopSubmit('loginForm', { _error: 'メールアドレスもしくはパスワードが違います。' }));
    }
  } catch (e) {
    yield put(stopSubmit('loginForm', { _error: 'メールアドレスもしくはパスワードが違います。' }));
  }
}

function* handleIsLoginRequest(action) {
  try {
    const state = yield select(getLoginState);
    const role = state.get('role');
    const values = JSON.parse(JSON.stringify(action.values).replace(/"\s+|\s+"/g, '"'));
    const data = yield call(request, 'login', values, 'GET');

    
    if (data.role === role) {
      if (get(data, 'phoneVerified', false)) {
        yield put(loginRequest(action.values, data.role));
      } else {
        // Info login account: convert to base64 => Array[email, password]
        window.sessionStorage.setItem('account_login', 
          JSON.stringify([
            btoa(values.email),
            btoa(values.password),
            btoa(data.role),
            btoa(data.sessionToken),
            get(data, 'phone') && btoa(data.phone)
          ].filter(Boolean)));
        yield put(push('/login-sms-verification'));
      }
    } else {
      yield put(stopSubmit('loginForm', { _error: 'メールアドレスもしくはパスワードが違います。' }));
    }
  } catch (e) {
    yield put(stopSubmit('loginForm', { _error: 'メールアドレスもしくはパスワードが違います。' }));
  }
}

function* handleIsLoginRequest3rdParty(action) {
  let { meta } = action; 
  try {
    let token = action.token;
    if (token) {
      const data = yield call(request, 'functions/getUserInformation', {}, 'POST', { 
        headers: { 'X-Parse-Session-Token': token }
      });
      
      if (data) {
        const phoneVerified = get(data, 'result.infoUser.phoneVerified', false);

        if (phoneVerified) {
          yield put(getCurrentAccount(token));
        } else {
          // Info login account: convert to base64 => Array[email, password]
          window.sessionStorage.setItem('account_login_3rd', 
            JSON.stringify([
              btoa(data.role),
              token
            ].filter(Boolean)));
          yield put(push('/login-sms-verification'));
        }
      }
    } else {
      yield put(push('/'));
    }
  } catch (e) {
    yield put(push('/'));
    yield put(unsetCurrentAccount(meta));
  }
}

export default function* watchLogin() {
  yield takeEvery(IS_CHECK_LOGIN_REQUEST, handleIsLoginRequest);
  yield takeEvery(IS_CHECK_LOGIN_3RD_PARTY_REQUEST, handleIsLoginRequest3rdParty);
  yield takeEvery(LOGIN_REQUEST, handleLogin);
}

 