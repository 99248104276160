import { put, takeEvery } from 'redux-saga/effects';
import { COMPLETE_PROFILE_REQUEST_EVENT_POPUP } from './action';
import { openRequestEventReasonPopUpRequest } from 'containers/RequestEventRessonPopUp/action';
import { closeCompleteProfilePopUp } from 'components/StudentFirstJoinEventProfileForm/action';

function* completeProfilePopUp() {
  try {
    yield put(closeCompleteProfilePopUp());
    yield put(openRequestEventReasonPopUpRequest());
  } catch (error) {
    console.log(error);
  }
}


export default function* watchCloseProfilePopUp() {
  yield takeEvery(COMPLETE_PROFILE_REQUEST_EVENT_POPUP, completeProfilePopUp);
}