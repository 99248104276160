import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { setCommonPopup } from './../../Popup/actions';
import { getValueByKey } from './../../Popup/selectors';
import { Modal } from 'react-bootstrap';
import LinkWithRedirect from './../../LinkWithRedirect';
import './style.scss';

class LogInRoleSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hoverStudent: true,
      hoverPresident: false
    };
  }

  componentWillUnmount() {
    this.props.closePopup();
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.closePopup}
          size="lg"
          className="center-modal-dialog"
          dialogClassName="login-modal-dialog"
          containerClassName="login-modal-container"
          backdrop={true}
          id="login-modal"
        >
          <Modal.Body className="login-modal-body">
            <div className="login-modal-title">
              <div>ログイン</div>
            </div>
            <div style={{ height: '366px', marginBottom: '82px' }}>
            
              <LinkWithRedirect to='/loginStudent' storeSession={true}>
                <div className={'role-box ' + (this.state.hoverStudent && 'role-box-active')} onMouseEnter={() => { this.setState({ hoverStudent: true, hoverPresident: false }); }} style={{ marginLeft: '85px' }}>
                  <div className="upper-section">
                    {this.state.hoverStudent ? <img alt="" src="/images/role-student-active.svg" /> : <img alt="" src="/images/role-student.svg" />}
                  </div>
                  <div className="below-section">
                    <div>学生の方はこちら</div>
                  </div>
                </div>
              </LinkWithRedirect>

              <div className="divider">
                <div className="devider-text">OR</div>
              </div>

              <LinkWithRedirect /*to={'https://shachomeshi.com/company/'}*/ to='/loginPresident'>
                <div className={'role-box ' + (this.state.hoverPresident && 'role-box-active')} onMouseEnter={() => { this.setState({ hoverPresident: true, hoverStudent: false }); }} style={{ marginRight: '84px' }}>
                  <div className="upper-section">
                    {this.state.hoverPresident ? <img alt="" src="/images/role-president-active.svg" /> : <img alt="" src="/images/role-president.svg" />}
                  </div>
                  <div className="below-section">
                    <div>社長の方はこちら</div>
                  </div>
                </div>
              </LinkWithRedirect>

            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

LogInRoleSelect.propTypes = {
  show: PropTypes.bool,
  closePopup: PropTypes.func,
  unsetCurrentAccount: PropTypes.func,
  setRedirectURN: PropTypes.func
};

LogInRoleSelect.defaultProps = {
  show: false
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'loginRoleSelect')
  };
};

const mapDispatchToProps = dispatch => ({
  closePopup: () => dispatch(setCommonPopup('loginRoleSelect', false))
});

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(withImmutablePropsToJS(LogInRoleSelect)));
