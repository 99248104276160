import { request } from './../../utilities/Api';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { takeFirst } from '../../utilities/supportSaga';
import {
  openAbsence,
  closeAbsence,
  requestGetAbsenceWarning,
  requestGetAbsenceWarningSuccess,
  REQUEST_GET_ABSENCE_WARNING,
  REQUEST_STUDENT_SEEN_ABSENCE_WARNING,
  requestStudentSeenAbsenceWarningDone
} from './action';

function* getEventStudentAbsence() {
  const role = yield select(state => {
    return state.get('account').get('data') ? state.get('account').get('data').get('role') : '';
  });
  if (role === 'STUDENT') {
    const data = yield call(request, 'functions/getStudentAbsence', {}, 'POST');
    const { hasAbsenceEvent, event } = data.result;
    if (hasAbsenceEvent) {
      yield put(requestGetAbsenceWarningSuccess(event));
      yield put(openAbsence());
    }
  }
}

function* studentSeenAbsenceWarning(action) {
  const role = yield select(state => 
    state.get('account').get('data').get('role')
  );
  const single = yield select(state => 
    state.get('studentAbsence').get('single')
  );
  try {
    if (role === 'STUDENT') {
      const data = yield call(request, 'functions/studentSeenAbsenceWarning', {eventId: action.eventId}, 'POST');
      const { status } = data.result;
      yield put(closeAbsence());
      yield put(requestStudentSeenAbsenceWarningDone());
      if (status === 'OK' && single === false) {
        yield put(requestGetAbsenceWarning());
      }
    }
  } catch (err) {
    yield put(closeAbsence());
  }
}

export function* watchGetAbsenceOfStudentEvent() {
  yield takeEvery(REQUEST_GET_ABSENCE_WARNING, getEventStudentAbsence);
  yield takeFirst(REQUEST_STUDENT_SEEN_ABSENCE_WARNING, studentSeenAbsenceWarning);
}