export const FETCH_MESSAGE_LIST_REQUEST = 'FETCH_MESSAGE_LIST_REQUEST';
export const FETCH_MESSAGE_LIST_SUCCESS = 'FETCH_MESSAGE_LIST_SUCCESS';
export const REMOVE_MESSAGE_LIST = 'REMOVE_MESSAGE_LIST';
export const FETCH_UNREAD_MESSAGE_NUMBER_REQUEST = 'FETCH_UNREAD_MESSAGE_NUMBER_REQUEST';
export const FETCH_UNREAD_MESSAGE_NUMBER_SUCCESS = 'FETCH_UNREAD_MESSAGE_NUMBER_SUCCESS';
export const FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_REQUEST = 'FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_REQUEST';
export const FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_SUCCESS = 'FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_SUCCESS';
export const ADD_TOP_EVENT_KEY_TO_MESSAGES_LIST = 'ADD_TOP_EVENT_KEY_TO_MESSAGES_LIST';

export function requestMessageList(page) {
  return {
    type: FETCH_MESSAGE_LIST_REQUEST, 
    page,
    meta: {
      thunk: true
    }
  };
}

export function requestMessageListSuccess(list, page, meta) {
  return {
    type: FETCH_MESSAGE_LIST_SUCCESS, 
    list, 
    page,
    meta
  };
}


export function requestMessageListFail(payload, meta) {
  return {
    type: FETCH_MESSAGE_LIST_SUCCESS, 
    meta,
    error: true,
    payload
  };
}

export function removeMessageList() {
  return {type: REMOVE_MESSAGE_LIST};
}

export function requestUnreadMessageNumber() {
  return {type: FETCH_UNREAD_MESSAGE_NUMBER_REQUEST};
}

export function receiveUnreadMessageNumber(number) {
  return {type: FETCH_UNREAD_MESSAGE_NUMBER_SUCCESS, number};
}

export const SET_MOBILE_MSG_VIEW = 'SET_MOBILE_MSG_VIEW';

export function setMobileMessageView(viewNumber = 0) {
  return {type: SET_MOBILE_MSG_VIEW, viewNumber};
}

export function requestEventDetailForNewConversation(eventId) {
  return {type: FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_REQUEST, eventId};
}

export function receiveEventDetailForNewConversation(event) {
  return {type: FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_SUCCESS, event};
}

export function addTopMessageList(eventId) {
  return {type: ADD_TOP_EVENT_KEY_TO_MESSAGES_LIST, eventId};
}