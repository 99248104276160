import Parse from 'parse';
import { pushCommingMessage } from './action';

class MessageAdapter {
  subscription = {};

  subscribeHandler = {
    create: (newMessage) => {
      let sender = newMessage.get('sender') || {};
      sender = sender.toJSON ? sender.toJSON() :sender;
      sender = {
        username: sender.username, 
        displayName: sender.displayName,
        role: sender.role,
        avatarImage: sender.avatarImage,
        objectId: sender.objectId
      };

      const user = window.store.getState().get('account').get('data').toJS();

      let media = newMessage.get('media');
      media = media && media.url();

      newMessage = newMessage.toJSON();
      newMessage = {
        eventId: newMessage.eventId,
        senderId: newMessage.senderId,
        message: newMessage.message,
        createdAt: newMessage.createdAt,
        objectId: newMessage.objectId,
        isSystem: newMessage.isSystem,
        isMine: user.objectId === newMessage.senderId,
        media,
        sender
      };
      window.store.dispatch(pushCommingMessage(newMessage));
    },
    update: (Message) => {
      //do something with updated Message
    }
  };

  subscribe = async () => {
    this.unsubscribe();
    const currUser = Parse.User.current();
    const handler = this.subscribeHandler;
    if (!currUser || !currUser.id) {
      throw new Error('login Require for live adapter');
    }

    const MsgQuery = new Parse.Query('Message');
    MsgQuery.contains('memberIds', currUser.id);
    
    const msgSub = this.subscription = await MsgQuery.subscribe();
    msgSub.on('open', () => {})
    msgSub.on('create', handler.create);
    msgSub.on('close', () => window.location.reload());
  };

  unsubscribe = () => {
    let subscription = this.subscription;
    subscription && subscription.unsubscribe && subscription.unsubscribe();
    this.subscription = {};
  };
};

window.MessageAdapter = MessageAdapter;

export default MessageAdapter;