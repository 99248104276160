import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types'; 
import CollapsedGroup from './CollapsedGroup';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _invoke from 'lodash/invoke';

import Button from 'components/Button';
import { setCommonPopup } from 'containers/Popup/actions';
import './styles.scss';

const UniversitySelectorModal = ({
  universitySelectorModal,
  closePopup,
  university = [],
  universityGroup = []
}) => {
  const [selected, setSelected] = useState(_get(universitySelectorModal, 'selectedUniversities', []));
  React.useEffect(() => {
    setSelected(_get(universitySelectorModal, 'selectedUniversities', []))
  }, [universitySelectorModal])
  const universityListByGroup = useMemo(() => {
    const listByGroup = {};
    university.forEach(({ group, ...rest }) => {
      const groupDetail = universityGroup.find(detail => detail._id === group);
      if (!listByGroup[group]) {
        listByGroup[group] = {
          list: [rest],
          nameList: [rest.name],
          ...groupDetail
        };
      } else {
        listByGroup[group].list.push(rest);
        listByGroup[group].nameList.push(rest.name);
      }
    });
    return listByGroup;
  }, [university, universityGroup]);

  return (
    <Modal 
      show={!_isEmpty(universitySelectorModal)}
      onHide={closePopup}
      className="university-selector-modal"
    >
      <img className="close-icon" alt="close" src="/images/icon-close.svg" onClick={closePopup} />
      <div className="modal-title">大学名</div>
      <div>
        {Object.keys(universityListByGroup).map(groupKey => (
          <CollapsedGroup
            key={groupKey}
            groupDetail={universityListByGroup[groupKey]}
            selectedState={[selected, setSelected]}
          />
        ))}
      </div>
      <div className="university-selector-footer">
        <Button className="btn-confirm" onClick={() => {
          console.log('--------->selected:', selected)
          _invoke(universitySelectorModal, 'onConfirm', selected);
        }}>
          確認
        </Button>
      </div>
    </Modal>
  );
};

UniversitySelectorModal.propTypes = {
  universitySelectorModal: PropTypes.object,
  closePopup: PropTypes.func,
  university: PropTypes.array,
  universityGroup: PropTypes.array
};

const mapStateToProps = state => ({
  currentUser: state.get('account').get('data'),
  universitySelectorModal: state.get('commonPopup').get('universitySelectorModal'),
  university: state.get('resources').get('university'),
  universityGroup: state.get('resources').get('universitygroup')
});

const mapDispatchToProps = dispatch => ({
  closePopup: () => dispatch(setCommonPopup('universitySelectorModal', {}))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(withImmutablePropsToJS(UniversitySelectorModal));