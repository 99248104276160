import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form/immutable';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import moment from 'moment';
import _ from 'lodash';
import Checkbox from 'rc-checkbox';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const checkboxOptions = [
  {
    value: 'hasJoinedEvent',
    name: 'イベント参加有無'
  },
  {
    value: 'hasAcceptedOfferEvent',
    name: 'オファー承諾有無 '
  },
  {
    value: 'justLogin',
    name: '最後ログイン'
  }
];

const SearchStudentsForm = ({
  handleSubmit,
  change,
  formValues,
  handleOpenUniversitySelector,
  availableSearchOptions,
  industries = []
}) => {
  const currentYear = moment().year();
  const years = _.range(currentYear, currentYear + 11);
  const yearsOption = years.map((year) => ({
    name: _.toString(year),
    value: _.toString(year)
  }));
  yearsOption.unshift({ name: '卒業年度', value: '' });
  let university = '';
  if (formValues.university) {
    formValues.university.forEach(
      (item) => (university = `${university},${item.name}`)
    );
  }
  return (
    <form onSubmit={handleSubmit} className="search-form">
      <Dropdown
        id="graduation-dropdown"
        options={yearsOption}
        onClickDropdown={(key) => {change('graduationYear', key);}}
        placeholder="卒業年度"
        activeKey={formValues.graduationYear}
        className="filter-dropdown"
        disabled={!availableSearchOptions.includes('graduationYear')}
      />
      <Dropdown
        id="gender-dropdown"
        options={[
          {
            name: '性別',
            value: ''
          },
          {
            name: '男性',
            value: 'MALE'
          },
          {
            name: '女性',
            value: 'FEMALE'
          },
          {
            name: 'どちらでもない',
            value: 'NEITHER'
          }
        ]}
        placeholder="性別"
        activeKey={formValues.gender}
        onClickDropdown={(key) => change('gender', key)}
        onDoubleClick={() => change('gender', '')}
        className="filter-dropdown"
        disabled={!availableSearchOptions.includes('gender')}
      />
      <Dropdown
        id="university-dropdown"
        options={[]}
        placeholder={university.substring(1)}
        onToggle={(isOpen) => {
          if (isOpen) {
            handleOpenUniversitySelector();
          }
        }}
        className="filter-dropdown"
        disabled={!availableSearchOptions.includes('university')}
      />
      {checkboxOptions.map((option) => (
        <label
          className={`filter-checkbox ${formValues[option.value] &&
            'checkbox-checked'}`}
          key={option.value}
          data-disabled={!availableSearchOptions.includes(option.value)}
        >
          {option.name}
          <Checkbox
            checked={formValues[option.value]}
            name={option.name}
            value={option.value}
            onChange={(e) => {
              change(option.value, e.target.checked);
            }}
            disabled={!availableSearchOptions.includes(option.value)}
          />
        </label>
      ))}
      <Dropdown
        id="industry-dropdown"
        options={[ {
          name: '希望業界',
          value: ''
        }, ...industries.map(industry => ({ name: industry.name, value: industry.name }))]}
        onClickDropdown={(key) => { change('industry', key); }}
        placeholder="希望業界"
        activeKey={formValues.industry}
        className="filter-dropdown"
        disabled={!availableSearchOptions.includes('industry')}
      />
      <div className="filter-dropdown" />
      <div className="filter-dropdown" />
      <div className="submit-btn-wrapper">
        <div className="line" />
        <Button className="submit-search" type="submit">
          <img src="/images/icon-search-white.svg" alt="search-icon" />
          検索
        </Button>
      </div>
    </form>
  );
};

SearchStudentsForm.propTypes = {
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  graduateYearFormValue: PropTypes.string,
  genderFormValue: PropTypes.string,
  formValues: PropTypes.object,
  handleOpenUniversitySelector: PropTypes.func,
  availableSearchOptions: PropTypes.array,
  industries: PropTypes.array
};

export default compose(
  reduxForm({
    form: 'searchStudents',
    destroyOnUnmount: false
  }),
  withImmutablePropsToJS
)(SearchStudentsForm);
