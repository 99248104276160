import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const FollowButton = ({ isFollowing = false, handleOpenGochiRequest }) => (
  <div id="set-follow-btn">

    <button
      onClick={isFollowing ? () => true : handleOpenGochiRequest}
      className={isFollowing ? 'unfollow-btn' : 'follow-btn'}
    >
      ごちリクエストを送る<img alt="bowl" src="/images/bowl.svg" />
    </button>
  </div>
);

FollowButton.propTypes = {
  isFollowing: PropTypes.bool,
  handleOpenGochiRequest: PropTypes.func
};

export default FollowButton;