import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Image extends Component {
  state = {
    error: false
  }

  handleError = () => {
    this.setState({ error: true });
  }

  render() {
    const { alt, src, defaultSrc, className = '', handleOnClick } = this.props;
    const { error } = this.state;

    return (
      <img 
        className={className} 
        alt={alt} onError={this.handleError} 
        src={!error ? (src || defaultSrc) : defaultSrc}
        onClick={handleOnClick ? handleOnClick : undefined}
      />
    );
  }
}

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  defaultSrc: PropTypes.string,
  handleOnClick: PropTypes.func
};