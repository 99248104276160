import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import CustomModal from 'containers/CustomModal';
import RequestGochiForm from './form';
import { sendGochiRequest } from '../actions';
import './styles.scss';

class RequestGochiModal extends React.Component {
  handleSubmit = (data) => {
    const { presidentId } = this.props;
    const values = data.toJS ? data.toJS() : data;
    const { message, code, extraText } = values;
    this.props.sendGochiRequest({
      presidentId, 
      message, 
      answer: { code, extraText } 
    });
  }

  render() {
    const { gochiResources = [] } = this.props;
    const initialValues = {
      message: '', code: '', extraText: '', hasExtraText: false
    };

    return (
      <CustomModal closeButton modalName='requestGochi'>
        <RequestGochiForm onSubmit={this.handleSubmit} initialValues={initialValues} gochiResources={gochiResources} />
      </CustomModal>
    );
  }
}

RequestGochiModal.propTypes = {
  presidentId: PropTypes.string,
  gochiResources: PropTypes.array,
  sendGochiRequest: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  sendGochiRequest: 
    ({ presidentId, message, answer }) => dispatch(sendGochiRequest({ presidentId, message, answer }))
});

export default connect(
  null,
  mapDispatchToProps
)(withImmutablePropsToJS(RequestGochiModal));