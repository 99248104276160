import React, { useEffect, useState } from 'react';
import CreateEventForm from '../CreateEvent/form';
import '../CreateEvent/style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { requestEventDetail, unsetEventDetail } from '../EventDetail/actions';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { updateEventRequest } from './action';
import moment from 'moment';
import { destroy } from 'redux-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const EditEvent = ({ match }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const eventId = _get(match, 'params.id', '');
  const eventInfo = useSelector((state) =>
    state.get('eventDetail').get('eventInfo')
  );

  useEffect(() => {
    dispatch(requestEventDetail(eventId));
    return () => {
      dispatch(unsetEventDetail());
    };
  }, [dispatch, eventId]);

  useEffect(() => {
    if (!_isEmpty(eventInfo)) {
      const formattedTime = _get(eventInfo, 'eventDate', '')
        ? moment(eventInfo.eventDate).format('YYYY-MM-DD')
        : '';
      setInitialValues({
        duration: _get(eventInfo, 'duration', '60'),
        eventDate: formattedTime,
        capacity: _get(eventInfo, 'capacity', '5'),
        location: _get(eventInfo, 'location', ''),
        name: _get(eventInfo, 'name', ''),
        eventTime: _get(eventInfo, 'eventTime', moment(_get(eventInfo, 'eventDate'))
          .local()
          .format('HH:mm')),
        image: {
          objectId: _get(eventInfo, 'image.objectId', ''),
          imageUrl: _get(eventInfo, 'image.thumbSmall', '')
        },
        graduateYear:
          _get(eventInfo, 'graduateYear.objectId', '') &&
          _get(eventInfo, 'graduateYear.name', '')
            ? `${eventInfo.graduateYear.objectId}|${eventInfo.graduateYear.name}`
            : '',
        prefecture:
          _get(eventInfo, 'prefecture.objectId', '') &&
          _get(eventInfo, 'prefecture.name', '')
            ? `${eventInfo.prefecture.objectId}|${eventInfo.prefecture.name}`
            : '',
        newDescription: [
          {
            title: _get(eventInfo, 'newDescription[0].title', ''),
            explanation:
              _get(eventInfo, 'newDescription[0].explanation', '') ||
              _get(eventInfo, 'explanation', '')
          },
          {
            title: _get(eventInfo, 'newDescription[1].title', ''),
            explanation: _get(eventInfo, 'newDescription[1].explanation', '')
          },
          {
            title: _get(eventInfo, 'newDescription[2].title', ''),
            explanation: _get(eventInfo, 'newDescription[2].explanation', '')
          }
        ]
      });
      dispatch(destroy('createEvent'));
    }
  }, [eventInfo]);

  const handleSubmit = (value) => {
    let eventInfoData = value.toJS ? value.toJS() : value;
    if (typeof eventInfoData.prefecture === 'string') {
      let prefectureData = eventInfoData.prefecture || '';
      prefectureData = prefectureData.toString().split('|');
      eventInfoData.prefecture = {
        name: prefectureData[1],
        objectId: prefectureData[0]
      };
    }

    if (typeof eventInfoData.graduateYear === 'string') {
      let graduateData = eventInfoData.graduateYear || '';
      graduateData = graduateData.toString().split('|');

      eventInfoData.graduateYear = {
        name: graduateData[1],
        objectId: graduateData[0]
      };
    }

    let image =
      eventInfoData.image.objectId === undefined
        ? eventInfo.image
        : eventInfoData.image;
    eventInfoData.image = image
      ? {
        ...image,
        thumbSmall: image.imageUrl,
        thumbLarge: image.imageUrl
      }
      : {
        thumbSmall: eventInfo.image && eventInfo.image.thumbSmall,
        thumbLarge: eventInfo.image && eventInfo.image.thumbLarge
      };
    dispatch(updateEventRequest(eventId, eventInfoData));
  };

  return (
    <div id="create-event">
      <div className="create-event-container">
        <div className="page-title">イベント編集</div>
        <div className="form-container">
          <CreateEventForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            isEdit
          />
        </div>
      </div>
    </div>
  );
};

EditEvent.propTypes = {
  match: PropTypes.object
};

export default withRouter(EditEvent);
