import { CHOOSE_STUDENT } from './actions';
import { put, takeEvery, call } from 'redux-saga/effects';
import { requestEventDetail } from '../EventDetail/actions';
import { request } from 'utilities/Api';

function* chooseStudent(action) {
  const { eventId = ''} = action.payload;
  try {
    const { userId, actionType } = action.payload;
    if (actionType === 'accept') {
      yield call(request, 'functions/acceptJoinEvent', { eventId, userId }, 'POST');
    }
    if (actionType === 'deny') {
      yield call(request, 'functions/denyRequestJoinEvent', { eventId, userId }, 'POST');
    }
    yield put(requestEventDetail(eventId));
  } catch (e) {
    if (e.code === 143) {
      yield put(requestEventDetail(eventId));
    }
  }
}

export default function* watchChooseStudent() {
  yield takeEvery(CHOOSE_STUDENT, chooseStudent);
}