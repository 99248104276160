import React from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton } from 'react-bootstrap';

import './styles.scss';

const Button = ({
  type = 'button',
  className = '',
  disabled = false,
  block = false,
  children,
  ...props
}) => {
  return (
    <BootstrapButton
      disabled={disabled}
      block={block}
      type={type}
      className={`bootstrap-btn-wrapper ${className}`}
      {...props}
    >
      {children}
    </BootstrapButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.any
};

export default Button;
