import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import TextField from './../../components/Inputs/TextField';
import { Button } from 'react-bootstrap';
import validate from './validate';
import Checkbox from 'rc-checkbox';
import { useState } from 'react';

const StudentSignupForm = (props) => {
  const { handleSubmit, error } = props;
  const [disabled, setDisabled] = useState(true);

  return (
    <form onSubmit={handleSubmit} className="form-horizontal">
      {/* username */}
      <div className="field-container">
        <Field
          component={TextField}
          className="input-form"
          name="username"
          placeholder="メールアドレス"
          type="email"
          autocomplete="off"
        />
      </div>
      {/* Password */}
      <div className="field-container">
        <Field
          component={TextField}
          className="input-form"
          name="password"
          placeholder="パスワード"
          type="password"
          showEye={true}
        />
      </div>
      {
        error ?
          (<div className="alert alert-danger">{error}</div>)
          : null
      }
      {/* Checkbox privacy policy check box */}
      <div className='checkboxGroup'>
        <Checkbox onClick={() => setDisabled(!disabled)} />
        <span className="checkboxLabel">
          <a href="https://careertrip.jp/shachomeshi-agreement/" target="_blank" rel="noopener noreferrer">
            利用規約
          </a>、
          <a href="https://www.unitedwill.jp/privacy/" target="_blank" rel="noopener noreferrer">
            プライバシーポリシー及び個人情報の取り扱い
          </a>
          についての同意
        </span>
      </div>
      <Button
        type="submit"
        disabled={disabled || !props.valid}
        name="submit"
        className={`btn-login ${(!disabled && props.valid) && 'btn-active'}`}
      >
        次へ
      </Button>
    </form>
  );
};

StudentSignupForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  valid: PropTypes.bool
};

export default compose(
  reduxForm({
    form: 'StudentSignupForm',
    destroyOnUnmount: false,
    validate
  }),
  withImmutablePropsToJS
)(StudentSignupForm);