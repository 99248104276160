import { RECEIVE_EVENTS_BY_BANNER, RESET_EVENTS_BANNER } from './action';
import { fromJS } from 'immutable';
import _ from 'lodash';
export const initialState = fromJS({
  list: [],
  hasList: true
});  
export default function bannerEvents(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_EVENTS_BY_BANNER: {
      if (_.isEmpty(action.result)) {
        return state.set('hasList', false);
      }
      return state.set('list', action.result);
    }
    case RESET_EVENTS_BANNER:
      return initialState;
    default:
      return state;
  }
}