import { request } from './../../utilities/Api';
import { put, call, takeEvery } from 'redux-saga/effects';
import { CONFIRM_ATTENDANCE } from './actions';
import { setCommonPopup } from 'containers/Popup/actions';
import { requestEventDetailForMessage } from 'containers/Message/MessageConversation/actions';

function* confirmAttendance(action) {
  try {
    yield call(request, 'functions/confirmAttendance', { eventId: action.eventId }, 'POST');
    yield put(requestEventDetailForMessage(action.eventId));
    yield put(setCommonPopup('24hConfirm', false));
  } catch (error) {
    console.log(error);
  }
}

export function* watchConfirmAttendance() {
  yield takeEvery(CONFIRM_ATTENDANCE, confirmAttendance);
}