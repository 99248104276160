export const REQUEST_EVENTS_BY_BANNER = 'REQUEST_EVENTS_BY_BANNER';
export const RECEIVE_EVENTS_BY_BANNER = 'RECEIVE_EVENTS_BY_BANNER';
export const RECEIVE_EVENTS_BY_BANNER_FAIL = 'RECEIVE_EVENTS_BY_BANNER_FAIL';
export const RESET_EVENTS_BANNER = 'RESET_EVENTS_BANNER';
export const requestGetEventByBanner = events => {
  return {
    type: REQUEST_EVENTS_BY_BANNER,
    events
  };
};

export const receiveGetEventByBanner = result => {
  return {
    type: RECEIVE_EVENTS_BY_BANNER,
    result
  };
};

export const receiveGetEventByBannerFail = () => {
  return {
    type: RECEIVE_EVENTS_BY_BANNER_FAIL
  };
};

export const resetEventByBanner = () => {
  return {
    type: RESET_EVENTS_BANNER
  };
};