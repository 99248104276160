import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { destroy } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import _get from 'lodash/get';

import EventCell from '../EventList/EventCell';
import { getPresidentEventsRequest } from '../actions';
import PrivateLink from 'containers/PrivateLink';
import './styles.scss';

const ReuseEventModal = ({ show, onHide, history }) => {
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    threshold: 1
  });
  const [isVisibleOldEvent, setIsVisibleOldEvent] = useState(false);
  const { list, hasMore, page, isLoading } = useSelector((state) =>
    state.getIn(['eventsManagementByStatus', 'presidentEvents']).toJS()
  );

  useEffect(() => {
    if (!show && isVisibleOldEvent) {
      setIsVisibleOldEvent(false);
    }
    if (show && isVisibleOldEvent) {
      dispatch(getPresidentEventsRequest({ page: 1 }));
    }
  }, [show, isVisibleOldEvent]);

  useEffect(() => {
    if (page > 1 && hasMore && inView && !isLoading) {
      dispatch(getPresidentEventsRequest({ page }));
    }
  }, [inView]);

  const handleDuplicateEvent = (event) => {
    history.push('/createEvent', {
      initialValues: {
        duration: _get(event, 'duration', '60'),
        eventDate: '',
        capacity: _get(event, 'capacity', '5'),
        location: _get(event, 'location', ''),
        name: _get(event, 'name', ''),
        eventTime: '',
        image: {
          objectId: _get(event, 'image.objectId', ''),
          imageUrl: _get(event, 'image.thumbSmall', '')
        },
        graduateYear:
          _get(event, 'graduateYear.objectId', '') &&
          _get(event, 'graduateYear.name', '')
            ? `${event.graduateYear.objectId}|${event.graduateYear.name}`
            : '',
        prefecture:
          _get(event, 'prefecture.objectId', '') &&
          _get(event, 'prefecture.name', '')
            ? `${event.prefecture.objectId}|${event.prefecture.name}`
            : '',
        newDescription: [
          {
            title: _get(event, 'newDescription[0].title', ''),
            explanation:
              _get(event, 'newDescription[0].explanation', '') ||
              _get(event, 'explanation', '')
          },
          {
            title: _get(event, 'newDescription[1].title', ''),
            explanation: _get(event, 'newDescription[1].explanation', '')
          },
          {
            title: _get(event, 'newDescription[2].title', ''),
            explanation: _get(event, 'newDescription[2].explanation', '')
          }
        ]
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} className="reuse-event-modal">
      <div className="reuse-event-modal-header">
        {isVisibleOldEvent && (
          <span
            className="icon-arrow-left"
            onClick={() => setIsVisibleOldEvent(false)}
          />
        )}
        イベントを作成する
      </div>
      {!isVisibleOldEvent ? (
        <div className="create-event-options-wrapper">
          <PrivateLink
            to="/createEvent"
            className="option-content-wrapper"
            handleAction={() => {
              dispatch(destroy('createEvent'));
            }}
          >
            新しいイベント作成
          </PrivateLink>
          <div className="divider">
            <div className="devider-text">OR</div>
          </div>
          <div
            className="option-content-wrapper"
            onClick={() => {
              setIsVisibleOldEvent(true);
            }}
          >
            過去のイベントを読み込み
          </div>
        </div>
      ) : (
        <div className="select-event-list-wrapper">
          {list.map((event) => {
            return (
              <EventCell
                event={event}
                key={`event-${event.objectId}`}
                onInvite={() => {
                  dispatch(destroy('createEvent'));
                  handleDuplicateEvent(event);
                }}
              />
            );
          })}
          {!isLoading && list.length === 0 && (
            <div className="empty-event">
              現在該当するイベントはありません。
            </div>
          )}
          {(hasMore || isLoading) && (
            <div ref={!isLoading ? ref : undefined} className="loading">
              Loading...
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

ReuseEventModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  history: PropTypes.object
};

export default withRouter(ReuseEventModal);
