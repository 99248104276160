/* eslint-disable space-before-function-paren */
/* eslint-disable semi */
import { put, takeEvery, select } from 'redux-saga/effects'

import {
  loginFirebase,
  listenForGroup,
  logoutFirebase,
  listenUnreadMsgs
} from 'utilities/firebase'
import { FETCH_CURRENT_ACCOUNT_SUCCESS, UNSET_ACCOUNT } from '../Account/actions'
import { loginFirebaseSuccess, logOutFirebaseSuccess } from './actions'

function * initFirebase () {
  try {
    const getDataReceived = (state) => state.get('firebaseListener').get('userDataReceived');
    const firebaseDataReceived = yield select(getDataReceived);
    if (!firebaseDataReceived) {
      const data = yield loginFirebase()
      if (data && data.groupId) {
        yield listenUnreadMsgs(data.uid, data.groupId)
      } else {
        yield listenForGroup(data.uid)
      }
      yield put(loginFirebaseSuccess())
    }
  } catch (e) {
    console.log('eor login firebase', e)
  }
}

function * logOutFirebase () {
  try {
    yield logoutFirebase()
    yield put(logOutFirebaseSuccess())
  } catch (err) {
    console.log(err)
  }
}

export default function * watchFirebaseListener () {
  yield takeEvery(FETCH_CURRENT_ACCOUNT_SUCCESS, initFirebase)
  yield takeEvery(UNSET_ACCOUNT, logOutFirebase)
}

                                                       