import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { 
  GET_SEO_DATA_BY_CODE_REQUEST, 
  GET_SEO_DATA_BY_EVENT_ID_REQUEST, 
  GET_SEO_DATA_BY_USER_ID_REQUEST, 
  GET_SEO_DATA_HOME_PAGE,
  getSeoDataSuccess, 
  getSeoDataFailure,
  getSeoDataHomePageSuccess,
  getSeoDataHomePageFail
} from './actions';

function* getSeoDataByCode(action) {
  try {
    const {code} = action;
    const data = yield call(request, 'functions/getSeoDataByCode', {type: 'page', code}, 'POST');
    yield put(getSeoDataSuccess(data.result));
  } catch (e) {
    yield put(getSeoDataFailure());
  }
}

function* getSeoDataByEventId(action) {
  try {
    const {objectId} = action;
    const data = yield call(request, 'functions/getSeoDataByEventId', {type: 'event', objectId}, 'POST');
    yield put(getSeoDataSuccess(data.result));
  } catch (e) {
    yield put(getSeoDataFailure());
  }
}

function* getSeoDataByUserId(action) {
  try {
    const {objectId} = action;
    const data = yield call(request, 'functions/getSeoDataByUserId', {type: 'user', objectId}, 'POST');
    yield put(getSeoDataSuccess(data.result));
  } catch (e) {
    yield put(getSeoDataFailure());
  }
}

function* getSeoDataHomePage(action){
  const { meta } = action;
  try {
    const data = yield call(request, 'functions/getSeoData', {type: 'page', objectId: 'home_page'}, 'POST');
    yield put(getSeoDataHomePageSuccess(meta, data.result ? data.result.seo : {}));
  } catch (e) {
    yield put(getSeoDataHomePageFail(meta));
  }
}

export default function* watchGetSeo() {
  yield takeEvery(GET_SEO_DATA_BY_CODE_REQUEST, getSeoDataByCode);
  yield takeEvery(GET_SEO_DATA_BY_EVENT_ID_REQUEST, getSeoDataByEventId);
  yield takeEvery(GET_SEO_DATA_BY_USER_ID_REQUEST, getSeoDataByUserId);
  yield takeEvery(GET_SEO_DATA_HOME_PAGE, getSeoDataHomePage);
}