import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import './style.scss';

const CampaignList = ({
  columns = [],
  list = [],
  isHaveLoadMore = false,
  onLoadMore,
  keyIndex,
  formatCol,
  isLoading
}) => {
  const isEmptyList = isEmpty(list);
  const columnName = isEmptyList ? [] : columns.map(item => item.name);

  return (
    <>
      <div className={`campaign-point-table
              ${isHaveLoadMore ? ' is-have-more-item' : ''}${isEmptyList ? ' empty-list' : ''}`}>
        <div className="campaign-point-table-row">
          {
            columns.map(item => <div key={item.name} className="campaign-point-table-col">{item.label}</div>)
          }
        </div>

        {
          !isEmptyList && !isEmpty(columnName) && 
            list.map((item, index) => 
              <div 
                key={`item-table-${index}`} 
                className="campaign-point-table-row content-row"
              >
                {
                  columnName.map((ele, idx) => 
                    <div 
                      key={`${keyIndex ? keyIndex : `${ele}-${index}`}`} 
                      className="campaign-point-table-col"
                    >
                      <div className='content-text'>
                        {
                          isEmpty(formatCol) ? item[ele] : (
                            !isEmpty(formatCol[idx]) ? (
                              formatCol[idx].name === ele ? formatCol[idx].render(item[ele]) : item[ele]
                            ) : item[ele]
                          )
                        }
                      </div>
                    </div>)
                }
              </div>
            )
        }
      </div>
      {
        isHaveLoadMore && (
          <div className={`load-more-button${isLoading ? ' disabled' : ''}`} onClick={!isLoading && onLoadMore}>
            {isLoading && <div className="loadersmall"></div> }
            <span>もっと見る</span>
          </div>
        )
      }
      {
        isEmptyList && (
          <div className="empty-list-text">
            履歴がありません。
          </div>
        )
      }
    </>
  );
};

CampaignList.propTypes = {
  columns: PropTypes.array,
  list: PropTypes.object,
  isHaveLoadMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  keyIndex: PropTypes.string,
  formatCol: PropTypes.array,
  isLoading: PropTypes.bool
};

export default CampaignList;
