import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { reduxForm, Field, formValueSelector, change } from 'redux-form/immutable';
import { compose } from 'redux';
import _ from 'lodash';

import { Textarea, RadioBtn } from 'components/Form';
import Button from 'components/Button';
import validate from './validate';

class RequestPresidentForm extends Component {
  componentWillUnmount() {
    const { change } = this.props;
    change('code', '');
    change('extraText', '');
  }
  componentDidUpdate(prevProps) {
    const { change, gochiResources, code } = this.props;
    if (!_.isEqual(prevProps.code, code)) {
      gochiResources.map(item => {
        if (item.objectId === code) {
          change('hasExtraText', item.hasExtraText);
          change('extraText', '');
        }

        return item;
      });
    }
  }

  render() {
    const { 
      message = '', 
      hasExtraText = false, 
      handleSubmit, error, valid, 
      isLoading, 
      gochiResources = [] 
    } = this.props;
    const optionList = gochiResources.map((item) => ({
      value: item.objectId,
      text: item.text
    }));

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-content-container">
          <div className="form-title">主催者へのメッセージ</div>
          <Field
            name="message"
            component={Textarea}
            maxLength={100}
            className="reason-textarea"
            placeholder='社長へのメッセージを記入すると、ごちリクエストが通る可能性がアップします！'
          />
          <div className="reason-counter">{message.length}/100</div>
          <div className="form-title">ごちリクエストを送る目的</div>
          <Field
            name="code"
            className="reason-extra"
            customCheckboxPath='/images/checked.svg'
            customUnCheckboxPath='/images/uncheck.svg'
            options={optionList}
            component={RadioBtn}
          />
          <Field
            name="extraText"
            className={`reason-ex-textarea ${!hasExtraText && 'reason-ex-hide'}`}
            placeholder="コメントを書く…"
            component={Textarea}
            maxLength={100}
          />
          <div className="submit-button">
            <Button 
              disabled={!valid || error || isLoading}
              type="submit">
                ごちリクエスト 送信完了
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

RequestPresidentForm.propTypes = {
  code: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.any,
  gochiResources: PropTypes.array,
  valid: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasExtraText: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  getGochiResources: PropTypes.func
};


const mapStateToProps = (state) => {
  return {
    code: (formValueSelector('requestPresidentForm'))(state, 'code'),
    message: (formValueSelector('requestPresidentForm'))(state, 'message'),
    hasExtraText: (formValueSelector('requestPresidentForm'))(state, 'hasExtraText'),
    isLoading: state.get('gochiEvent').get('isLoading')
  };
};

const mapDispatchToProps = (dispatch) => ({
  change: (field, value) => dispatch(change('requestPresidentForm', field, value))
});

export default compose(
  reduxForm({
    form: 'requestPresidentForm',
    validate,
    destroyOnUnmount: false,
    enableReinitialize: true
  }),
  withImmutablePropsToJS
)(connect(mapStateToProps, mapDispatchToProps)(RequestPresidentForm));