import { fromJS } from 'immutable';
import { FETCH_EVENT_BY_TAG_SUCCESS, RESET_EVENT_BY_TAG } from './actions';
import _ from 'lodash';

const initState = fromJS({
  events: [],
  page: 1,
  hasMore: true
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function eventsByTag(state = initState, action) {
  switch (action.type) {
    case FETCH_EVENT_BY_TAG_SUCCESS: {
      const { events = [] } = action;

      if (_.isEmpty(events)) {
        return state.set('hasMore', false).set('page', state.get('page') + 1);
      }

      state = state.update('events', oldList => {
        oldList = oldList.toJS ? oldList.toJS() : oldList;
        return fromJS([...new Set(oldList.concat(events))]);
      });

      return state.set('page', state.get('page') + 1);
    }

    case RESET_EVENT_BY_TAG: {
      return initState;
    }

    default:
      return state;
  }
}
