import { request } from './../../utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { getCurrentAccount } from './../Account/actions';
import get from 'lodash/get';
import { push } from 'connected-react-router';

function* loginWithLine(action) {
  const { meta } = action;
  try {
    const reqBody = {
      grant_type: 'authorization_code',
      code: action.code,
      redirect_uri: process.env.REACT_APP_LINE_REDIRECT_URI + '/auth',
      client_id: process.env.REACT_APP_LINE_CHANNEL_ID,
      client_secret: process.env.REACT_APP_LINE_CHANNEL_SECRET
    };
    const data = yield call(request, 'functions/getLINEauthData', {authData: reqBody}, 'POST');
    if (!data.result.authData) {
      yield put({type: 'LINE_LOGIN_FAILED', meta, error: true});
      return;
    }

    let authData = yield call(request, 'users', {authData: { line: data.result.authData}}, 'POST', {
      headers: { 'X-Parse-Revocable-Session': 1 }
    });

    if (authData.statusCode === 201) {
      const updateData = {};
      if (data.result.authData.name) {
        updateData.displayName = data.result.authData.name;
        updateData.fullName = data.result.authData.name;
      }
      if (data.result.authData.email) {
        updateData.email = data.result.authData.email;
      }
      try {
        yield call(request, `users/${authData.objectId}`, updateData, 'PUT', {
          headers: { 'X-Parse-Session-Token': authData.sessionToken }
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (authData) {
      if (get(authData, 'phoneVerified', false)) {
        yield put(getCurrentAccount(authData.sessionToken, 'LINE_LOGIN'));
        yield put({type: 'LINE_LOGIN_SUCCESS', meta});
      } else {
        // Info login account: convert to base64 => Array[email, password]
        window.sessionStorage.setItem('account_login_3rd', 
          JSON.stringify([
            btoa(authData.role),
            authData.sessionToken
          ].filter(Boolean)));
        yield put(push('/login-sms-verification'));
      }
    }
  } catch (error) {
    console.log(error);
  }
}


export function* watchLineLogin() {
  yield takeEvery('LOGIN_LINE_REQUEST', loginWithLine);
}