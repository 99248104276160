import React, { Component } from 'react';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import DayOfWeek from './../DayOfWeek';
import Taglist from '../TagList';

class EventItem extends Component {

  render() {
    const { hideInfo = false } = this.props;
    const {image, name, createdBy, graduateYear, location, eventDate, duration, objectId} = this.props.data;
    const eventDateObject = moment(eventDate);
    const finishDate = moment(eventDate).add(duration, 'minutes');
    const background = {
      backgroundImage: 
        `linear-gradient(to top, #000000, rgba(50, 50, 50, 0.3) 40%, rgba(0, 0, 0, 0)),  url('${image.thumbSmall}')`
    };
    const avatar = createdBy.avatarImage ? createdBy.avatarImage.thumbSmall : 'images/default-profile.png';
    return (
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 detail-col">
        <div className="event-detail">
          <Link to={`/event-detail/${objectId}`} className="event-banner" >
            <div className="event-background" style={background} />
            <div className="graduate-title-container">
              {graduateYear && graduateYear.name ? (
                <p className="graduate-title">
                  {graduateYear.name}
                </p>
              ) : null}
            </div>
            {/* <img className="new-icon" src="images/new-icon.svg" /> */}
            <div className="info-container">
              <div className="info">
                <span className="day">
                  <img alt="" src="images/calendar.svg" />
                  {eventDateObject.format('YYYY/MM/DD')} (<DayOfWeek day={eventDateObject.day()}/>)
                </span>
                <span className="location"><img alt="" src="images/location.svg" /> {location}</span>
                <div style={{clear: 'both'}} ></div>
                <span className="time">
                  <img alt="" src="images/time.svg" /> {eventDateObject.format('HH:mm')} ~ {finishDate.format('HH:mm')}
                </span>
              </div>
            </div>
          </Link>
          {!hideInfo && (
            <>
              <div className="description">
                <Link to={`/event-detail/${objectId}`} title="Show more">
                  <img alt="" src="images/next-icon.svg" className="detail-icon"/>
                </Link>
                <div className="ellipsises" onClick={() => this.props.history.push(`/event-detail/${objectId}`)}>
                  <div>{name}</div>
                </div>
                <hr />
                <Link to={`/presidentDetail/${createdBy.objectId}`} className="user-info">
                  <img alt="社長メシ" src={avatar} />
                  <div className="user-col">
                    <p className="username auto-trim">{createdBy.displayName}</p>
                    <p className="status auto-trim">
                      {createdBy.presidentProfile ? createdBy.presidentProfile.companyName : '　'}
                    </p>
                  </div>
                </Link>
              </div>
              <Taglist createdBy={createdBy} />
            </>
          )}
        </div>
      </div>
    );
  }
}

EventItem.propTypes = {
  hideInfo: PropTypes.bool,
  history: PropTypes.object,
  data: PropTypes.object.isRequired
};

export default withRouter(EventItem);
