import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { setCommonPopup } from 'containers/Popup/actions';
import { getMediaInfoByUrlRequest } from '../../SocialMedia/actions';
import { getValueByKey } from 'containers/Popup/selectors';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form/immutable';
import SocialMedia from '../../SocialMedia';
import './style.scss';

class PopupMedia extends Component {
  state = {
    title: '',
    url: '',
    image: '',
    host_name: '',
    error: true,
    isLoading: false
  }

  COOLDOWN_TIME = 760;
  coolDown = null;

  fetchMetaData = url => {
    if (url === '') {
      this.setState({ error: true, isLoading: false });
      return;
    }

    this.props.getMediaInfoByUrl(url).then(data => {
      this.setState({ title: data.title, image: data.image, host_name: data.host_name, error: false, isLoading: false });
    }).catch(() => {
      this.setState({ error: true, isLoading: false });
    });
  }

  handleChange = e => {
    const url = e.target.value;
    clearTimeout(this.coolDown);
    this.coolDown = setTimeout(() => this.fetchMetaData(url), this.COOLDOWN_TIME);
    this.setState({ url: url, isLoading: true });
  }

  handleChangeTitleInput = (e) => {
    this.setState({ title: e.target.value });
  }

  handleCloseMediaPopup = index => {
    const { url, title, image, host_name } = this.state;
    let socialMedias = [...this.props.mediaArticles];
    
    if (index === -1) {
      socialMedias.push({
        url: url,
        title: title,
        image: image,
        host_name: host_name
      });
    }
    if (index !== -1) {
      socialMedias.splice(index, 1, {
        url: url,
        title: title,
        image: image,
        host_name: host_name
      });
    }

    this.props.change('mediaArticles', socialMedias);
    this.setState({ url: '', error: true, isLoading: false });
    this.props.closeMediaPopup();
  }

  // edit
  componentWillReceiveProps(nextProps) {
    const {index} = nextProps.show;
    if (this.props.show.index !== index && index > -1) {
      let socialMedias = this.props.mediaArticles;
      this.setState({
        url: socialMedias[index].url,
        title: socialMedias[index].title,
        image: socialMedias[index].image,
        host_name: socialMedias[index].host_name,
        error: false,
        isLoading: false
      });
    }
  }

  render() {
    const { show, closeMediaPopup } = this.props;
    const { url, title, image, host_name, error, isLoading } = this.state;

    return (
      <Modal show={!!show} className="media-popup">
        <img 
          alt="" 
          src="/images/Quit.svg" 
          className="close-btn" 
          onClick={() => { 
            closeMediaPopup(); 
            this.setState({ url: '', error: true }); 
          }} 
        />
        <div className="title">リンクを追加</div>
        <div className="custom-field-wrapper">
          <div className="custom-input-container">
            <span className="subtitle">URLを入力してください</span>
            <input type="url" className="form-control input-md" onChange={this.handleChange} value={url} />
          </div>
        </div>

        {!error && (
          <Fragment>
            <SocialMedia socialMedia={{ url, title, image, host_name }} preview={true} />
            <div className="custom-field-wrapper">
              <div className="custom-input-container">
                <span className="subtitle">タイトルを編集</span>
                <input 
                  type="text" 
                  className="form-control input-md" 
                  value={title} 
                  onChange={this.handleChangeTitleInput} 
                />
              </div>
            </div>
          </Fragment>
        )}
        
        {error && (
          <div className="error">
            <img alt="" src="/images/not-found-og.svg" />
            <div className="error-message">
              {(url !== '' && !isLoading) ? '該当するページが見つかりません' : 'あなたのクリップボードにリンクはありません'}
            </div>
          </div>
        )}
        
        <div 
          className={`submit-btn ${(error || isLoading || title === '') ? 'submit-btn--disabled' : ''}`} 
          onClick={() => !error && !isLoading && title !== '' && this.handleCloseMediaPopup(show.index)}
        >
          追加する
        </div>
      </Modal>
    );
  }
}

PopupMedia.propTypes = {
  show: PropTypes.any,
  change: PropTypes.func,
  closeMediaPopup: PropTypes.func,
  getMediaInfoByUrl: PropTypes.func,
  mediaArticles: PropTypes.array
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('StudentProfileForm');

  return {
    show: getValueByKey(state, 'media'),
    mediaArticles: selector(state, 'mediaArticles') || []
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeMediaPopup: () => dispatch(setCommonPopup('media', false)),
  getMediaInfoByUrl: url => dispatch(getMediaInfoByUrlRequest(url))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
  reduxForm({
    form: 'StudentProfileForm'
  })
)(PopupMedia);