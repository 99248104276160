import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';

import './styles.scss';

const CollapseComponent = ({
  title,
  className = '',
  isOpen,
  isEmpty = false,
  handleToggle,
  handleLoadMore,
  animationDuration = 300,
  children
}) => (
  <div className={`collapse-container ${className}`}>
    <div className={`collapse-title ${isOpen && 'open'} ${isEmpty && 'hidden-icon'}`} onClick={handleToggle}>
      {title}
    </div>  
    <Collapse in={isOpen} timeout={animationDuration}>
      <div className="collapse-content">
        {children}
        {handleLoadMore && (
          <button className="collapse-load-more" onClick={handleLoadMore}>
            もっと見る
          </button>
        )}
      </div>
    </Collapse>
  </div>
);

CollapseComponent.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  isEmpty: PropTypes.bool,
  handleToggle: PropTypes.func,
  handleLoadMore: PropTypes.func,
  animationDuration: PropTypes.number,
  children: PropTypes.node
};

export default CollapseComponent;