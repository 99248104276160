import { request } from './../../utilities/Api';
import { put, call } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { FETCH_BANNER_REQUEST, fetchBannerSuccess, fetchBannerFail } from './actions';

function* getBanners() {
  try {
    const data = yield call(request, 'functions/getBanners', null, 'POST');
    yield put(fetchBannerSuccess(data.result));
  } catch (error) {
    yield put(fetchBannerFail());
  }
}

export default function* watchBannersForCarousel() {
  yield takeFirst(FETCH_BANNER_REQUEST, getBanners);
}