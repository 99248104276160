import React, { Component } from 'react';
import EmailChangeForm from './form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import { resetChangeEmail } from './action';

class EmailChange extends Component {
  
  componentWillUnmount() {
    this.props.resetChangeEmail();
  }

  render() {
    return (
      <div className='email-change'>
        <div className='email-change-container'>
          <div className='email-change-title'>
            メールアドレスの変更
          </div>
          <div className='email-change-form-container'>
            { !this.props.changeEmailSuccess ?
              <EmailChangeForm />
              : <div className='email-change-form-success'>
                <div className='email-change-form-success-img'>
                  <img src='images/email-success.svg' alt='change-success'/>
                </div>
                <div className='email-change-form-success-content'>
                  <div className='email-change-form-success-content-text'>
                  新しいメールアドレスへメールをお送りしました。
                    <br/>
                  メールに記載されたURLをクリックし、メールアドレスの変更を完了してください。
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

EmailChange.propTypes = {
  changeEmailSuccess: PropTypes.bool,
  resetChangeEmail: PropTypes.func
};

const mapStateToProps = (state) => ({
  changeEmailSuccess: state.get('emailChange').get('changeEmailSuccess')
});

const mapDispatchToProps = (dispatch) => ({
  resetChangeEmail: () => dispatch(resetChangeEmail())
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailChange);