import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

class TriggerSaveAccount extends Component {

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentUser = this.props.currentUser;
    const nextUser = nextProps.currentUser;
    if (
      currentUser.loading === true &&
      nextUser.loading === false &&
      !(currentUser.token !== null && nextUser.token === null)
    ) {
      this.props.endSaveAccount(nextUser.message);
    }
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

TriggerSaveAccount.propTypes = {
  endSaveAccount: PropTypes.func,
  currentUser: PropTypes.any,
  children: PropTypes.any
};

TriggerSaveAccount.defaultProps = {
  endSaveAccount: () => null
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account')
  };
};

export default connect(
  mapStateToProps
)(withImmutablePropsToJS(TriggerSaveAccount));
