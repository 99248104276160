import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import _ from 'lodash';

import { reviewEvent, completeReview } from './action';
import Loading from 'components/Loading';
import Button from 'components/Button';
import PresidentReview from './PresidentReview';
import StudentReview from './StudentReview';
import { trimObjectList } from 'utilities/handleValues';
import { requestEventDetail } from 'containers/EventDetail/actions';
import './style.scss';

class EventReview extends React.Component {  
  componentDidMount() {
    this.props.requestEventDetail(this.props.review.eventId);
  }
  
  handleSubmit = values => {
    values = values.toJS();
    if (this.props.role === 'STUDENT') {
      if (values.hasJoin) {
        this.props.reviewEvent(trimObjectList(values));
      }
      if (!values.hasJoin) {
        this.props.reviewEvent({ eventId: values.eventId, hasJoin: false });
      }
    }
    
    if (this.props.role === 'PRESIDENT') {
      const invitedStudents = [];
      const attendanceInfo = values.reviewStudents.map(e => {
        if (e.isInvited) invitedStudents.push(e.userId);
        return {
          userId: e.userId,
          status: e.attendance
        };
      });
      values.attendanceInfo = [...attendanceInfo];
      values.invitedStudents = invitedStudents;
      this.props.reviewEvent(trimObjectList(values));
    }
  };
  
  render() {
    const { review, role } = this.props;
    const { eventId, reviewStudents, reviewSuccess, haveAbsence } = review;
    
    let initialValues;
    if (role === 'PRESIDENT') {
      initialValues = {
        eventId: eventId,
        reviewStudents: [...reviewStudents],
        reviewEvent: {
          star: 0
        }
      };
    }
    if (role === 'STUDENT') {
      initialValues = {
        eventId: eventId,
        votePresident: 0,
        questionAnswers: {},
        hasJoin: true
      };
    }

    if (reviewSuccess) {
      return (
        <div id='review-page'>
          <div className='congrats-message-container event-review-container'>
            <img src='images/thanks-icon.svg' alt='congratulation'/>
            <div className='congrats-message'>
              { role === 'PRESIDENT' && (haveAbsence ? 
                <>
                  イベント開催お疲れさまでした！
                  <br />
                  レビュー登録ありがとうございます。
                  <br />
                  欠席をご登録いただいておりますので運営より詳細を確認させていただき、
                  <br />
                  万が一無断欠席の場合は利用停止の対応をいたします。
                  <br />
                  次回開催もよろしくお願いいたします。
                </> 
                : 'イベント開催お疲れさまでした！レビュー登録ありがとうございます。次回開催もよろしくお願いいたします。'
              )}
              { role === 'STUDENT' &&
                'イベント参加お疲れ様でした！レビュー登録ありがとうございます。今後もよろしくお願いいたします。'
              }
            </div>
              
            <div className='btn-wrapper'>
              <Button 
                className='btn-submit' 
                type='button'
                onClick={() => this.props.completeReview()}
              >
                HOMEへ戻る
              </Button>
            </div>
          </div>
        </div>
      );
    }
    
    if (_.isEmpty(eventId)) return <Loading />;
    return (
      <div id='review-page'>
        {role === 'PRESIDENT' ? 
          <PresidentReview
            initialValues={initialValues}
            key={eventId}
            onSubmit={this.handleSubmit}
          /> : 
          <StudentReview
            initialValues={initialValues}
            key={eventId}
            onSubmit={this.handleSubmit}
          />
        }
      </div>
    );
  }
}

EventReview.propTypes = {
  review: PropTypes.object,
  role: PropTypes.string,
  history: PropTypes.object,
  reviewStudents: PropTypes.func,
  reviewEvent: PropTypes.func,
  toggleAbsencePopup: PropTypes.func,
  requestEventDetail: PropTypes.func,
  completeReview: PropTypes.func
};

const mapStateToProps = state => ({
  review: state.get('review'),
  role: state.get('account').get('data').get('role')
});

const mapDispatchToProps = dispatch => ({
  reviewEvent: (values) => dispatch(reviewEvent(values)),
  requestEventDetail: (eventId) => dispatch(requestEventDetail(eventId)),
  completeReview: () => dispatch(completeReview())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withImmutablePropsToJS(EventReview));