import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { reduxForm, Field } from 'redux-form/immutable';
import { Input } from 'components/Form';
import './style.scss';
import validate from './validate';

class ForgotPasswordForm extends Component {

  render() {
    const { pristine, valid, handleSubmit } = this.props;

    return (
      <Fragment>
        <div className="forgot-pass-section">
          <div className="forgot-pass-title">パスワードの再設定</div>
          <form className="forgot-pass-form-container" onSubmit={handleSubmit}>
            <Field
              name="email"
              type="email"
              label="メールアドレス"
              component={Input}
              customForm={true}
              maxLength={100}
            />
            <button
              className={ `send-email-btn ${!valid || pristine ? 'disable-send-btn' : 'active-send-btn'} `}
              disabled={!valid || pristine}
              name="submit"
              type="submit"
            >
              送信
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

ForgotPasswordForm.propTypes = {
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  handleSubmit: PropTypes.func
};


export default compose(
  reduxForm({
    form: 'forgotPassForm',
    validate,
    enableReinitialize: false
  }),
  withImmutablePropsToJS
)(ForgotPasswordForm);

