import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form/immutable'; 
import _ from 'lodash';

import StudentList from '../StudentList';
import EventCell from 'containers/Management/EventsManagement/EventList/EventCell';

const StepOne = ({
  eventInfo,
  setOpenEventDetail,
  reviewStudents,
  openReviewSelectedStudent,
  openConfirmPopup,
  onChangeField
}) => {
  return (
    <div className='step-one-wrapper'>
      <div className='text-note mb-4'>社長メシのご利用ありがとうございました。学生の出欠とレビューを登録してください </div>
      <div className='event-joined-container'>
        <div className='header'>
          参加したイベント
        </div>
        <div
          className='event-detail'
          onClick={() => setOpenEventDetail(true)}
        >
          { !_.isEmpty(eventInfo) && 
            <EventCell event={eventInfo} />
          }
        </div> 
      </div>
      <div className='text-note mt-40 mb-4'>
        この評価は学生には表示されません
      </div>
      <FieldArray 
        name='reviewStudents' 
        component={StudentList}
        reviewStudents={reviewStudents}
        openReviewSelectedStudent={openReviewSelectedStudent}
        onChangeField={onChangeField}
        openConfirmPopup={openConfirmPopup}
      />
    </div>
  );
};

StepOne.propTypes = {
  reviewStudents: PropTypes.array,
  eventInfo: PropTypes.object,
  setOpenEventDetail: PropTypes.func,
  openReviewSelectedStudent: PropTypes.func,
  openConfirmPopup: PropTypes.func,
  onChangeField: PropTypes.func
};

export default StepOne;
