import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { fetchNoticeList } from 'containers/Notification/actions';
import NotificationList from 'containers/Notification/NotificationPage/NotificationList';
class NotificationListForIcon extends Component {
  componentDidMount() {
    if (this.props.notificationList.length === 0) {
      this.props.fetchNoticeList(this.props.page, this.props.hasMore, false);
    }
  }
  render() {
    const notificationList = this.props.notificationList || [];
    const data = this.props.data || {};
    return (
      <div className="noti-list">
        <NotificationList
          closePopOver={this.props.closePopOver}
          notificationList={notificationList}
          data={data}
          displayNumber={4} />
      </div>
    );
  }
}

NotificationListForIcon.propTypes = {
  notificationList: PropTypes.array,
  page: PropTypes.number,
  hasMore: PropTypes.bool,
  fetchNoticeList: PropTypes.func
};
const mapStateToProps = (state) => {
  return {
    notificationList: state.get('getNoticeList').get('noticeList'),
    data: state.get('getNoticeList').get('data'),
    page: state.get('getNoticeList').get('page'),
    hasMore: state.get('getNoticeList').get('hasMore')
  };
};
const mapDispatchToProps = dispatch => ({
  fetchNoticeList: (page, hasMore, loading) => dispatch(fetchNoticeList(page, hasMore, loading))
});
NotificationListForIcon.propTypes = {
  notificationList: PropTypes.array,
  data: PropTypes.object,
  closePopOver: PropTypes.func,
  fetchNoticeList: PropTypes.func,
  page: PropTypes.number,
  hasMore: PropTypes.bool
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(NotificationListForIcon));