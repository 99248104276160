import { fromJS } from 'immutable';
import { SET_REDIRECT_URN } from './action';

const initState = fromJS({
  URN: ''
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function redirectURNStorage(state = initState, action) {
  switch (action.type) {
    case SET_REDIRECT_URN: {
      const newState = state;
      return newState.set('URN', action.URN);
    }
    default:
      return state;
  }
}
