import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ReferralCode = ({
  onHandleCopyCode,
  value
}) => {
  const [tooltip, setTooltip] = useState('コピー紹介コード');
  const val = value || '紹介コード';
  
  return (
    <div className='referral-code-container'>
      <div className='view-code-box'>{val}</div>
      <div type='input'  
        onClick={() => {
          setTooltip(`コピー: ${value}`);
          value && onHandleCopyCode(value);
        }}
        onMouseOut={() => setTooltip('コピー紹介コード')}
        className={'campaign-button'}
      >
        <span>コピー</span>
        <span className='campaign-tooltip'>{tooltip}</span>
      </div>
    </div>
  );
};

ReferralCode.propTypes = {
  onHandleCopyCode: PropTypes.func,
  value: PropTypes.string
};

export default ReferralCode;