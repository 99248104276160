import React, { Component } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { STUDENT_MANAGEMENT_FILTERS } from 'utilities/constants';
import EventListSmallCell from './EventListSmallCell';
import DeniedStudentCell from '../../containers/Management/StudentsManagement/FollowList/FollowerCell';
import ReviewCell from '../../containers/Management/StudentsManagement/EventList/EventSection/ReviewCell';
import './style.css';

class EventListSmall extends Component {
  constructor(props) {
    super(props);

    /**
     * @property {string} path
     * 
     *    @value = '/event-history' (Student role) Actually equal to undefined b/c of not passing this prop
     *    @value = '/management-student/' (President role)
     */
    const { path } = props;
    const open = path && path.startsWith('/management-student') ? false : true;

    this.state = {
      open: open
    };
  }

  /**
   * Automatically collapse with page = 1 & limit = 5
   * 
   * @case '/event-history' (Student role) All event section
   * @case '/management-student/' (President role) Only first event section
   */
  componentDidMount() {
    if (this.props.firstSection) {
      this.setState({ open: true });
    }
  }

  // Render event cells in '/event-history' (Student role)
  renderCells() {
    let cells = this.props.events.map((event) => {
      return <EventListSmallCell eventInfo={event} key={event.objectId} role={this.props.role} />;
    });

    return (<div className="event-list">{cells}</div>);
  }

  // Render denied student cells in '/management-student/students-of-event' (President role)
  renderDeniedStudentCells() {
    const { event, data, eventId } = this.props;

    if (!event.list) {
      return null;
    }
    
    /**
     * @property {string} status Use for changing content under info
     * @property {string} eventId Need for going to student page by clicking on denied student cell in students management
     */
    let cells = event.list.map(studentId => {
      return (
        <DeniedStudentCell 
          userInfo={data[studentId]} 
          key={studentId + eventId} 
          status="denied" 
          eventId={eventId} 
        />
      );
    });

    return (<div className="denied-student-list">{cells}</div>);
  }

  // Render approved cells in '/management-student/students-of-event' (President role)
  renderReviewCells() {
    const { event } = this.props;

    if (!event.list) {
      return null;
    }

    let cells = event.list.map(reviewId => {
      return (<ReviewCell review={this.props.data[reviewId]} key={reviewId} status="review" />);
    });

    return (<div className="review-list">{cells}</div>);
  }

  loadMore = () => {
    const { path, event } = this.props;
    
    if (path && path.startsWith('/management-student')) {
      this.props.fetchCellData(event.page);
    } else {
      this.props.requestEventHistoryByStatus();
    }
  }

  render() {
    const { open } = this.state;
    const { path, events, hasMore, activeFilter } = this.props;
    const isEmptyList = hasMore === false && events.length === 0;

    let collapseBtnClass = 'collapse-btn down';
    let titleClass = 'closeTitle';
    // Just collapse when having event (list isn't empty)
    if (open && events.length !== 0) {
      collapseBtnClass = 'collapse-btn up';
      titleClass = 'openTitle';
    }

    let cells = null;
    if (!path) { // "/event-history" (student role)
      cells = this.renderCells();
    }
    if (path === '/management-student/students-of-event') {
      if (activeFilter === STUDENT_MANAGEMENT_FILTERS.APPROVED) {
        cells = this.renderReviewCells();
      } else {
        cells = this.renderDeniedStudentCells();
      }
    }

    return (
      <div className="event-list-small">
        <div className="event-group-title">
          <Button
            onClick={() => {
              if (path && path.startsWith('/management-student') && !open) {
                this.props.fetchCellData(1);
              }

              !isEmptyList && this.setState({ open: !open });
            }}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className={titleClass}
          >
            <span className="title">{this.props.title}</span>
            {!isEmptyList && (<div className={collapseBtnClass} />)}
          </Button>
        </div>
        <div className="event-group cell-container">
          <Collapse in={this.state.open}>
            <div id="example-collapse-text" className={hasMore === false ? 'noMore' : ''}>
              {cells}
              {hasMore && (
                <Button className="load-more-btn" onClick={this.loadMore}>
                  もっと見る
                </Button>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

EventListSmall.defaultProps = {
  events: []
};

EventListSmall.propTypes = {
  firstSection: PropTypes.bool,
  activeFilter: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.string,
  eventId: PropTypes.string,
  events: PropTypes.array,
  role: PropTypes.string,
  hasMore: PropTypes.any,
  event: PropTypes.object,
  data: PropTypes.object,
  fetchCellData: PropTypes.func,
  requestEventHistoryByStatus: PropTypes.func
};

export default EventListSmall;