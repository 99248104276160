import { fromJS } from 'immutable';
import { GET_SEO_DATA_SUCCESS, GET_SEO_DATA_FAILURE } from './actions';
const THUMB_LOGO = '/thumbnail-logo.jpg';

const initState = fromJS({
  title: '社長メシ | 就活の場をデスクからテーブルへ',
  description: '「社長ゴチになります」社長メシは社長に直接自分をPRできるこれまでになかった社長直接採用サイトです',
  keyword: '',
  image: THUMB_LOGO
});

/**
 * example
 * @param state
 * @param action
 * @returns {object}
 */
export default function getSeo(state = initState, action) {
  switch (action.type) {
    case GET_SEO_DATA_SUCCESS:
      return fromJS(action.seo);
    case GET_SEO_DATA_FAILURE:
      return initState;
    default:
      return state;
  }
}
