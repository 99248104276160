import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const StepTwo = ({
  reviewStudents
}) => {
  const list = _.reduce(reviewStudents, function(result, value) {
    (result[value.attendance] || (result[value.attendance] = [])).push(value);
    return result;
  }, {});

  const renderStatus = (isInvited) => (isInvited ? 
    <div className='student-invited'>選考に進める</div> :
    <div className='student-invited denied'>選考に進めない</div>);
  
  const renderItem = (list) => {
    if (list) {
      const title = list[0].attendance === 'ATTENDANCE' ? '出席' : 
        list[0].attendance === 'DELAY' ? '遅刻' : '欠席';
      const className = list[0].attendance === 'ATTENDANCE' ? '' : 
        list[0].attendance === 'DELAY' ? 'banner-late' : 'banner-absence';
      return (
        <div className='student-list'>
          <div className={`banner ${className}`}>{title}</div>
          {list.map(item => (
            <div key={item.userId} className='student-status'>
              <div className='avatar-container'>
                <img 
                  src={_.get(item, 'avatarImage.thumbSmall', '/images/default-avatar.png')} 
                  alt='avatar'
                  draggable='false'
                />
              </div>
              <div className='student-name'>{item.displayName}</div>
              {item.attendance !== 'ABSENT' && renderStatus(item.isInvited)}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className='step-two-wrapper'>
      {renderItem(list.ATTENDANCE)}
      {renderItem(list.DELAY)}
      {renderItem(list.ABSENT)}
      <hr />
    </div>
  );
};

StepTwo.propTypes = {
  reviewStudents: PropTypes.array
};

export default StepTwo;
