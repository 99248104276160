import React, { Component } from 'react';
import PropTypes from 'prop-types';

const RequiredText = <span style={{color: 'red'}}> (*)</span>;

class ElementRenderer extends Component {
  render() {
    const { required, meta, label, ...rest} = this.props;
    return (
      <div className={[
        'form-group',
        meta.error && meta.touched ? 'has-error' : ''
      ].join(' ')}>
        <label className="col-md-4 control-label">{label}{required ? RequiredText : null}</label>
        <div className="col-md-8">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              {this.props.render(rest)}
            </div>
            {meta.error && meta.touched &&
              <div className="col-xs-12 col-md-12">
                <p className="help-block text-danger">
                  {meta.error}
                </p>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

ElementRenderer.propTypes = {
  required: PropTypes.bool,
  render: PropTypes.func.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string
};

export default ElementRenderer;