/* eslint-disable no-useless-escape */
/* eslint-disable no-irregular-whitespace */
import { request } from 'utilities/Api';
import { put, takeEvery, call, takeLatest, select } from 'redux-saga/effects';
import {
  CREATE_OFFER_TEMPLATE_REQUEST,
  createOfferTemplateSuccess,
  createOfferTemplateFailure,
  FETCH_OFFER_TEMPLATE_REQUEST,
  fetchOfferTemplateSuccess,
  fetchOfferTemplateFailure,
  REMOVE_OFFER_TEMPLATE_REQUEST,
  removeOfferTemplateSuccess,
  removeOfferTemplateFailure,
  CREATE_OFFER_EVENT_REQUEST,
  createOfferEventSuccess,
  createOfferEventFailure
} from './actions';
import { setCommonPopup } from '../../containers/Popup/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _find from 'lodash/find';

import { destroy } from 'redux-form';
import { toast } from 'react-toastify';
import { getPlans } from '../Plans/actions';

function* createOfferTemplate(action) {
  try {
    yield put(showLoading());
    const { payload } = action;
    const data = yield call(
      request,
      'functions/createOfferTemplate',
      payload,
      'POST'
    );
    yield put(createOfferTemplateSuccess(data, action.meta));
    toast.success('テンプレートを正常に作成しました。');
    yield put(destroy('create-template'));
    yield put(hideLoading());
  } catch (e) {
    if (e /*&& e.error*/) {
      yield put(createOfferTemplateFailure(e, action.meta));
      yield put(hideLoading());
    }
  }
}

function* fetchOfferTemplate() {
  try {
    const data = yield call(
      request,
      'functions/getOfferTemplates',
      { page: 1, limit: 20 },
      'POST'
    );
    yield put(fetchOfferTemplateSuccess(data.result));
  } catch (error) {
    if (error /*&& e.error*/) {
      yield put(fetchOfferTemplateFailure(error));
      yield put(hideLoading());
    }
  }
}

function* removeOfferTemplate(action) {
  try {
    const { payload } = action;
    yield call(request, 'functions/removeOfferTemplate', payload, 'POST');
    yield put(removeOfferTemplateSuccess(payload, action.meta));
  } catch (error) {
    if (error /*&& e.error*/) {
      yield put(removeOfferTemplateFailure(error));
      yield put(hideLoading());
    }
  }
}

function* createOfferEvent(action) {
  try {
    const currentUser = yield select((state) =>
      state
        .get('account')
        .get('data')
        .toJS()
    );
    if (
      _get(currentUser, 'isCompletedProfile') === false ||
      !_get(currentUser, 'avatarImage')
    ) {
      yield put(
        setCommonPopup('success', {
          title:
            'プロフィール写真を登録してください。\n※プロフィールアップデート完了後にご利用いただけます。',
          btnLabel: 'はい'
        })
      );
      return;
    }
    yield put(showLoading());
    const { payload } = action;
    let filter = yield select((state) =>
      state.getIn(['form', 'searchStudents', 'values'])
    );
    if (filter) {
      filter = { ...filter.toJS() };
      if (
        filter.university &&
        !_find(filter.university, { objectId: 'u000000196' })
      ) {
        const universities = filter.university.map((item) => item.objectId);
        filter.university = universities.join(',');
      } else {
        filter.university = '';
      }
    }
    const params = payload.selectAll
      ? {
        selectAll: payload.selectAll,
        excludedIds: payload.studentIds,
        ...filter
      }
      : { studentIds: payload.studentIds };
    const { result: checkingResult } = yield call(
      request,
      'functions/checkCreatingOfferEvent',
      params,
      'POST'
    );
    const createNewParams = {
      ..._pick(payload, ['name', 'description']),
      ...params
    };
    let passChecking = true;
    checkingResult.forEach((result) => {
      if (!result.value) {
        passChecking = false;
        if (result.key === 'availablePlanSlot') {
          window.store.dispatch(
            setCommonPopup('success', {
              title: `オファー枠を確認してください。\n 選択した学生数　：${_get(
                result,
                'data.numOfStudent',
                0
              )} \n 残りオファー枠数　：${_get(result, 'data.totalSlot', 0)}`,
              btnLabel: 'はい'
            })
          );
        } else {
          toast.error(`${result.key} failed`);
        }
      }
      return;
    });
    if (passChecking) {
      const { result } = yield call(
        request,
        'functions/createOfferEventByPresident',
        createNewParams,
        'POST'
      );
      if (!_isEmpty(_get(result, 'requestedStudents', []))) {
        toast.success('送信が完了しました。');
      } else {
        yield put(
          setCommonPopup('success', {
            title: 'Cant create event with any students',
            btnLabel: 'はい'
          })
        );
      }
      yield put(createOfferEventSuccess(result, action.meta));
      yield put(getPlans());
    }
    yield put(hideLoading());
  } catch (e) {
    yield put(
      setCommonPopup('success', {
        title: e.error,
        btnLabel: 'はい'
      })
    );
    yield put(hideLoading());
    yield put(createOfferEventFailure(e, action.meta));
  }
}

export function* watchOfferTemplate() {
  yield takeEvery(CREATE_OFFER_TEMPLATE_REQUEST, createOfferTemplate);
  yield takeEvery(FETCH_OFFER_TEMPLATE_REQUEST, fetchOfferTemplate);
  yield takeEvery(REMOVE_OFFER_TEMPLATE_REQUEST, removeOfferTemplate);
  yield takeLatest(CREATE_OFFER_EVENT_REQUEST, createOfferEvent);
}
