import isAsciiEmail from 'sane-email-validation';

const validate = values => {
  const errors = {};
  if (!values.has('email')) {
    errors.email = 'この値は必須です。';
  } else if (!isAsciiEmail(values.get('email'))) {
    errors.email = '有効なメールアドレスを入力してください';
  }
  if (!values.has('password')) {
    errors.password = 'この値は必須です。';
  }
  if (Object.keys(errors).length === 0) {
    errors.loginButton = 'btn-active';
  }
  return errors;
};

export default validate;
