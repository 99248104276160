import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import _get from 'lodash/get';
import Checkbox from 'rc-checkbox';
import moment from 'moment';

import Button from 'components/Button';
import {
  GOCHI_TYPE,
  gochiAvailableStatus,
  offerAvailableStatus,
  OFFER_EVENT_STATUS_KEYS
} from 'utilities/constants';
import StudentAvatar from './StudentAvatar';
import Dropdown from 'components/Dropdown';
import './style.scss';
import Countdown from '../../../../../components/DayRemain/Countdown';

class FollowerCell extends Component {
  renderAcceptGochiButton = () => {
    const { status, userInfo } = this.props;

    if (_.get(userInfo, 'presidentStatus', '')) {
      if (userInfo.presidentStatus === GOCHI_TYPE.NEW) {
        return (
          <Button
            disabled={_.get(userInfo, 'status', '') === 'ACCEPTED'}
            className="follower-cell__info__request"
            onClick={(e) => {
              e.preventDefault();
              if (this.props.acceptGochiRequest)
                this.props.acceptGochiRequest();
            }}
          >
            この学生に会いたい
          </Button>
        );
      }
      return (
        <div className="follower-cell__info__offer-student">
          <Dropdown
            id="gochi-status-dropdown"
            options={gochiAvailableStatus[userInfo.presidentStatus] || []}
            activeKey={userInfo.presidentStatus}
            onToggle={(isOpen, e) => e.preventDefault()}
            onClickDropdown={this.props.onChangeOfferStatus}
            hiddenActive
          />
          {userInfo.conversationId && (
            <div className="follower-cell__info__to-chat-wrapper">
              <Link
                to={{
                  pathname: `/messages/${userInfo.conversationId}`,
                  state: {
                    eventId: userInfo.conversationId
                  }
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Button className={'follower-cell__info__request'}>
                  <img src="images/icon-dashboard-message.png" alt="Chat icon" />
                チャットする
                </Button>
              </Link>
              <div className="follower-cell__info__search-student__note">
              ※β版有料
              </div>
            </div>
          )}
        </div>
      );
    } else if (status === 'search') {
      const { openSelectTemplateModal, checkboxProps } = this.props;
      const time = _get(userInfo, 'presidentRejectStudentOfferCandidate', '');

      return (
        <div className="follower-cell__info__search-student">
          <Button
            className="follower-cell__info__request"
            onClick={(e) => {
              e.preventDefault();
              if (openSelectTemplateModal) openSelectTemplateModal();
            }}
            disabled={checkboxProps.disabled}
          >
            オファーを送る
            <Countdown time={time} />
          </Button>
          <div className="follower-cell__info__search-student__note">
            ※β版有料
          </div>
        </div>
      );
    } else if (this.props.offerEventStatus) {
      const { offerEventStatus, eventId } = this.props;
      const isAccepted = [
        OFFER_EVENT_STATUS_KEYS.ACCEPTED_REQUEST,
        OFFER_EVENT_STATUS_KEYS.REJECTED_CANDIDATE,
        OFFER_EVENT_STATUS_KEYS.EXAMINING_CANDIDATE,
        OFFER_EVENT_STATUS_KEYS.APPOINTED
      ].includes(offerEventStatus);
      return (
        <div className="follower-cell__info__offer-student">
          <Dropdown
            id="offer-status-dropdown"
            options={offerAvailableStatus[offerEventStatus] || []}
            activeKey={offerEventStatus}
            onToggle={(isOpen, e) => e.preventDefault()}
            onClickDropdown={this.props.onChangeOfferStatus}
            hiddenActive
            disabled={(offerAvailableStatus[offerEventStatus] || []).length < 2}
          />
          <div className="follower-cell__info__to-chat-wrapper">
            <Link
              to={
                isAccepted
                  ? {
                    pathname: `/messages/${eventId}`,
                    state: {
                      eventId: eventId
                    }
                  }
                  : '#'
              }
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                className={`follower-cell__info__request
                ${isAccepted ? '' : 'disabled'}`}
              >
                <img src="images/icon-dashboard-message.png" alt="Chat icon" />
                チャットする
              </Button>
            </Link>
            <div className="follower-cell__info__search-student__note">
              ※β版有料
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  renderExtraContent = () => {
    const { userInfo } = this.props;
    const { studentProfile = {} } = userInfo;

    return (
      <React.Fragment>
        {studentProfile.introduction && (
          <Fragment>
            <div className="follower-cell__intro__title">自己PR</div>
            <div className="follower-cell__intro__content ellip-overflow">
              {studentProfile.introduction}
            </div>
          </Fragment>
        )}

        <div className="follower-cell__tag-group">
          {studentProfile.graduationYear && (
            <div className="follower-cell__tag mw-fit mr-2">
              {studentProfile.graduationYear}年卒
            </div>
          )}
          {studentProfile.club && (
            <div className="follower-cell__tag ellip-overflow">
              {studentProfile.club}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      userInfo = {},
      status,
      eventId,
      currentUserId,
      offerEventStatus
    } = this.props;
    const {
      studentProfile = {},
      rejectedPresidentsInOfferEvent = [],
      displayName,
      fullname
    } = userInfo;
    const studentSchoolName = _.get(studentProfile, 'university', '');
    const studentName = `${displayName || fullname || ''}${studentSchoolName ? ` (${studentSchoolName})` : ''}`;

    const isDisplayStudentName = 
      ['ACCEPTED', 'EXAMINATION', 'REJECTED', 'CONFIRMED'].includes(_.get(userInfo, 'presidentStatus', '')) || 
      ['ACCEPTED_REQUEST'].includes(offerEventStatus);

    return (
      /**
       * @param {string} eventId Need for going to student page by clicking on denied student cell in students management
       */
      <Link
        to={{
          pathname: `/studentDetail/${userInfo.objectId}`,
          search: `?eventId=${eventId}`
        }}
      >
        <div className="follower-cell">
          {status === 'search' && (
            <label onClick={(e) => {
              e.stopPropagation();
            }}>
              <Checkbox
                disabled={_.find(
                  rejectedPresidentsInOfferEvent,
                  (e) =>
                    e.presidentId === currentUserId &&
                          moment().diff(e.rejectedTime, 'd') < 30
                )}
                className="follower-cell__checkbox-student"
                {...this.props.checkboxProps}
              />
            </label>
          )}
          <div style={{ width: '100%' }}>
            <div className="follower-cell__info">
              <div className="follower-cell__info-left">
                <StudentAvatar studentInfo={userInfo} status={status} />

                <div className="follower-cell__info__name">
                  <div className="ellip-overflow">
                    {isDisplayStudentName ? studentName : studentSchoolName}
                  </div>
                  <div className="ellip-overflow follower-cell__info__major">
                    {studentProfile.major}
                  </div>
                </div>
              </div>
              <div className="follower-cell__info-right">
                {this.renderAcceptGochiButton()}
              </div>
            </div>

            {this.renderExtraContent()}
          </div>
        </div>
      </Link>
    );
  }
}

FollowerCell.propTypes = {
  status: PropTypes.string,
  eventId: PropTypes.string,
  userInfo: PropTypes.object,
  acceptGochiRequest: PropTypes.func,
  currentUserId: PropTypes.string,
  offerEventStatus: PropTypes.string,
  onChangeOfferStatus: PropTypes.func,
  openSelectTemplateModal: PropTypes.func,
  checkboxProps: PropTypes.object
};

export default FollowerCell;
