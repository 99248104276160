import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { reduxForm, Field } from 'redux-form/immutable';

import { Input, Textarea } from '../../components/Form';
import Button from 'components/Button';

const CreateEventFormByTemplate = ({ handleSubmit, pristine, valid }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="name"
        type="text"
        label="タイトル"
        component={Input}
        customForm={true}
        maxLength={100}
      />
      <Field
        name="description"
        label="テキスト"
        component={Textarea}
        customForm={true}
        required={true}
        maxLength={1000}
        counter={1000}
        rows={5}
      />
      <Button type="submit" disabled={pristine || !valid} className="create-event-btn">送信</Button>
    </form>
  );
};

CreateEventFormByTemplate.propTypes = {
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  handleSubmit: PropTypes.func
};

export default compose(
  reduxForm({
    form: 'createEventByTemplate',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      if (!values.get('name'))
        errors.name = 'Please enter the name';
      else if (values.get('name') && !values.get('name').trim()) {
        errors.name = 'Please enter the name';
      }
      if (!values.get('description'))
        errors.description = 'Please enter the description';
      else if (values.get('description') && !values.get('description').trim()) {
        errors.description = 'Please enter the description';
      }
      return errors;
    }
  }),
  withImmutablePropsToJS
)(CreateEventFormByTemplate);