import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ElementRenderer from './ElementRenderer';
import { Link } from 'react-router-dom';

class Input extends Component {
  render() {
    return (
      <Fragment>
        {!this.props.customForm ? (
          <ElementRenderer
            {...this.props}
            render={({ input, type, placeholder, maxLength, disabled }) => (
              <input
                {...input}
                type={type}
                placeholder={placeholder}
                maxLength={maxLength}
                className="form-control input-md"
                disabled={disabled} />
            )} />
        ) : (
          <Fragment>
            <div 
              className={`
              custom-input-container
              ${!this.props.label && 'custom-input-no-label'}
              ${this.props.meta.error && 'error-form'}
              ${this.props.withEditEmail && 'with-edit-email'}
              `}
            >
              { this.props.withEditEmail && 
                (
                  <Link to='/edit-my-page/email-change' >
                    <img className='edit-email-icon' src='images/pen.svg' alt='edit-icon'/>
                  </Link>
                )
              }
              { this.props.label ? (<span className="subtitle">{this.props.label}</span>) : null }
              <input
                {...this.props.input}
                type={this.props.type}
                placeholder={this.props.placeholder}
                maxLength={this.props.maxLength}
                className="form-control input-md"
                disabled={this.props.disabled} />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

Input.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  customForm: PropTypes.bool,
  label: PropTypes.string,
  withEditEmail: PropTypes.bool
};

export default Input;