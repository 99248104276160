import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { setCommonPopup } from 'containers/Popup/actions';
import { getValueByKey } from 'containers/Popup/selectors';
import './styles.scss';

class PopupSuccess extends Component {
  OKcallback = () => {
    this.props.closeSuccessPopUp();
    this.props.show && this.props.show.OKcallback && this.props.show.OKcallback();
  }

  onHideCallback = () => {
    this.props.closeSuccessPopUp();
    this.props.show && this.props.show.onHideCallback && this.props.show.onHideCallback();
  }

  render() {
    return (
      <Modal 
        show={!!this.props.show}
        onHide={this.onHideCallback} className="success-popup-component"
        {
          ...this.props.show && this.props.show.backdrop && ({
            backdrop: this.props.show.backdrop
          })
        }
        {
          ...this.props.show && this.props.show.keyboard && ({
            backdrop: this.props.show.keyboard
          })
        }
      >
        {this.props.show.title && <div className="popup-title">{this.props.show.title}</div>}
        <div className="popup-message text-center">{this.props.show.message}</div>
        <button onClick={this.OKcallback}>{this.props.show.btnLabel || 'OK'}</button>
      </Modal>
    );
  }
}

PopupSuccess.propTypes = {
  show: PropTypes.any,
  closeSuccessPopUp: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    show: getValueByKey(state, 'success')
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeSuccessPopUp: () => dispatch(setCommonPopup('success', false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PopupSuccess));