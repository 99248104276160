import { fromJS } from 'immutable';
import _ from 'lodash';

import { ACCEPT_GOCHI_SUCCESS } from 'containers/GochiEvent/actions';
import { FETCH_FOLLOW_LIST_SUCCESS, REMOVE_FOLLOW_LIST } from './action';

const initState = fromJS({
  data: {},
  list: [],
  page: 1,
  hasMore: true
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function getFollowList(state = initState, action) {
  switch (action.type) {
    case FETCH_FOLLOW_LIST_SUCCESS: {
      const { followList = [] } = action;
      if (_.isEmpty(followList)) return state.set('hasMore', false).update('page', page => page + 1);

      const tempList = followList.map(e => {
        state = state.setIn(['data', e.objectId], e);
        return e.objectId;
      });

      state = state.update('list', oldList => {
        oldList = oldList.toJS ? oldList.toJS() : oldList;
        return fromJS([...new Set(oldList.concat(tempList))]);
      });

      return state.update('page', page => page + 1);
    }
    case ACCEPT_GOCHI_SUCCESS: {
      const { payload } = action;
      return state.setIn(['data', payload.studentId, 'status'], 'ACCEPTED');
    }
    case REMOVE_FOLLOW_LIST:
      return initState;
    default:
      return state;
  }
}
