import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

class WelcomePresident extends Component {

  componentDidMount() {
    if (this.props.message !== 'PRESIDENT_SIGNUP' && this.props.message !== 'UNAPPROVED_PRESIDENT_LOGIN') {
      this.props.history.replace('/');
    }
  }

  render() {
    return (
      <div id="signup-page-container">
        <div className="row">
          <section
            id="left-col-container"
            className="col-lg-7 col-md-12 col-sm-12 col-xs-12"
          >
            <div id="left-col">
              <div className="tutorial-title">アカウント申請完了</div>
              <div className="tutorial-welcome-step">
                <img alt="welcome-img" src="images/signup-step.svg" />
              </div>
              <div className="tutorial-welcome-container">
                <div className="tutorial-welcome-content">
                  <img alt="welcome-img" className='tutorial-welcome-container-overlay' src='images/over.svg'/>
                  <div>仮アカウントが発行されました。</div>
                  <div>3営業日以内にご登録の</div>
                  <div>メールアドレスまたは電話番号へ ご連絡いたします。</div>
                </div>
              </div>
              <div className="tutorial-welcome-button">
                <a className="btn tutorial-welcome-button-redirect" href="https://shachomeshi.com/company/">
                  ご利用の流れを確認する
                  <img alt="welcome-img" src='images/external-link.svg'/>
                </a>
                <Link className="btn tutorial-welcome-button-home" to="/">
                  HOMEへ
                </Link>
              </div>
            </div>
          </section>
          <section
            id="right-col-container"
            style={{ backgroundImage: "url('images/background-login.png')" }}
            className="col-lg-5 hidden-md hidden-sm hidden-xs"
          >
            <div id="right-col">
              <img alt="welcome-img" src="images/logo-login.svg" className="logo" />
              <p className="meshi-slogan">
                就活の場を
                <br />
                「デスク」から「テーブル」へ
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  message: state.get('account').get('message')
});

WelcomePresident.propTypes = {
  message: PropTypes.string,
  history: PropTypes.object
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withImmutablePropsToJS
)(WelcomePresident);