import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { reset } from 'redux-form/immutable';

import CreateOfferEventForm from './form';
import { createOfferTemplateRequest, fetchOfferTemplateRequest } from '../../containers/OfferTemplate/actions';
import './styles.scss';

const PopupCreateTemplate = ({
  isOpen,
  closePopup,
  resetForm,
  createOfferTemplate,
  changeValue,
  formValues,
  fetchOfferTemplate
}) => {
  const initialValues = {
    templateName: '',
    name: '',
    description: ''
  };

  const handleSubmit = (values) => {
    const params = values.toJS();
    createOfferTemplate({
      ...params
    }).then(() => {
      fetchOfferTemplate();
      closePopup();
    });
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);



  return (
    <Modal show={isOpen} onHide={closePopup} className="create-template-modal" centered={true} backdrop={false}>
      <img className="close-icon" alt="close" src="/images/icon-close.svg" onClick={closePopup} />
      <div className="create-template-title">
        テンプレート作成
      </div>
      <CreateOfferEventForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        changeValue={changeValue}
        formValues={formValues}
      />
    </Modal>
  );
};

PopupCreateTemplate.propTypes = {
  isOpen: PropTypes.bool,
  closePopup: PropTypes.func,
  createOfferTemplate: PropTypes.func,
  resetForm: PropTypes.func,
  changeValue: PropTypes.func,
  formValues: PropTypes.object,
  fetchOfferTemplate: PropTypes.func
};
const mapStateToProps = (state) => ({
  formValues: state.getIn(['form', 'create-template', 'values']) || {}
});

const mapDispatchToProps = dispatch => ({
  resetForm: () => dispatch(reset('create-template')),
  createOfferTemplate: (params) => dispatch(createOfferTemplateRequest(params)),
  changeValue: (field, value) => dispatch(change('create-template', field, value)),
  fetchOfferTemplate: () => dispatch(fetchOfferTemplateRequest())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(withImmutablePropsToJS(PopupCreateTemplate));