import { request } from './../../utilities/Api';
import { put, call, takeEvery } from 'redux-saga/effects';
import { receiveStickers, FETCH_STICKERS_REQUEST, GREETING } from './actions';
import { setCommonPopup } from 'containers/Popup/actions';
import { takeFirst } from 'utilities/supportSaga';
import { requestEventDetailForMessage } from 'containers/Message/MessageConversation/actions';
import { replace } from 'connected-react-router';

function* getStickers() {
  try {
    const data = yield call(request, 'functions/getStickers', {}, 'POST');
    yield put(receiveStickers(data.result));
  } catch (error) {
    console.log(error);
  }
}

export function* watchStickers() {
  yield takeEvery(FETCH_STICKERS_REQUEST, getStickers);
}

function* greeting(action) {
  try {
    yield call(request, 'functions/greeting', { sticker: action.sticker, eventId: action.eventId }, 'POST');
    yield put(requestEventDetailForMessage(action.eventId));
    yield put(setCommonPopup('greeting', false));
  } catch (error) {
    if (error.code === 9001) {
      yield put(replace('/'));
    }
  }
}

export function* watchGreeting() {
  yield takeFirst(GREETING, greeting);
}