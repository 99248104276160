export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST';
export const GET_PLANS_REQUEST_FAIL = 'GET_PLANS_REQUEST_FAIL';
export const GET_PLANS_REQUEST_SUCCESS = 'GET_PLANS_REQUEST_SUCCESS';

export function getPlans(payload) {
  return {
    type: GET_PLANS_REQUEST, 
    payload,
    meta: {
      thunk: true
    }
  };
}

export function getPlansRequestFail(payload, meta) {
  return {
    type: GET_PLANS_REQUEST_FAIL, 
    payload,
    error: true,
    meta
  };
}

export function getPlansRequestSuccess(payload, meta) {
  return {
    type: GET_PLANS_REQUEST_SUCCESS, 
    payload,
    meta
  };
}



