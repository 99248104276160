export const GET_SEO_DATA_BY_CODE_REQUEST = 'GET_SEO_DATA_BY_CODE_REQUEST';
export const GET_SEO_DATA_BY_EVENT_ID_REQUEST = 'GET_SEO_DATA_BY_EVENT_ID_REQUEST';
export const GET_SEO_DATA_BY_USER_ID_REQUEST = 'GET_SEO_DATA_BY_USER_ID_REQUEST';
export const GET_SEO_DATA_SUCCESS = 'GET_SEO_DATA_SUCCESS';
export const GET_SEO_DATA_FAILURE = 'GET_SEO_DATA_FAILURE';

export const GET_SEO_DATA_HOME_PAGE = 'GET_SEO_DATA_HOME_PAGE';
export const GET_SEO_DATA_HOME_PAGE_SUCCESS = 'GET_SEO_DATA_HOME_PAGE_SUCCESS';
export const GET_SEO_DATA_HOME_PAGE_FAILURE = 'GET_SEO_DATA_HOME_PAGE_FAILURE';



export function getSeoDataByCodeRequest(code) {
  return {type: GET_SEO_DATA_BY_CODE_REQUEST, code};
}

export function getSeoDataByEventIdRequest(objectId) {
  return {type: GET_SEO_DATA_BY_EVENT_ID_REQUEST, objectId};
}

export function getSeoDataByUserIdRequest(objectId) {
  return {type: GET_SEO_DATA_BY_USER_ID_REQUEST, objectId};
}

export function getSeoDataSuccess(seo) {
  return {type: GET_SEO_DATA_SUCCESS, seo};
}

export function getSeoDataFailure() {
  return {type: GET_SEO_DATA_FAILURE};
}

export function getSeoDataHomePage(){
  return {
    type: GET_SEO_DATA_HOME_PAGE,
    meta: {
      thunk: true
    }
  };
}

export function getSeoDataHomePageSuccess(meta, data = {}) {
  return {
    type: GET_SEO_DATA_HOME_PAGE_SUCCESS,
    payload: data,
    meta
  };
}

export function getSeoDataHomePageFail(meta, data = {}) {
  return {
    type: GET_SEO_DATA_HOME_PAGE_FAILURE,
    payload: data,
    meta,
    error: true
  };
}