import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import EventList from './EventList';
import MagnementMenu from '../ManagementMenu';
import ManagementHeader from '../ManagementHeader';
import {EventManagementMenu} from '../../../utilities/constants';
import PropTypes from 'prop-types';
import './style.scss';

class EventsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 48 - currentScrollY) {
      if (this.state.collapse === false) {
        this.setState({ collapse: true });
      }
    } else {
      if (this.state.collapse === true) {
        this.setState({ collapse: false });
      }
    }
  }
  
  render() {
    const { collapse } = this.state;

    return (
      <div className='events-management'>
        <ManagementHeader collapse={collapse} title='イベント管理' />

        <div className='events-management-container'>
          <div className={`events-management-controller ${collapse ? 'controller-collapse' : ''}`}>
            <div className='events-management-menu-items'>
              <MagnementMenu 
                ManagementMenu={EventManagementMenu} 
                history={this.props.history} 
                location={this.props.location}
                hasCreateEventBtn={true}
              />
            </div>
          </div>
          
          <div className='events-management-list'>
            <Switch>
              <Route key='draftEvent' exact path='/management-event' component={EventList}/>
              <Route key='wantedEvent' exact path='/management-event/wanted' component={EventList}/>
              <Route key='lockedEvent' exact path='/management-event/locked' component={EventList}/>
              <Route key='finishedEvent' exact path='/management-event/finished' component={EventList}/>
              <Route key='canceledEvent' exact path='/management-event/canceled' component={EventList}/>
              <Redirect to='/404-not-found'/>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

EventsManagement.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default EventsManagement;