import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setCommonPopup } from 'containers/Popup/actions';

class LightboxGallery extends Component {
  length = 0
  gallery = []

  recursiveMap = (children) => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return child;
  
      if (child.props.children) {
        child = React.cloneElement(child, {
          children: this.recursiveMap(child.props.children)
        });
      }
  
      if (child.type === 'img') {
        let index = this.length;
        this.length++;
        this.gallery.push(child.props.thumblarge);
        
        return React.cloneElement(child, { onClick: () => this.props.showLightboxControl(this.gallery, index)});
      }

      return child;
    });
  }

  render() {
    return (
      <div>{this.recursiveMap(this.props.children)}</div>
    );
  }
}

LightboxGallery.propTypes = {
  children: PropTypes.any,
  showLightboxControl: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  showLightboxControl: (gallery, index) => dispatch(setCommonPopup('lightboxControl', { gallery, index }))
});

export default connect(null, mapDispatchToProps)(LightboxGallery);