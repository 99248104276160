import React, { Component } from "react";
import MessageCell from "./MessageCell";
import MessageCellOperator from "./MessageCellOperator";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import "./style.scss";

class MessageList extends Component {
  render() {
    const {
      messageOperator,
      groupOperatorChatPreview,
      activeChatViewId,
      list,
      data,
      unread,
    } = this.props;
    return (
      <div className="msg-list">
        {messageOperator && (
          <MessageCellOperator
            item={groupOperatorChatPreview}
            active={
              activeChatViewId === groupOperatorChatPreview.goupId
                ? true
                : false
            }
            unread={unread}
          />
        )}
        {list &&
          list.map((e, i) => (
            <MessageCell
              key={i}
              item={data[e] || {}}
              active={activeChatViewId === e ? true : false}
              eventId={e}
            />
          ))}
      </div>
    );
  }
}

MessageList.propTypes = {
  list: PropTypes.array,
  active: PropTypes.bool,
  unread: PropTypes.bool,
  data: PropTypes.object,
  groupOperatorChatPreview: PropTypes.object,
  messageOperator: PropTypes.bool,
  activeChatViewId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    data: state.get("message").get("data"),
    groupOperatorChatPreview: state
      .get("firebaseListener")
      .get("groupChatPreview"),
  };
};

export default connect(
  mapStateToProps,
  null
)(withImmutablePropsToJS(MessageList));
