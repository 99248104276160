import React, { useState } from 'react';
import { reduxForm, formValueSelector } from 'redux-form/immutable'; 
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import PopupChooseStudent from 'containers/PopupChooseStudent';
import PopupViewStudentProfile from 'containers/EventDetail/PopupViewStudentProfile';
import { setCommonPopup } from 'containers/Popup/actions';
import Stepper from 'components/Stepper';
import Button from 'components/Button';
import EventDetail from '../EventDetail';
import { openReviewSelectedStudent, closeReviewSelectedStudent } from '../action';
import validate from './validate';

import { StepOne, StepTwo, StepThree } from './Step';
import ReviewStudentForm from './ReviewStudentForm';

const PresidentReview = props => {
  const { invalid, reviewStudents = [], reviewEvent, eventInfo, selectedStudent } = props;
  const [step, setStep] = useState(1);
  const [openEventDetail, setOpenEventDetail] = useState(false);

  const stepDetail = [
    {key: 1, text: '出欠確認 / レビュー'}, 
    {key: 2, text: '今後の選考意向の確認'}, 
    {key: 3, text: 'イベントレビュー'}];

  const onChangeField = (fieldName, value) => props.change(fieldName, value);
  
  const initialValues = reviewStudents[_.get(selectedStudent, 'fieldIndex', 0)] || {};
  const handleReviewSelectedStudent = (values) => {
    values = values.toJS();
    props.change(selectedStudent.fieldName, values);
    setStep(1);
    props.closeReviewSelectedStudent();
  };

  const renderStep = (step) => {
    if (step === 1) {
      return (
        <StepOne 
          eventInfo={eventInfo}
          reviewStudents={reviewStudents}
          setOpenEventDetail={setOpenEventDetail}
          openReviewSelectedStudent={props.openReviewSelectedStudent}
          openConfirmPopup={props.openConfirmPopup}
          onChangeField={onChangeField}
        />
      );
    }
    if (step === 2) {
      return <StepTwo reviewStudents={reviewStudents}/>;
    }
    if (step === 3) {
      return <StepThree reviewEvent={reviewEvent}/>;
    }
  };

  const renderSubmitButton = (step) => {
    if (step === 3) {
      return <Button type='submit' className='btn-submit' disabled={invalid}>送信</Button>;
    }
    return (
      <Button
        type='button'
        className='btn-submit'
        disabled={invalid}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setStep(step + 1);
        }}
      >次へ</Button>
    );
  };

  const renderBackStep = (step) => {
    if (step === 1) return null;
    return (
      <div className='btn-back mt-4' onClick={() => setStep(step - 1)}>
        戻る
      </div>
    );
  };

  return (
    <>
      { openEventDetail && 
        <div className='event-review-container'>
          <div className='event-info-header'>
            <img 
              onClick={() => setOpenEventDetail(false)} 
              className='back-icon' 
              src='images/back.svg' 
              alt='back' draggable='false'
            />
            <div className='event-name bold'>
              {eventInfo.name}
            </div>
          </div>
          <EventDetail event={eventInfo} />
        </div> 
      }
      <div className={openEventDetail ? 'hidden' : 'event-review-container'}>
        <div className='header-container'>
          <Stepper stepCount={step} options={stepDetail} />
        </div>
        <div className='review-content'>
          {_.isEmpty(selectedStudent) ? (
            <form id='prez-review' onSubmit={props.handleSubmit} >
              {renderStep(step)}
              <div className='btn-wrapper mt-40'>
                {renderSubmitButton(step)}
                {renderBackStep(step)}
              </div>
            </form>
          ) : (
            <ReviewStudentForm
              initialValues={initialValues}
              onSubmit={handleReviewSelectedStudent}
              onBack={() => {
                setStep(1);
                props.closeReviewSelectedStudent();
              }}
              onChangeField={onChangeField}
              openPopupViewStudentProfile={
                () => props.openPopupViewStudentProfile(reviewStudents[selectedStudent.fieldIndex || 0].userId)
              }
            />
          )}
        </div>
      </div>
      <PopupChooseStudent />
      <PopupViewStudentProfile 
        eventId={eventInfo.objectId} 
      />
    </>
  );
};

PresidentReview.propTypes = {
  reviewEvent: PropTypes.object,
  eventInfo: PropTypes.object,
  invalid: PropTypes.bool,
  reviewStudents: PropTypes.array,
  invitedStudents: PropTypes.array,
  selectedStudent: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  openPopupViewStudentProfile: PropTypes.func,
  openReviewSelectedStudent: PropTypes.func,
  closeReviewSelectedStudent: PropTypes.func,
  openConfirmPopup: PropTypes.func
};

const selector = formValueSelector('prezReview');
const mapStateToProps = state => {
  return {
    reviewSelectStudent: state.get('review').get('reviewSelectStudent'),
    selectedStudent: state.get('review').get('selectedStudent'),
    eventInfo: state.get('eventDetail').get('eventInfo'),
    reviewStudents: selector(state, 'reviewStudents'),
    reviewEvent: selector(state, 'reviewEvent')
  };
};

const mapDispatchToProps = dispatch => ({
  openPopupViewStudentProfile: (studentId) => 
    dispatch(setCommonPopup('studentProfile', {index: 0, list: [studentId], status: 'joined'})),
  openConfirmPopup: (show) => dispatch(setCommonPopup('confirm', show)),
  openReviewSelectedStudent: (fieldIndex) => dispatch(openReviewSelectedStudent(fieldIndex)),
  closeReviewSelectedStudent: () => dispatch(closeReviewSelectedStudent())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'prezReview',
    shouldError: () => true,
    validate
  }),
  withImmutablePropsToJS
)(PresidentReview);