import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import $ from 'jquery';

import Tags from 'components/Tags';

class TagList extends Component {
  componentDidMount = () => {
    this.$el = $(this.el);
  }

  handleClickNext = () => {
    this.$el.scrollLeft(this.$el.scrollLeft() + 100);
  }

  handleClickBack = () => {
    this.$el.scrollLeft(this.$el.scrollLeft() - 100);
  }

  renderTagList = () => {
    const { createdBy, selectedTags, addFilter, removeFilter } = this.props;
    if (_.has(this.props, 'selectedTags')) {
      return (
        <Tags
          className='tag-detail'
          tags={createdBy.tags}
          selectedTags={selectedTags}
          addItem={addFilter}
          removeItem={removeFilter}
          isOrdered
        />
      );
    }
    return _.get(createdBy, 'tags').map((tag, index) => {
      return (
        <div className="tag-detail" key={index}>
          <Link to={`/tags/${tag}`} href="#">{tag}</Link>
        </div>
      );
    });
  }

  render() {
    const { createdBy } = this.props;
    return (
      <div className="tag-list-container">
        {
          _.get(createdBy, 'tags') && createdBy.tags.length > 0 && (
            <Fragment>
              <div className="back-item-container">
                <div className="back-item">
                  <img alt="" src="images/active.svg" onClick={this.handleClickBack} />
                </div>
              </div>
              <div className="next-item-container">
                <div className="next-item">
                  <img alt="" src="images/active.svg" onClick={this.handleClickNext} />
                </div>
              </div>
            </Fragment>
          )
        }
        <div className="tag-list-absolute">
          <div className="tag-list" ref={el => this.el = el}>
            {this.renderTagList()}
            <div className="tag-detail-empty">.</div>
          </div>
        </div>
      </div>
    );
  }
}

TagList.propTypes = {
  createdBy: PropTypes.object,
  selectedTags: PropTypes.array,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func
};

export default TagList;
