import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { setCommonPopup } from '../Popup/actions';
import { getValueByKey } from '../Popup/selectors';
import { getValueByKey as getValueByKeyResource } from '../Resources/selectors';
import { getResources } from '../Resources/actions';
//Component
import SelectWithExtraAndSearch from '../../components/Inputs/SelectWithExtraAndSearch';
import { Input } from 'components/Form';
import { reduxForm, Field } from 'redux-form/immutable';
//Style
import './style.scss';

class UpdateUniversityForm extends Component {

  constructor(props) {
    super(props);
    this.showExtraField = this.showExtraField.bind(this);
    this.state = { extraField: false };
  }

  showExtraField(value) {
    this.setState({
      extraField: value
    });
    if (!value) {
      this.props.change('extraAnswer', '');
    }
  }

  render() {
    const { answerList } = this.props;
    const { extraField } = this.state;

    return (
      <Modal id="student-update-university-container" show={this.props.show} >
        <Modal.Header>
          <Modal.Title >
            大学データをアップデートしてください。
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.props.handleSubmit}>
            <div className="field-container">
              <Field
                component={SelectWithExtraAndSearch}
                name="answer"
                label=""
                showExtraField={this.showExtraField}
                getResources={this.props.getResources}
                items={
                  answerList.map(
                    element => ({ name: element.name, value: element.objectId, hasExtraInput: element.hasExtraInput})
                  )
                }
                customForm={true}
              />
            </div>
            {extraField ? (
              <div className="field-container">
                <Field
                  name="extraAnswer"
                  type="text"
                  label="大学名"
                  maxLength={15}
                  customForm={true}
                  component={Input}
                />
              </div>
            ) : null}
            <div className="action-container">
              <Button type="submit" className="btn-submit btn-active"> OK </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

UpdateUniversityForm.propTypes = {
  show: PropTypes.bool,
  setPopup: PropTypes.func,
  getResources: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  answerList: PropTypes.array
};

UpdateUniversityForm.defaultProps = {
  setPopup: () => null,
  show: false
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'studentUpdateuniversity'),
    answerList: getValueByKeyResource(state, 'availableuniversity')
  };
};

const mapDispatchToProps = dispatch => ({
  setPopup: (open) => dispatch(setCommonPopup('studentUpdateuniversity', open)),
  getResources: () => dispatch(getResources(['AvailableUniversity']))
  // submitSurvey: (values) => dispatch(studentSurvey(values))
});


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'StudentUpdateUniversity' }),
  withImmutablePropsToJS
)(UpdateUniversityForm);


