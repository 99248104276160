export const FETCH_NOTIFICATION_SETTING_REQUEST = 'FETCH_NOTIFICATION_SETTING_REQUEST';
export const FETCH_NOTIFICATION_SETTING_SUCCESS = 'FETCH_NOTIFICATION_SETTING_SUCCESS';

export const UPDATE_NOTIFICATION_SETTING_REQUEST = 'UPDATE_NOTIFICATION_SETTING_REQUEST';
export const UPDATE_NOTIFICATION_SETTING_SUCCESS = 'UPDATE_NOTIFICATION_SETTING_SUCCESS';

export const fetchNotificationSettingRequest = (payload) => {
  return {
    type: FETCH_NOTIFICATION_SETTING_REQUEST,
    payload,
    meta: {
      thunk: true
    }
  };
};

export const fetchNotificationSettingSuccess = (payload, meta) => ({
  type: FETCH_NOTIFICATION_SETTING_SUCCESS,
  payload,
  meta
});

export const updateNotificationSettingRequest = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_SETTING_REQUEST,
    payload,
    meta: {
      thunk: true
    }
  };
};

export const updateNotificationSettingSuccess = (payload, meta) => ({
  type: UPDATE_NOTIFICATION_SETTING_SUCCESS,
  payload,
  meta
});

