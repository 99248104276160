import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchBannerRequest } from './actions';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { formatExternalLink } from '../../utilities/browser';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import './style.scss';
import * as firebase from 'firebase/app'
import 'firebase/analytics';

class BannerCarousel extends Component {
  componentDidMount() {
    !this.props.list && this.props.fetchBannerRequest();
  }

  trackingBanner = banner_id => {
    firebase.analytics().logEvent('view_banner', {banner_id});
  }
  
  BannerImage = banner => {
    if (banner.events.length && banner.type === 'event_list') {
      if (banner.events.length > 1) {
        return (
          <Link to={`/banner/${banner.objectId}?event=${banner.events}`} onClick={() => this.trackingBanner(banner.objectId)}>
            <img width="100%" alt='社長メシ' src={banner.image} />
          </Link>);
      }
      return (
        <Link to={`/event-detail/${banner.events[0]}`} onClick={() => this.trackingBanner(banner.objectId)}>
          <img width="100%" alt='社長メシ' src={banner.image} />
        </Link>
      );
    } else if (banner.type === 'url') {
      return (
        <a href={formatExternalLink(banner.url)} target="_blank" rel="noopener noreferrer" onClick={() => this.trackingBanner(banner.objectId)}>
          <img width="100%" alt='社長メシ' src={banner.image} />
        </a>
      );
    }
    return <img width="100%" alt='社長メシ' src={banner.image} />;
  }
  BannerList = () => {
    if (_.isEmpty(this.props.list)) {
      return null;
    }
    return (
      <div
        className="col-lg-6 col-md-8 col-sm-12 col-xs-12 detail-col banner-oneline"
        style={{paddingBottom: '15.91px'}}>
        <Carousel 
          id="banner-carousel" 
          style={{ width: '100%' }}
          controls={this.props.list.length === 1 ? false : true}
          indicators={this.props.list.length === 1 ? false : true}
          nextIcon={<span><img src="/images/active-copy.svg" alt="right"/></span>}
          prevIcon={<span><img src="/images/in-active-copy.svg" alt="left"/></span>}
        >
          { this.props.list.map((banner) => {
            return (
              <Carousel.Item key={banner.objectId}>
                {this.BannerImage(banner)}
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    );
  };

  render() {
    return this.BannerList();
  }
}

BannerCarousel.propTypes = {
  fetchBannerRequest: PropTypes.func,
  list: PropTypes.array
};

const mapStateToProps = state => ({
  list: state.get('banners')
});

const mapDispatchToProps = dispatch => ({
  fetchBannerRequest: () => dispatch(fetchBannerRequest())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(BannerCarousel));
