import React, { Component } from 'react';
import ConversationCell from '../ConversationCell';
import MyConversationCell from '../MyConversationCell';
import SystemConversationCell from '../SystemConversationCell';
import { listenConversation, loadConversation } from 'utilities/firebase';
import { stopListenConversationWithOperator, loadingMoreChatConversation } from '../../../FirebaseListener/actions';
import { connect } from 'react-redux';
import {
  requestConversationLoadmore,
  conversationLoadingMore,
  readMessages
} from '../actions';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import './style.scss';
import _ from 'lodash';
import TimeSection from '../TimeSection';

const browser = (function(agent) {
  switch (true) {
    case agent.indexOf('edge') > -1: return 'edge';
    case agent.indexOf('opr') > -1 && !!window.opr: return 'opera';
    case agent.indexOf('chrome') > -1 && !!window.chrome: return 'chrome';
    case agent.indexOf('trident') > -1: return 'ie';
    case agent.indexOf('firefox') > -1: return 'firefox';
    case agent.indexOf('safari') > -1: return 'safari';
    default: return 'other';
  }
})(window.navigator.userAgent.toLowerCase());

const firefoxFix = browser === 'firefox' ? 'firefox-fix' : '';
const safari = browser === 'safari' && true;

class ChatSection extends Component {
  constructor(props) {
    super(props);
    this.chatSection = React.createRef();
  }
  
  loadMore = () => {
    const {
      fromOperator,
      eventId,
      requestConversationLoadmore,
      conversationWithOperator,
      groupOperatorChatPreview,
      currentUserId
    } = this.props;

    if (!fromOperator) {
      requestConversationLoadmore(eventId);
    } else {
      if (conversationWithOperator.length >= 20) {
        loadConversation(groupOperatorChatPreview.groupId, false, currentUserId);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const {fromOperator, groupOperatorChatPreview, conversation, conversationWithOperator} = this.props;
    if (!fromOperator && fromOperator !== nextProps.fromOperator) {
      return true;
    }
    if (groupOperatorChatPreview.groupId !== nextProps.groupOperatorChatPreview.groupId) {
      return true;
    }
    if (_.isEqual(conversation.list, nextProps.conversation.list) &&
    (_.isEqual(conversationWithOperator, nextProps.conversationWithOperator))) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    const {
      fromOperator,
      groupOperatorChatPreview: {groupId},
      listeningConversationWithOperator,
      currentUserId
    } = this.props;
    if (!fromOperator) {
      this.readMessages();
    } else if (!listeningConversationWithOperator) {
      loadConversation(groupId, true, currentUserId);
      listenConversation(groupId, currentUserId);
    }
    if (this.chatSection.current) {
      this.chatSection.current.addEventListener('scroll', this.handleScroll);
      this.chatSection.current.scrollTo(0, this.chatSection.current.scrollHeight);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      eventId,
      fromOperator,
      conversationWithOperator,
      groupOperatorChatPreview: {groupId},
      listeningConversationWithOperator,
      currentUserId
    } = this.props;
    const containerRef = this.chatSection.current || null;
    if ((containerRef && containerRef.scrollHeight - containerRef.scrollTop <= 1083) || prevProps.eventId !== eventId) {
      containerRef.scrollTo(0, containerRef.scrollHeight);
    }
    if (!this.props.fromOperator) {
      this.readMessages();
    }
    if (fromOperator && groupId && !listeningConversationWithOperator && conversationWithOperator.length === 0) {
      loadConversation(groupId, true, currentUserId);
      listenConversation(groupId, currentUserId);
    }
    if (fromOperator &&
        conversationWithOperator.length > 0 &&
        !_.isEqual(conversationWithOperator[0], prevProps.conversationWithOperator[0])) {
      containerRef.scrollTo(0, containerRef.scrollHeight);
    }
    if (!fromOperator &&
        groupId &&
        conversationWithOperator.length > 0 &&
        listeningConversationWithOperator &&
        window.unsubscribeConversationWithOperator) {
      window.unsubscribeConversationWithOperator();
      this.props.stopListenConversationWithOperator();
      window.unsubscribeConversationWithOperator = null;
    }
  }

  componentWillUnmount() {
    this.chatSection.current && this.chatSection.current.removeEventListener('scroll', this.handleScroll);
    if (this.props.listeningConversationWithOperator &&
        window.unsubscribeConversationWithOperator) {
      window.unsubscribeConversationWithOperator();
      this.props.stopListenConversationWithOperator();
      window.unsubscribeConversationWithOperator = null;
    }
  }

  readMessages = () => {
    if (!this.props.fromOperator) {
      setTimeout(() => {
        this.props.readMessages(new Date(), this.props.eventId);
      }, 700);
    }
  }

  renderChatCells = () => {
    const {fromOperator, conversation, data, currentUserId} = this.props;
    if (!fromOperator) {
      return (conversation.list && conversation.list.slice(0).reverse().map(e => {
        const itemData = data[e] || {};
        if (itemData.isSystem) {
          return <SystemConversationCell key={itemData.objectId} item={itemData} />;
        }
        if (itemData.senderId === currentUserId) {
          return <MyConversationCell key={itemData.objectId} item={itemData} />;
        }
        if (itemData.senderId !== currentUserId) {
          return <ConversationCell key={itemData.objectId} item={itemData} />;
        }
        return null;
      })) || (<div/>);
    }
    return <div/>;
  }

  renderOperatorChatCells = () => {
    const {
      currentUserId,
      conversationWithOperator
    } = this.props;
    if (conversationWithOperator.length > 0) {
      return (conversationWithOperator.slice(0).reverse().map(item => {
        if (item.senderId === currentUserId) {
          return <MyConversationCell key={item.id} item={item} />;
        }
        if (item.senderId !== currentUserId) {
          return <ConversationCell key={item.id} item={item} />;
        }
        return null;
      })) || (<div/>);
    }
    return <div/>;
  }

  handleScroll = () => { 
    const {
      conversation,
      fromOperator,
      loadingMoreMessages,
      loadingMoreChatConversationOperator,
      conversationLoadingMore,
      loadingMoreConversation
    } = this.props;
    const {
      scrollTop,
      clientHeight,
      scrollHeight
    } = this.chatSection.current;

    if (safari) {
      if (scrollTop - clientHeight <= -(scrollHeight)) {
        if ((conversation && conversation.hasMore && !loadingMoreConversation) ||
          (fromOperator && !loadingMoreMessages)) {
          if (fromOperator) {
            loadingMoreChatConversationOperator();
          } else {
            conversationLoadingMore();
          }
          this.loadMore();
        } 
      }
    } else if (scrollTop + clientHeight <= clientHeight) {
      if ((conversation && conversation.hasMore && !loadingMoreConversation) ||
        (fromOperator && !loadingMoreMessages)) {
        if (fromOperator) {
          loadingMoreChatConversationOperator();
        } else {
          conversationLoadingMore();
        }
        this.loadMore();
      } 
    }
  }

  render() {
    return (
      <div ref={this.chatSection} className={`chat-section ${firefoxFix}`}>
        <TimeSection >
          {this.props.fromOperator ? this.renderOperatorChatCells() : this.renderChatCells()}
        </TimeSection>
      </div>
    );
  }
}

ChatSection.propTypes = {
  eventId: PropTypes.string,
  currentUserId: PropTypes.string,
  fromOperator: PropTypes.bool,
  data: PropTypes.object,
  conversation: PropTypes.object,
  readMessages: PropTypes.func,
  conversationWithOperator: PropTypes.array,
  groupOperatorChatPreview: PropTypes.object,
  listeningConversationWithOperator: PropTypes.bool,
  stopListenConversationWithOperator: PropTypes.func,
  conversationLoadingMore: PropTypes.func, //for old chat
  loadingMoreChatConversationOperator: PropTypes.func,
  loadingMoreConversation: PropTypes.bool, // for old chat
  loadingMoreMessages: PropTypes.bool, // for chat with operator
  requestConversationLoadmore: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    conversation: state.get('conversation').get('conversationsInfo').get(ownProps.eventId) || {},
    listeningConversationWithOperator: state.get('firebaseListener').get('listeningConversationWithOperator'),
    loadingMoreMessages: state.get('firebaseListener').get('loadingMoreMessages'), // for chat with operator
    conversationWithOperator: state.get('firebaseListener').get('conversationWithOperator'),
    groupOperatorChatPreview: state.get('firebaseListener').get('groupChatPreview'),
    loadingMoreConversation: state.get('conversation').get('loadingMoreConversation'), // for old chat
    data: state.get('conversation').get('chatMessagesData')
  };
};

const mapDispatchToProps = dispatch => ({
  requestConversationLoadmore: (eventId) => dispatch(requestConversationLoadmore(eventId)),
  stopListenConversationWithOperator: () => dispatch(stopListenConversationWithOperator()),
  conversationLoadingMore: () => dispatch(conversationLoadingMore(true)), // for old chat
  loadingMoreChatConversationOperator: () => dispatch(loadingMoreChatConversation(true)), // for chat with operator
  readMessages: (deviceReadTime, eventId) => dispatch(readMessages(deviceReadTime, eventId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ChatSection));