import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './style.scss';

export default class TimeSection extends Component {
  groupMessage = () => {
    const { children } = this.props;
    let curDateHeader = '';
    let msgList = [];
    let dateHeader = '';

    for (let i = 0; i < children.length; i++) {
      if (children[i].props.item && children[i].props.item.id) { // for items in the Operator/Student Chat
        const createdTime = moment.unix(children[i].props.item.createdAt.seconds).toISOString();
        dateHeader = moment(createdTime).format('YYYY/MM/DD');
      } else {
        dateHeader = children[i].props.item && moment(children[i].props.item.createdAt).format('YYYY/MM/DD');
      }
      if (curDateHeader !== dateHeader) {
        msgList.push(
          <div className='date-header' key={`time-sec-dead-${i + Math.random().toString()}`}>{dateHeader}</div>
        );
        curDateHeader = dateHeader;
      }

      msgList.push(children[i]);
    }

    return msgList;
  }

  render() {
    return (
      <div>
        {this.groupMessage()}
      </div>
    );
  }
}

TimeSection.propTypes = {
  children: PropTypes.any
};