import { request } from '../../../../utilities/Api';
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_REQUEST,
  receiveHistoryEventsHaveDeniedUser,
  FETCH_DENIED_USERS_OF_EVENT_REQUEST,
  receiveDeniedUsersOfEvent,
  FETCH_EVENT_REVIEWS_REQUEST,
  receiveEventReviews,
  FETCH_EVENT_LIST_BY_STATUS_REQUEST,
  GET_STUDENTS_OF_EVENT_REQUEST,
  getStudentsOfEventSuccess,
  GET_EVENTS_OF_STUDENT_REQUEST,
  getEventsOfStudentSuccess,
  GET_OFFER_EVENT_LIST_REQUEST,
  getOfferEventListSuccess,
  CHANGE_OFFER_STATUS_REQUEST,
  changeOfferStatusSuccess
} from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE,
  STUDENT_MANAGEMENT_EVENT_PERPAGE
} from '../../../../utilities/constants';

function* getHistoryEventsHaveDeniedUser() {
  try {
    yield put(showLoading());
    const page = yield select((state) =>
      state.getIn(['studentManagement', 'studentsOfEvent', 'page'])
    );

    const data = yield call(
      request,
      'functions/getHistoryEventsHaveDeniedUser',
      {
        page,
        limit: STUDENT_MANAGEMENT_EVENT_PERPAGE
      },
      'POST'
    );
    const eventList = data.result;

    yield put(receiveHistoryEventsHaveDeniedUser(eventList));
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}

function* getDeniedUsersOfEvent(action) {
  try {
    const { eventId, page } = action;
    let hasMore = yield select((state) =>
      state.getIn([
        'studentManagement',
        'studentsOfEvent',
        'eventList',
        eventId,
        'hasMore'
      ])
    );
    let pageInState = yield select((state) =>
      state.getIn([
        'studentManagement',
        'studentsOfEvent',
        'eventList',
        eventId,
        'page'
      ])
    );
    if (hasMore === false || page < pageInState) {
      return;
    }

    yield put(showLoading());

    const data = yield call(
      request,
      'functions/getDeniedUsersOfEvent',
      {
        eventId,
        page,
        limit: STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE
      },
      'POST'
    );
    const deniedUsers = data.result;
    yield put(receiveDeniedUsersOfEvent(eventId, deniedUsers, page));

    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}

function* getEventReviews(action) {
  try {
    const { eventId, page } = action;
    let hasMore = yield select((state) =>
      state.getIn([
        'studentManagement',
        'studentsOfEvent',
        'eventList',
        eventId,
        'hasMore'
      ])
    );
    let pageInState = yield select((state) =>
      state.getIn([
        'studentManagement',
        'studentsOfEvent',
        'eventList',
        eventId,
        'page'
      ])
    );
    if (hasMore === false || page < pageInState) {
      return;
    }

    yield put(showLoading());

    const data = yield call(
      request,
      'functions/getEventReviews',
      {
        eventId,
        page,
        limit: STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE
      },
      'POST'
    );
    const reviews = data.result;
    yield put(receiveEventReviews(eventId, reviews, page));

    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}

function* getEventListByStatus(action) {
  try {
    const { status } = action;
    const page = yield select((state) =>
      state.getIn(['studentManagement', 'studentsOfEvent', 'page'])
    );
    yield put(showLoading());

    const { result } = yield call(
      request,
      'functions/getHistoryEventsByStatus',
      {
        page,
        status,
        limit: STUDENT_MANAGEMENT_EVENT_PERPAGE
      },
      'POST'
    );
    yield put(
      receiveHistoryEventsHaveDeniedUser(_.get(result, 'eventList', []))
    );

    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}

function* getStudentsOfEvent(action) {
  try {
    const { payload } = action;
    yield put(showLoading());
    const page = yield select((state) =>
      state.getIn(['studentManagement', 'eventsOfStudent', 'page'])
    );

    const { result } = yield call(
      request,
      'functions/getStudentsOfEvents',
      {
        page,
        limit: STUDENT_MANAGEMENT_EVENT_PERPAGE,
        ...payload
      },
      'POST'
    );
    yield put(getStudentsOfEventSuccess(result));
    yield put(hideLoading());
  } catch (e) {
    yield put(hideLoading());
  }
}

function* getEventsOfStudent(action) {
  try {
    yield put(showLoading());
    const { payload } = action;
    const status = yield select((state) =>
      state.getIn(['studentManagement', 'activeFilter'])
    );
    const page = yield select((state) =>
      state.getIn([
        'studentManagement',
        'eventsOfStudent',
        'studentEvents',
        payload.studentId,
        'page'
      ])
    );
    const { result } = yield call(
      request,
      'functions/getEventsOfAStudent',
      {
        page,
        limit: STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE,
        status,
        ...payload
      },
      'POST'
    );
    yield put(
      getEventsOfStudentSuccess({ studentId: payload.studentId, ...result })
    );
    yield put(hideLoading());
  } catch (e) {
    yield put(hideLoading());
  }
}

function* getOfferEventList(action) {
  try {
    yield put(showLoading());
    const statuses = !_.isEmpty(_.get(action, 'payload.statuses', []))
      ? action.payload.statuses
      : [
        'ACCEPTED_REQUEST',
        'EXAMINING_CANDIDATE',
        'REJECTED_CANDIDATE',
        'APPOINTED',
        'WAITTING_FOR_ANSWER'
      ];
    const { result } = yield call(
      request,
      '/functions/getOfferEventsByPresident',
      {
        ...action.payload,
        statuses
      },
      'POST'
    );
    yield put(getOfferEventListSuccess(result));
    yield put(hideLoading());
  } catch (e) {
    yield put(hideLoading());
  }
}

function* changeOfferStatus(action) {
  try {
    const { result } = yield call(
      request,
      '/functions/changeOfferEventStatusByPresident',
      action.payload,
      'POST'
    );
    yield put(changeOfferStatusSuccess(result, action.meta));
    yield put(hideLoading());
  } catch (e) {
    toast.error(e.error);
    yield put(hideLoading());
  }
}

export default function* watchStudentManagement() {
  yield takeFirst(
    FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_REQUEST,
    getHistoryEventsHaveDeniedUser
  );
  yield takeEvery(FETCH_DENIED_USERS_OF_EVENT_REQUEST, getDeniedUsersOfEvent);
  yield takeEvery(FETCH_EVENT_REVIEWS_REQUEST, getEventReviews);
  yield takeFirst(FETCH_EVENT_LIST_BY_STATUS_REQUEST, getEventListByStatus);
  yield takeFirst(GET_STUDENTS_OF_EVENT_REQUEST, getStudentsOfEvent);
  yield takeFirst(GET_EVENTS_OF_STUDENT_REQUEST, getEventsOfStudent);
  yield takeFirst(GET_OFFER_EVENT_LIST_REQUEST, getOfferEventList);
  yield takeLatest(CHANGE_OFFER_STATUS_REQUEST, changeOfferStatus);
}
