export const OPEN_ABSENCE_WARNING = 'OPEN_ABSENCE_WARNING';
export const CLOSE_ABSENCE_WARNING = 'CLOSE_ABSENCE_WARNING';
export const OPEN_ABSENCE_WARNING_NOTIFICATION = 'OPEN_ABSENCE_WARNING_NOTIFICATION';
export const REQUEST_GET_ABSENCE_WARNING = 'REQUEST_GET_ABSENCE_WARNING';
export const REQUEST_GET_ABSENCE_WARNING_SUCCESS = 'REQUEST_GET_ABSENCE_WARNING_SUCCESS';
export const REQUEST_GET_ABSENCE_WARNING_FAILED = 'REQUEST_GET_ABSENCE_WARNING_FAILED';
export const REQUEST_STUDENT_SEEN_ABSENCE_WARNING = 'REQUEST_STUDENT_SEEN_ABSENCE_WARNING';
export const REQUEST_STUDENT_SEEN_ABSENCE_WARNING_DONE = 'REQUEST_STUDENT_SEEN_ABSENCE_WARNING_DONE';

export const openAbsence = () => {
  return {
    type: OPEN_ABSENCE_WARNING
  };
};

export const openAbsenceFromNotification = (event) => {
  return {
    type: OPEN_ABSENCE_WARNING_NOTIFICATION,
    event
  };
};

export const closeAbsence = () => {
  return {
    type: CLOSE_ABSENCE_WARNING
  };
};

export const requestGetAbsenceWarningSuccess = (event) => {
  return {
    type: REQUEST_GET_ABSENCE_WARNING_SUCCESS,
    event
  };
}; 

export const requestGetAbsenceWarning = () => {
  return {
    type: REQUEST_GET_ABSENCE_WARNING
  };
};

export const requestGetAbsenceWarningFailed = () => {
  return {
    type: REQUEST_GET_ABSENCE_WARNING_FAILED
  };
};

export const requestStudentSeenAbsenceWarning = (eventId) => {
  return {
    type: REQUEST_STUDENT_SEEN_ABSENCE_WARNING,
    eventId
  };
};

export const requestStudentSeenAbsenceWarningDone = () => {
  return {
    type: REQUEST_STUDENT_SEEN_ABSENCE_WARNING_DONE
  };
};