import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from 'rc-checkbox';
import { formatExternalLink } from 'utilities/browser';

const CheckboxGroup = ({ className = '', options, input }) => {
  let values = input.value ? input.value : [];
  values = values.toJS ? values.toJS() : values;

  const handleOnChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      input.onChange([...values, e.target.value]);
    } else {
      input.onChange(values.filter((value) => value !== e.target.value));
    }
  };

  return (
    <div className={`checkbox-group-container ${className}`}>
      <div className="checkbox-options-wrapper">
        {options.map((option, index) => {
          return (
            <label key={`${option.value}-${index}`} className="checkbox-item">
              <CheckBox checked={values.includes(option.value)} onChange={handleOnChange} value={option.value} />
              <div className="checkbox-label">
                <p>{option.label}</p>
                {option.description && <p>{option.description}</p>}
                {option.url && <div className='checkbox-extra'>{option.label}の<a
                  href={formatExternalLink(option.url)}
                  target='_blank'
                  rel="noopener noreferrer">利用規約</a>に同意して登録する</div>}
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

CheckboxGroup.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  name: PropTypes.string
};

export default CheckboxGroup;
