import React, { Component } from 'react';
import './style.scss';

const buildVersion = process.env.REACT_APP_BUILD_VERSION || '';
const appstoreURL = process.env.REACT_APP_STORE_URL || 'https://www.apple.com/ios/app-store/';
export default class Footer extends Component {
  prevScroll = 0;

  state = {
    appear: true
  }

  doesHitBottom = () => {
    return Math.ceil(window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight - 20;
  }

  changeState = () => {

    if((this.prevScroll > window.scrollY || window.scrollY <= 1) && !this.doesHitBottom()){
      this.setState({ appear: true }); //scrolling up
    }
    else if(this.prevScroll < window.scrollY && window.scrollY > 1){
      this.setState({ appear: false }); //scrolling down
    }
    this.prevScroll = window.scrollY;
    return;
  }

  componentDidMount() {
    this.prevScroll = window.scrollY;
    window.addEventListener('scroll', this.changeState);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.changeState);
  }

  render() {
    const footerClass = this.state.appear && this.props.show ? 'container' : 'container disappear';
    return (
      <div className={footerClass} id="footer">
        <div className="footer-content">
          <div id="container">
            <h1 id="left-container">
                就職・採用活動に特化したマッチングサービス。食事会で社長とつながる新しい就職活動
            </h1>

            <div id="right-container">
              <div id="references">
                <a href="https://careertrip.jp/shachomeshi-agreement/" rel="noopener noreferrer" target="_blank">利用規約</a>
                |
                <a href="https://www.unitedwill.jp/" rel="noopener noreferrer" target="_blank">運営会社</a>
                |
                <a href="https://shachomeshi.com/company/" rel="noopener noreferrer" target="_blank">社長の方はこちら</a>
              </div>
              <a id="appstore-btn" aria-label='appstore' href={appstoreURL}><div/></a>
            </div>
          </div>
          <div id="trademark">
            <div id="trademark-container">
              <div id="left-trademark">
                Copyright © 2018 Shacho Meshi. All rights reserved.
              </div>
              <div id="right-trademark">
                {buildVersion}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
