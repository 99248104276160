export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const IN_CHANGE_EMAIL_REQUEST = 'IN_CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILED = 'CHANGE_EMAIL_FAILED';
export const RESET_EMAIL_CHANGE = 'RESET_EMAIL_CHANGE';

export const changeEmailRequest = (values) => ({ type: CHANGE_EMAIL_REQUEST, values});
export const inChangeEmailRequest = () => ({ type: IN_CHANGE_EMAIL_REQUEST});
export const changeEmailSuccess = () => ({ type: CHANGE_EMAIL_SUCCESS});
export const changeEmailFailed = () => ({ type: CHANGE_EMAIL_FAILED});
export const resetChangeEmail = () => ({ type: RESET_EMAIL_CHANGE});
