export const FETCH_CONVERSATION_LOADMORE_REQUEST = 'FETCH_CONVERSATION_LOADMORE_REQUEST';
export const CONVERSATION_LOADING_MORE = 'CONVERSATION_LOADING_MORE';
export const FETCH_CONVERSATION_SWITCH_REQUEST = 'FETCH_CONVERSATION_SWITCH_REQUEST';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_EVENT_DETAIL_FOR_MESSAGE_REQUEST = 'FETCH_EVENT_DETAIL_FOR_MESSAGE_REQUEST';
export const FETCH_EVENT_DETAIL_FOR_MESSAGE_SUCCESS = 'FETCH_EVENT_DETAIL_FOR_MESSAGE_SUCCESS';

export const READ_MESSAGES = 'READ_MESSAGES';
export const READ_MESSAGES_SUCCESS = 'READ_MESSAGES_SUCCESS';

export const FETCH_NEW_SENDER_REQUEST = 'FETCH_NEW_SENDER_REQUEST';
export const FETCH_NEW_SENDER_SUCCESS = 'FETCH_NEW_SENDER_SUCCESS';

export function requestConversationLoadmore(eventId) {
  return {type: FETCH_CONVERSATION_LOADMORE_REQUEST, eventId};
}

export function conversationLoadingMore(data) {
  return {type: CONVERSATION_LOADING_MORE, data};
}

export function requestConversationSwitch(eventId) {
  return {type: FETCH_CONVERSATION_SWITCH_REQUEST, eventId};
}

export function receiveConversation(payload) {
  return {type: FETCH_CONVERSATION_SUCCESS, payload};
}

export function requestEventDetailForMessage(eventId) {
  return {type: FETCH_EVENT_DETAIL_FOR_MESSAGE_REQUEST, eventId};
}

export function receiveEventDetailForMessage(greeting, attendance, eventId, eventDate, offerEvent) {
  return {type: FETCH_EVENT_DETAIL_FOR_MESSAGE_SUCCESS, greeting, attendance, eventId, eventDate, offerEvent};
}

export function readMessages(deviceReadTime, eventId) {
  return {type: READ_MESSAGES, deviceReadTime, eventId};
}

export function readMessagesSuccess(eventId) {
  return {type: READ_MESSAGES_SUCCESS, eventId};
}

export function requestNewSender(senderId) {
  return {type: FETCH_NEW_SENDER_REQUEST, senderId};
}

export function receiveNewSender(sender) {
  return {type: FETCH_NEW_SENDER_SUCCESS, sender};
}