import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { getRootReducer } from './rootReducer';
import rootSaga from './rootSaga';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';

window.store = {};

export function getStore(preloadedState = {}, history) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunkMiddleware, sagaMiddleware, routerMiddleware(history), loadingBarMiddleware()];
  
  let composeEnhancers = compose
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    composeEnhancers =
      typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;
  }
  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    // other store enhancers if any
  );
  window.store = createStore(getRootReducer(history), fromJS(preloadedState), enhancer);
  sagaMiddleware.run(rootSaga);
  return window.store;
}

export function getState() {
  return window.store;
};

export default window.store;