export const FETCH_RESOURCES_REQUEST = 'FETCH_RESOURCES_REQUEST';
export const FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS';
export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE';
export const SET_LOADING_RESOURCES = 'SET_LOADING_RESOURCES';

export function getResources(resources) {
  return {
    type: FETCH_RESOURCES_REQUEST,
    resources,
    meta: {
      thunk: true
    }
  };
}

export function receiveResources(resources, meta) {
  return { type: FETCH_RESOURCES_SUCCESS, resources, payload: resources, meta };
}

export function setLoading(value) {
  return { type: SET_LOADING_RESOURCES, value };
}

export function fecthResourcesFailure(message, meta) {
  return { type: FETCH_RESOURCES_FAILURE, message, meta, error: true };
}
