import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import {timeDisplay} from '../../../../utilities/timeFormat';
import PlatinumLabel from '../../../../components/PlatinumLabel';
class NotificationCell extends Component {
  render() {
    const notificationInfo = this.props.notificationInfo || {};
    const timeAgo = timeDisplay(notificationInfo.createdAt);
    return (
      <div
        className="notification-cell-container"
        onClick={this.props.handleClick}
      >
        <div className="notification-cell">
          <div className="notification-avatar">
            <img
              alt="avatar"
              src={
                notificationInfo.iconUrl
                  ? notificationInfo.iconUrl
                  : 'images/default-avatar.png'
              }
            />
          </div>
          <div className="notification-message-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {notificationInfo.isPlatinum && <PlatinumLabel style={{ marginRight: 8 }} />}
              {notificationInfo.message && notificationInfo.message}
            </div>
            <div className="notification-time">{timeAgo}</div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
NotificationCell.propTypes = {
  notificationInfo: PropTypes.object,
  data: PropTypes.object,
  handleClick: PropTypes.func
};
export default NotificationCell;
