import { request } from './../../utilities/Api';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { receivePresidents, FETCH_PRESIDENT_REQUEST, requestPresidentFailure } from './action';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* getPresidentList(action) {
  try {
    let hasMore = yield select(state => state.get('presidentList').get('hasMore'));
    if (!hasMore) {
      return;
    }
    const { page, tags } = action.payload;
    if (page !== 1) {
      yield put(showLoading());
    }
    yield delay(500);
    const data = yield call(request, 'functions/getPresidentList', { limit: 24, page, tags }, 'POST');
    yield put(receivePresidents(data.result.presidents, page));
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
    yield put(requestPresidentFailure());
  }

}


export default function* watchGetPresidentList() {
  yield takeLatest(FETCH_PRESIDENT_REQUEST, getPresidentList);
}