import React, { PureComponent } from 'react';
import { setRedirectURN } from './action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import isExternal from 'is-url-external';

class LinkWithRedirect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toRefWithRedirectURN = () => {
    this.props.onClick && this.props.onClick();

    let pathname = this.props.location ? this.props.location.pathname : null;
    this.props.setRedirectURN(pathname);

    if (this.props.storeSession === true) {
      sessionStorage.setItem('meshiRedirectURL', pathname);
    }

    const link = this.props.to;
    if (link) {
      if (isExternal(link)) {
        window.location.href = link;
      } else {
        this.props.history.push(link);
      }
    }

    this.props.onClickAfterStore && this.props.onClickAfterStore();
  }

  render() {
    return (
      <span className={this.props.className} onClick={this.toRefWithRedirectURN}>
        {this.props.children}
      </span>
    );
  }
}

LinkWithRedirect.propTypes = {
  onClick: PropTypes.func,
  onClickAfterStore: PropTypes.func,
  to: PropTypes.string,
  storeSession: PropTypes.bool,
  className: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  setRedirectURN: (link) => dispatch(setRedirectURN(link))
});


export default withRouter(connect(
  null, mapDispatchToProps
)(withImmutablePropsToJS(LinkWithRedirect)));