export const PRESIDENT_UPDATE_PROFILE = 'PRESIDENT_UPDATE_PROFILE';
export const PRESIDENT_UPDATE_PROFILE_SUCCESS = 'PRESIDENT_UPDATE_PROFILE_SUCCESS';
export const PRESIDENT_UPDATE_PROFILE_FAIL = 'PRESIDENT_UPDATE_PROFILE_FAIL';

export function presidentUpdateProfile(values, sessionToken, message) {
  return {
    type: PRESIDENT_UPDATE_PROFILE,
    values,
    sessionToken,
    message,
    meta: {
      thunk: true
    }
  };
}

export function presidentUpdateProfileSuccess(meta, data = {}) {
  return {
    type: PRESIDENT_UPDATE_PROFILE_SUCCESS,
    data,
    meta
  };
}

export function presidentUpdateProfileFail(meta, data = {}) {
  return {
    type: PRESIDENT_UPDATE_PROFILE_FAIL,
    data,
    meta,
    error: true
  };
}