import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import StarRatings from 'react-star-ratings';
import _ from 'lodash';

import Button from 'components/Button';
import PresidentDetailCarousel from '../../components/PresidentDetailCarousel';
import './style.scss';
import { formatExternalLink } from '../../utilities/browser';
import SocialMedia from '../SocialMedia';
import LightboxGallery from '../LightboxGallery';
import PresidentProfileTab from './PresidentProfileTab';
import ReviewList from 'containers/ReviewList/';
import Plans from 'containers/Plans';
import Plan from 'containers/Plans/Plan';
class PresidentDetail2 extends Component {
  renderGochiRequest = () => {
    const { currentUser, currentRole, isShow } = this.props;
    if (currentRole !== 'PRESIDENT' && isShow) {
      return (
        <div className="gochi-president">
          <Button
            className="request-gochi"
            onClick={this.props.openGochiRequestModal}
            disabled={_.get(currentUser, 'isFollowing', false)}
          >ごちリクエストを送る</Button>
        </div>
      );
    }
    return null;
  }

  render() {
    const { currentUser, isShow, presidentReviews, url, pathname, isLogin } = this.props;
    const presidentProfile = currentUser.presidentProfile || {};
    presidentProfile.companyUrl = formatExternalLink(presidentProfile.companyUrl);
    presidentProfile.otherUrl = formatExternalLink(presidentProfile.otherUrl);
    const companyLogo = (presidentProfile && presidentProfile.companyLogo && presidentProfile.companyLogo.thumbSmall)
      || '/images/no-img.svg';
    let { mediaArticles = [], mediaImages = [] } = currentUser;
    if (mediaArticles === null) mediaArticles = [];
    if (mediaImages === null) mediaImages = [];

    const isReviewTab = pathname.includes('review');
    const isPlansTab = pathname.includes('plans');

    // not login can not see review
    if ((isPlansTab || isReviewTab) && !isLogin) {
      return (<Redirect to={url} />);
    }

    return (
      <Fragment>
        <div id="president-details-container" className="pre-line">
          <section className="president-details-frame" />
          <section className="president-details-wrapper">
            <div className="president-details-row">
              {/* Carousel */}
              <div className="president-details-column-avatar">
                <div className="col-square">
                  <PresidentDetailCarousel images={currentUser.photos} />
                </div>
              </div>
              {!isShow &&
                <div className="president-details-column-button president-details-button-mobile">
                  {/* <div className="president-details-button--edit"> */}
                  <Link to="/edit-my-page">
                    <button><img src="/images/edit.svg" alt="🖉 " />編集</button>
                  </Link>
                  {/* </div> */}
                </div>
              }
              <div className="president-info-container">
                <div style={{ display: 'flex' }} className="president-name-container">
                  <div className="president-details-label--name">
                    {`${currentUser.fullname || currentUser.displayName || ''}`}
                  </div>
                  {
                    !isShow &&
                    <div className="president-details-column-button">
                      {/* <div className="president-details-button--edit"> */}
                      <Link to="/edit-my-page">
                        <button><img src="/images/edit.svg" alt="🖉 " />編集</button>
                      </Link>
                      {/* </div> */}
                    </div>
                  }
                </div>

                {/* president Info */}
                <div className="president-details-column-info">
                  {isLogin && (<div className="president-rating">

                    {parseFloat(currentUser.star) !== 0 && (
                      <div className='president-rating-number'>{currentUser.star}</div>
                    )}
                    <div className='president-rating-stars'>
                      <StarRatings
                        starRatedColor='#FF8C00'
                        starDimension='20px'
                        svgIconViewBox='0 0 35 32'
                        // eslint-disable-next-line max-len 
                        svgIconPath='M17.5 25.718L28.315 32l-2.87-11.84L35 12.194l-12.582-1.028L17.5 0l-4.918 11.166L0 12.194l9.555 7.966L6.685 32z'
                        starEmptyColor='#C8C8C8'
                        starSpacing='2px'
                        rating={currentUser.star ? parseFloat(currentUser.star) : 0}
                        numberOfStars={5} />
                    </div>
                    <div className='president-rating-times'>
                      ({currentUser.reviewedTimes > 0 ? `${currentUser.reviewedTimes}件のレビュー` : 'レビューなし'})
                    </div>
                  </div>)}
                  {(presidentProfile && presidentProfile.slogan) ? (
                    <div className="president-quotes" >
                      <div><img className="quotes-image" src="/images/quotes.png" alt="“ " /></div>
                      <p className="quotes-content">{presidentProfile.slogan}</p>
                    </div>
                  ) : null
                  }
                </div>

                {this.renderGochiRequest()}
                {isShow && (
                  <div className="link-to-presidents">
                    <Link to={`/event-list/${currentUser.objectId}`}>
                      <p>{_.get(currentUser, 'fullname', '')}社長の社長メシを全て見る
                        <img src="images/next-icon.svg" alt="→" />
                      </p>
                    </Link>
                  </div>
                )}
              </div>
            </div>

            {(isLogin && presidentReviews.list.length !== 0) && (
              <PresidentProfileTab curPathname={pathname} url={url} isShow={isShow} />
            )}

            {!isReviewTab && !isPlansTab && (<div className="present-details-bottom">
              <div className="history-container">
                <div className="field-title">タグ</div>
                <div className="hash-container">
                  {currentUser.tags && currentUser.tags.map(tag => (
                    <div key={tag} className="hash-content">{tag}</div>
                  ))}
                </div>
              </div>

              <div className="history-container">
                <div className="field-title">自己紹介</div>
                <p className="history-content">{presidentProfile && presidentProfile.introduction}</p>
              </div>

              <div className="media-info">
                {(mediaArticles.length !== 0 || mediaImages.length !== 0) && (
                  <div className="media-info__title">メディア/PR情報</div>
                )}

                {mediaArticles.length !== 0 && (
                  <div className="media-info__social-container">
                    <div>Web記事/Youtube動画</div>
                    <div className="row">
                      {mediaArticles.map((socialMedia, i) => (
                        <div key={i} className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <SocialMedia socialMedia={socialMedia} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <LightboxGallery>
                  {mediaImages.length !== 0 && (
                    <div className="media-info__img-container">
                      <div>画像（雑誌記事など）</div>
                      <div className="media-info__img-list">
                        {mediaImages.map(img => (
                          <img
                            key={img.objectId}
                            alt="社長メシ"
                            src={img.thumbSmall}
                            thumblarge={img.thumbLarge}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </LightboxGallery>
              </div>

              <div className="link-container">
                <div className="field-title"> ブログ </div>
                <div className="link-content">
                  <a
                    href={presidentProfile && presidentProfile.otherUrl ?
                      presidentProfile.otherUrl : ''
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {presidentProfile && presidentProfile.otherUrl}
                  </a>
                </div>
              </div>

              <hr className="break" />
              <div className="contact-logo">
                <img className="logo-container" src={companyLogo} alt="社長メシ" />
                <div className="logo-button-container">
                  <div className="logo-text">{presidentProfile && presidentProfile.companyName}</div>
                  {(presidentProfile && presidentProfile.companyUrl) ?
                    (
                      <a
                        className="orange-btn"
                        href={presidentProfile.companyUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        ウェブサイトへ
                      </a>
                    ) : (
                      <a href="true" className="grey-btn"> ウェブサイトへ </a>
                    )
                  }
                </div>
              </div>
              <div className="foot-text">
                {presidentProfile && presidentProfile.companyIntroduction}
              </div>
            </div>)}

            {isLogin && (
              <ReviewList
                currentUser={currentUser}
                isShow={isShow}
                style={{ display: isReviewTab ? 'block' : 'none' }}
              />
            )}
            {isLogin && isPlansTab && <Plans ><Plan /></Plans>}
          </section>
        </div>
      </Fragment>
    );
  }
}
PresidentDetail2.propTypes = {
  currentUser: PropTypes.object,
  currentRole: PropTypes.string,
  presidentReviews: PropTypes.object,
  url: PropTypes.string,
  pathname: PropTypes.string,
  isLogin: PropTypes.bool,
  isShow: PropTypes.bool,
  openGochiRequestModal: PropTypes.func
};
export default PresidentDetail2;