import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CreateEventButton from '../CreateEventButton';
import './style.scss';

export default class ManagementHeader extends Component {
  render() {
    const { collapse, title } = this.props;

    return (
      <div className='management-header-wrapper'>
        <div className={`management-header ${collapse ? 'header-collapse' : ''}`}>
          <div className={`title ${collapse ? 'title-collapse' : ''}`}>
            {title}
          </div>

          <CreateEventButton />
        </div>
      </div>
    );
  }
}

ManagementHeader.propTypes = {
  collapse: PropTypes.bool,
  title: PropTypes.string
};
