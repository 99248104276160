export const STUDENT_SIGNUP_REQUEST = 'STUDENT_SIGNUP_REQUEST';
export const IS_CHECK_SIGNUP_REQUEST = 'IS_CHECK_SIGNUP_REQUEST';
export const STUDENT_SIGNUP_BY_OTP_REQUEST = 'STUDENT_SIGNUP_BY_OTP_REQUEST';

export function studentSignup(values) {
  return {type: STUDENT_SIGNUP_REQUEST, values};
}

export function isCheckSignupRequest(values) {
  return {type: IS_CHECK_SIGNUP_REQUEST, values};
}

export function studentSignupByOTP(values) {
  return {type: STUDENT_SIGNUP_BY_OTP_REQUEST, values};
}
