import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { reset } from 'redux-form/immutable';

import CreateOfferEventForm from './form';
import { setCommonPopup } from 'containers/Popup/actions';
import './styles.scss';
import _ from 'lodash';
import PopupCreateTemplate from '../PopupCreateTemplate';
import {
  fetchOfferTemplateRequest,
  removeOfferTemplateRequest,
  createOfferEventRequest
} from '../OfferTemplate/actions';
import { createOfferTemplateRequest } from '../../containers/OfferTemplate/actions';

const PopupSelectTemplate = ({
  show,
  closePopup,
  resetForm,
  createOfferEvent,
  fetchOfferTemplate,
  templateList,
  changeValue,
  removeOfferTemplate,
  openConfirmPopup,
  createOfferTemplate
}) => {
  const initialValues = {
    name: '',
    description: ''
  };
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const handleSubmitCreateTemplateOffer = (values) => {
    createOfferTemplate({
      ...values
    }).then(() => {
      fetchOfferTemplate();
      closePopup();
    });
  };
  const [templateSelected, setTemplateSelected] = useState('');
  const handleSubmit = (values) => {
    const params = values.toJS();
    const { onSubmit, ...rest } = show;
    if (onSubmit) {
      onSubmit({
        ...params,
        ...rest
      });
      closePopup();
    } else {
      createOfferEvent({
        ...params,
        studentIds: _.get(show, 'studentIds', []),
        selectAll: _.get(show, 'selectAll', false),
        filter: show.filter
      }).then(() => {
        if (show.onSuccess) {
          show.onSuccess();
        }
        if (templateSelected === '' && templateList.length < 6) {
          openConfirmPopup({
            title: '新しいテンプレートを追加しますか?',
            btnConfirm: 'はい',
            btnCancel: 'いいえ',
            onConfirm: () => handleSubmitCreateTemplateOffer(params),
            onHide: () => {
              closePopup();
            }
          });
        } else {
          setTemplateSelected('');
          closePopup();
        }
      });
    }
  };

  useEffect(() => {
    if (show) {
      fetchOfferTemplate();
    }
  }, [show]);

  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show]);

  const selectTemplate = (data) => {
    setTemplateSelected(data._id);
    changeValue('name', data.name);
    changeValue('description', data.description);
  };

  const handleRemoveOfferTemplate = (value) => {
    openConfirmPopup({
      title: 'テンプレートを削除しますか？',
      btnConfirm: 'はい',
      btnCancel: 'いいえ',
      onConfirm: () =>
        removeOfferTemplate({
          id: value
        }).then(() => {
          fetchOfferTemplate();
          if (value === templateSelected) {
            changeValue('name', '');
            changeValue('description', '');
          }
        })
    });
  };

  return (
    <Modal
      show={!!show}
      onHide={closePopup}
      className="select-template-modal"
      centered={true}
    >
      <PopupCreateTemplate
        isOpen={isCreateTemplate}
        closePopup={() => setIsCreateTemplate(false)}
      />
      <img
        className="close-icon"
        alt="close"
        src="/images/icon-close.svg"
        onClick={() => {
          closePopup();
          setTemplateSelected('');
        }}
      />
      <div className="select-template-title">入力画面</div>
      <>
        <div className="create-and-use-template-text">
          テンプレート作成、使用
        </div>

        <div className="template-list">
          {templateList.map((item) => {
            return (
              <div
                key={item._id}
                className={`template-item ${
                  templateSelected === item._id ? 'item-selected' : ''
                }`}
                onClick={() => {
                  selectTemplate(item);
                }}
              >
                <div className="ellipsis-text-one-line">
                  {item.templateName}
                </div>
                <img
                  className="icon-remove-template"
                  alt="close"
                  src="/images/remove-upload-image.svg"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveOfferTemplate(item._id);
                  }}
                />
              </div>
            );
          })}
          {templateList.length < 5 && (
            <div
              onClick={() => setIsCreateTemplate(true)}
              className="create-template-btn"
            >
              <div className="ellipsis-text-one-line">テンプレート作成</div>
              <div className="icon-add-template">+</div>
            </div>
          )}
        </div>
        <CreateOfferEventForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      </>
    </Modal>
  );
};

PopupSelectTemplate.propTypes = {
  show: PropTypes.objectOf({
    studentIds: PropTypes.array.isRequired,
    selectAll: PropTypes.bool,
    filter: PropTypes.object,
    onSubmit: PropTypes.func
  }),
  closePopup: PropTypes.func,
  createOfferEvent: PropTypes.func,
  resetForm: PropTypes.func,
  fetchOfferTemplate: PropTypes.func,
  templateList: PropTypes.array,
  changeValue: PropTypes.func,
  removeOfferTemplate: PropTypes.func,
  openConfirmPopup: PropTypes.func,
  createOfferTemplate: PropTypes.func
};

const mapStateToProps = (state) => ({
  show: state.get('commonPopup').get('selectTemplateModal'),
  templateList: state.get('offerTemplate').get('templateList')
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(setCommonPopup('selectTemplateModal', false)),
  resetForm: () => dispatch(reset('createEventByTemplate')),
  createOfferEvent: (params) => dispatch(createOfferEventRequest(params)),
  createOfferTemplate: (params) => dispatch(createOfferTemplateRequest(params)),
  fetchOfferTemplate: () => dispatch(fetchOfferTemplateRequest()),
  changeValue: (field, value) =>
    dispatch(change('createEventByTemplate', field, value)),
  removeOfferTemplate: (params) => dispatch(removeOfferTemplateRequest(params)),
  openConfirmPopup: (show) => dispatch(setCommonPopup('confirm', show))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withImmutablePropsToJS(PopupSelectTemplate)
);
