export const FETCH_EVENT_BY_DATE_REQUEST = 'FETCH_EVENT_BY_DATE_REQUEST';
export const FETCH_EVENT_BY_DATE_SUCCESS = 'FETCH_EVENT_BY_DATE_SUCCESS';
export const FETCH_EVENT_BY_DATE_FAILURE = 'FETCH_EVENT_BY_DATE_FAILURE';
export const RESET_EVENT_BY_DATE = 'RESET_EVENT_BY_DATE';

export function requestEventsByDate(more = 0) {
  return {type: FETCH_EVENT_BY_DATE_REQUEST, more};
}

export function receiveEventsByDate(events, page) {
  return {type: FETCH_EVENT_BY_DATE_SUCCESS, events: events, page: page};
}

export function requestEventsByDateFail(payload) {
  return {type: FETCH_EVENT_BY_DATE_FAILURE, payload};
}

export function resetEventsByDateList(payload) {
  return {type: RESET_EVENT_BY_DATE, payload};
}