import React from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import './style.scss';

const OtpField = ({
  inputStyle,
  disabled,
  ...props
}) => {
  const { type, input, className, numInputs, indexFocus = null } = props;
  return (
    <div className={className}>
      <OtpInput
        renderInput={(props, index) => {
          return (
            <input 
              {...props}
              type={type}
              className={'otp-input'}
              style={inputStyle ? inputStyle : {}}
              disabled={disabled}
              {...(index === indexFocus && {
                autoFocus: true
              })}
            />
          );
        }}
        disabled={disabled}
        numInputs={numInputs}
        {...input}
      />
    </div>
  );
};

OtpField.propTypes = {
  type: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  className: PropTypes.string,
  numInputs: PropTypes.number,
  inputStyle: PropTypes.object,
  disabled: PropTypes.bool,
  indexFocus: PropTypes.number
};
export default OtpField;

