import React, { Component } from 'react';
import EventCell from './EventCell';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { requestGetEventsByStatus, resetEventsByStatus } from '../actions';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import _ from 'lodash';

class EventList extends Component {
  isAddLoadMore = false;

  constructor(props) {
    super(props);
    this.state = {
      status: ''
    };
  }

  doesHitBottom = () => {
    return (Math.ceil(window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight);
  };

  loadMore = () => {
    if (this.doesHitBottom()) {
      this.props.requestGetEventsByStatus(this.state.status);
    }
  };

  getStatus = () => {
    switch (this.props.match.path) {
      case '/management-event':
        return 'DRAFT';
      case '/management-event/wanted':
        return 'ACTIVE';
      case '/management-event/locked':
        return 'LOCKED';
      case '/management-event/finished':
        return 'FINISHED';
      case '/management-event/canceled':
        return 'CANCELED';
      default:
        return null;
    }
  };

  componentDidMount() {
    const status = this.getStatus();
    if (status) {
      this.props.requestGetEventsByStatus(status);
      this.setState({ status });
    }
  }

  componentDidUpdate() {
    if (!_.isEmpty(this.props.list)) {
      // Only addEventListener first time update
      if (!this.isAddLoadMore) {
        window.addEventListener('scroll', this.loadMore);
        this.isAddLoadMore = true;
      }
    }
  }

  componentWillUnmount() {
    this.props.resetEventsByStatus();
    window.removeEventListener('scroll', this.loadMore);
  }

  renderContent() {
    const { path } = this.props.match;
    if (_.isEmpty(this.props.list)) {
      if (!this.props.hasMore) {
        return <div className="management-event-screen-holder">
          現在該当するイベントはありません。
        </div>;
      }
    }
    return (
      <React.Fragment>
        {this.props.list.map(event => (
          <Link key={event.objectId} to={`/event-detail/${event.objectId}`}>
            <EventCell path={path} event={event} />
          </Link>
        ))}
      </React.Fragment>
    );
  }

  render() {
    return this.renderContent();
  }
}

EventList.propTypes = {
  match: PropTypes.object,
  requestGetEventsByStatus: PropTypes.func,
  list: PropTypes.array,
  resetEventsByStatus: PropTypes.func,
  hasMore: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    list: state.get('eventsManagementByStatus').get('list'),
    hasMore: state.get('eventsManagementByStatus').get('hasMore')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestGetEventsByStatus: action =>
      dispatch(requestGetEventsByStatus(action)),
    resetEventsByStatus: () => dispatch(resetEventsByStatus())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(EventList);
