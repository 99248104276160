import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
class NotFound extends Component {
  render() {
    const message = this.props.location.pathname === '/unsubscribe'
      ? 'お探しのページは退会されているアカウントのページです。'
      : 'お探しのページは一時的にアクセスできない状態か、移動または削除された可能性があります。';
    return (
      <div className="notfound-container">
        <img src="images/404 icon.svg" alt="Not Found" />
        <p className="notfound-boldtext">ページが見つかりません</p>
        <p>{message}</p>
        <Link to="/" className="backToHome">
          <span className="toHome">HOME画面へ戻る</span>
        </Link>
      </div>
    );
  }
}
NotFound.propTypes = {
  location: PropTypes.object
};

export default NotFound;
