import React, { Component } from 'react';
import { Route, Switch, NavLink, Redirect } from 'react-router-dom';
import HomeEvent from './../HomeEvent';
import PresidentList from './../../containers/PresidentList';
import WaitingStudentList from './../../containers/WaitingStudentList';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { getCurrentAccount } from 'containers/Account/actions';

class Home extends Component {

  activeHomeEvent(match, location) {
    if (location.pathname === '/' || location.pathname === '/event') {
      return true;
    }
    return false;
  }

  render() {
    const { currentUser } = this.props;
    const currentRole = get(currentUser, 'role', false);
    return (
      <div>
        <div id="tab-list-container">
          <NavLink to="/" activeClassName="active" isActive={this.activeHomeEvent} >イベント</NavLink>
          <NavLink to="/presidents" activeClassName="active">社長一覧</NavLink>
          {
            currentRole === 'PRESIDENT' &&
              <NavLink to="/management-student/offer" activeClassName="active">オファー管理</NavLink>
          }
          {
            currentRole === 'STUDENT' && 
              <NavLink to="/offer-management" activeClassName="active">オファー</NavLink> 
          }
          <div className="clearfix"></div>
        </div>
        <Switch>
          <Route path="/presidents" component={PresidentList} />
          {
            currentRole && <Route path="/waiting-student" component={WaitingStudentList} />
          }
          <Route exact path="/home" render={() => (<Redirect to="/" />)} />
          <Route exact path="/home/event" render={() => (<Redirect to="/event" />)} />
          <Route exact path="/home/presidents" render={() => (<Redirect to="/presidents" />)} />
          <Route path="/" component={HomeEvent} />
          <Redirect to="/404-not-found"/>
        </Switch>
      </div>
    );
  }

}

Home.propTypes = {
  token: PropTypes.string,
  currentUser: PropTypes.any,
  getCurrentAccount: PropTypes.func
};

const mapStateToProps = state => {
  return {
    token: state.get('account').get('token'),
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  getCurrentAccount: (token) => dispatch(getCurrentAccount(token, 'app'))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(Home));
