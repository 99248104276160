
import { fromJS } from 'immutable';
import { REQUEST_PRESIDENT_REVIEWS_SUCCESS, RESET_PRESIDENT_REVIEWS } from './actions';

const initialState = fromJS({
  list: [],
  page: 1,
  hasMore: true
});

export default function presidentReviews(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PRESIDENT_REVIEWS_SUCCESS: {
      const { result = [] } = action.data; 
      const hasMore = result.length < 10 ? false : true;
      const page = state.get('page');
      const newList = page <= 1 ? result : state.get('list').concat(result);
      const newState = {
        list: newList,
        hasMore: hasMore,
        page: page + 1
      };
      return fromJS(newState);
    }
    case RESET_PRESIDENT_REVIEWS:
      return initialState;
    default:
      return state;
  }
}
