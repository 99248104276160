import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { SIGNUP_REQUEST } from './actions';
import { getCurrentAccount } from './../Account/actions';
import { stopSubmit } from 'redux-form';

function* handlePresidentSignUp(action) {
  try {
    const values = action.values;
    if (action.values.confirmPassword) {
      delete values.confirmPassword;
    }
    const data = yield call(request, 'functions/createPresidentAccount', values, 'POST');
    yield put(getCurrentAccount(data.result.sessionToken, 'PRESIDENT_SIGNUP'));
  } catch (e) {
    if (e.code === 202) {
      e.error = 'このメールアドレスはすでに登録されています';
    }
    yield put(stopSubmit('SignUpForPresident', { _error: e.error }));
  }
}

export default function* watchPresidentSignUp() {
  yield takeEvery(SIGNUP_REQUEST, handlePresidentSignUp);
}

 