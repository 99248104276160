export const FETCH_DENIED_USERS_OF_EVENT_REQUEST = 'FETCH_DENIED_USERS_OF_EVENT_REQUEST';
export const FETCH_DENIED_USERS_OF_EVENT_SUCCESS = 'FETCH_DENIED_USERS_OF_EVENT_SUCCESS';
export const FETCH_EVENT_REVIEWS_REQUEST = 'FETCH_EVENT_REVIEWS_REQUEST';
export const FETCH_EVENT_REVIEWS_SUCCESS = 'FETCH_EVENT_REVIEWS_SUCCESS';
export const FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_REQUEST = 'FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_REQUEST';
export const FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_SUCCESS = 'FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_SUCCESS';
export const RESET_MANAGEMENT_LIST = 'RESET_MANAGEMENT_LIST';
export const FETCH_EVENT_LIST_BY_STATUS_REQUEST = 'FETCH_EVENT_LIST_BY_STATUS_REQUEST';
export const GET_STUDENTS_OF_EVENT_REQUEST = 'GET_STUDENTS_OF_EVENT_REQUEST';
export const GET_STUDENTS_OF_EVENT_SUCCESS = 'GET_STUDENTS_OF_EVENT_SUCCESS';
export const GET_EVENTS_OF_STUDENT_REQUEST = 'GET_EVENTS_OF_STUDENT_REQUEST';
export const GET_EVENTS_OF_STUDENT_SUCCESS = 'GET_EVENTS_OF_STUDENT_SUCCESS';
export const TOGGLE_EVENTS_OF_STUDENT_COLLAPSE = 'TOGGLE_EVENTS_OF_STUDENT_COLLAPSE';
export const GET_OFFER_EVENT_LIST_REQUEST = 'GET_OFFER_EVENT_LIST_REQUEST';
export const GET_OFFER_EVENT_LIST_SUCCESS = 'GET_OFFER_EVENT_LIST_SUCCESS';
export const CHANGE_OFFER_STATUS_REQUEST = 'CHANGE_OFFER_STATUS_REQUEST';
export const CHANGE_OFFER_STATUS_SUCCESS = 'CHANGE_OFFER_STATUS_SUCCESS';

export const getStudentsOfEventRequest = (payload) => ({
  type: GET_STUDENTS_OF_EVENT_REQUEST,
  payload
});

export const getStudentsOfEventSuccess = (payload) => ({
  type: GET_STUDENTS_OF_EVENT_SUCCESS,
  payload
});

export const getEventsOfStudentRequest = (payload) => ({
  type: GET_EVENTS_OF_STUDENT_REQUEST,
  payload
});

export const getEventsOfStudentSuccess = (payload) => ({
  type: GET_EVENTS_OF_STUDENT_SUCCESS,
  payload
});

export function requestHistoryEventsHaveDeniedUser() {
  return {type: FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_REQUEST};
}

export function receiveHistoryEventsHaveDeniedUser(eventList) {
  return {type: FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_SUCCESS, eventList};
}

export function requestDeniedUsersOfEvent(eventId, page) {
  return {type: FETCH_DENIED_USERS_OF_EVENT_REQUEST, eventId, page};
}

export function receiveDeniedUsersOfEvent(eventId, deniedUsers, page) {
  return {type: FETCH_DENIED_USERS_OF_EVENT_SUCCESS, eventId, deniedUsers, page};
}

export function requestEventReviews(eventId, page) {
  return {type: FETCH_EVENT_REVIEWS_REQUEST, eventId, page};
}

export function receiveEventReviews(eventId, reviews, page) {
  return {type: FETCH_EVENT_REVIEWS_SUCCESS, eventId, reviews, page};
}

export function resetManagementList() {
  return {type: RESET_MANAGEMENT_LIST};
}

export function requestEventListByStatus(status) {
  return {type: FETCH_EVENT_LIST_BY_STATUS_REQUEST, status};
}

export function toggleCollapsed(studentId) {
  return {
    type: TOGGLE_EVENTS_OF_STUDENT_COLLAPSE, 
    payload: { studentId }
  };
}

export const getOfferEventListRequest = (payload) => ({
  type: GET_OFFER_EVENT_LIST_REQUEST,
  payload
});

export const getOfferEventListSuccess = (payload) => ({
  type: GET_OFFER_EVENT_LIST_SUCCESS,
  payload
});

export const changeOfferStatusRequest = (payload) => ({
  type: CHANGE_OFFER_STATUS_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});

export const changeOfferStatusSuccess = (payload, meta) => ({
  type: CHANGE_OFFER_STATUS_SUCCESS,
  payload,
  meta
});
