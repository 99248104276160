import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import queryString from 'query-string';

import ManagementFilter from '../ManagementFilter';
import { STUDENT_MANAGEMENT_FILTERS } from 'utilities/constants';
import { removeEventHistory } from '../../../EventHistory/actions';
import {
  requestHistoryEventsHaveDeniedUser,
  resetManagementList,
  requestEventListByStatus,
  getStudentsOfEventRequest,
  getEventsOfStudentRequest,
  toggleCollapsed
} from './actions';
import EventSection from './EventSection';
import ViewByStudent from './ViewByStudent';
import './styles.scss';
class EventList extends Component {
  doesHitBottom = () => {
    return Math.ceil(window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight;
  }

  loadMore = () => {
    const { eventOfStudents, match, studentsOfEvent, location } = this.props;
    const { path } = match;
    const query = queryString.parse(location.search);
    const activeFilter = _.get(query, 'status', STUDENT_MANAGEMENT_FILTERS.APPROVED);

    if (this.doesHitBottom()) {
      if (eventOfStudents.hasMore && path === '/management-student/events-of-student') {
        this.props.getStudentsOfEvent({ status: activeFilter });
      }
      if (studentsOfEvent.hasMore && path === '/management-student/students-of-event') {
        if (activeFilter === STUDENT_MANAGEMENT_FILTERS.APPROVED) {
          this.props.getEventHistoryByStatus('FINISHED');
        } else {
          this.props.getHistoryEventsHaveDeniedUser();
        }
      }
    }
  }

  fetchData = path => {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    const activeFilter = _.get(query, 'status', STUDENT_MANAGEMENT_FILTERS.APPROVED);
    // reset event list when changing path to avoid duplicated & catched data 
    this.props.resetManagementList();
    this.props.removeEventHistory();

    // get event list
    if (path === '/management-student/students-of-event') {
      if (activeFilter === STUDENT_MANAGEMENT_FILTERS.APPROVED) {
        this.props.getEventHistoryByStatus('FINISHED');
      } else {
        this.props.getHistoryEventsHaveDeniedUser();
      }
    }

    // get student list
    if (path === '/management-student/events-of-student') {
      this.props.getStudentsOfEvent({ status: activeFilter });
    }
  }

  componentDidMount() {
    const { eventOfStudents: { page }, history } = this.props;
    window.addEventListener('scroll', this.loadMore);
    if (page > 1 && history.action === 'POP') {
      return;
    }
    this.fetchData(this.props.match.path);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.loadMore);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const prevLocation = prevProps.location;

    if (location.search !== prevLocation.search || location.pathname !== prevLocation.pathname) {
      this.fetchData(location.pathname);
    }
  }

  render() {
    const { 
      studentsOfEvent, 
      eventOfStudents,
      location,
      toggleCollapsed
    } = this.props;
    let eventSection = null;
    const query = queryString.parse(location.search);
    const activeFilter = _.get(query, 'status', STUDENT_MANAGEMENT_FILTERS.APPROVED);
  
    /**
     * Render event section
     * 
     * @property {string} eventId Use for calling api
     * @property {string} path Use for calling api
     * @property {boolean} firstSection First section will collapse automatically
     */
    
    if (location.pathname === '/management-student/students-of-event') {
      const { list = [] } = studentsOfEvent;
      eventSection = list.map((event, index) => (
        <EventSection 
          key={event.objectId} 
          eventId={event.objectId}
          path={location.pathname} 
          title={`${moment(event.eventDate).format('YYYY/MM/DD')} - ${event.name}`} 
          firstSection={index === 0}
          activeFilter={activeFilter}
        />
      ));
    }

    if (location.pathname === '/management-student/events-of-student') {
      const { studentList = [], studentEvents = {} } = eventOfStudents;
      eventSection = studentList.map((student, index) => {
        return (
          <ViewByStudent 
            key={student._id}
            toggleCollapsed={toggleCollapsed}
            studentInfo={student}
            studentEvents={studentEvents[student._id]}
            firstSection={index === 0}
            getEventsOfStudent={this.props.getEventsOfStudent}
            activeFilter={activeFilter}
          />
        );
      });
    }

    return (
      <div className="event-management-list-container">
        <ManagementFilter className="event-management-filter" />
        {!_.isEmpty(eventSection) 
          ? (<div className='event-management-list'>{eventSection}</div>) 
          : (<div className="student-management-screen-holder">該当する学生はいませんでした。</div>)
        }
      </div>
    );
  }
}

EventList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  eventOfStudents: PropTypes.object,
  studentsOfEvent: PropTypes.object,
  collapse: PropTypes.bool,
  getHistoryEventsHaveDeniedUser: PropTypes.func,
  getEventHistoryByStatus: PropTypes.func,
  resetManagementList: PropTypes.func,
  removeEventHistory: PropTypes.func,
  toggleCollapsed: PropTypes.func,
  getStudentsOfEvent: PropTypes.func,
  getEventsOfStudent: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    eventHistory: state.get('eventHistory'),
    studentsOfEvent: state.get('studentManagement').get('studentsOfEvent') || {},
    hasMore: state.get('studentManagement').get('hasMore'),
    eventOfStudents: state.get('studentManagement').get('eventsOfStudent') || {}
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHistoryEventsHaveDeniedUser: () => dispatch(requestHistoryEventsHaveDeniedUser()),
  getEventHistoryByStatus: (status) => dispatch(requestEventListByStatus(status)),
  resetManagementList: () => dispatch(resetManagementList()),
  removeEventHistory: () => dispatch(removeEventHistory()),
  getStudentsOfEvent: (params) => dispatch(getStudentsOfEventRequest(params)),
  getEventsOfStudent: (params) => dispatch(getEventsOfStudentRequest(params)),
  toggleCollapsed: (studentId) => dispatch(toggleCollapsed(studentId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(EventList));