import { fromJS } from 'immutable';
import { FETCH_STICKERS_SUCCESS } from './actions';

const initState = fromJS({
  list: []
});

export default function greeting(state = initState, action) {
  switch (action.type) {
    case FETCH_STICKERS_SUCCESS:
      return state.set('list', action.list);
    default:
      return state;
  }
}