import { request } from './../../utilities/Api';
import { put, call, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { 
  receiveFollowList, 
  FETCH_FOLLOW_LIST_REQUEST, 
  setFollowUserSuccess, 
  SET_FOLLOW_USER_REQUEST,
  setNotificationStatusSuccess,
  SET_NOTIFY_STATUS_REQUEST
} from './action';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* getFollowList() {
  try {
    yield put(showLoading());
    
    const hasMore = yield select(state => state.get('followList').get('hasMore'));   
    const page = yield select(state => state.get('followList').get('page'));
    const role = yield select(state => state.get('account').get('data').get('role'));
    const apiName = role === 'STUDENT' ? 'functions/getFollowedUsers' : 'functions/getFollowerUsers';

    if (hasMore) {
      const data = yield call(request, apiName, {limit: 12, page: page}, 'POST');
      yield put(receiveFollowList(data.result.followedUsers || data.result.followerUsers));
    }

    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }

}

export function* watchGetFollowList() {
  yield takeFirst(FETCH_FOLLOW_LIST_REQUEST, getFollowList);
}

function* setFollowUser(action) {
  const { meta } = action; 
  try {
    yield put(showLoading());
    const data = yield call(request, 'functions/setFollowUser', {userId: action.userId, action: action.action}, 'POST');
    if (data.result.status === 'OK') {
      yield put(setFollowUserSuccess(data.result.status, meta));
    }
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }

}

export function* watchSetFollowUser() {
  yield takeFirst(SET_FOLLOW_USER_REQUEST, setFollowUser);
}


function* setNotificationStatus(action) {
  const { meta } = action; 
  try {
    yield put(showLoading());
    const data = yield call(request, 'functions/setNotificationStatus', {
      presidentId: action.presidentId, 
      status: action.status
    }, 'POST');
    if (data.result.status === 'OK') {
      yield put(setNotificationStatusSuccess(meta, data.result.status));
    }
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }

}

export function* watchSetNotificationStatus() {
  yield takeFirst(SET_NOTIFY_STATUS_REQUEST, setNotificationStatus);
}