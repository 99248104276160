import { fromJS } from 'immutable';

const initState = fromJS({
  show: false 
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function requestCompleteProfile(state = initState, action) {
  switch (action.type) {
    case 'CLOSE_PROFILE_REQUEST_EVENT_POPUP': {
      let newState = state.set('show', false);
      return newState;
    }
    case 'OPEN_PROFILE_REQUEST_EVENT_POPUP': {
      let newState = state.set('show', true);
      return newState;
    }
    default:
      return state;
  }
}