import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import MessageFileInput from './MessageFileInput';
import { sendMessage } from './actions';
import { sendMessageFirestore } from 'utilities/firebase';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

class MessageInput extends Component {
  constructor(props) {
    super(props);
    this.textarea = React.createRef();
  }

  sendImage = (media) => {
    this.props.sendMessage(this.props.eventId, this.props.currentUser.objectId, null, media);
    if (!this.props.currentUser.objectId) {
      return;
    }
  }
  sendMessageToOperator = (media) => {
    const { currentUser, groupOperatorChatPreview } = this.props;
    const operatorId = groupOperatorChatPreview.users.filter(userId => userId !== currentUser.objectId);
    if (operatorId[0] && groupOperatorChatPreview) {
      if (!media || !media.url) {
        if (this.textarea.current.value.trim().length > 0) {
          sendMessageFirestore(operatorId[0],
            groupOperatorChatPreview.groupId,
            currentUser.objectId,
            this.textarea.current.value.trim(),
            'text');
        }
        this.textarea.current.value = '';
      } else {
        sendMessageFirestore(operatorId[0],
          groupOperatorChatPreview.groupId,
          currentUser.objectId,
          media.url,
          'image');
      }
    }
    if (!currentUser.objectId) {
      return;
    }
  }

  sendTextMessage = () => {
    const { currentUser, eventId, sendMessage } = this.props;

    if (!currentUser.objectId) {
      return;
    }

    sendMessage(eventId, currentUser.objectId, this.textarea.current.value.trim(), null);
    this.textarea.current.value = '';
  }
  
  keyPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && !window.isMobileDevice) {
      e.preventDefault();
      if (this.props.fromOperator) {
        this.sendMessageToOperator();
      } else {
        this.sendTextMessage();
      }
    }
  }

  render() {
    return (
      <div className="msg-input">
        <MessageFileInput sendImage={this.props.fromOperator ? this.sendMessageToOperator : this.sendImage} />
        <textarea ref={this.textarea} onKeyDown={this.keyPress} rows='1' placeholder="メッセージを入力" />
        <div className="send" onClick={this.props.fromOperator ? this.sendMessageToOperator : this.sendTextMessage}>
          <img alt="" src="/images/send.svg" />
        </div>
      </div>
    );
  }
}

MessageInput.propTypes = {
  sendMessage: PropTypes.func,
  eventId: PropTypes.string,
  currentUser: PropTypes.any,
  groupOperatorChatPreview: PropTypes.any,
  fromOperator: PropTypes.bool
};

const mapStateToProps = state => ({
  currentUser: state.get('account').get('data'),
  groupOperatorChatPreview: state.get('firebaseListener').get('groupChatPreview')
});

const mapDispatchToProps = dispatch => ({
  sendMessage: (eventId, senderId, message, media) => dispatch(sendMessage(eventId, senderId, message, media))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(MessageInput));