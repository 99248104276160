/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SMSVerification from '../../components/SMSVerification';
import { studentSignupByOTP } from '../StudentSignup/actions';
import { sendSmsNumberPhoneRequest, checkOtpRequest } from '../../components/SMSVerification/actions';
import { getCurrentNationSelect } from 'utilities/handleValues';

const SignUpSMSVerification = ({
  onStudentSignup,
  onSendSmsNumberPhone,
  onSubmitOtp,
  ...props
}) => {
  const infoAccount = JSON.parse(window.sessionStorage.getItem('account_register')) || {};
  const [isCheckedPhone, setCheckedPhone] = useState(false);
  const [currentNumberPhone, setCurrentNumber] = useState(null);

  const handleCheckPhoneNumber = (value) => {
    onSendSmsNumberPhone({
      phone: `+${getCurrentNationSelect()}${value}`
    }, (res) => {
      if (res.statusCode === 200) {
        setCheckedPhone(true);
        setCurrentNumber(`+${getCurrentNationSelect()}${value}`);
      } else setCheckedPhone(false);
    });
  };
  
  const handleSubmitOtp = (value, callback, errorCallback) => {
    if (value && currentNumberPhone) {
      onSubmitOtp({
        code: value,
        phone: currentNumberPhone
      }, (res) => {
        if (res.statusCode === 200) {
          callback(true);
        } else {
          errorCallback();
        }
      }, () => errorCallback());
    }
  };

  const handleSubmitConfirmOtp = () => {
    // Parse info register account to send request api
    const token = atob(get(infoAccount, `${[3]}`));
    
    if (!isEmpty(token)) onStudentSignup(token);
  };

  return (
    <>
      <SMSVerification
        {...props}
        infoAccount={infoAccount}
        isCheckedPhone={isCheckedPhone}
        onCheckPhoneNumber={handleCheckPhoneNumber}
        onSubmitOtp={handleSubmitOtp}
        onSubmitConfirmOtp={handleSubmitConfirmOtp}
      />
    </>
  );
};

SignUpSMSVerification.protoType = {
  history: PropTypes.object,
  onLogin: PropTypes.func,
  onSendSmsNumberPhone: PropTypes.func,
  onSubmitOtp: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  onStudentSignup: (values) => dispatch(studentSignupByOTP(values)),
  onSendSmsNumberPhone: (value, callback) => dispatch(sendSmsNumberPhoneRequest(value, callback)),
  onSubmitOtp: (value, callback, errorCallback) => dispatch(checkOtpRequest(value, callback, errorCallback))
});

export default compose(
  connect(null, mapDispatchToProps),
)(SignUpSMSVerification);
