import { put, call, takeLatest } from 'redux-saga/effects';
import _get from 'lodash/get';
import { push } from 'connected-react-router';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as firebase from 'firebase/app';

import { request } from 'utilities/Api';
import { setCommonPopup } from 'containers/Popup/actions';
import {
  GET_OFFER_EVENT_DETAIL_REQUEST,
  getOfferEventDetailSuccess,
  getOfferEventDetailFailure,
  RESPONSE_OFFER_EVENT_REQUEST,
  responseOfferEventSuccess,
  responseOfferEventFailure,
  SHOWN_FIRST_OFFER_EVENT_REQUEST,
  shownFirstOfferEventSuccess,
  shownFirstOfferEventFailure
} from './actions';

function* getOfferEventDetail({ payload, meta }) {
  try {
    yield put(showLoading());
    const { result = {} } = yield call(request, 'functions/getShortOfferEvent', { eventId: payload.eventId }, 'POST');
    if (['LOCKED', 'FINISHED'].includes(_get(result, 'status', ''))) {
      yield put(push(`/messages/${payload.eventId}`));
    } else if (_get(result, 'status', '') === 'CANCELED') {
      yield put(setCommonPopup('success', {
        message: 'Request is expired'
      }));
    } else {
      const { result: presidentDetail = {} } = yield call(
        request, 'functions/getUserInformation', { userId: _get(result, 'jCreatedBy.objectId', '') }, 'POST'
      );
      if (payload.reference) {
        firebase.analytics().logEvent('open_offer_notification', {
          offer_id: result.objectId,
          reference_page: payload.reference
        });
      }
      yield put(
        getOfferEventDetailSuccess({ detail: result, presidentDetail: _get(presidentDetail, 'infoUser', {}) }, meta)
      );
    }
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    if (_get(error, 'error', '')) {
      yield put(setCommonPopup('success', {
        message: error.error
      }));
    }
    yield put(getOfferEventDetailFailure(error, meta));
  }
}

function* responseOfferEvent({ payload, meta }) {
  try {
    yield put(showLoading());
    const { isAccepted = false, eventId = ''} = payload;
    const { result } = yield call(request, isAccepted 
      ? 'functions/studentAcceptRequest'
      : 'functions/studentDenyRequest', { eventId }, 'POST');
    yield put(responseOfferEventSuccess(result, meta));
    firebase.analytics().logEvent(
      isAccepted ? 'confirm_offer' : 'reject_offer', 
      { offer_id: eventId }
    );
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    if (_get(error, 'error', '')) {
      yield put(setCommonPopup('success', {
        message: error.error
      }));
    }
    yield put(responseOfferEventFailure(error, meta));
  }
}

function* shownFirstOfferEvent({ meta }) {
  try {
    yield put(showLoading());
    yield call(request, 'functions/showFirstOfferEventPopup', {}, 'POST');
    yield put(shownFirstOfferEventSuccess({}, meta));
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    if (_get(error, 'error', '')) {
      yield put(setCommonPopup('success', {
        message: error.error
      }));
    }
    yield put(shownFirstOfferEventFailure(error, meta));
  }
}

export default function* watchOfferEvent() {
  yield takeLatest(GET_OFFER_EVENT_DETAIL_REQUEST, getOfferEventDetail);
  yield takeLatest(RESPONSE_OFFER_EVENT_REQUEST, responseOfferEvent);
  yield takeLatest(SHOWN_FIRST_OFFER_EVENT_REQUEST, shownFirstOfferEvent);
}
