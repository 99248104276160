import { fromJS } from 'immutable';
import { FETCH_USER_INFO_SUCCESS, REMOVE_USER_INFO } from './action';
import { SEND_GOCHI_SUCCESS } from 'containers/GochiEvent/actions';

const initState = fromJS({
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function getUserInfo(state = initState, action) {
  switch (action.type) {
    case FETCH_USER_INFO_SUCCESS:
      return fromJS(action.infoUser);
    case REMOVE_USER_INFO:
      return initState;
    case SEND_GOCHI_SUCCESS:
      return state.set('isFollowing', true);
    default:
      return state;
  }
}
