export const FETCH_WAITING_STUDENT_OFFER_EVENT_REQUEST = 'FETCH_WAITING_STUDENT_OFFER_EVENT_REQUEST';
export const FETCH_WAITING_STUDENT_OFFER_EVENT_SUCCESS = 'FETCH_WAITING_STUDENT_OFFER_EVENT_SUCCESS';
export const FETCH_WAITING_STUDENT_OFFER_EVENT_FAILURE = 'FETCH_WAITING_STUDENT_OFFER_EVENT_FAILURE';
export const RESET_WAITING_STUDENT_OFFER_EVENT = 'RESET_WAITING_STUDENT_OFFER_EVENT';

export function requestWaitingStudentOfferEvents(param = {}) {
  return {type: FETCH_WAITING_STUDENT_OFFER_EVENT_REQUEST, param};
}

export function receiveWaitingStudentOfferEvents(events) {
  return {type: FETCH_WAITING_STUDENT_OFFER_EVENT_SUCCESS, events: events};
}

export function failToGetWaitingStudentOfferEvents(payload) {
  return {type: FETCH_WAITING_STUDENT_OFFER_EVENT_FAILURE, payload};
}

export function resetWaitingStudentOfferEventList(payload) {
  return {type: RESET_WAITING_STUDENT_OFFER_EVENT, payload};
}