import { request } from 'utilities/Api';
import { put, call, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import _ from 'lodash';

import { unsetCurrentAccount } from '../Account/actions';
import { setCommonPopup } from '../Popup/actions';
import {
  COMMON_LOGIN_REQUEST,
  commonLoginSuccess,
  commonLoginFailure
} from './actions';
import parseJwt from '../../utilities/parseJwt';

function* commonLogin(action) {
  try {
    yield put(showLoading());
    const tokenDecode = parseJwt(action.payload.token);
    const accountData = localStorage.getItem('account')
      ? JSON.parse(localStorage.getItem('account'))
      : null;
    if (_.get(accountData, 'data.objectId') === _.get(tokenDecode, 'userId', '')) {
      yield put(commonLoginSuccess({}, action.meta));
      yield put(hideLoading());
      return;
    }
    if (_.get(accountData, 'token')) {
      yield put(unsetCurrentAccount());
    }
    const { result: commonLoginResult } = yield call(
      request,
      'functions/commonLogin',
      action.payload,
      'POST'
    );
    const responseData = yield call(
      request,
      'users',
      {
        authData: commonLoginResult.authData
      },
      'POST',
      {
        headers: {
          'X-Parse-Revocable-Session': 1
        },
        transformRequest: [(data, headers) => {
          delete headers['X-Parse-Session-Token'];
          headers.post['Content-Type'] = 'application/json;charset=UTF-8';
          return JSON.stringify(data);
        }]
      }
    );
    localStorage.setItem('account', JSON.stringify({ token: responseData.sessionToken, data: responseData }));
    yield put(commonLoginSuccess(responseData, action.meta));
    yield put(hideLoading());
  } catch (e) {
    yield put(
      setCommonPopup('success', {
        title: e.error,
        btnLabel: 'はい'
      })
    );
    yield put(commonLoginFailure(e, action.meta));
    yield put(hideLoading());
  }
}

export default function* watchCommonAuth() {
  yield takeLatest(COMMON_LOGIN_REQUEST, commonLogin);
}
