import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import PropTypes from 'prop-types';
import Taglist from '../TagList';

class PresidentCell extends Component {
  render() {
    const objectId = this.props.presidentJSON.objectId;
    const presidentProfile = this.props.presidentJSON.presidentProfile;
    const avatarImage = this.props.presidentJSON.avatarImage;
    let avatarURL = avatarImage && avatarImage.thumbSmall ? avatarImage.thumbSmall : 'images/default-avatar.png';

    let companyName = null;
    if (this.props.presidentJSON.presidentProfile && this.props.presidentJSON.presidentProfile.companyName) {
      companyName = this.props.presidentJSON.presidentProfile.companyName;
    }
    return (
      <div className="president-cell-container col-xs-12 col-lg-4 col-sm-6 middle-col">
        <Link to={'/presidentDetail/' + objectId} >
          <div className="content-card">
            <div className="card-col-left">
              <img className="avatar-img" src={avatarURL} alt="社長メシ" />
            </div>
            <div className="card-col-right">
              <div className="details-content">
                <img src="images/orange.svg" alt="orange" className="arrow-point" />
                <div className="text-container">
                  <div className="prez-title">
                    {this.props.presidentJSON.displayName || this.props.presidentJSON.fullname + ' 社長'}
                  </div>
                </div>
                {companyName &&
                  (<div className="company-title">
                    <img className="comp-icon" src="images/group.svg" alt="logo" />
                    <span>{companyName}</span>
                  </div>)}
                <div className="shorten-des">
                  <div className="elli"><div>{presidentProfile ? presidentProfile.introduction : null}</div></div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Taglist 
          createdBy={this.props.presidentJSON}
          selectedTags={this.props.selectedTags}
          addFilter={this.props.addFilter}
          removeFilter={this.props.removeFilter}
        />
      </div>
    );
  }
}

PresidentCell.propTypes = {
  presidentJSON: PropTypes.object,
  selectedTags: PropTypes.array,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func
};


export default PresidentCell;
