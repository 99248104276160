import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import _ from 'lodash';
import moment from 'moment';
import { reset } from 'redux-form/immutable';

import { setCommonPopup } from 'containers/Popup/actions';
import { getResources } from 'containers/Resources/actions';
import ManagementFilter from '../ManagementFilter';
import {
  getSearchedStudentsRequest,
  setSelectedStudents
} from './actions';
import FollowerCell from '../FollowList/FollowerCell';
import ListHeader from '../FollowList/ListHeader';
import './styles.scss';

class SearchStudents extends Component {
  constructor(props) {
    super(props);
    this.onSendOfferSuccess = this.onSendOfferSuccess.bind(this);
  }
  loadMore = () => {
    if (
      Math.ceil(window.innerHeight + window.scrollY + 1) >=
      document.body.offsetHeight
    ) {
      const { hasMore, isLoading } = this.props;

      if (hasMore && !isLoading) {
        this.props.getSearchedStudents();
      }
    }
  };

  componentDidMount() {
    const { page, history } = this.props;
    window.addEventListener('scroll', this.loadMore);
    if (page > 1 && history.action === 'POP') {
      return;
    }
    this.props.resetForm();
    this.props.setSelectedStudents({
      selectedStudentIds: [],
      selectedAll: false
    });
    this.props.getSearchedStudents({ page: 1 });
    this.props.getResources();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
  }

  onSendOfferSuccess() {
    this.props.setSelectedStudents({
      selectedStudentIds: [],
      selectedAll: false
    });
    this.props.getSearchedStudents({ page: 1 });
  }

  render() {
    const {
      isLoading,
      selectedAll,
      selectedStudentIds,
      list,
      data,
      totalSlot,
      totalAvailableStudent,
      currentUser
    } = this.props;

    const totalSelected = selectedAll
      ? totalAvailableStudent - selectedStudentIds.length
      : selectedStudentIds.length;

    return (
      <div className="search-students-management-container">
        <ManagementFilter
          className="search-students-filter"
          totalPlanSlots={totalSlot}
        />
        {list.length > 0 && (
          <div className='student-list'>
            <ListHeader
              checkedAll={
                totalAvailableStudent === 0 ? false : selectedAll
                  ? selectedStudentIds.length !== totalAvailableStudent
                  : selectedStudentIds.length === totalAvailableStudent
              }
              disabledCheckbox={totalAvailableStudent === 0}
              onCheckAll={(e) => {
                if (e.target.checked) {
                  this.props.setSelectedStudents({
                    selectedStudentIds: [],
                    selectedAll: true
                  });
                } else {
                  this.props.setSelectedStudents({
                    selectedStudentIds: [],
                    selectedAll: false
                  });
                }
              }}
              disabled={!totalSelected}
              openSelectTemplateModal={() => {
                if (totalSlot < totalSelected) {
                  this.props.openAlertPopup({
                    title: `オファー枠を確認してください。\n選択した学生数：${totalSelected}\n残りオファー枠数：${totalSlot}`,
                    btnLabel: 'はい'
                  });
                  return;
                }
                this.props.openSelectTemplateModal({
                  studentIds: selectedStudentIds,
                  selectAll: selectedAll,
                  onSuccess: this.onSendOfferSuccess
                });
              }}
              totalSelected={totalSelected}
            />
            {list.map((id) => {
              const userInfo = data[id];
              const notAllowed =
              !!userInfo.presidentRejectStudentOfferCandidate &&
              moment().diff(userInfo.presidentRejectStudentOfferCandidate, 'd') <
                7;
              return (
                <FollowerCell
                  key={id}
                  userInfo={userInfo}
                  status="search"
                  currentUserId={_.get(currentUser, 'objectId', '')}
                  openSelectTemplateModal={() => {
                    if (!totalSlot) {
                      this.props.openConfirmPopup({
                        title: 'この機能はオファー機能ご契約企業のみ利用可能です。',
                        isAlert: true,
                        btnConfirm: 'OK'
                      });
                      return;
                    }
                    this.props.openSelectTemplateModal({
                      studentIds: [id],
                      selectAll: false,
                      onSuccess: this.onSendOfferSuccess
                    });
                  }}
                  checkboxProps={{
                    disabled: notAllowed,
                    checked: notAllowed ? false : selectedAll
                      ? !selectedStudentIds.includes(id)
                      : selectedStudentIds.includes(id),
                    onChange: (e) => {
                      if (
                        (!selectedAll &&
                        e.target.checked &&
                        !selectedStudentIds.includes(id)) ||
                      (selectedAll &&
                        !e.target.checked &&
                        !selectedStudentIds.includes(id))
                      ) {
                        this.props.setSelectedStudents({
                          selectedStudentIds: [...selectedStudentIds, id],
                          selectedAll: selectedAll
                        });
                      } else {
                        this.props.setSelectedStudents({
                          selectedStudentIds: selectedStudentIds.filter(
                            (studentId) => studentId !== id
                          ),
                          selectedAll: selectedAll
                        });
                      }
                    }
                  }}
                />
              );
            })}
          </div>
        )}
        {!isLoading && list.length === 0 && (
          <div className="no-student-found">
            該当する学生はいませんでした。
          </div>
        )}
      </div>
    );
  }
}

SearchStudents.propTypes = {
  currentUser: PropTypes.object,
  list: PropTypes.array,
  data: PropTypes.object,
  page: PropTypes.number,
  hasMore: PropTypes.bool,
  totalSlot: PropTypes.number,
  totalStudent: PropTypes.number,
  totalAvailableStudent: PropTypes.number,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  getSearchedStudents: PropTypes.func,
  selectedStudentIds: PropTypes.array,
  selectedAll: PropTypes.bool,
  openConfirmPopup: PropTypes.func,
  openSelectTemplateModal: PropTypes.func,
  openAlertPopup: PropTypes.func,
  setSelectedStudents: PropTypes.func,
  getResources: PropTypes.func,
  resetForm: PropTypes.func
};

const mapStateToProps = (state) => {
  const searchStudentsManagement = state.get('searchStudentsManagement');
  return {
    currentUser: state.get('account').get('data'),
    ...searchStudentsManagement.toJS()
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSearchedStudents: (params) => dispatch(getSearchedStudentsRequest(params)),
  openSelectTemplateModal: ({ studentIds, selectAll, onSuccess }) =>
    dispatch(
      setCommonPopup('selectTemplateModal', {
        studentIds,
        selectAll,
        onSuccess
      })
    ),
  openConfirmPopup: (show) => dispatch(setCommonPopup('confirm', show)),
  openAlertPopup: (show) => dispatch(setCommonPopup('success', show)),
  setSelectedStudents: (params) => dispatch(setSelectedStudents(params)),
  getResources: () =>
    dispatch(
      getResources([
        'University',
        'UniversityGroup',
        'SearchOption',
        'Industry'
      ])
    ),
  resetForm: () => dispatch(reset('searchStudents'))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(SearchStudents));
