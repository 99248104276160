import { fromJS } from 'immutable';
import { 
  FETCH_EVENT_DETAIL_SUCCESS, 
  FETCH_EVENT_DETAIL_FAILURE,
  UNSET_EVENT_DETAIL
} from './actions';

const initState = fromJS({
  eventInfo: {}
});

export default function eventDetail(state = initState, action) {
  if (action.type === FETCH_EVENT_DETAIL_SUCCESS) {
    return state.set('eventInfo', action.payload);
  }
  if (action.type === FETCH_EVENT_DETAIL_FAILURE) {
    return state;
  }
  if (action.type === UNSET_EVENT_DETAIL) {
    return initState;
  }
  return state;
}