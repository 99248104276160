export const FETCH_NOTICE_LIST_SUCCESS = 'FETCH_NOTICE_LIST_SUCCESS';
export const FETCH_NOTICE_LIST = 'FETCH_NOTICE_LIST';
export const REMOVE_RELATE_NOTICE_LIST = 'REMOVE_RELATE_NOTICE_LIST';

export const FETCH_UNREAD_NOTIFICATION_SUCCESS = 'FETCH_UNREAD_NOTIFICATION_SUCCESS';
export const FETCH_UNREAD_NOTIFICATION = 'FETCH_UNREAD_NOTIFICATION';
export const PUSH_NEW_NOTIFICATION = 'PUSH_NEW_NOTIFICATION';
export function fetchNoticeList(page, hasMore = true, loading = true, update = true) {
  return { type: FETCH_NOTICE_LIST, page, hasMore, loading, update };
}
export function fetchNoticeListSuccess(noticeList) {
  return { type: FETCH_NOTICE_LIST_SUCCESS, noticeList };
}
export function removeRelateNoticeList() {
  return { type: REMOVE_RELATE_NOTICE_LIST };
}


export function fetchUnreadNotification() {
  return { type: FETCH_UNREAD_NOTIFICATION };
}
export function fetchUnreadNotificationSuccess(number) {
  return { type: FETCH_UNREAD_NOTIFICATION_SUCCESS, number };
}
export function pushNewNotification(notification) {
  return { type: PUSH_NEW_NOTIFICATION, notification};
}

