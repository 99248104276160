import { fromJS } from 'immutable';
import {
  CLOSE_ABSENCE_WARNING,
  OPEN_ABSENCE_WARNING,
  REQUEST_GET_ABSENCE_WARNING_SUCCESS,
  REQUEST_GET_ABSENCE_WARNING_FAILED,
  OPEN_ABSENCE_WARNING_NOTIFICATION,
  REQUEST_STUDENT_SEEN_ABSENCE_WARNING_DONE
} from './action';
// import _ from 'lodash';

const initState = fromJS({
  event: null,
  isOpen: false,
  single: false
});

export default function studentAbsence(state = initState, action) {
  switch (action.type) {
    case OPEN_ABSENCE_WARNING: {
      return state.set('isOpen', true).set('single', false);
    }
    case CLOSE_ABSENCE_WARNING: {
      return state.set('isOpen', false);
    }
    case REQUEST_GET_ABSENCE_WARNING_SUCCESS: {
      return state.set('event', action.event);
    }
    case REQUEST_GET_ABSENCE_WARNING_FAILED: {
      return state.set('event', null).set('isOpen', false);
    }
    case REQUEST_STUDENT_SEEN_ABSENCE_WARNING_DONE: {
      return initState;
    }
    case OPEN_ABSENCE_WARNING_NOTIFICATION: {
      const { eventId, eventName, eventDate } = action.event;
      return state
        .set('event', { objectId: eventId, name: eventName, eventDate })
        .set('isOpen', true)
        .set('single', true);
    }
    default:
      return state;
  }
}