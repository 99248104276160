import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { setCommonPopup } from './../Popup/actions';
import PropTypes from 'prop-types';
import { setMenu } from 'containers/Header/actions';
import PrivateLink from './../PrivateLink';
import './style.scss';
import Message from '../Message';
import Notification from 'containers/Notification';
import { studentResendEmail } from 'containers/StudentUpdateProfile/actions';
import { getCurrentAccount } from 'containers/Account/actions';

const defaultAvatarURL = 'images/default-avatar.png';

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.checkEmailVerified = this.checkEmailVerified.bind(this);
  }

  checkEmailVerified() {
    const { showVerifyPopup, resendEmail, currentUser, history, sessionToken, getCurrentAccount } = this.props;
    if (!currentUser.emailVerified) {
      getCurrentAccount(sessionToken).then((result) => {
        if (!result.emailVerified) {
          showVerifyPopup({
            title: 'メール認証が必要です',
            message: 'このキャンペーンにアクセスするには、メールを認証する必要があります。',
            onConfirm: () => resendEmail({ showSuccessToast: true }),
            btnConfirm: 'メールを認証する',
            isAlert: true
          });
        } else history.push('/campaign');
      });
    } else history.push('/campaign');
  }

  profileDropDown() {
    const currentUser = this.props.currentUser;
    const { currentCampaign } = this.props;
    let userName = '';
    let avatarImage = '';

    if (currentUser) {
      userName = currentUser.displayName || currentUser.fullname;
      avatarImage = currentUser.avatarImage
        ? currentUser.avatarImage.thumbSmall
        : defaultAvatarURL;
    }

    if (!currentUser) {
      return (
        <div className="nav-user-segment">
          <div
            className="nav-signup-btn"
            onClick={this.props.openSignUpRoleSelector}
            style={{ backgroundImage: 'url("images/signup-btn.svg")' }}
          />
          <div
            className="nav-login-btn"
            onClick={this.props.openLogInRoleSelector}
            style={{ backgroundImage: 'url("images/login-btn.svg")' }}
          />
        </div>
      );
    }

    if (currentUser.role === 'PRESIDENT') {
      return (
        <div className="nav-user-segment">
          <div className="user-segment-drop-icon"></div>
          <div className="user-segment-username">Hi, {userName}</div>
          <div
            className="user-segment-avatar"
            style={{ backgroundImage: 'url(' + avatarImage + ')' }}
          ></div>
          <div className="user-dropdown-content">
            <PrivateLink to="/my-page">プロフィール</PrivateLink>
            <PrivateLink to="/management-event/wanted">
              イベント管理
            </PrivateLink>
            <PrivateLink to="/management-student">ユーザー管理</PrivateLink>
            <hr></hr>
            <span className="user-dropdown-logout" onClick={this.props.logout}>
              <div className="user-dropdown-logout-button">
                <img src="images/log-out.svg" alt="logout" /> ログアウト
              </div>
            </span>
          </div>
        </div>
      );
    }

    if (currentUser.role === 'STUDENT') {
      return (
        <div className="nav-user-segment">
          <div className="user-segment-drop-icon"></div>
          <div className="user-segment-username">Hi, {userName}</div>
          <div
            className="user-segment-avatar"
            style={{ backgroundImage: 'url(' + avatarImage + ')' }}
          ></div>
          <div className="user-dropdown-content user-dropdown-content__student">
            <PrivateLink to="/my-page">プロフィール</PrivateLink>
            <PrivateLink to="/event-history">食事会一覧</PrivateLink>
            <PrivateLink to="/offer-management">オファー</PrivateLink>
            <PrivateLink to="/gochi-management">ごちリクエスト</PrivateLink>
            {/* <PrivateLink to="/mypresidents">ごちリクエスト</PrivateLink> */}
            <a
              href="https://careertrip.jp/shachomeshi-guide/faq/"
              rel="noopener noreferrer"
              target="_blank"
            >
              よくあるご質問
            </a>
            <span onClick={this.checkEmailVerified}>
              {currentCampaign.title || '最大1万円分のアマギフキャンペーン'}
            </span>
            <PrivateLink to="/notification-settings">設定</PrivateLink>
            <hr></hr>
            <span className="user-dropdown-logout" onClick={this.props.logout}>
              <div className="user-dropdown-logout-button">
                <img src="images/log-out.svg" alt="logout" /> ログアウト
              </div>
            </span>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    if (this.props.isLogoOnly) {
      return (
        <nav className="meshi-navbar">
          <div className="nav-container">
            <div className="nav-left">
              <div className="nav-icon-container">
                <div className="logo" />
              </div>
            </div>
          </div>
        </nav>
      );
    }
    return (
      <nav className="meshi-navbar">
        <div className="nav-container">
          <div className="nav-left">
            <Link to="/" aria-label="home" className="nav-icon-container">
              <div className="logo" />
            </Link>
          </div>

          {this.profileDropDown()}
          <div
            className="nav-menu"
            onClick={this.props.controlMainMenu}
            style={{ backgroundImage: 'url("images/burger-btn.svg")' }}
          >
            {this.props.unreadMessageNumber + this.props.unreadNotiNumber >
              0 && <div id="have-something-dot" />}
          </div>
          <div className="nav-right">
            <Message />
            <Notification />
          </div>
        </div>
      </nav>
    );
  }
}

NavigationBar.propTypes = {
  location: PropTypes.object,
  currentUser: PropTypes.object,
  controlMainMenu: PropTypes.func,
  openSignUpRoleSelector: PropTypes.func,
  openLogInRoleSelector: PropTypes.func,
  logout: PropTypes.func,
  unreadMessageNumber: PropTypes.number,
  unreadNotiNumber: PropTypes.number,
  isLogoOnly: PropTypes.bool,
  currentCampaign: PropTypes.object,
  showVerifyPopup: PropTypes.func,
  resendEmail: PropTypes.func,
  history: PropTypes.object,
  getCurrentAccount: PropTypes.func,
  sessionToken: PropTypes.string
};

NavigationBar.defaultProps = {
  currentUser: undefined
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.get('account').get('data'),
    unreadNotiNumber: state.get('getNoticeList').get('number'),
    unreadMessageNumber: state.get('message').get('badgeNumber'),
    currentCampaign: state.get('campaign').get('data'),
    sessionToken: state.get('account').get('token')
  };
};

const mapDispatchToProps = (dispatch) => ({
  controlMainMenu: () => dispatch(setMenu('mainMenu', 1)),
  // logout: () => dispatch(unsetCurrentAccount()),
  logout: () => dispatch(setCommonPopup('logout', true)),
  openSignUpRoleSelector: () =>
    dispatch(setCommonPopup('signUpRoleSelect', true)),
  openLogInRoleSelector: () =>
    dispatch(setCommonPopup('loginRoleSelect', true)),
  showVerifyPopup: (payload) => dispatch(setCommonPopup('confirm', payload)),
  resendEmail: (payload) => dispatch(studentResendEmail(payload)),
  getCurrentAccount: (token) => dispatch(getCurrentAccount(token))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(NavigationBar);
