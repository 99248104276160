import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import Input from './Input'
import Textarea from './Textarea'
import './style.scss'

class NewDescription extends Component {

    render() {
        const { fields } = this.props
        return (
            <div className="new-description ">
                <div className="container-explanation">
                    <h5>内容</h5>
                    <p>
                        イベント内容は3つに分けて入力できます<br />
                        （例:会社について、社長について、イベントについて、どんな話が聞けるの？)
                    </p>
                </div>
                {fields.map((name, index, fields) => {
                    return <div key={`newDescription-${index}`} className="new-description-item">
                        <h5 className="title">内容{index + 1}</h5>
                        <div className="create-event-field">
                            <Field
                                name={`${name}.title`}
                                type="text"
                                component={Input}
                                customForm={true}
                                label="タイトル"
                                maxLength={100}
                            />
                        </div>
                        <div className="create-event-field">
                            <Field
                                name={`${name}.explanation`}
                                label="説明"
                                component={Textarea}
                                customForm={true}
                                required={true}
                                counter={1000}
                                maxLength={1000}
                                rows={8}
                            ></Field>
                        </div>
                    </div>
                })}

            </div>
        );
    }
}

NewDescription.propTypes = {
    fields: PropTypes.object.isRequired,
};

export default NewDescription;