import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { SEND_FORGOT_PASS_EMAIL } from './action';
import { setCommonPopup } from 'containers/Popup/actions';
import { setMenu } from 'containers/Header/actions';
import { push } from 'connected-react-router';

function* sendForgotPassEmail(action) {
  try {
    const data = yield call(request, 'requestPasswordReset', {
      email: action.email
    }, 'POST');

    if (data.statusCode === 200) {
      yield put(setCommonPopup('success', {
        message: 'パスワード再設定のURLを\nメールにお送りしています。\nメールをご確認ください',
        OKcallback: () => {
          window.store.dispatch(push('/'));
          if (window.isMobileDevice) {
            setTimeout(() => window.store.dispatch(setMenu('mainMenu', 1)), 1300);
          } else {
            setTimeout(() => window.store.dispatch(setCommonPopup('loginRoleSelect', 1)), 1300);
          }
        }
      }));
    }
  } catch (error) {
    if (error.code && error.code === 205) {
      yield put(setCommonPopup('success', { message: '正しいメールアドレスを入力してください' }));
    }
  }
}

export function* watchsendForgotPassEmail() {
  yield takeEvery(SEND_FORGOT_PASS_EMAIL, sendForgotPassEmail);
}