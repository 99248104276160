import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ElementRenderer from './ElementRenderer';

class Checkbox extends Component {
  render() {
    return (
      <Fragment>
        {!this.props.customForm ? (
          <ElementRenderer
            {...this.props}
            render={({ input, children }) => (
              <div className="checkbox">
                <label>
                  <input type="checkbox" {...input} />
                  {children}
                </label>
              </div>
            )} />
        ) : (
          <Fragment>
            <div className="checkbox">
              <label>
                <input type="checkbox" {...this.props.input} />
                {this.props.children}
              </label>
            </div>
            {this.props.meta.error && this.props.meta.touched &&
              <p className="help-block text-danger">
                {this.props.meta.error}
              </p>
            }
          </Fragment>
        )}
      </Fragment>

    );
  }
}

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.any,
  customForm: PropTypes.bool,
  meta: PropTypes.any
};

export default Checkbox;