import { request } from 'utilities/Api';
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { 
  receiveConversation,
  conversationLoadingMore,
  FETCH_CONVERSATION_SWITCH_REQUEST,
  FETCH_CONVERSATION_LOADMORE_REQUEST,
  receiveEventDetailForMessage,
  FETCH_EVENT_DETAIL_FOR_MESSAGE_REQUEST,
  READ_MESSAGES,
  readMessagesSuccess,
  receiveNewSender,
  FETCH_NEW_SENDER_REQUEST
} from './actions';
import { requestUnreadMessageNumber } from '../actions';
import _ from 'lodash';
import { replace } from 'connected-react-router';

function* getConversation(action) {
  try {
    let conversation = yield select((state) => 
      state.get('conversation').get('conversationsInfo').get(action.eventId) || {}
    );
    conversation = conversation.toJS ? conversation.toJS() : conversation;
    let page = conversation.page || 1;
    let hasMore = conversation.hasMore;
    if (hasMore === false || (action.type === FETCH_CONVERSATION_SWITCH_REQUEST && page > 1)) {
      return;
    }
    
    const data = yield call(request, 'functions/getMessageList', { eventId: action.eventId, page, limit: 10 }, 'POST');
    const payload = {
      eventId: action.eventId,
      hasMore: !_.isEmpty(data.result.messageList),
      page: page + 1,
      newData: data.result.messageList
    };
    yield put(receiveConversation(payload));
  } catch (error) {
    yield put(conversationLoadingMore(false));
    if (error.code === 9001) {
      yield put(replace('/'));
    }
  }
}

export function* watchConversationSwitch() {
  yield takeEvery(FETCH_CONVERSATION_SWITCH_REQUEST, getConversation);
}

export function* watchConversationLoadmore() {
  yield takeFirst(FETCH_CONVERSATION_LOADMORE_REQUEST, getConversation);
}

function* getEventDetailForMessage(action) {
  try {
    const data = yield call(request, 'functions/getEventDetailForMessage', { eventId: action.eventId }, 'POST');
    yield put(receiveEventDetailForMessage(
      data.result.greeting, 
      data.result.attendance, 
      action.eventId,
      data.result.eventDate,
      _.get(data, 'result.offerEvent', false)
    ));
  } catch (error) {
    if (error.code === 104) {
      yield put(replace('/'));
    }
  }
}

export function* watchEventDetailForMessage() {
  yield takeLatest(FETCH_EVENT_DETAIL_FOR_MESSAGE_REQUEST, getEventDetailForMessage);
}

function* readMessages(action) {
  try {
    yield call(request, 'functions/readMessages', { 
      deviceReadTime: action.deviceReadTime, 
      eventId: action.eventId 
    }, 'POST');
    yield put(requestUnreadMessageNumber());
    yield put(readMessagesSuccess(action.eventId));
    //yield put(requestMessageList());
  } catch (error) {
    console.log(error);
  }
}

export function* watchReadMessages() {
  yield takeEvery(READ_MESSAGES, readMessages);
}

function* getNewSender(action) {
  try {
    const data = yield call(request, 'functions/getUserInformation', { userId: action.senderId }, 'POST');
    yield put(receiveNewSender(data.result.infoUser));
  } catch (error) {
    console.log(error);
  }
}

export function* watchNewSender() {
  yield takeEvery(FETCH_NEW_SENDER_REQUEST, getNewSender);
}