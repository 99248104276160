import { fromJS } from 'immutable';
import _ from 'lodash';
import {
  GET_SEARCHED_STUDENTS_REQUEST,
  GET_SEARCHED_STUDENTS_SUCCESS,
  GET_SEARCHED_STUDENTS_FAILED,
  COUNT_AVAILABLE_STUDENTS_SUCCESS,
  SET_SELECTED_STUDENTS
} from './actions';
// import { CREATE_OFFER_EVENT_SUCCESS } from 'containers/OfferTemplate/actions';

const initialState = fromJS({
  page: 1,
  hasMore: true,
  list: [],
  data: {},
  isLoading: false,
  totalSlot: 0,
  totalStudent: 0,
  totalAvailableStudent: 0,
  selectedStudentIds: [],
  selectedAll: false
});

export default function searchStudentsManagement(state = initialState, action) {
  switch (action.type) {
    case GET_SEARCHED_STUDENTS_REQUEST: {
      return state.set('isLoading', true);
    }
    case GET_SEARCHED_STUDENTS_SUCCESS: {
      const payload = _.get(action, 'payload', {});
      const { page, result } = payload;
      const students = result.students;
      const totalSlot = result.totalSlot;
      const totalStudent = result.totalStudent;
      let curList = state.get('list') || [];
      const currentPage = page;

      const list = students.map((student) => student.objectId);
      state = state.set('hasMore', students.length >= 12);
      if (page === 1) {
        // reset list when page === 1
        curList = [];
        state = state.set('data', {});
      }
      students.forEach((student) => {
        state = state.setIn(['data', student.objectId], student);
      });
      return state
        .set('list', curList.concat(list))
        .set('page', currentPage + 1)
        .set('totalSlot', totalSlot)
        .set('totalStudent', totalStudent)
        .set('isLoading', false);
    }
    case GET_SEARCHED_STUDENTS_FAILED: {
      return state.set('isLoading', false);
    }
    case COUNT_AVAILABLE_STUDENTS_SUCCESS: {
      return state.set('totalAvailableStudent', action.payload);
    }
    // case CREATE_OFFER_EVENT_SUCCESS: {
    //   // Remove student from list if request offer
    //   const requestedStudents = _.get(action, 'payload.requestedStudents', []) || [];
    //   const studentIds = requestedStudents.map(student => student.objectId);
    //   const currentList = state.get('list') || [];
    //   const newList = currentList.filter(id => !studentIds.includes(id));

    //   return state.set('list', newList);
    // }
    case SET_SELECTED_STUDENTS: {
      return state
        .set('selectedStudentIds', action.payload.selectedStudentIds)
        .set('selectedAll', action.payload.selectedAll);
    }
    default:
      return state;
  }
}
