import React, { useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import Component from './Component';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import validate from './validate';

const SMSVerification = ({
  currentUser,
  infoAccount,
  ...props
}) => {
  // If account have login => direct to home
  useEffect(() => {
    if (currentUser || isEmpty(infoAccount)) {
      const returnURN = queryString.parse(get(props, 'location.search')) || {};
      get(props, 'history').replace({
        pathname: returnURN.pathname || '/',
        search: ''
      });
    }
  }, [currentUser, infoAccount]);

  return (
    <Component 
      {...props}
    />
  );
};

SMSVerification.propTypes = {
  history: PropTypes.object,
  error: PropTypes.object,
  valid: PropTypes.bool,
  isCheckedPhone: PropTypes.bool,
  onCheckPhoneNumber: PropTypes.func,
  onSubmitOtp: PropTypes.func,
  currentUser: PropTypes.object,
  infoAccount: PropTypes.object,
  onSubmitConfirmOtp: PropTypes.func
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data')
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({ 
    form: 'smsForm',
    validate
  }),
  withImmutablePropsToJS
)(SMSVerification);