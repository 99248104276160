import { request } from './../../utilities/Api';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  SEND_JOIN_EVENT_REQUEST,
  OPEN_REQUEST_EVENT_REASON_POPUP_REQUEST,
  closeRequestEventReasonPopUp
} from './action';
import { requestEventDetail } from '../../containers/EventDetail/actions';
import { getAccount } from './../Account/selectors';
import { openRequestEventReasonPopUp } from './action';
import { openCompleteProfilePopUp } from '../../components/StudentFirstJoinEventProfileForm/action';
import { destroy } from 'redux-form';
import _ from 'lodash';
import { setCommonPopup } from 'containers/Popup/actions';
import { getCurrentAccount } from 'containers/Account/actions';
//import { showLoading, hideLoading } from 'react-redux-loading-bar';
import firebase from 'firebase/app';

function* sendJoinReason(action) {
  try {
    let { payload = {} } = action;
    let { eventId = '', reason = '', reasonEx = '', presidentId = '' } = payload;

    yield call(request, 'functions/requestJoinEvent', { eventId, reason, reasonEx }, 'POST');
    yield put(requestEventDetail(payload.eventId, 'join_refresh'));
    firebase.analytics().logEvent(
      'complete_request_event', 
      { event_id: eventId, president_id: presidentId, purpose_of_participating: reason }
    );
    yield put(destroy(`requestJoinEventForm-${eventId}`));
    let reasonStorage = localStorage.getItem('reasonStorage')
      ? JSON.parse(localStorage.getItem('reasonStorage')) : [];
    const wait = _.remove(reasonStorage, (item) => {
      return item.eventId === eventId;
    });
    Promise.all(wait).then(() => {
      localStorage.setItem('reasonStorage', JSON.stringify(reasonStorage));
    });
  } catch (error) {
    console.log(error);
    if (error.error) {
      if (error.code === 8008) {
        const account = yield select(getAccount);
        const token = account.get('token');
        yield put(getCurrentAccount(token, 'requestJoinEvent'));
      } else {
        yield put(setCommonPopup('success', {message: error.error}));
      }
      yield put(closeRequestEventReasonPopUp());
    }
  }

}


export function* watchSendJoinReason() {
  yield takeEvery(SEND_JOIN_EVENT_REQUEST, sendJoinReason);
}

function* openJoinReasonPopUpRequest() {
  try {
    let currentUser = (yield select(getAccount));
    currentUser = currentUser.get('data').toJS();

    if (!currentUser) {
      return false;
    }

    if (
      !currentUser.nameHiragana ||
      !currentUser.phone ||
      !currentUser.email ||
      !currentUser.studentProfile.companyTags ||
      !currentUser.studentProfile.introduction ||
      !currentUser.studentProfile.club
    ) {
      yield put(openCompleteProfilePopUp());
    } else {
      yield put(openRequestEventReasonPopUp());
    }

  } catch (error) {
    console.log(error);
  }
}

export function* watchOpenJoinReasonPopUpReqest() {
  yield takeEvery(OPEN_REQUEST_EVENT_REASON_POPUP_REQUEST, openJoinReasonPopUpRequest);
}