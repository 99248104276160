export const FETCH_EVENT_HISTORY_REQUEST = 'FETCH_EVENT_HISTORY_REQUEST';
export const FETCH_EVENT_HISTORY_SUCCESS = 'FETCH_EVENT_HISTORY_SUCCESS';
export const FETCH_EVENT_HISTORY_FAILURE = 'FETCH_EVENT_HISTORY_FAILURE';

export function requestEventsHistory() {
  return {type: FETCH_EVENT_HISTORY_REQUEST};
}

export function receiveEventsHistory(eventGroups) {
  return {type: FETCH_EVENT_HISTORY_SUCCESS, eventGroups: eventGroups};
}

export const FETCH_EVENT_HISTORY_BY_STATUS_REQUEST = 'FETCH_EVENT_HISTORY_BY_STATUS_REQUEST';
export const FETCH_EVENT_HISTORY_BY_STATUS_SUCCESS = 'FETCH_EVENT_HISTORY_BY_STATUS_SUCCESS';
export const FETCH_EVENT_HISTORY_BY_STATUS_FAILURE = 'FETCH_EVENT_HISTORY_BY_STATUS_FAILURE';
export const REMOVE_EVENT_HISTORY = 'REMOVE_EVENT_HISTORY';

export function requestEventHistoryByStatus(status, page) {
  return {type: FETCH_EVENT_HISTORY_BY_STATUS_REQUEST, status, page};
}

export function receiveEventHistoryByStatus(eventList, status) {
  return {type: FETCH_EVENT_HISTORY_BY_STATUS_SUCCESS, eventList, status};
}

export function removeEventHistory() {
  return {type: REMOVE_EVENT_HISTORY};
}

export const GET_OFFER_EVENTS_BY_STATUS_REQUEST = 'GET_OFFER_EVENTS_BY_STATUS_REQUEST';
export const GET_OFFER_EVENTS_BY_STATUS_SUCCESS = 'GET_OFFER_EVENTS_BY_STATUS_SUCCESS';
export const GET_OFFER_EVENTS_BY_STATUS_FAILURE = 'GET_OFFER_EVENTS_BY_STATUS_FAILURE';

export const getOfferEventsByStatusRequest = (payload) => ({
  type: GET_OFFER_EVENTS_BY_STATUS_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});

export const getOfferEventsByStatusSuccess = (payload, meta) => ({
  type: GET_OFFER_EVENTS_BY_STATUS_SUCCESS,
  payload,
  meta
});

export const getOfferEventsByStatusFailure = (payload, meta) => ({
  type: GET_OFFER_EVENTS_BY_STATUS_FAILURE,
  payload,
  meta,
  error: true
});