function telephoneCheck(str) {
  return str.length > 8 && str.length < 15;
}

const validate = values => {
  const errors = {};
  //numberPhone
  const numberPhone = values.get('numberPhone');
  if (!numberPhone) {
    errors.numberPhone = 'この値は必須です。';
  } else if (!telephoneCheck(values.get('numberPhone'))) {
    errors.numberPhone = 'Invalid Phone Number';
  }
  return errors;
};

export default validate;