const validate = values => {
  const errors = {};
  if (!values.get('eventDate'))
    errors.eventDate = '開始時間を入力してください。';
  if (!values.get('eventTime'))
    errors.eventTime = '開催時間を入力してください。';
  if (!values.get('duration'))
    errors.duration = 'Please choose the duration';
  if (!values.get('name'))
    errors.name = 'Please enter a name';
  else if (values.get('name').length > 100 || values.get('name').length < 1)
    errors.name = 'The length is inappropriate';
  if (!values.get('location'))
    errors.location = 'Please enter a location';
  else if (values.get('location').length > 100 || values.get('location').length < 1)
    errors.location = 'The length is inappropriate';
  if (!values.get('capacity'))
    errors.capacity = 'Please choose a number';
  if (!values.get('prefecture'))
    errors.prefecture = 'Please choose a prefecture';
  if (!values.get('graduateYear'))
    errors.graduateYear = 'Please choose a year';
  if (!values.get('newDescription'))
    errors.newDescription = 'Please enter the explanation';
  else {
    let hasExplanation = false;
    const newDescription = values.get('newDescription').toJS();
    newDescription.forEach((element, index) => {
      if (element.explanation) {
        hasExplanation = true;
      }
      if (element.title && !element.explanation) {
        if (!errors.newDescription) {
          errors.newDescription = {};
        }
        errors.newDescription[index] = { explanation: 'please enter newDescription' };
      }
    });

    if (!hasExplanation) {
      if (!errors.newDescription) {
        errors.newDescription = {};
      }
      errors.newDescription[0] = { explanation: 'please enter newDescription' };
    }
  }
  return errors;
};
export default validate;
