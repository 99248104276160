import isAsciiEmail from 'sane-email-validation';

const validate = values => {
  const errors = {};
  if (!values.get('username')) {
    errors.username = 'この値は必須です。';
  } else if (!isAsciiEmail(values.get('username'))) {
    errors.username = 'Invalid email';
  }
  if (!values.get('password')) {
    errors.password = 'この値は必須です。';
  }
  return errors;
};

export default validate;