import { request } from './../../utilities/Api';
import { takeFirst } from './../../utilities/supportSaga';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { replace } from 'connected-react-router';

import { 
  GET_EVENT_NEED_REVIEW, 
  getUserListToReview, 
  reviewStudentsSuccess,
  getEventNeedReview as getEventNeedReviewAction,
  REVIEW_EVENT,
  GET_REVIEW_STATUS_OF_EVENT,
  studentDoneReview,
  resetReview,
  resetReviewInfomation,
  COMPLETE_REVIEW,
  completeReview
} from './action';
import { setCommonPopup } from 'containers/Popup/actions';
import _ from 'lodash';
import { requestGetAbsenceWarning } from '../AbsenceWarning/action';

function* getEventNeedReview() {
  try {
    const role = yield select(state => 
      state.get('account').get('data').get('role')
    );
    let data;
    if (role === 'PRESIDENT') {
      data = yield call(request, 'functions/getEventNeedReview', {}, 'POST');
    }
    if (role === 'STUDENT') {
      data = yield call(request, 'functions/getEventStudentNeedReview', {}, 'POST');
    }
    
    const { eventId, hasReviewingEvent } = data.result;
    if (!hasReviewingEvent) {
      yield put(resetReview());
      yield put(requestGetAbsenceWarning());
    }
    if (hasReviewingEvent) {
      yield put(resetReviewInfomation());
      const data = yield call(request, 'functions/getUserListToReview', { eventId }, 'POST');
      yield put(getUserListToReview(eventId, data.result, hasReviewingEvent));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetEventNeedReview() {
  yield takeEvery(GET_EVENT_NEED_REVIEW, getEventNeedReview);
}

function * handleSubmitReview(action) {
  try {
    yield put(showLoading());
    const { values } = action;
    const role = yield select(state => state.get('account').get('data').get('role'));
    if (role === 'PRESIDENT') {
      yield call(request, 'functions/reviewStudentsV2', values, 'POST');
      yield put(reviewStudentsSuccess(values));
    }

    if (role === 'STUDENT') {
      yield call(request, 'functions/reviewEvent', values, 'POST');
      if (values.hasJoin) yield put(studentDoneReview());
      else {
        yield put(completeReview());
      }
    }
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
    yield put(setCommonPopup('success', {
      title: '登録済み', 
      message: '出欠登録は完了しています。',
      OKcallback: () => {
        window.store.dispatch(replace('/'));
        window.store.dispatch(resetReview());
      }
    }));
  }
}

export function* watchHandleSubmitReview() {
  yield takeFirst(REVIEW_EVENT, handleSubmitReview);
}

function* handleCompleteReview() {
  try {
    const isSingle = yield select(state => 
      state.get('review').get('isSingle')
    );
    const locationBeforeReview = yield select(state => 
      state.get('review').get('locationBeforeReview')
    );
    if (!isSingle) { // Get new review event if isn't single else go to homepage
      yield put(getEventNeedReviewAction());
    } else {
      yield put(replace(locationBeforeReview));
      yield put(resetReview());
    }
  } catch (error) {
    console.log(error);
  }
}

function* getReviewStatusOfEvent(action) {
  try {
    const { eventId, locationBeforeReview } = action;
    const data = yield call(request, 'functions/getReviewStatusOfEvent', { eventId }, 'POST');
    const { result } = data;
    const hasProperty = _.has(result, 'attendanceUpdated');
    const reviewed = hasProperty ? result.attendanceUpdated : result.reviewed;

    if (reviewed) {
      yield put(setCommonPopup('success', { title: '登録済み', message: '出欠登録は完了しています。' }));
    }
    if (!reviewed) {
      const data = yield call(request, 'functions/getUserListToReview', { eventId }, 'POST');
      yield put(getUserListToReview(eventId, data.result, true, true, locationBeforeReview));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetReviewStatusOfEvent() {
  yield takeFirst(GET_REVIEW_STATUS_OF_EVENT, getReviewStatusOfEvent);
  yield takeFirst(COMPLETE_REVIEW, handleCompleteReview);
}