import { takeEvery } from 'redux-saga/effects';
import { NEW_NOTIFICATION_COME } from './action';
import webPush from 'utilities/webPush';

const PREVENT_PUSH_MESSAGE_PAGES = ['messages'];

function* webPushNotification(action) {
  const type = action.notification && action.notification.type;
  
  if (type === 'HAVE_NEW_MESSAGE') {
    let currentPage = window.ReactHistory.location.pathname;
    currentPage = (currentPage.split('/'))[1];
    if (PREVENT_PUSH_MESSAGE_PAGES.includes(currentPage))
      return;
  }
  
  yield webPush.push(action.notification);
}

export function* _watchWebNotification() {
  yield takeEvery(NEW_NOTIFICATION_COME, webPushNotification);
}