export const FETCH_NEW_EVENTS_REQUEST = 'FETCH_NEW_EVENTS_REQUEST';
export const FETCH_NEW_EVENTS_SUCCESS = 'FETCH_NEW_EVENTS_SUCCESS';
export const FETCH_NEW_EVENTS_FAILURE = 'FETCH_NEW_EVENTS_FAILURE';
export const RESET_NEW_EVENTS = 'RESET_NEW_EVENTS';

export function requestNewEvents(more = 0) {
  return {type: FETCH_NEW_EVENTS_REQUEST, more};
}

export function receiveNewEvents(events, page) {
  return {type: FETCH_NEW_EVENTS_SUCCESS, events: events, page: page};
}

export function failToGetNewEvents(payload) {
  return {type: FETCH_NEW_EVENTS_FAILURE, payload};
}

export function resetNewEventList(payload) {
  return {type: RESET_NEW_EVENTS, payload};
}