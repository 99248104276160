/* eslint-disable space-before-function-paren */
/* eslint-disable semi */
import { fromJS } from 'immutable'
import _ from 'lodash'
import {
  LOGIN_FIREBASE_SUCCESS,
  LOGOUT_FIREBASE_SUCCESS,
  UNREAD_MESSAGE_UPDATE,
  CHAT_GROUP_PREVIEW_UPDATE,
  CHAT_CONVERSATION_UPDATE,
  STOP_CHAT_CONVERSATION_UPDATE,
  LOADING_MORE_CHAT_CONVERSATION,
  START_CHAT_CONVERSATION_LISTENING,
  SET_CONVERSATION_DATA
} from './constants'

export const initialState = fromJS({
  userDataReceived: false,
  unreadMessages: 0,
  groupChatPreview: {},
  listeningConversationWithOperator: false,
  conversationWithOperator: [],
  loadingMoreMessages: false
})

function messageAdapterReducer (state = initialState, action) {
  switch (action.type) {
    case LOGIN_FIREBASE_SUCCESS: {
      return state
        .set('userDataReceived', true)
    }
    case UNREAD_MESSAGE_UPDATE: {
      const { unreadMessages } = action.data
      return state.set('unreadMessages', unreadMessages)
    }
    case CHAT_GROUP_PREVIEW_UPDATE: {
      return state.set('groupChatPreview', action.data)
    }
    case SET_CONVERSATION_DATA: {
      let conversation = state.get('conversationWithOperator')
      if (conversation.length > 0) {
        const oldMessagesRepeated = conversation.filter(msg => msg.id === action.data[0].id);
        if (oldMessagesRepeated.length === 0) {
          conversation = conversation.concat(...action.data)
        }
      } else {
        conversation = action.data
      }
      return state
        .set('conversationWithOperator', conversation)
        .set('loadingMoreMessages', false)
    }
    case CHAT_CONVERSATION_UPDATE: {
      let conversation = state.get('conversationWithOperator')
      if (conversation.size && conversation.size === 0) {
        return state
          .set('conversationWithOperator', action.data)
      } else if (!_.isEqual(action.data[0], conversation[0])) {
        conversation = action.data.concat(...conversation)
      }
      return state
        .set('conversationWithOperator', conversation)
    }
    case LOADING_MORE_CHAT_CONVERSATION: {
      return state
        .set('loadingMoreMessages', action.data)
    }
    case START_CHAT_CONVERSATION_LISTENING: {
      return state
        .set('listeningConversationWithOperator', true)
    }
    case STOP_CHAT_CONVERSATION_UPDATE: {
      return state
        .set('conversationWithOperator', [])
        .set('listeningConversationWithOperator', false)
    }
    case LOGOUT_FIREBASE_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default messageAdapterReducer
