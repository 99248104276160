import React, { Component, Fragment } from 'react';
import EventListSmall from '../../components/EventListSmall';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import {
  requestEventsHistory,
  requestEventHistoryByStatus,
  removeEventHistory,
  getOfferEventsByStatusRequest
} from './actions';
import PropTypes from 'prop-types';
import Loading from './../../components/Loading';
import _ from 'lodash';
import './style.css';

class EventHistory extends Component {
  isReloadPage = () => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        return true;
      } else {
        return false;
      }
    }
  };

  componentDidMount() {
    const {
      lockedEvents,
      requestedEvents,
      finishedEvents,
      activeEvents
    } = this.props;

    const isPushed = this.props.history && this.props.history.action === 'PUSH';
    const isAllEmpty =
      _.isEmpty(lockedEvents.list) &&
      _.isEmpty(requestedEvents.list) &&
      _.isEmpty(finishedEvents.list) &&
      _.isEmpty(activeEvents.list);
    if (isPushed || isAllEmpty) {
      this.props.requestEventHistoryByStatus('LOCKED');
      if (this.props.role === 'STUDENT') {
        this.props.requestEventHistoryByStatus('REQUESTED');
      }
      this.props.requestEventHistoryByStatus('FINISHED');
      if (this.props.role === 'PRESIDENT') {
        this.props.requestEventHistoryByStatus('ACTIVE');
      }
    }
  }

  componentWillMount() {
    if (this.props.history && this.props.history.action === 'PUSH') {
      this.props.removeEventHistory();
    }
  }

  renderContent() {
    const {
      lockedEvents,
      requestedEvents,
      finishedEvents,
      activeEvents
    } = this.props;
    if (
      _.isEmpty(lockedEvents.list) &&
      _.isEmpty(requestedEvents.list) &&
      _.isEmpty(finishedEvents.list) &&
      _.isEmpty(activeEvents.list)
    ) {
      if (
        lockedEvents.page > 1 ||
        requestedEvents.page > 1 ||
        finishedEvents.page > 1 ||
        activeEvents.page > 1
      ) {
        return (
          <div className="screen-holder">
            応募した社長メシや過去に
            <br className="br-text" />
            参加した社長メシが表示されます。
            <br />
            参加したい社長メシを探して応募しましょう。
          </div>
        );
      } else {
        return <Loading />;
      }
    }

    return (
      <Fragment>
        <EventListSmall
          title={'今後の食事会'}
          events={lockedEvents.list}
          heightHeader={180}
          role={this.props.role}
          hasMore={lockedEvents.hasMore}
          requestEventHistoryByStatus={() =>
            this.props.requestEventHistoryByStatus('LOCKED', lockedEvents.page)
          }
        />
        {this.props.role === 'PRESIDENT' ? (
          <EventListSmall
            title={'募集中の食事会'}
            events={activeEvents.list}
            heightHeader={180}
            hasMore={activeEvents.hasMore}
            requestEventHistoryByStatus={() =>
              this.props.requestEventHistoryByStatus(
                'ACTIVE',
                activeEvents.page
              )
            }
          />
        ) : (
          <EventListSmall
            title={'応募中の食事会'}
            events={requestedEvents.list}
            heightHeader={180}
            hasMore={requestedEvents.hasMore}
            requestEventHistoryByStatus={() =>
              this.props.requestEventHistoryByStatus(
                'REQUESTED',
                requestedEvents.page
              )
            }
          />
        )}
        <EventListSmall
          title={'過去の食事会'}
          events={finishedEvents.list}
          heightHeader={180}
          hasMore={finishedEvents.hasMore}
          requestEventHistoryByStatus={() =>
            this.props.requestEventHistoryByStatus(
              'FINISHED',
              finishedEvents.page
            )
          }
        />
      </Fragment>
    );
  }

  render() {
    return (
      <div className="row event-history-background">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="event-history-container">
            <div className="event-history-title">食事会一覧</div>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

EventHistory.propTypes = {
  lockedEvents: PropTypes.object,
  requestedEvents: PropTypes.object,
  finishedEvents: PropTypes.object,
  activeEvents: PropTypes.object,
  requestEventsHistory: PropTypes.func,
  requestEventHistoryByStatus: PropTypes.func,
  role: PropTypes.string,
  history: PropTypes.any,
  removeEventHistory: PropTypes.func,
  location: PropTypes.object,
  getOfferEventsByStatus: PropTypes.func,
  pendingOffers: PropTypes.array,
  acceptOffers: PropTypes.array,
  rejectOffers: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    role: state
      .get('account')
      .get('data')
      .get('role'),
    lockedEvents: state.get('eventHistory').get('lockedEvents') || {},
    requestedEvents: state.get('eventHistory').get('requestedEvents') || {},
    finishedEvents: state.get('eventHistory').get('finishedEvents') || {},
    activeEvents: state.get('eventHistory').get('activeEvents') || {},
    pendingOffers: state.get('eventHistory').get('pendingOffers'),
    acceptOffers: state.get('eventHistory').get('acceptOffers'),
    rejectOffers: state.get('eventHistory').get('rejectOffers')
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestEventsHistory: () => dispatch(requestEventsHistory()),
  removeEventHistory: () => dispatch(removeEventHistory()),
  requestEventHistoryByStatus: (status, page) =>
    dispatch(requestEventHistoryByStatus(status, page)),
  getOfferEventsByStatus: ({ status }) => dispatch(getOfferEventsByStatusRequest({ status }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(EventHistory));
