import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ElementRenderer from './ElementRenderer';
import './style.scss';

class InputLocation extends Component {
  render() {
    return (
      <Fragment>
        {!this.props.customForm ? (
          <ElementRenderer
            {...this.props}
            render={({ input, type, placeholder, maxLength, disabled }) => (
              <input
                {...input}
                type={type}
                placeholder={placeholder}
                maxLength={maxLength}
                className="form-control input-md"
                disabled={disabled} />
            )} />
        ) : (
          <Fragment>
            <div
              className={`custom-input-container
              ${!this.props.label && 'custom-input-no-label'}
              ${this.props.meta.error && 'error-form'}
              `}>
              <div className='map-custom-input'>
                <div>
                  { this.props.label ? (<span className="subtitle">{this.props.label}</span>) : null }
                  <input
                    {...this.props.input}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    maxLength={this.props.maxLength}
                    className="form-control input-md"
                    disabled={this.props.disabled} />
                </div>
                {/* Only show if already input location */}
                <div className='map-marker-button'>
                  <img
                    alt='map-marker'
                    onClick={this.props.openMapModal}
                    src='images/locationEdit.png' />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

InputLocation.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  customForm: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.any,
  openMapModal: PropTypes.func
};

export default InputLocation;