import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './style.scss';

class Countdown extends React.Component {
  state = {
    daysRemaining: null
  };

  componentDidMount() {
    const { time } = this.props;
    this.calculateDaysRemaining(time);
  }

  calculateDaysRemaining = (time) => {
    const currentTime = moment();
    const expirationTime = moment(time);
    const diffDays = expirationTime.diff(currentTime, 'days');
    const daysRemaining = Math.max(7 + diffDays, 0);
    this.setState({
      daysRemaining
    });
  };

  render() {
    const { daysRemaining } = this.state;
    if (this.props.time === null || daysRemaining === 0) return <></>;
    return <div className='day-remain-text'> 辞退された学生には {daysRemaining} 日後、オファーを送信できる。</div>;
  }
}
Countdown.propTypes = {
  time: PropTypes.string
};

export default Countdown;