import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable'; 
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import { StarRating, RadioBtn } from 'components/Form';
import Button from 'components/Button';
import validate from './validate';
import './styles.scss';

const ReviewStudentForm = ({
  formValues,
  avatarImage,
  openPopupViewStudentProfile,
  onBack,
  ...props
}) => {
  const { valid } = props;
  const studentWishes = [
    { 
      value: true,
      text: '選考に進める'
    },
    {
      value: false,
      text: '選考に進めない'
    }
  ];

  const handleDelayCheckbox = () => {
    const newValue = formValues.attendance === 'DELAY' ? 'ATTENDANCE' : 'DELAY';
    props.change('attendance', newValue);
  };

  return (
    <form id='prez-review-stud' onSubmit={props.handleSubmit}>
      <div className="text-note">この評価は学生には表示されません</div>
      <div className="student-basic-information">
        <div className="avatar-container">
          <img
            src={_.get(avatarImage, 'thumbSmall', '/images/default-avatar.png')}
            alt='avatar'
            draggable='false' 
          />
        </div>
        <div className="student-content">
          <div className="student-name">{_.get(formValues, 'displayName', '')}</div>
          <div className="show-student-detail" onClick={openPopupViewStudentProfile}>プロフィールを見る</div>
        </div>
        <div className='student-delay-checkbox'>
          <img 
            src={formValues.attendance === 'DELAY' ? '/images/checked.svg' : '/images/uncheck.svg'} 
            alt='checkbox'
            draggable='false'
            onClick={handleDelayCheckbox}
          />
          <div className={`student-delay-text ${formValues.attendance === 'DELAY' && 'delay-checked'}`}>遅刻</div>
        </div>
      </div>
      <div className='president-review'>
        <div className='text-note text-highlight'>評価</div>
        <div className='rating-note'>★をタップ</div>
        <div className='star-rating-wrapper'>
          <div className='text-note'>コミュニケーション力</div>
          <div className='star-container'>
            <Field 
              name='communicateRating'
              component={StarRating}
              size='normal'
              className='student-rating'
            />
          </div>
        </div>
        <div className='star-rating-wrapper'>
          <div className='text-note'>素直さ</div>
          <div className='star-container'>
            <Field 
              name='honestnessRating'
              component={StarRating}
              size='normal'
            />
          </div>
        </div>
        <div className='star-rating-wrapper'>
          <div className='text-note'>地頭</div>
          <div className='star-container'>
            <Field 
              name='smartnessRating'
              component={StarRating}
              size='normal'
            />
          </div>
        </div>
        <div className='invite-student-wrapper'>
          <div className='text-note text-highlight'>今後の希望を選択してください</div>
          <Field
            name='isInvited'
            component={RadioBtn}
            options={studentWishes}
          />
        </div>
      </div>
      <div className='btn-wrapper mt-40'>
        <Button
          type='submit'
          block
          className='btn-submit'
          disabled={!valid}
        >
          登録
        </Button>
        <div className='btn-back mt-4' onClick={onBack}>
          戻る
        </div>
      </div>
    </form>
  );
};

ReviewStudentForm.propTypes = {
  formValues: PropTypes.object,
  avatarImage: PropTypes.object,
  onChangeField: PropTypes.func,
  onBack: PropTypes.func,
  openPopupViewStudentProfile: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  change: PropTypes.func,
  valid: PropTypes.bool
};

const selector = formValueSelector('prezReviewStud');

const mapStateToProps = state => ({
  formValues: selector(state, 'attendance', 'displayName'),
  avatarImage: selector(state, 'avatarImage')
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'prezReviewStud',
    validate,
    enableReinitialize: true
  }),
  withImmutablePropsToJS
)(ReviewStudentForm);
