export const INVITE_TO_EVENT_REQUEST = 'INVITE_TO_EVENT_REQUEST';
export const INVITE_TO_EVENT_SUCCESS = 'INVITE_TO_EVENT_SUCCESS';
export const INVITE_TO_EVENT_FAILURE = 'INVITE_TO_EVENT_FAILURE';

export const GET_ACTIVE_EVENTS_REQUEST = 'GET_ACTIVE_EVENTS_REQUEST';
export const GET_ACTIVE_EVENTS_SUCCESS = 'GET_ACTIVE_EVENTS_SUCCESS';
export const GET_ACTIVE_EVENTS_FAILURE = 'GET_ACTIVE_EVENTS_FAILURE';

export const STUDENT_RESPONSE_INVITATION_REQUEST = 'STUDENT_RESPONSE_INVITATION_REQUEST';
export const STUDENT_RESPONSE_INVITATION_SUCCESS = 'STUDENT_RESPONSE_INVITATION_SUCCESS';
export const STUDENT_RESPONSE_INVITATION_FAILURE = 'STUDENT_RESPONSE_INVITATION_FAILURE';

export const inviteToEventRequest = (payload) => ({
  type: INVITE_TO_EVENT_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const inviteToEventSuccess = (payload, meta) => ({
  type: INVITE_TO_EVENT_SUCCESS,
  payload,
  meta
});
export const inviteToEventFailure = (payload, meta) => ({
  type: INVITE_TO_EVENT_FAILURE,
  payload,
  meta,
  error: true
});

export const getActiveEventsRequest = (payload) => ({
  type: GET_ACTIVE_EVENTS_REQUEST,
  payload
});
export const getActiveEventsSuccess = (payload, meta) => ({
  type: GET_ACTIVE_EVENTS_SUCCESS,
  payload,
  meta
});
export const getActiveEventsFailure = (payload, meta) => ({
  type: GET_ACTIVE_EVENTS_FAILURE,
  payload,
  meta,
  error: true
});

export const studentResponseInvitaionRequest = (payload) => ({
  type: STUDENT_RESPONSE_INVITATION_REQUEST,
  payload
});
export const studentResponseInvitaionSuccess = (payload, meta) => ({
  type: STUDENT_RESPONSE_INVITATION_SUCCESS,
  payload,
  meta
});
export const studentResponseInvitaionFailure = (payload, meta) => ({
  type: STUDENT_RESPONSE_INVITATION_REQUEST,
  payload,
  meta,
  error: true
});