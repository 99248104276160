/* eslint-disable camelcase */
import { all } from 'redux-saga/effects';
import watchIncrementAsync from './../containers/Example/saga';
import watchNewEvents from './../containers/NewEvent/saga';
import watchWaitingStudentEvents from './../containers/WaitingStudentList/saga';
import watchEventsByDate from './../containers/EventByDate/saga';
import watchEventsByTag from './../containers/EventByTag/saga';
import watchGetPresidentList from './../containers/PresidentList/saga';
import watchGetUserInfo from './../containers/PresidentDetail2/saga';
import watchEventDetail from './../containers/EventDetail/saga';
import watchAccount, { watchSetAccount } from './../containers/Account/saga';
import { watchLineLogin } from '../containers/Auth/saga';
import watchLogin from './../containers/Login/saga';
import watchResources from './../containers/Resources/saga';
import watchPresidentSignUp from './../containers/SignUpForPresident/saga';
import watchStudentSignUp from './../containers/StudentSignup/saga';
import watchStudentCompleteProfile from './../containers/StudentUpdateProfile/saga';
import watchPresidentViewApprovalNotification from './../containers/ApprovalNotification/saga';
import { watchSendJoinReason, watchOpenJoinReasonPopUpReqest } from './../containers/RequestEventRessonPopUp/saga';
import watchCloseProfilePopUp from './../components/StudentFirstJoinEventProfileForm/saga';
import watchCancelRequestJoin from './../containers/CancelRequestEventPopUp/saga';
import watchEventsHistory from './../containers/EventHistory/saga';
import { watchEventsHistoryBySTatus } from './../containers/EventHistory/saga';
import {
  watchGetFollowList,
  watchSetFollowUser,
  watchSetNotificationStatus
} from './../containers/FollowList/saga';
import { watchStudentSurvey } from './../containers/StudentSurvey/saga';
import { watchPresidentUpdateProfile } from './../containers/PresidentUpdateProfile/saga';
import { watchUseRedirectURN } from './../containers/LinkWithRedirect/saga';
import { watchCreateEvent } from '../containers/CreateEvent/saga';
import { watchDestroyAllForm } from '../containers/Form/saga';
import watchLockEvent from './../containers/PopupLockEvent/saga';
import watchChooseStudent from '../containers/PopupChooseStudent/saga';
import watchRelateEventList from './../containers/EventListOfPresident/saga';
import {
  watchMessageList,
  watchUnreadMessageNumber,
  watchEventDetailForNewConversation,
  watchIconMessageForNewMessageCome
} from 'containers/Message/saga';
import {
  watchConversationLoadmore,
  watchConversationSwitch,
  watchEventDetailForMessage,
  watchReadMessages,
  watchNewSender
} from 'containers/Message/MessageConversation/saga';
import { watchSendMessage } from 'containers/Message/MessageConversation/MessageInput/saga';
import { watchStickers, watchGreeting } from 'containers/PopupGreeting/saga';
import { watchConfirmAttendance } from 'containers/Popup24hConfirm/saga';
import {
  watchGetNoticeList,
  watchGetUnreadNotificationList,
  watchNewNotificationCome
} from 'containers/Notification/saga';
import {
  watchGetEventNeedReview,
  watchHandleSubmitReview,
  watchGetReviewStatusOfEvent
} from '../containers/Review/saga';
import watchBannersForCarousel from 'components/BannerCarousel/saga';
import watchEventByBanner from '../containers/EventByBanner/saga';
import watchEventsByStatus from '../containers/Management/EventsManagement/saga';

import { watchsendForgotPassEmail } from 'containers/ForgotPassword/saga';
import { _adapter_watchUnsetAccount, _adapter_watchLogin } from '../utilities/LiveAdapter/saga';
import { _watchWebNotification } from '../utilities/LiveAdapter/modules/Notification/saga';
import { watchGetMediaInfoByUrl } from '../components/SocialMedia/saga';
import watchPresidentReviews from '../containers/ReviewList/saga';
import watchStudentManagement from '../containers/Management/StudentsManagement/EventList/saga';
import { watchEventDetailStudentDataCSV } from '../components/EventDetail/ExportEventStudentsCSVButton/saga';
import watchChangeEmail from '../containers/EmailChange/saga';
import watchGetSeo from '../containers/Seo/saga';
import watchFirebaseListener from '../containers/FirebaseListener/saga';
import watchAcceptPolicy from '../containers/PopupPolicy/saga';
import { watchGetAbsenceOfStudentEvent } from '../containers/AbsenceWarning/saga';
import watchGochiEvent from 'containers/GochiEvent/saga';
import { watchPlans } from 'containers/Plans/saga';
import { watchOfferTemplate } from 'containers/OfferTemplate/saga';
import watchInviteToEvent from 'containers/InviteToEventModal/saga';
import watchOfferEvent from 'containers/OfferEventRequestModal/saga';
import { watchUpdateEvent } from '../containers/EditEvent/saga';
import watchCommonAuth from '../containers/CommonAuth/saga';
import watchSearchStudentsManagement from 'containers/Management/StudentsManagement/SearchStudents/saga';
import watchCampaign from 'containers/Campaign/saga';
import watchSMSVerification from '../components/SMSVerification/saga';
import watchNotificationSetting from 'containers/NotificationSetting/saga';

export default function* rootSaga() {
  yield all([
    watchIncrementAsync(),
    watchNewEvents(),
    watchWaitingStudentEvents(),
    watchEventsByDate(),
    watchEventsByTag(),
    watchGetPresidentList(),
    watchGetUserInfo(),
    watchEventDetail(),
    watchAccount(),
    watchSetAccount(),
    watchLogin(),
    watchLineLogin(),
    watchResources(),
    watchPresidentSignUp(),
    watchStudentSignUp(),
    watchStudentCompleteProfile(),
    watchPresidentViewApprovalNotification(),
    watchSendJoinReason(),
    watchCloseProfilePopUp(),
    watchCancelRequestJoin(),
    watchEventsHistory(),
    watchGetFollowList(),
    watchSetFollowUser(),
    watchSetNotificationStatus(),
    watchEventsHistoryBySTatus(),
    watchOpenJoinReasonPopUpReqest(),
    watchStudentSurvey(),
    watchPresidentUpdateProfile(),
    watchUseRedirectURN(),
    watchCreateEvent(),
    watchUpdateEvent(),
    watchDestroyAllForm(),
    watchChooseStudent(),
    watchLockEvent(),
    watchRelateEventList(),
    watchMessageList(),
    watchUnreadMessageNumber(),
    watchConversationSwitch(),
    watchConversationLoadmore(),
    watchSendMessage(),
    watchEventDetailForMessage(),
    watchStickers(),
    watchGreeting(),
    watchConfirmAttendance(),
    watchsendForgotPassEmail(),
    watchReadMessages(),
    watchGetNoticeList(),
    watchGetUnreadNotificationList(),
    watchNewNotificationCome(),
    watchIconMessageForNewMessageCome(),
    watchEventDetailForNewConversation(),
    watchNewSender(),
    watchGetEventNeedReview(),
    watchHandleSubmitReview(),
    watchGetReviewStatusOfEvent(),
    watchGetMediaInfoByUrl(),
    _adapter_watchUnsetAccount(),
    _adapter_watchLogin(),
    _watchWebNotification(),
    watchBannersForCarousel(),
    watchEventByBanner(),
    watchPresidentReviews(),
    watchEventsByStatus(),
    watchStudentManagement(),
    watchEventDetailStudentDataCSV(),
    watchChangeEmail(),
    watchGetSeo(),
    watchFirebaseListener(),
    watchAcceptPolicy(),
    watchGetAbsenceOfStudentEvent(),
    watchGochiEvent(),
    watchOfferEvent(),
    watchPlans(),
    watchOfferTemplate(),
    watchInviteToEvent(),
    watchCommonAuth(),
    watchSearchStudentsManagement(),
    watchCampaign(),
    watchSMSVerification(),
    watchNotificationSetting()
  ]);
}
