import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

class RouteForStudent extends Component {
  render() {
    const { currentUser, WrapRoute, ...rest } = this.props;
    if (currentUser === null || currentUser.role !== 'STUDENT') {
      return <Redirect to="/"/>;
    }
    return <WrapRoute {...rest} />;
  }
}

RouteForStudent.propTypes = {
  WrapRoute: PropTypes.any,
  currentUser: PropTypes.any
};

RouteForStudent.defaultProps = {
  WrapRoute: Route
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data')
  };
};

export default connect(
  mapStateToProps
)(withImmutablePropsToJS(RouteForStudent));
