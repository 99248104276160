import { fromJS } from 'immutable';
import {
  FETCH_NOTICE_LIST_SUCCESS, REMOVE_RELATE_NOTICE_LIST,
  FETCH_UNREAD_NOTIFICATION_SUCCESS,
  PUSH_NEW_NOTIFICATION
} from './actions';
import _ from 'lodash';
import { NOTIFICATION_TYPE, NOTIFICATION_MESSAGE_BY_TYPE } from '../../utilities/constants';
const initState = fromJS({
  data: {},
  page: 1,
  noticeList: [],
  hasMore: true,
  number: 0
});

export function getNoticeList(state = initState, action) {
  switch (action.type) {
    case FETCH_NOTICE_LIST_SUCCESS: {
      const { noticeList = [] } = action;
      if (_.isEmpty(noticeList)) {
        return state.set('hasMore', false).set('page', state.get('page') + 1);
      }
  
      const tempList = noticeList.map(cell => {
        if (cell.type === NOTIFICATION_TYPE.NOTIFICATION_TYPE) {
          cell = { ...cell, message: NOTIFICATION_MESSAGE_BY_TYPE.INVITE_JOIN_EVENT };
        }
        state = state.setIn(['data', cell.objectId], cell);
        return cell.objectId;
      });

      state = state.update('noticeList', oldList => {
        oldList = oldList.toJS ? oldList.toJS() : oldList;
        return fromJS([...new Set(oldList.concat(tempList))]);
      });

      return state.set('page', state.get('page') + 1);
    }


    case FETCH_UNREAD_NOTIFICATION_SUCCESS:
      return state.set('number', action.number);


    case PUSH_NEW_NOTIFICATION: {
      state = state.update('noticeList', oldList => {
        oldList = oldList.toJS ? oldList.toJS() : oldList;
        oldList.unshift(action.notification.objectId);
        return fromJS([...new Set(oldList)]);
      });


      state = state.setIn(['data', action.notification.objectId], action.notification);
      return state;
    }


    case REMOVE_RELATE_NOTICE_LIST:
      return initState;

      
    default:
      return state;
  }
}
