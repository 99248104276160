import React from 'react';
import PropTypes from 'prop-types';

const DayOfWeek = ({day}) => {
  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
  return (
    <span>{dayOfWeek[day]}</span>
  );
};

DayOfWeek.propTypes = {
  day: PropTypes.any
};

export default DayOfWeek;
