export const GET_SEARCHED_STUDENTS_REQUEST = 'GET_SEARCHED_STUDENTS_REQUEST';
export const GET_SEARCHED_STUDENTS_SUCCESS = 'GET_SEARCHED_STUDENTS_SUCCESS';
export const GET_SEARCHED_STUDENTS_FAILED = 'GET_SEARCHED_STUDENTS_FAILED';
export const getSearchedStudentsRequest = (payload) => ({
  type: GET_SEARCHED_STUDENTS_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const getSearchedStudentsSuccess = (payload, meta) => ({
  type: GET_SEARCHED_STUDENTS_SUCCESS,
  payload,
  meta
});
export const getSearchedStudentsFailed = (payload, meta) => ({
  type: GET_SEARCHED_STUDENTS_FAILED,
  payload,
  meta,
  error: true
});

export const COUNT_AVAILABLE_STUDENTS_REQUEST = 'COUNT_AVAILABLE_STUDENTS_REQUEST';
export const COUNT_AVAILABLE_STUDENTS_SUCCESS = 'COUNT_AVAILABLE_STUDENTS_SUCCESS';
export const COUNT_AVAILABLE_STUDENTS_FAILED = 'COUNT_AVAILABLE_STUDENTS_FAILED';
export const countAvailableStudentsRequest = (payload) => ({
  type: COUNT_AVAILABLE_STUDENTS_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const countAvailableStudentsSuccess = (payload, meta) => ({
  type: COUNT_AVAILABLE_STUDENTS_SUCCESS,
  payload,
  meta
});
export const countAvailableStudentsFailed = (payload, meta) => ({
  type: COUNT_AVAILABLE_STUDENTS_SUCCESS,
  payload,
  meta,
  error: true
});

export const SET_SELECTED_STUDENTS = 'SET_SELECTED_STUDENTS';
export const setSelectedStudents = (payload) => ({
  type: SET_SELECTED_STUDENTS,
  payload
});