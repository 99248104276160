import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import OtpField from '../../../components/Form/OtpField';
import Button from '../../Button';
import { COUNT_DOWN_TIME } from '../../../utilities/constants';
import { useCountDown } from '../../../hooks';
import { getCurrentNationSelect } from 'utilities/handleValues';

const OptVerification = ({
  currentPhoneNumber,
  onSubmitOtp,
  onSubmitConfirmOtp,
  onSubmitPhoneNumber
}) => {
  const refSubmitOtp = useRef();
  const { value, reStart, pause, play } = useCountDown(COUNT_DOWN_TIME); // Seconds
  const [otp, setOtp] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const disabled = value && Number(value.replaceAll(':', '')) === 0;
  const [isShowResend, setShowResend] = useState(undefined);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);

  useEffect(() => {
    return setOtp('');
  }, []);

  useEffect(() => {
    if (value && Number(value.replaceAll(':', '')) === 0) {
      setShowResend(true);
      setOtp('');
    }
  }, [value]);

  useEffect(() => {
    if (otp.length === 6) {
      if (refSubmitOtp.current) {
        refSubmitOtp.current.click();
      }
    }
  }, [otp]);

  const handleOtp = value => {
    setOtp(value);
  };

  const handleReCountDown = () => {
    if (isShowResend) {
      reStart();
      setShowResend(false);
      // Resend API
      const currentNumberPhone = currentPhoneNumber.replace(`+${getCurrentNationSelect()}`, '');
      currentNumberPhone && onSubmitPhoneNumber(currentNumberPhone);
    }
  };

  const handleSubmitOtp = () => {
    pause();
    setIsSubmit(true);
    setIsSubmitFail(false);
    onSubmitOtp && onSubmitOtp(
      otp, 
      (value) => setIsShowModal(value),
      () => {
        play();
        setIsSubmit(false);
        setIsSubmitFail(true);
      }
    );
  };

  useEffect(() => {
    if (isSubmitFail) {
      if (document.getElementsByClassName('otp-input')[5]) {
        setTimeout(() => {
          document.getElementsByClassName('otp-input')[5].focus();
        }, 500);
      }
    }
  }, [isSubmitFail]);

  
  return (
    <>
      <h1 className='non-margin'>認証コードを入力</h1>
      <p className='subtext'>SMSで受信して6桁の認証コードを入力してください <br />
        <span className='semi-text'>{currentPhoneNumber.replace(`+${getCurrentNationSelect()}`, '')}</span>
      </p>
      <div className='input-container'>
        <Field
          component={OtpField}
          type="number"
          input={{
            name: 'otpNumber',
            value: otp,
            onChange: handleOtp
          }}
          numInputs={6}
          disabled={isSubmit}
          indexFocus={isSubmitFail ? 5 : 0}
        />
        <span 
          ref={refSubmitOtp}
          onClick={() => handleSubmitOtp()}
          style={{
            display: 'none'
          }}
        />
      </div>
      {
        disabled ? <></> : (
          <div className='countdown-content'>
            <p className="light-text">{value} 後にコードを再送できます</p>
          </div>
        )
      }
      {
        isShowResend !== undefined ? (
          <div className={['resend-container', !isShowResend && 're-sending'].filter(Boolean).join(' ')}>
            <img src={`images/${isShowResend ? 'icon-circle' : 'icon-gray-circle'}.svg`} />
            <span 
              className='highlight'
              onClick={() => handleReCountDown()}
            >コードを再送する</span>
          </div>
        ) : <></>
      }
      {
        isShowModal && (
          <Modal 
            show 
            onHide={() => {}} 
            className="custom-modal-container"
          >
            <div className='content-container'>
              <p className='title-modal'>SMS認証が完了しました。</p>
              <img 
                src="images/icon_phone_number_verified.svg"
                alt="icon_phone_number_verified" className='image-modal' 
              />
              <p className='text-modal'>ありがとうございます。</p>
            </div>

            <div className="submit-button">
              <Button onClick={() => onSubmitConfirmOtp && onSubmitConfirmOtp()}>
                閉じる
              </Button>
            </div>
          </Modal>
        )
      }
    </>
  );
};

OptVerification.propTypes = {
  currentPhoneNumber: PropTypes.string,
  onSubmitOtp: PropTypes.func,
  onSubmitConfirmOtp: PropTypes.func,
  onSubmitPhoneNumber: PropTypes.func
};
export default OptVerification;