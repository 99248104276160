import { fromJS } from 'immutable';
import { CLOSE_CANCEL_REQUEST_EVENT_POPUP, OPEN_CANCEL_REQUEST_EVENT_POPUP } from './actions';

const initState = fromJS({
  show: false
});

export default function cancelRequestEventPopUp(state = initState, action) {
  if (action.type === OPEN_CANCEL_REQUEST_EVENT_POPUP) {
    return state.set('show', true);
  }
  if (action.type === CLOSE_CANCEL_REQUEST_EVENT_POPUP) {
    return state.set('show', false);
  }
  return state;
}