import { fromJS } from 'immutable';
import { SET_POPUP_LOGIN, SET_ROLE_AND_OPEN, SET_PRESIDENT_ROLE, SET_STUDENT_ROLE } from './actions';

const initState = fromJS({
  show: false,
  role: 'STUDENT'
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function login(state = initState, action) {
  switch (action.type) {
    case SET_POPUP_LOGIN:
      return state.set('show', action.open);
    case SET_ROLE_AND_OPEN: {
      const openPopup = state.set('show', true);
      return openPopup.set('role', action.role);
    }
    case SET_PRESIDENT_ROLE:
      return state.set('role', 'PRESIDENT');
    case SET_STUDENT_ROLE: 
      return state.set('role', 'STUDENT');
    default:
      return state;
  }
}
