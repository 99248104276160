import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';
class PresidentDetailCarousel extends Component {
  imagesCheck() {
    if (this.props.images) {
      if (this.props.images.length === 1) return false;
      return true;
    }
    return false;
  }
  render() {
    let images = this.props.images || [{thumbSmall: '/images/default-avatar.png'}];
    return (
      <React.Fragment>
        <Carousel
          controls={this.imagesCheck()}
          indicators={this.imagesCheck()}
          nextIcon={<span onClick={(e) => e.preventDefault()}><img src="/images/active-copy.svg" alt="right"/></span>}
          prevIcon={<span onClick={(e) => e.preventDefault()}><img src="/images/in-active-copy.svg" alt="left"/></span>}
        >
          {images && images.length && images.map((pic, index) => (
            <Carousel.Item key={index}>
              <img src={pic.thumbSmall} alt="社長メシ" className="carousel-image" />
            </Carousel.Item>)
          )}
        </Carousel>
      </React.Fragment>
    );
  }
}

PresidentDetailCarousel.propTypes = {
  images: PropTypes.array
};

export default PresidentDetailCarousel;
