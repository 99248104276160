import { request } from 'utilities/Api';
import { put, call, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import { push } from 'connected-react-router';

import { requestEventDetail } from 'containers/EventDetail/actions';
import {
  INVITE_TO_EVENT_REQUEST,
  inviteToEventSuccess,
  inviteToEventFailure,
  GET_ACTIVE_EVENTS_REQUEST,
  getActiveEventsSuccess,
  getActiveEventsFailure,
  STUDENT_RESPONSE_INVITATION_REQUEST,
  studentResponseInvitaionSuccess,
  studentResponseInvitaionFailure
} from './actions';
import { getPlans } from '../Plans/actions';

function* getActiveEvents(action) {
  try {
    yield put(showLoading());
    const { result } = yield call(
      request,
      'functions/getEventList',
      action.payload,
      'POST'
    );
    yield put(
      getActiveEventsSuccess(_get(result, 'eventList', []), action.meta)
    );
    yield put(hideLoading());
  } catch (e) {
    yield put(getActiveEventsFailure(e, action.meta));
    yield put(hideLoading());
  }
}

function* inviteToEvent(action) {
  try {
    yield put(showLoading());
    const data = yield call(
      request,
      'functions/presidentInviteStudent',
      action.payload,
      'POST'
    );
    yield put(inviteToEventSuccess(data, action.meta));
    toast.success('学生を正常に招待しました。');
    yield put(getPlans());
    yield put(hideLoading());
  } catch (e) {
    yield put(inviteToEventFailure(e, action.meta));
    yield put(hideLoading());
  }
}

function* studentResponseInvitaion({ payload, meta }) {
  try {
    yield put(showLoading());
    const data = yield call(
      request,
      _get(payload, 'accepted', false)
        ? 'functions/studentAcceptInviteRequest'
        : 'functions/studentDenyInviteRequest',
      { eventId: _get(payload, 'eventId', '') },
      'POST'
    );
    yield put(studentResponseInvitaionSuccess(data, meta));
    yield put(hideLoading());
    if (_get(payload, 'accepted', false)) {
      yield put(push(`/messages/${_get(payload, 'eventId', '')}`, {
        eventId: _get(payload, 'eventId', '')
      }));
    } else {
      yield put(requestEventDetail(_get(payload, 'eventId', '')));
    }
  } catch (e) {
    yield put(studentResponseInvitaionFailure(e, meta));
    yield put(hideLoading());
  }
}

export default function* watchInviteToEvent() {
  yield takeLatest(INVITE_TO_EVENT_REQUEST, inviteToEvent);
  yield takeLatest(GET_ACTIVE_EVENTS_REQUEST, getActiveEvents);
  yield takeLatest(
    STUDENT_RESPONSE_INVITATION_REQUEST,
    studentResponseInvitaion
  );
}
