import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import './style.scss';
import Popover from 'components/Popover';
import PrivateLink from 'containers/PrivateLink';
import { fetchUnreadNotification, fetchNoticeList } from 'containers/Notification/actions';
import NotificationListForIcon from 'containers/Notification/NotificationListForIcon';
import NotificationEmpty from 'containers/Notification/NotificationEmpty';

class Notification extends Component {
  state = {
    show: false
  }
  componentDidMount() {
    if (this.props.currentUser) {
      this.props.fetchUnreadNotificationNumber();
    }
  }

  togglePopOver = () => {
    this.setState({ show: !this.state.show }, () => {
      
      if (this.props.unreadNotificationNumber > 0 && this.state.show) {
        this.props.fetchNoticeList(1, true, false, false);
      }
    });
  }

  closePopOver = () => {
    this.state.show && this.setState({ show: false });
    if (this.props.unreadNotificationNumber > 0) {
      this.props.fetchNoticeList(1, true, false, false);
    }
  }

  renderPopover = () => {
    if (this.state.show) {
      const { notificationList, page } = this.props;
      return (
        <Popover header="通知" footer="全て見る" link="notifications" clickOutSide={this.closePopOver}>
          <NotificationListForIcon closePopOver={this.closePopOver} />
          {page > 1 && notificationList.length === 0 && <NotificationEmpty />}
          <PrivateLink handleAction={this.closePopOver} to="/notifications">
            <div className="footer text-center">全て見る</div>
          </PrivateLink>
        </Popover>
      );
    }
  }
  render() {
    if (!this.props.currentUser) {
      return null;
    }
    return (
      <PrivateLink id="notification-icon" handleAction={this.togglePopOver} displayBlock={true}>
        <img alt="icon" src="/images/notification.svg" />
        {this.renderPopover()}
        {this.props.unreadNotificationNumber > 0 && (
          <div className="number">
            {this.props.unreadNotificationNumber}
          </div>
        )}
      </PrivateLink>
    );
  }
}

Notification.propTypes = {
  unreadNotificationNumber: PropTypes.number,
  notificationList: PropTypes.array,
  currentUser: PropTypes.object,
  page: PropTypes.number,
  fetchUnreadNotificationNumber: PropTypes.func,
  fetchNoticeList: PropTypes.func
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data'),
    unreadNotificationNumber: state.get('getNoticeList').get('number'),
    notificationList: state.get('getNoticeList').get('noticeList') || [],
    page: state.get('getNoticeList').get('page')
  };
};

const mapDispatchToProps = dispatch => ({
  fetchNoticeList: (page, hasMore, loading, update) => dispatch(fetchNoticeList(page, hasMore, loading, update)),
  fetchUnreadNotificationNumber: () => dispatch(fetchUnreadNotification())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(Notification));