import MessageAdapter from './modules/Message';
import NotificationAdapter from './modules/Notification';
import Parse from 'parse';

class LiveAdapter {
  static Message = null;
  static Notification = null;
  static isOn = false; // ON | OFF

  static initSDK = () => new Promise(async (resolve, reject) => {
    try {
      if (LiveAdapter.isOn) {
        throw new Error('already init Parse');
      }

      Parse.initialize(process.env.REACT_APP_API_APPLICATION_ID, process.env.REACT_APP_JS_KEY);
      Parse.serverURL = process.env.REACT_APP_API_URI || 'https://localhost:1337/';
      Parse.liveQueryServerURL = process.env.REACT_APP_LIVE_QUERY_SERVER_URL;
      LiveAdapter.isOn = true;

      await LiveAdapter.fuseUserFromStore();

      Parse.LiveQuery.on('open', () => {
        console.log('CONSOLE  >>> LIVEQUERY CONNECTION OPENED');
      });

      Parse.LiveQuery.on('close', () => {
        console.error('CONSOLE  >>> LIVEQUERY CONNECTION CLOSED');
      });

      Parse.LiveQuery.on('error', (error) => {
        console.log('CONSOLE  >>> LIVEQUERY CONNECTION ERROR', error);
      });

      LiveAdapter.Message = new MessageAdapter();
      LiveAdapter.Notification = new NotificationAdapter();

      resolve(true);
    } catch (err) {
      console.log('[LiveAdapter]error: ', err)
      reject(err);
    }
  });

  static fuseUserFromStore = async () => {
    const { token } = window.store.getState().get('account').toJS();
    if (token) {
      await Parse.User.become(token);
    }

    return this;
  };

  static subscribe = async () => {
    await LiveAdapter.Message.subscribe();
    await LiveAdapter.Notification.subscribe();
  }

  static terminate = () => {
    if (!LiveAdapter.isOn) {
      return false;
    }

    //LiveAdapter.Message.unsubscribe();
    //LiveAdapter.Notification.unsubscribe();
    Parse.LiveQuery.close();
    Parse.User.logOut();
    LiveAdapter.isOn = false;
    return (true);
  };
};

export default LiveAdapter;