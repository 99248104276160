import React, { Component } from 'react';
import ReactDOM from 'react-dom'
// import NotificationList from 'components/NoticeList/NoticeList'
import NotificationList from 'containers/Notification/NotificationPage/NotificationList'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { fetchNoticeList } from 'containers/Notification/actions'
import _ from 'lodash'
import { setMenu } from 'containers/Header/actions'
import NotificationEmpty from '../NotificationEmpty'
import CloseMenuButton from 'components/MenuRight/CloseMenuButton';
import './style.scss'

class NotificationListForMenu extends Component {

  loadMore = () => {
    const notiMenu = document.getElementById('notiDisplay')
    if (notiMenu.clientHeight + notiMenu.scrollTop >= notiMenu.scrollHeight) {
      this.props.fetchNoticeList(this.props.page, this.props.hasMore);
    }
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this.refs.notiMenu).addEventListener('scroll', this.loadMore);
  }
  
  componentWillReceiveProps(nextProps) {
    if (this.props.notificationMenu === nextProps.notificationMenu) {
      return;
    }
    
    if (this.props.notificationMenu === 0 && nextProps.notificationMenu === 1) {
      if (_.isEmpty(this.props.notificationList)) {
        setTimeout(this.props.fetchNoticeList, 500);
      }
      else if(this.props.unreadNotificationNumber > 0) {
        this.props.fetchNoticeList(1, true, false, false)
      }
    }

    if (this.props.notificationMenu === 1 && nextProps.notificationMenu === 0 && this.props.unreadNotificationNumber > 0) {
      this.props.fetchNoticeList(1, true, false, false)
    }
  }

  closeNotiMenu = () => {
    this.props.controlNotificationMenu();
  }

  render() {
    const { notificationList = []} = this.props;
    const { data = {} } = this.props;
    return (
      <div id="notiMenu" className="notification-list-menu">
        <div className="noti-menu-header">
          <div className="back-main-menu" onClick={() => this.props.controlNotificationMenu()}>
            <img alt="back" src="/images/back.svg" />
          </div>
          <div className="noti-menu-text">お知らせ</div>
          <CloseMenuButton />
        </div>
        <div id="notiDisplay" className="noti-list-display" ref="notiMenu">
          <div className="notice-list-content-box">
          {!_.isEmpty(notificationList) ?
            <NotificationList data={data} notificationList={notificationList} />
            : <NotificationEmpty />
          }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    notificationList: state.get('getNoticeList').get('noticeList'),
    data: state.get('getNoticeList').get('data'),
    page: state.get('getNoticeList').get('page'),
    hasMore: state.get('getNoticeList').get('hasMore'),
    notificationMenu: state.get('menu').get('notificationMenu'),
    unreadNotificationNumber: state.get('getNoticeList').get('number')
  };
};

const mapDispatchToProps = (dispatch) => ({
  controlNotificationMenu: () => dispatch(setMenu('notificationMenu', 0)),
  fetchNoticeList: (page, hasMore, loading, update) => dispatch(fetchNoticeList(page, hasMore, loading, update)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(NotificationListForMenu));