import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Collapse from 'components/Collapse';
import EventListSmallCell from 'components/EventListSmall/EventListSmallCell';
import Image from 'components/Image';
import './styles.scss';

class ViewByStudent extends React.Component {
  componentDidMount() {
    const { studentEvents } = this.props;

    if (this.props.firstSection && _.isEmpty(studentEvents.events)) {
      this.handleGetEventsOfStudent();
    }
  }

  handleGetEventsOfStudent = () => {
    const { studentInfo, studentEvents, getEventsOfStudent, toggleCollapsed, activeFilter } = this.props;
    if (_.isEmpty(studentEvents.events)) {
      getEventsOfStudent({ studentId: studentInfo._id, status: activeFilter });
    }

    toggleCollapsed(studentInfo._id);
  }

  handleLoadMore = () => {
    const { studentInfo, getEventsOfStudent, activeFilter } = this.props;
    getEventsOfStudent({ studentId: studentInfo._id, status: activeFilter });
  }

  render() {
    const { studentInfo, studentEvents } = this.props;

    return (
      <Collapse
        isOpen={studentEvents.isOpen}
        className='events-of-student-container'
        handleToggle={this.handleGetEventsOfStudent}
        handleLoadMore={studentEvents.hasMore ? this.handleLoadMore : undefined}
        title={
          <div className='events-of-student-title'>
            <Image 
              className='events-of-student-avatar' 
              src={_.get(studentInfo, 'avatarImage.thumbSmall', '')} 
              alt='avatar'
              defaultSrc='/images/default-avatar.png'
            />
            <div className='events-of-student-info'>
              <div className='events-of-student-name'>{_.get(studentInfo, 'displayName', '')}</div>
              <div className='events-of-student-graduation'>
                {_.get(studentInfo, 'studentProfile.graduationYear', '') + '年卒'}
              </div>
            </div>
          </div>
        }
      >
        {_.get(studentEvents, 'events', []).map((event, index) => {
          _.set(event, 'image', event.jImage);
          _.set(event, 'objectId', event._id);
          return (
            <EventListSmallCell key={index} eventInfo={event} type='FINISHED' />
          );
        })}
      </Collapse>
    );
  }
}

ViewByStudent.propTypes = {
  studentInfo: PropTypes.object,
  studentEvents: PropTypes.object,
  firstSection: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
  getEventsOfStudent: PropTypes.func,
  activeFilter: PropTypes.string
};

export default ViewByStudent;