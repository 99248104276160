// action types
export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

// action creators
export const updateEventRequest = (eventId, eventData) => ({
  type: UPDATE_EVENT_REQUEST,
  eventId,
  eventData
});

export function updateEventSuccess(payload, meta) {
  return {
    type: UPDATE_EVENT_SUCCESS,
    payload,
    meta
  };
}

export const updateEventFailure = (error) => ({
  type: UPDATE_EVENT_FAILURE,
  error
});