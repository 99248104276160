import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import './styles.scss';

const PlatinumLabel = ({ className = '', style = {}, currentUser }) => {
  if (_get(currentUser, 'role') === 'PRESIDENT') return <></>;
  return (
    <div className={`premium-badge ${className}`} style={style}>
      <img src='/images/crown.svg' alt='' />
      プラチナ
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.get('account').get('data')
});

PlatinumLabel.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  currentUser: PropTypes.object
};

export default connect(
  mapStateToProps,
  null,
)(withImmutablePropsToJS(PlatinumLabel));
