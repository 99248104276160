import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tags from 'components/Tags';
import ElementRenderer from './ElementRenderer';
import './style.scss';

class ToggleGroupTag extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false
    }
  }

  toggle = () => {
    this.setState({
      show: !this.state.show
    })
  }

  addItem = (value) => {
    let selectedTags = this.props.input.value ? this.props.input.value : [];
    selectedTags = selectedTags.toJS ? selectedTags.toJS() : selectedTags;

    if (!selectedTags.includes(value)) {
      let newVal = selectedTags.slice(0);
      newVal.push(value);
      this.props.input.onChange(newVal);
    }
  }

  removeItem = (value) => {
    let selectedTags = this.props.input.value ? this.props.input.value : [];
    selectedTags = selectedTags.toJS ? selectedTags.toJS() : selectedTags;

    let index = selectedTags.indexOf(value);
    if (index >= 0) {
      let newVal = selectedTags.slice(0);
      newVal.splice(index, 1);
      this.props.input.onChange(newVal);
    }
  }
  render() {
    let selectedTags = this.props.input.value ? this.props.input.value : [];
    selectedTags = selectedTags.toJS ? selectedTags.toJS() : selectedTags;

    let tagGroup = this.props.tagGroup || [];

    let renderer = [];
    tagGroup.forEach((group) => {
      for (let groupname in group) {
        renderer.push(
          <div key={groupname}>
            <div className="group-title">
              {groupname}
            </div>
            <div className="event-detail-tag">
              <Tags
                tags={group[groupname]}
                selectedTags={selectedTags}
                addItem={this.addItem}
                removeItem={this.removeItem}
              />
            </div>
          </div>
        );
      }
    });

    const toggleState = this.state.show ? 'active' : 'inactive';

    return (
      <Fragment>
        { !this.props.customForm ? (
          <ElementRenderer
          {...this.props}
          render={({ input }) => (
            <Fragment>
              <div>
                <span className="toogle-text" onClick={this.toggle}>{this.state.show ? 'たたむ 🔼' : '全て見る 🔽'}</span>
              </div>
              <div className={"toggle-container " + toggleState}>
                {renderer}
              </div>
            </Fragment>

          )} />
        ) : (
          <Fragment>
            <div className={`custom-tagsfield-container ${(this.props.meta.error) ? 'error-form' : null}`}>
              {this.props.label ? (<span className="subtitle">{this.props.label}</span>) : null}
              <div>
                <span className="toogle-text" onClick={this.toggle}>{this.state.show ? 'たたむ 🔼' : '全て見る 🔽'}</span>
              </div>
              <div className={"toggle-container " + toggleState}>
                {renderer}
              </div>
            </div>
            </Fragment>
        ) }
      </Fragment>
    );
  }
}

ToggleGroupTag.propTypes = {
  input: PropTypes.any.isRequired,
  onChanged: PropTypes.func,
  selected: PropTypes.object,
  tagGroup: PropTypes.array
};

export default ToggleGroupTag;