import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class Rating extends Component {
  createStar = () => {
    let starArr = [];

    for (let i = 0; i < 5; i++) {
      starArr.push(
        <img key={i} alt="" src={i < this.props.rating ? '/images/yellow-star.svg' : '/images/gray-star.svg'} />
      );
    }

    return starArr;
  }

  render() {
    return (
      <div className="rating-container">
        <div className="star">{this.createStar()}</div>
        <div className="number">{this.props.rating}</div>
      </div>
    );
  }
}

Rating.propTypes = {
  rating: PropTypes.string
};

export default (Rating);