// action types
export const GET_CURRENT_CAMPAIGN_REQUEST = 'GET_CURRENT_CAMPAIGN_REQUEST';
export const GET_CURRENT_CAMPAIGN_SUCCESS = 'GET_CURRENT_CAMPAIGN_SUCCESS';
export const GET_CURRENT_CAMPAIGN_FAILURE = 'GET_CURRENT_CAMPAIGN_FAILURE';
export const GET_POINT_HISTORY_REQUEST = 'GET_POINT_HISTORY_REQUEST';
export const GET_POINT_HISTORY_SUCCESS = 'GET_POINT_HISTORY_SUCCESS';
export const GET_POINT_HISTORY_FAILURE = 'GET_POINT_HISTORY_FAILURE';
export const CHANGE_POINT_REQUEST = 'CHANGE_POINT_REQUEST';
export const CHANGE_POINT_SUCCESS = 'CHANGE_POINT_SUCCESS';
export const CHANGE_POINT_FAILURE = 'CHANGE_POINT_FAILURE';

// action creators
export const getCurrentCampaignRequest = () => ({
  type: GET_CURRENT_CAMPAIGN_REQUEST,
  meta: { thunk: true }
});

export function getCurrentCampaignSuccess(payload, meta) {
  return {
    type: GET_CURRENT_CAMPAIGN_SUCCESS,
    payload,
    meta
  };
}

export const getCurrentCampaignFailure = (error) => ({
  type: GET_CURRENT_CAMPAIGN_FAILURE,
  error
});

export const getPointHistoryRequest = (params) => ({
  type: GET_POINT_HISTORY_REQUEST,
  meta: { thunk: true },
  params
});

export function getPointHistorySuccess(payload, meta) {
  return {
    type: GET_POINT_HISTORY_SUCCESS,
    payload,
    meta
  };
}

export const getPointHistoryFailure = (error) => ({
  type: GET_POINT_HISTORY_FAILURE,
  error
});

export const changePointRequest = () => ({
  type: CHANGE_POINT_REQUEST,
  meta: { thunk: true }
});

export function changePointSuccess(payload, meta) {
  return {
    type: CHANGE_POINT_SUCCESS,
    payload,
    meta
  };
}

export const changePointFailure = (error, meta) => ({
  type: CHANGE_POINT_FAILURE,
  payload: error,
  error: true,
  meta
});
