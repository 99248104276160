import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { STUDENT_SIGNUP_REQUEST, IS_CHECK_SIGNUP_REQUEST, STUDENT_SIGNUP_BY_OTP_REQUEST } from './actions';
import { getCurrentAccount } from './../Account/actions';
import { stopSubmit } from 'redux-form';
import { push } from 'connected-react-router';
import get from 'lodash/get';

function* handleStudentSignUp(action) {
  try {
    const data = yield call(request, 'users', action.values, 'POST');
    yield put(getCurrentAccount(data.sessionToken, 'STUDENT_SIGNUP'));
  } catch (e) {
    if (e.code === 202) {
      e.error = 'このメールアドレスはすでに登録されています';
    }
    yield put(stopSubmit('StudentSignupForm', { _error: e.error }));
  }
}

function* handleStudentSignUpByOTP(action) {
  try {
    yield put(getCurrentAccount(action.values, 'STUDENT_SIGNUP'));
  } catch (e) {
    if (e.code === 202) {
      e.error = 'このメールアドレスはすでに登録されています';
    }
    yield put(stopSubmit('StudentSignupForm', { _error: e.error }));
  }
}

function* handleIsStudentSignUpRequest(action) {
  try {
    const data = yield call(request, 'users', action.values, 'POST');
    window.sessionStorage.setItem('account_register', 
      JSON.stringify([
        btoa(action.values.email),
        btoa(action.values.password),
        btoa(action.values.username),
        btoa(data.sessionToken),
        get(data, 'phone') && btoa(data.phone)
      ].filter(Boolean)));
    yield put(push('/signup-sms-verification'));
  } catch (e) {
    if (e.code === 202) {
      e.error = 'このメールアドレスはすでに登録されています';
    }
    yield put(stopSubmit('StudentSignupForm', { _error: e.error }));
  }
}

export default function* watchStudentSignUp() {
  yield takeEvery(STUDENT_SIGNUP_REQUEST, handleStudentSignUp);
  yield takeEvery(IS_CHECK_SIGNUP_REQUEST, handleIsStudentSignUpRequest);
  yield takeEvery(STUDENT_SIGNUP_BY_OTP_REQUEST, handleStudentSignUpByOTP);
}

 