import React, {Component} from 'react';
import {connect} from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { setCommonPopup } from 'containers/Popup/actions';
import { getValueByKey } from 'containers/Popup/selectors';

import OfflineImage from './OfflineImage';
import './style.scss';

class Offline extends Component {
  componentWillUnmount() {
    this.props.closePopup();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.show === this.props.show) {
      return false;
    }
    return true;
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div id="offline-page">
        <div>
          <OfflineImage />
          <div id="offline-message">
            ネットワークの状況がよくないようです。
            <br />
            しばらく経ってからやり直してください。
          </div>
          <Button onClick={() => window.location.reload()}>再読み込み 🗘</Button>
        </div>
      </div>
    );
  }
}

Offline.propTypes = {
  show: PropTypes.bool,
  closePopup: PropTypes.func
};

Offline.defaultProps = {
  closePopup: () => null,
  show: false
};

const mapStateToProps = state => ({
  show: getValueByKey(state, 'offline')
});

const mapDispatchToProps = dispatch => ({
  closePopup: () => dispatch(setCommonPopup('offline', false))
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withImmutablePropsToJS(Offline));