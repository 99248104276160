import { request } from '../../../utilities/Api';
import { put, select, call, takeLatest } from 'redux-saga/effects';
import {
  receiveGetEventsByStatus,
  REQUEST_GET_EVENTS_BY_STATUS,
  GET_PRESIDENT_EVENTS_REQUEST,
  getPresidentEventsSuccess
} from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { takeFirst } from 'utilities/supportSaga';

function* getEventsByStatus(action) {
  try {
    yield put(showLoading());
    const status = yield select((state) =>
      state.get('eventsManagementByStatus').get('status')
    );
    if (action.status === status) {
      const page = yield select((state) =>
        state.get('eventsManagementByStatus').get('page')
      );
      const hasMore = yield select((state) =>
        state.get('eventsManagementByStatus').get('hasMore')
      );
      if (hasMore) {
        const data = yield call(
          request,
          'functions/getHistoryEventsByStatus',
          { page, limit: 10, status: action.status },
          'POST'
        );
        yield put(receiveGetEventsByStatus(data.result, action.status));
      }
    } else {
      const data = yield call(
        request,
        'functions/getHistoryEventsByStatus',
        { page: 1, limit: 10, status: action.status },
        'POST'
      );
      yield put(receiveGetEventsByStatus(data.result, action.status));
    }
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
  }
}

function* getPresidentEvents(action) {
  try {
    yield put(showLoading());
    const { page } = action.payload;
    const { result } = yield call(
      request,
      'functions/getHistoryEventsByStatus',
      { limit: 10, page: page, status: 'ACTIVE,LOCKED,FINISHED,CANCELED' },
      'POST'
    );
    yield put(getPresidentEventsSuccess({ ...result, page }, action.meta));
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
  }
}

export default function* watchEventsByStatus() {
  yield takeFirst(REQUEST_GET_EVENTS_BY_STATUS, getEventsByStatus);
  yield takeLatest(GET_PRESIDENT_EVENTS_REQUEST, getPresidentEvents);
}
