import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ja';

import Button from 'components/Button';
import './style.scss';
class EventCell extends Component {

  renderBaseInfo = (responsive) => {
    const {event = {}} = this.props;
    const lockDate = moment(event.eventDate).subtract(3, 'd').format('MM月DD日HH時');
    if (this.props.path === '/management-event/wanted') {
      return <div className={`event-deadline ${responsive}`}>
        <div className='deadline'>
          <img src='/images/deadline.svg' alt='deadline' />
        </div>
        <div className='date-text'>
          <div className='deadline-date '>
            {lockDate}
          </div>
          <div className='deadline-date-info'>
            までに参加者を確定させてください
          </div>
        </div>
      </div>;
    }
  }

  renderAdditionInfo = () => {
    const { event = {}, path, onInvite } = this.props;
    const totalJoinedDenied = Number(event.joinedQuantity || 0) + Number(event.deniedUsersQuantity || 0);
    const totalJoinedRequest = Number(event.joinedQuantity || 0) + Number(event.requestedQuantity || 0);

    if ((path === '/management-event/locked' || path === '/management-event/finished') && totalJoinedDenied > 0) {
      return (
        <div className='event-management-addition-info'>
          {totalJoinedDenied > 0 && <div className='event-management-appliance'>
            <span className='event-management-appliance-number'>
              {totalJoinedDenied}
            </span>
            応募総数
          </div>}
          {event.joinedQuantity > 0 && <div className='event-management-confirm'>
            <span className='event-management-confirm-number'>{event.joinedQuantity}</span>
            参加確定
          </div>}
        </div>
      );
    } else if (path === '/management-event/wanted' && totalJoinedRequest > 0) {
      return (
        <div className='event-management-addition-info'>
          {event.requestedQuantity > 0 && <div className='event-management-selected'>
            <span className='event-management-selected-number'>
              {event.requestedQuantity}
            </span>
            選考中
          </div>}
          {event.joinedQuantity > 0 && <div className='event-management-confirm'>
            <span className='event-management-confirm-number'>{event.joinedQuantity}</span>
            参加確定
          </div>}
        </div>
      );
    } else if (onInvite) {
      return <Button onClick={onInvite} className="invite-to-event-btn">選択</Button>;
    }
  }

  render() {
    const { event = {} } = this.props;
    const { eventDate, duration, image } = event;
    const imageEvent = `url('${image.thumbSmall}')`;
    let eventTime = eventDate;
    const timeStart = moment(eventTime).format('HH:mm');
    const timeEnd = moment(eventTime).add(duration, 'minutes').format('HH:mm');

    return (
      <div className='event-management-cell'>
        <div className='event-management-cell-row'>
          <div className='event-management-info-wrapper'>
            <div
              className='event-management-img-sm-container'
              style={{ backgroundImage: imageEvent}}
            />
            <div className='event-management-cell-base-info'>
              <div className='ellip-overflow event-title'>
                {event.name}
              </div>
              <div>
                <div className='ellip-overflow time'>
                  <div className='calendar'>
                    <img src='/images/calendar2.svg' alt='calendar'/>
                  </div>
                  {' '}
                  <div className='text' >
                    {moment(eventDate).format('YYYY/MM/DD (ddd)')}
                  </div>
                </div>
                <div className='time'>
                  <div className='clock'>
                    <img src='/images/clock.svg' alt='clock'/>
                  </div>
                  {' '}
                  <div className='text event-time' >
                    {timeStart}~{timeEnd}
                  </div>
                </div>
              </div>
              {this.renderBaseInfo('desktop')}
            </div>
          </div>
          <div className=''>
            {this.renderBaseInfo('mobile')}
          </div>
          {this.renderAdditionInfo()}
        </div>
      </div>
    );
  }
}

EventCell.propTypes = {
  path: PropTypes.string,
  event: PropTypes.object,
  isDisabledNavigation: PropTypes.bool,
  onInvite: PropTypes.func
};

export default EventCell;