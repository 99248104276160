/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Modal } from 'react-bootstrap';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestStudentSeenAbsenceWarning } from './action';
import _ from 'lodash';
import moment from 'moment';
import './style.scss';

const AbsenceWarning = ({ isOpen, studentSeenAbsenceWarning, event }) => {
  if (_.isEmpty(event)) {
    return null;
  }
  const { name, eventDate } = event;
  return (
    <Modal backdropClassName="absence-modal-backdrop" id="absence-student" show={isOpen}>
      <div className="absence-student-container">
        <img
          onClick={() => {studentSeenAbsenceWarning(event.objectId);}}
          className="absence-student-close"
          src="images/Quit.svg"
          alt='quit'
        />
        <div className="absence-student-title">欠席確認</div>
        <img className="absence-student-image" src="images/absence.svg" alt='absence'/>
        <div className="absence-student-content">
          {moment(eventDate).format('MM')}月{moment(eventDate).format('DD')}日開催の
          <br/>
          「{name}」において主催者より
          <br/>
          欠席報告がありました。
          <br/>
          出席している場合は運営までご連絡ください。
        </div>
        <a href="https://line.me/R/ti/p/%40mhs7705k" target="_blank">
          <div className="absence-student-button">LINEサポート</div>
        </a>
      </div>
    </Modal>
  );
};

AbsenceWarning.propTypes = {
  isOpen: PropTypes.bool,
  event: PropTypes.object,
  studentSeenAbsenceWarning: PropTypes.func
};

const mapStateToProps = (state) => ({
  isOpen: state.get('studentAbsence').get('isOpen'),
  event: state.get('studentAbsence').get('event')
});

const mapDispatchToProps = (dispatch) => ({
  studentSeenAbsenceWarning: (eventId) => dispatch(requestStudentSeenAbsenceWarning(eventId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(AbsenceWarning));