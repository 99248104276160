import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './style.scss';
import { requestMessageList, requestUnreadMessageNumber } from './actions';
import Popover from 'components/Popover';
import MessageList from './MessageList';
import MessageEmpty from './MessageEmpty';
import PrivateLink from 'containers/PrivateLink';

class Message extends Component {
  state = {
    show: false
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.requestMessageList();
      this.props.requestUnreadMessageNumber();
    }
  }

  renderPopover = () => {
    if (this.state.show) {
      const { messageList, unreadMessageFromOperator, groupOperatorChatPreview } = this.props;
      let msgStatusOperator = false;
      if (unreadMessageFromOperator > 0) {
        msgStatusOperator = true;
      }
      
      if (messageList.length > 0) {
        return (
          <Popover header="メッセージ" clickOutSide={this.closePopOver}>
            {!_.isEmpty(groupOperatorChatPreview) ?
              (
              <>
                <MessageList list={messageList.slice(0, 3)} messageOperator={true} unread={msgStatusOperator}/>
                <PrivateLink
                  to={{
                    pathname: `/messages/${groupOperatorChatPreview.groupId}`,
                    state: {
                      chatWithOperatorId: groupOperatorChatPreview.groupId
                    }
                  }}
                >
                  <div className="footer text-center">全て見る</div>
                </PrivateLink>
              </>) :
              (
              <>
                <MessageList list={messageList.slice(0, 4)} messageOperator={false} unread={msgStatusOperator}/>
                <PrivateLink
                  to={{
                    pathname: `/messages/${messageList[0]}`,
                    state: {
                      eventId: messageList[0]
                    }
                  }}
                >
                  <div className="footer text-center">全て見る</div>
                </PrivateLink>
              </>
              )}
          </Popover>
        );
      } else if (messageList.length === 0 && !_.isEmpty(groupOperatorChatPreview)) {
        return (
          <Popover header="メッセージ" clickOutSide={this.closePopOver}>
            <MessageList list={[]} messageOperator={true} unread={msgStatusOperator}/>
            <PrivateLink
              to={{
                pathname: `/messages/${groupOperatorChatPreview.groupId}`,
                state: {
                  chatWithOperatorId: groupOperatorChatPreview.groupId
                }
              }}
            >
              <div className="footer text-center">全て見る</div>
            </PrivateLink>
          </Popover>
        );

      } else {
        return (
          <Popover header="メッセージ" clickOutSide={this.closePopOver}>
            <MessageEmpty />
            <hr />
            <PrivateLink to="/messages">
              <div className="footer text-center">全て見る</div>
            </PrivateLink>
          </Popover>
        );
      }
    }
  }

  togglePopOver = () => {
    this.setState({ show: !this.state.show });
  }

  closePopOver = () => {
    this.state.show && this.setState({ show: false });
  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }
    let messageFromOperator = 0;
    if (this.props.unreadMessageFromOperator > 0) {
      messageFromOperator = 1;
    }
    const totalUnreadMessages = this.props.unreadMessageNumber + messageFromOperator;

    return (
      <PrivateLink id="message-icon" handleAction={this.togglePopOver}>
        <img alt="" src="/images/message.svg" />
        {this.renderPopover()}
        {
          totalUnreadMessages > 0 && (
            <div className="number">{totalUnreadMessages}</div>
          )
        }
      </PrivateLink>
    );
  }
}

Message.propTypes = {
  messageList: PropTypes.array,
  currentUser: PropTypes.object,
  groupOperatorChatPreview: PropTypes.object,
  unreadMessageFromOperator: PropTypes.number,
  requestMessageList: PropTypes.func,
  requestUnreadMessageNumber: PropTypes.func,
  unreadMessageNumber: PropTypes.number,
  messageTileData: PropTypes.object
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data'),
    messageList: state.get('message').get('list') || [],
    unreadMessageNumber: state.get('message').get('badgeNumber'),
    unreadMessageFromOperator: state.get('firebaseListener').get('unreadMessages'),
    groupOperatorChatPreview: state.get('firebaseListener').get('groupChatPreview')
  };
};

const mapDispatchToProps = dispatch => ({
  requestMessageList: () => dispatch(requestMessageList()),
  requestUnreadMessageNumber: () => dispatch(requestUnreadMessageNumber())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(Message));