import { fromJS } from 'immutable';
import { 
  FETCH_CONVERSATION_SUCCESS,
  FETCH_EVENT_DETAIL_FOR_MESSAGE_SUCCESS,
  FETCH_NEW_SENDER_SUCCESS,
  CONVERSATION_LOADING_MORE
} from './actions';

const initState = fromJS({
  conversationsInfo: {},
  chatMessagesData: {},
  sendersData: {},
  eventId: '',
  loadingMoreConversation: false
});

export default function conversation(state = initState, action) {
  switch (action.type) {
    case FETCH_CONVERSATION_SUCCESS: {
      let { eventId, hasMore, page, newData } = action.payload;
      let newList = newData.map(chatMsg => {
        state = state.setIn(['chatMessagesData', chatMsg.objectId], chatMsg);
        if (!state.hasIn(['sendersData', chatMsg.sender.objectId])) {
          state = state.setIn(['sendersData', chatMsg.sender.objectId], chatMsg.sender);
        }
        return chatMsg.objectId;
      });

      state = state.updateIn(
        ['conversationsInfo', eventId, 'list'],
        oldList => [...new Set((oldList || []).concat(newList))]
      );

      state = state.setIn(
        ['conversationsInfo', eventId, 'hasMore'],
        hasMore
      );
      state = state.setIn(
        ['conversationsInfo', eventId, 'page'],
        page
      );
      state = state.set('loadingMoreConversation', false);

      return state;
    }
    case CONVERSATION_LOADING_MORE: {
      return state
        .set('loadingMoreConversation', action.data);
    }
    case FETCH_EVENT_DETAIL_FOR_MESSAGE_SUCCESS: {
      const { eventId } = action;
      return state.setIn(['conversationsInfo', eventId, 'greeting'], action.greeting)
        .setIn(['conversationsInfo', eventId, 'attendance'], action.attendance)
        .set('eventId', action.eventId)
        .setIn(['conversationsInfo', eventId, 'eventDate'], action.eventDate)
        .setIn(['conversationsInfo', eventId, 'offerEvent'], action.offerEvent);
    }
    case 'NEW_MESSAGE_COME': {
      const { message } = action;
      state = state.setIn(['chatMessagesData', message.objectId], message);
      state = state.updateIn(
        ['conversationsInfo', message.eventId, 'list'],
        oldList => {
          oldList = oldList || [];
          oldList.unshift(message.objectId);
          return [...new Set(oldList)];
        }
      );
      return state;
    }
    case FETCH_NEW_SENDER_SUCCESS: {
      const { sender } = action;
      if (!state.hasIn(['sendersData', sender.objectId])) {
        state = state.setIn(['sendersData', sender.objectId, 'avatarImage'], sender.avatarImage);
        state = state.setIn(['sendersData', sender.objectId, 'displayName'], sender.displayName || sender.fullname);
        state = state.setIn(['sendersData', sender.objectId, 'role'], sender.role);
      }
      return state;
    }
    default:
      return state;
  }
}