export const CANCEL_REQUEST_JOIN_EVENT_REQUEST = 'CANCEL_REQUEST_JOIN_EVENT_REQUEST';
export const CANCEL_REQUEST_JOIN_EVENT_SUCCESS = 'CANCEL_REQUEST_JOIN_EVENT_SUCCESS';
export const CLOSE_CANCEL_REQUEST_EVENT_POPUP = 'CLOSE_CANCEL_REQUEST_EVENT_POPUP';
export const OPEN_CANCEL_REQUEST_EVENT_POPUP = 'OPEN_CANCEL_REQUEST_EVENT_POPUP';

export function cancelRequestJoinEvent(eventId) {
  return {type: CANCEL_REQUEST_JOIN_EVENT_REQUEST, payload: {eventId}};
}
  
export function cancelRequestJoinEventSuccess(result) {
  return {type: CANCEL_REQUEST_JOIN_EVENT_SUCCESS, result: result};
}

export function closeCancelRequestEventPopUp() {
  return {type: CLOSE_CANCEL_REQUEST_EVENT_POPUP, payload: {}};
}

export function openCancelRequestEventPopUp() {
  return {type: OPEN_CANCEL_REQUEST_EVENT_POPUP, payload: {}};
}