import { request } from './../../utilities/Api';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
  receiveEventsHistory,
  FETCH_EVENT_HISTORY_REQUEST,
  receiveEventHistoryByStatus,
  FETCH_EVENT_HISTORY_BY_STATUS_REQUEST,
  GET_OFFER_EVENTS_BY_STATUS_REQUEST,
  getOfferEventsByStatusSuccess,
  getOfferEventsByStatusFailure
} from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* getEventsHistory() {
  try {
    yield put(showLoading());
    const data = yield call(request, 'functions/getHistoryEvents', {}, 'POST');
    yield put(receiveEventsHistory(data.result));
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}

export default function* watchEventsHistory() {
  yield takeEvery(FETCH_EVENT_HISTORY_REQUEST, getEventsHistory);
}

function* getEventsHistoryByStatus(action) {
  try {
    yield put(showLoading());
    const limit = process.env.REACT_APP_EVENT_HISTORY_PERPAGE || 5;
    const data = yield call(
      request,
      'functions/getHistoryEventsByStatus',
      { status: action.status, limit, page: action.page },
      'POST'
    );
    yield put(
      receiveEventHistoryByStatus(data.result.eventList, action.status)
    );
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}

function* getOfferEventsByStatus({ payload, meta }) {
  try {
    yield put(showLoading());
    const { result } = yield call(
      request,
      'functions/getOfferEventsByStudent',
      { status: payload.status },
      'POST'
    );
    yield put(getOfferEventsByStatusSuccess({ list: result, status: payload.status }, meta));
    yield put(hideLoading());
  } catch (e) {
    yield put(hideLoading());
    yield put(getOfferEventsByStatusFailure(e, meta));
  }
}

export function* watchEventsHistoryBySTatus() {
  yield takeEvery(
    FETCH_EVENT_HISTORY_BY_STATUS_REQUEST,
    getEventsHistoryByStatus
  );
  yield takeEvery(
    GET_OFFER_EVENTS_BY_STATUS_REQUEST,
    getOfferEventsByStatus
  );
}
