export const GET_EVENT_NEED_REVIEW = 'GET_EVENT_NEED_REVIEW';
export const GET_USER_LIST_TO_REVIEW_SUCCESS = 'GET_USER_LIST_TO_REVIEW_SUCCESS';
export const REVIEW_STUDENTS_SUCCESS = 'REVIEW_STUDENTS_SUCCESS';
export const REVIEW_EVENT = 'REVIEW_EVENT';
export const RESET_REVIEW_INFORMATION = 'RESET_REVIEW_INFORMATION';
export const RESET_REVIEW = 'RESET_REVIEW';
export const GET_REVIEW_STATUS_OF_EVENT = 'GET_REVIEW_STATUS_OF_EVENT';
export const STUDENT_DONE_REVIEW = 'STUDENT_DONE_REVIEW';
export const COMPLETE_REVIEW = 'COMPLETE_REVIEW';

export const OPEN_REVIEW_SELECTED_STUDENT = 'OPEN_REVIEW_SELECTED_STUDENT';
export const CLOSE_REVIEW_SELECTED_STUDENT = 'CLOSE_REVIEW_SELECTED_STUDENT';

export function getEventNeedReview() {
  return {type: GET_EVENT_NEED_REVIEW};
}

export function getUserListToReview(eventId, result, hasReviewingEvent, isSingle = false, locationBeforeReview = '/') {
  return {type: GET_USER_LIST_TO_REVIEW_SUCCESS, eventId, result, hasReviewingEvent, isSingle, locationBeforeReview};
}


export function reviewStudentsSuccess(values) {
  return {type: REVIEW_STUDENTS_SUCCESS, values};
}

export function reviewEvent(values) {
  return {type: REVIEW_EVENT, values};
}

export function resetReviewInfomation() {
  return {type: RESET_REVIEW_INFORMATION};
}

export function resetReview() {
  return {type: RESET_REVIEW};
}

export function getReviewStatusOfEvent(eventId, locationBeforeReview) {
  return {type: GET_REVIEW_STATUS_OF_EVENT, eventId, locationBeforeReview};
}

export function studentDoneReview() {
  return {type: STUDENT_DONE_REVIEW};
}

export function completeReview() {
  return {
    type: COMPLETE_REVIEW
  };
}

export function openReviewSelectedStudent(payload) {
  return {
    type: OPEN_REVIEW_SELECTED_STUDENT,
    payload
  };
}

export function closeReviewSelectedStudent() {
  return {type: CLOSE_REVIEW_SELECTED_STUDENT};
}