import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { getValueByKey } from 'containers/Popup/selectors';
import PropTypes from 'prop-types';
import { setCommonPopup } from 'containers/Popup/actions';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import './style.scss';
import { Link } from 'react-router-dom';

class PopupLogin extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.closePopupLogin} id="popup-login">
        <div className="text-right">
          <img src="/images/Quit.svg" className="close-icon" onClick={this.props.closePopupLogin} alt="close"/>
        </div>
        <div className="model-title">ごちリクエストを送るにはログインが必要です</div>
        <div className="main-icon">
          <img src="/images/login-required.svg" alt="login"/>
        </div>
        <Link className="btn-submit" to="/loginStudent" onClick={this.props.closePopupLogin} >ログインしてごちリクエストを送る</Link>
        <div className="bottom-text">
          アカウントをお持ちでない方 <Link to="/signUpStudent" onClick={this.props.closePopupLogin} >新規作成</Link>
        </div>
      </Modal>
    );
  }
}

PopupLogin.propTypes = {
  show: PropTypes.bool,
  closePopupLogin: PropTypes.func,
  confirmAttendance: PropTypes.func,
  eventId: PropTypes.string
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'PopupLogin')
  };
};

const mapDispatchToProps = dispatch => ({
  closePopupLogin: () => dispatch(setCommonPopup('PopupLogin', false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PopupLogin));