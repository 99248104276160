import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import queryString from 'query-string';
import { requestGetEventByBanner, resetEventByBanner } from './action';
import PropTypes from 'prop-types';
import EventList from '../../components/EventList';
import { compose } from 'redux';
import _ from 'lodash';
import './style.scss';
class EventByBanner extends Component {

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  componentDidMount() {
    if (this.props.location.search) {
      this.props.requestGetEventByBanner(queryString.parse(this.props.location.search).event.split(','));
    }
  }

  componentWillUnmount() {
    this.props.resetEventByBanner();
  }

  goBack() {
    this.props.history.goBack();
  }

  renderEventList() {
    if (_.isEmpty(this.props.events)) {
      if (!this.props.hasList || !this.props.location.search) {
        return <Redirect to='/404-not-found'/>;
      }
    }

    return (
      <EventList 
        showBanner={false} 
        events={this.props.events} 
        heightHeader={110} getMore={() => {return;}}
        hasMore={false}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <div id="banner-event">
          <img alt="" src="/images/back.svg" onClick={this.goBack} />
          <div>
          おすすめイベント
          </div>
        </div>
        {this.renderEventList()}
      </Fragment>
    );  
  }
}

EventByBanner.propTypes = {
  requestGetEventByBanner: PropTypes.func,
  resetEventByBanner: PropTypes.func,
  history: PropTypes.any,
  location: PropTypes.any,
  events: PropTypes.array,
  hasList: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    events: state.get('bannerEvents').get('list'),
    hasList: state.get('bannerEvents').get('hasList')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestGetEventByBanner: events => dispatch(requestGetEventByBanner(events)),
    resetEventByBanner: () => dispatch(resetEventByBanner())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS
)(EventByBanner);