import React, {Component} from 'react';
import {connect} from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { setCommonPopup } from './../Popup/actions';
import { getValueByKey } from './../Popup/selectors';
import { unsetCurrentAccount } from './../Account/actions';

class LogoutPopup extends Component {

  constructor(props) {
    super(props);
    this.handleAction = this.handleAction.bind(this);
  }

  handleAction() {
    this.props.unsetCurrentAccount();
  }

  componentWillUnmount() {
    this.props.closePopup();
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.closePopup} className="confirm-popup">
        <div className="text-center message">本当にログアウトしますか？</div>
        <div>
          <button onClick={this.props.closePopup}>いいえ</button>
          <button onClick={this.handleAction}>はい</button>
        </div>
      </Modal>
    );
  }
}

LogoutPopup.propTypes = {
  show: PropTypes.bool,
  closePopup: PropTypes.func,
  unsetCurrentAccount: PropTypes.func
};

LogoutPopup.defaultProps = {
  setPopupNotice: () => null,
  show: false
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'logout')
  };
};

const mapDispatchToProps = dispatch => ({
  closePopup: () => dispatch(setCommonPopup('logout', false)),
  unsetCurrentAccount: () => dispatch(unsetCurrentAccount())
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withImmutablePropsToJS(LogoutPopup));
