import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StudentAvatar from '../../../FollowList/FollowerCell/StudentAvatar';
import Rating from './Rating';
import moment from 'moment';
import './style.scss';

class ReviewCell extends Component {
  render() {
    const { review = {}, status } = this.props;
    const { reviewer = {}, questionAnswers = {} } = review;
      
    return (
      <div className="student-management-review-cell">
        <div className="review-info">
          <div className="reviewer-info">
            <StudentAvatar studentInfo={reviewer} status={status} />

            <div className="reviewer-profile">
              <div className="ellip-overflow">{reviewer.displayName}</div>
              <div className="graduation-year">{reviewer.graduationYear}年卒</div>
            </div>
          </div>

          <div className="review-date">{moment(review._created_at).format('YYYY/MM/DD')}</div>
        </div>

        <div className="review-for-event">
          <Rating rating={review.voteEvent} />
          <div>{review.feedback}</div>
        </div>

        <div className="review-for-president">
          <div className="title">For president:</div>
          <Rating rating={review.votePresident} />
          <div>{questionAnswers.QUESTIONNAIRE_APPLY_COMPANY_FREE_TEXT}</div>
        </div>
      </div>
    );
  }
}

ReviewCell.propTypes = {
  status: PropTypes.string,
  review: PropTypes.object
};

export default ReviewCell;
