import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import moment from 'moment';
import 'moment/locale/ja';
import { Link } from 'react-router-dom';
import {
  setMobileMessageView
} from 'containers/Message/actions';
import { timeDisplay } from '../../../../utilities/timeFormat';

class MessageCellOperator extends Component {
  renderMessage = () => {
    const {item: {lastMessageType, senderId, lastMessage}, currentUser} = this.props;
    if (senderId === currentUser.objectId) {
      if (lastMessageType === 'image') {
        return <span>添付ファイルを送信しました</span>;
      }
      return <span>You: {lastMessage}</span>;
    } else {
      if (lastMessageType === 'image') {
        return <span>社長メシ事務局より添付ファイルが届きました</span>;
      }
      return <span>{lastMessage}</span>;
    }
  }

  render() {
    const { item, unread } = this.props;
    const timeAgo = timeDisplay(moment.unix(item.updatedAt.seconds).toISOString());
    const operatorAvatar = '/images/shacho-meshi-operator.svg';
    return (
      <>
        <Link
          to={{
            pathname: `/messages/${item.groupId}`,
            state: {
              chatWithOperatorId: item.groupId
            }
          }}
          className={`msg-noti-cell-operator info ${unread &&
            'unread-bg'}`}
          onClick={this.props.turnToMessageView}
        >
          <div className='avatar ava-square'>
            <img alt="" src={operatorAvatar} />

            {unread && <div className="unread-point" />}
          </div>

          <div className="operator-info">
            <div className="name">社長メシ運営事務局</div>
            <div className="message">
              {this.renderMessage()}
            </div>
          </div>

          <div className="moment">{timeAgo}</div>
        </Link>
        <hr />
      </>
    );
  }
}

MessageCellOperator.propTypes = {
  item: PropTypes.object,
  turnToMessageView: PropTypes.func,
  unread: PropTypes.bool,
  currentUser: PropTypes.object
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  turnToMessageView: () => dispatch(setMobileMessageView(1))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(MessageCellOperator));
