export const FETCH_STICKERS_REQUEST = 'FETCH_STICKERS_REQUEST';
export const FETCH_STICKERS_SUCCESS = 'FETCH_STICKERS_SUCCESS';
export const GREETING = 'GREETING';

export function requestStickers() {
  return {type: FETCH_STICKERS_REQUEST};
}

export function receiveStickers(list) {
  return {type: FETCH_STICKERS_SUCCESS, list};
}

export function greeting(sticker, eventId) {
  return {type: GREETING, sticker, eventId};
}