import React, { Component } from 'react';
import { Field } from 'redux-form/immutable';
import StudentCell from './StudentCell';
import PropTypes from 'prop-types';

export default class StudentList extends Component {
  render() {
    const { reviewStudents = [] } = this.props;
    return (
      <div id="stud-list">
        {
          this.props.fields.map((stud, i) => reviewStudents[i] && 
            <Field 
              key={reviewStudents[i].userId}
              name={stud}
              component={StudentCell}
              stud={stud} 
              studInfo={reviewStudents[i]}
              openReviewSelectedStudent={
                () => this.props.openReviewSelectedStudent({ fieldIndex: i, fieldName: stud })
              }
              openConfirmPopup={this.props.openConfirmPopup}
              onChangeField={this.props.onChangeField}
            />
          )
        }
      </div>
    );
  }
}

StudentList.propTypes = {
  reviewStudents: PropTypes.array,
  fields: PropTypes.any,
  openReviewSelectedStudent: PropTypes.func,
  openConfirmPopup: PropTypes.func,
  onChangeField: PropTypes.func
};