const isReloadPage = () => {
  if (window.performance) {
    if (performance.navigation.type === 1) {
      return true;
    } else {
      return false;
    }
  }
};

export const doesGoBack = () => {
  return !isReloadPage() && this.props.history && this.props.history.action === 'POP';
};

/*ref:  detectmobilebrowsers.com*/
export function mobilecheck() {
  return document.documentElement.clientWidth <= 767;
}

export function initViewPortWatcher() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export function killMeta() {
  var elem = document.querySelector("meta[name='og:title']:not([data-react-helmet])");
  elem && elem.parentNode.removeChild(elem);
  elem = document.querySelector("meta[name='og:description']:not([data-react-helmet])");
  elem && elem.parentNode.removeChild(elem);
  elem = document.querySelector("meta[name='og:site_name']:not([data-react-helmet])");
  elem && elem.parentNode.removeChild(elem);
  elem = document.querySelector("meta[name='og:image']:not([data-react-helmet])");
  elem && elem.parentNode.removeChild(elem);
}

export function formatExternalLink(url) {
  if (!url) {
    return;
  }
  
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }

  return url;
}

export const detectBrowser = () => {
  if (typeof navigator === 'undefined') {
    return undefined;
  }
  const { userAgent } = navigator;
  if (userAgent.match(/edg/i)) {
    return 'Edge';
  }
  if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'Chrome';
  }
  if (userAgent.match(/firefox|fxios/i)) {
    return 'Firefox';
  }
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return 'iOS';
  }
  if (userAgent.match(/safari/i)) {
    return 'Safari';
  }
  return undefined;
};
