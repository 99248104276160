// STUDENT ROLE
export const SEND_GOCHI_REQUEST = 'SEND_GOCHI_REQUEST';
export const SEND_GOCHI_SUCCESS = 'SEND_GOCHI_SUCCESS';
export const sendGochiRequest = ({ presidentId, message, answer }) => ({
  type: SEND_GOCHI_REQUEST,
  payload: { presidentId, message, answer }
});
export const sendGochiSuccess = () => ({
  type: SEND_GOCHI_SUCCESS
});

// PRESIDENT ROLE
export const ACCEPT_GOCHI_REQUEST = 'ACCEPT_GOCHI_REQUEST';
export const ACCEPT_GOCHI_SUCCESS = 'ACCEPT_GOCHI_SUCCESS';
export const ACCEPT_GOCHI_FAILURE = 'ACCEPT_GOCHI_FAILURE';
export const acceptGochiRequest = (gochiId, studentId) => ({
  type: ACCEPT_GOCHI_REQUEST,
  payload: { gochiId, studentId },
  meta: {
    thunk: true
  }
});
export const acceptGochiSuccess = (payload, meta) => ({
  type: ACCEPT_GOCHI_SUCCESS,
  payload,
  meta
});
export const acceptGochiFailure = (payload, meta) => ({
  type: ACCEPT_GOCHI_FAILURE,
  payload,
  meta,
  error: true
});

// PRESIDENT ROLE
export const GET_STUDENT_GOCHI_REQUEST = 'GET_STUDENT_GOCHI_REQUEST';
export const GET_STUDENT_GOCHI_SUCCESS = 'GET_STUDENT_GOCHI_SUCCESS';
export const getStudentGochiRequest = (studentId) => ({
  type: GET_STUDENT_GOCHI_REQUEST,
  payload: { studentId }
});
export const getStudentGochiSuccess = (payload) => ({
  type: GET_STUDENT_GOCHI_SUCCESS,
  payload
});

export const INITIALIZE_GOCHI = 'INITIALIZE_GOCHI';
export const initializeGochiEvent = () => ({
  type: INITIALIZE_GOCHI
});

// STUDENT ROLE
export const GET_FOLLOWING_PRESIDENT_LIST_REQUEST = 'GET_FOLLOWING_PRESIDENT_LIST_REQUEST';
export const GET_FOLLOWING_PRESIDENT_LIST_SUCCESS = 'GET_FOLLOWING_PRESIDENT_LIST_SUCCESS';
export const GET_FOLLOWING_PRESIDENT_LIST_FAILURE = 'GET_FOLLOWING_PRESIDENT_LIST_FAILURE';
export const getFollowingPresidentListRequest = (payload) => ({
  type: GET_FOLLOWING_PRESIDENT_LIST_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const getFollowingPresidentListSuccess = (payload, meta) => ({
  type: GET_FOLLOWING_PRESIDENT_LIST_SUCCESS,
  payload,
  meta
});
export const getFollowingPresidentListFailure = (payload, meta) => ({
  type: GET_FOLLOWING_PRESIDENT_LIST_FAILURE,
  payload,
  meta,
  error: true
});

// STUDENT ROLE
export const SET_PRESIDENT_NOTIFY_REQUEST = 'SET_PRESIDENT_NOTIFY_REQUEST';
export const SET_PRESIDENT_NOTIFY_SUCCESS = 'SET_PRESIDENT_NOTIFY_SUCCESS';
export const SET_PRESIDENT_NOTIFY_FAILURE = 'SET_PRESIDENT_NOTIFY_FAILURE';
export const setPresidentNotifyRequest = (payload) => ({
  type: SET_PRESIDENT_NOTIFY_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const setPresidentNotifySuccess = (payload, meta) => ({
  type: SET_PRESIDENT_NOTIFY_SUCCESS,
  payload,
  meta
});
export const setPresidentNotifyFailure = (payload, meta) => ({
  type: SET_PRESIDENT_NOTIFY_FAILURE,
  payload,
  meta,
  error: true
});

// PRESIDENT ROLE
export const GET_STUDENT_AVAILABLE_SLOTS_REQUEST = 'GochiEvent/GET_STUDENT_AVAILABLE_SLOTS_REQUEST';
export const GET_STUDENT_AVAILABLE_SLOTS_SUCCESS = 'GochiEvent/GET_STUDENT_AVAILABLE_SLOTS_SUCCESS';
export const GET_STUDENT_AVAILABLE_SLOTS_FAILURE = 'GochiEvent/GET_STUDENT_AVAILABLE_SLOTS_FAILURE';
export const getStudentAvailableSlotsRequest = ({ studentId }) => ({
  type: GET_STUDENT_AVAILABLE_SLOTS_REQUEST,
  payload: { studentId },
  meta: {
    thunk: true
  }
});
export const getStudentAvailableSlotsSuccess = (payload, meta) => ({
  type: GET_STUDENT_AVAILABLE_SLOTS_SUCCESS,
  payload,
  meta
});
export const getStudentAvailableSlotsFaiure = (payload, meta) => ({
  type: GET_FOLLOWING_PRESIDENT_LIST_FAILURE,
  payload,
  meta,
  error: true
});

// PRESIDENT ROLE
export const GET_FOLLOWER_STUDENT_LIST_REQUEST = 'GET_FOLLOWER_STUDENT_LIST_REQUEST';
export const GET_FOLLOWER_STUDENT_LIST_SUCCESS = 'GET_FOLLOWER_STUDENT_LIST_SUCCESS';
export const GET_FOLLOWER_STUDENT_LIST_FAILURE = 'GET_FOLLOWER_STUDENT_LIST_FAILURE';
export const getFollowerStudentListRequest = (payload) => ({
  type: GET_FOLLOWER_STUDENT_LIST_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const getFollowerStudentListSuccess = (payload, meta) => ({
  type: GET_FOLLOWER_STUDENT_LIST_SUCCESS,
  payload,
  meta
});
export const getFollowerStudentListFailure = (payload, meta) => ({
  type: GET_FOLLOWER_STUDENT_LIST_FAILURE,
  payload,
  meta,
  error: true
});

// PRESIDENT ROLE
export const UPDATE_GOCHI_STATUS_REQUEST = 'UPDATE_GOCHI_STATUS_REQUEST';
export const UPDATE_GOCHI_STATUS_SUCCESS = 'UPDATE_GOCHI_STATUS_SUCCESS';
export const UPDATE_GOCHI_STATUS_FAILURE = 'UPDATE_GOCHI_STATUS_FAILURE';
export const updateGochiStatusRequest = (payload) => ({
  type: UPDATE_GOCHI_STATUS_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const updateGochiStatusSuccess = (payload, meta) => ({
  type: UPDATE_GOCHI_STATUS_SUCCESS,
  payload,
  meta
});
export const updateGochiStatusFailure = (payload, meta) => ({
  type: UPDATE_GOCHI_STATUS_FAILURE,
  payload,
  meta,
  error: true
});
