import Parse from 'parse';
import { pushCommingNotification } from './action';

class NotificationAdapter {
  subscription = {};

  subscribeHandler = {
    create: (newNotification) => {
      window.store.dispatch(pushCommingNotification(newNotification.toJSON()));
    },
    update: (notification) => {
      //do something with updated notification
    }
  };

  subscribe = async () => {
    this.unsubscribe();
    const currUser = Parse.User.current();
    const handler = this.subscribeHandler;
    if (!currUser || !currUser.id) {
      throw new Error('login Require for live adapter');
    }

    const NotiQuery = new Parse.Query('Notification');
    NotiQuery.contains('receivers', currUser.id);
    NotiQuery.notContainedIn('type', ['HAVE_NEW_MESSAGE', 'ALERT_BEFORE_24H']);

    const msgSub = this.subscription = await NotiQuery.subscribe();
    msgSub.on('open', () => {})
    msgSub.on('create', handler.create);
    msgSub.on('close', () => window.location.reload());
  };

  unsubscribe = () => {
    let subscription = this.subscription;
    subscription && subscription.unsubscribe && subscription.unsubscribe();
    delete this.subscription;
    this.subscription = {};
  };
};

window.NotificationAdapter = NotificationAdapter;

export default NotificationAdapter;