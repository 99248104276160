import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { ACCEPT_POLICY_REQUEST } from './actions';
import { getCurrentAccount } from './../Account/actions';

function* acceptPolicyRequest(payload) {
  try {
    const { token, eventId } = payload.action;
    yield call(request, 'functions/acceptPolicy', {}, 'POST');
    yield put(getCurrentAccount(token, 'ACCEPT_PILICY'));
    // yield put(push(`/messages/${eventId}`));
    yield put(window.ReactHistory.push({
      pathname: `/messages/${eventId}`,
      state: { eventId, acceptPolicy: true }
    }));
    
    // :TODO handle error
  } catch (e) {
    console.log(e.message);
  }
}

export default function* watchAcceptPolicy() {
  yield takeEvery(ACCEPT_POLICY_REQUEST, acceptPolicyRequest);
}