/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMediaInfoByUrlRequest } from './actions';
import { formatExternalLink } from '../../utilities/browser';
import Image from '../Image';
import './style.scss';

class SocialMedia extends Component {
  render() {
    const { socialMedia, index, preview, openMediaPopup } = this.props;
    const { image, host_name } = socialMedia;

    return (
      <Fragment>
        {preview && (
          <div className="social-media-preview">
            <div className="social-media-preview__container" onClick={() => openMediaPopup && openMediaPopup(index)}>
              <Image alt="" src={image} defaultSrc="/images/default-event.svg" />
              <div className="social-media-preview__info">
                <div className="social-media-preview__info__title ellipsis"><div>{socialMedia.title}</div></div>
                <div className="social-media-preview__info__host-name">{host_name}</div>
              </div>
            </div>
            <div className="social-media-preview__delete-btn">
              <img 
                alt="" 
                src="/images/remove-upload-image.svg" 
                onClick={() => this.props.handleDeleteSocialMedia(index)} 
              />
            </div>
          </div>
        )}

        {!preview && (
          <a href={formatExternalLink(socialMedia.url)} target="_blank" rel="noopener noreferrer">
            <div className="social-media">
              <Image alt="社長メシ" src={image} defaultSrc="/images/default-event.svg" />
              {socialMedia.type === 'YOUTUBE' && (
                <img alt="" src="/images/play-btn.svg" className="social-media__play-btn" />
              )}
              <div className="social-media__info">
                <div className="social-media__info__host-name">{host_name}</div>
                <div className="social-media__info__title ellipsis"><div>{socialMedia.title}</div></div>
              </div>
            </div>
          </a>
        )}
      </Fragment>
    );
  }
}

SocialMedia.propTypes = {
  preview: PropTypes.bool,
  index: PropTypes.number,
  socialMedia: PropTypes.object,
  handleDeleteSocialMedia: PropTypes.func,
  getMediaInfoByUrl: PropTypes.func,
  openMediaPopup: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  getMediaInfoByUrl: url => dispatch(getMediaInfoByUrlRequest(url))
});

export default connect(null, mapDispatchToProps)(SocialMedia);