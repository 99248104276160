import { put, takeEvery, select } from 'redux-saga/effects';

import { USE_REDIRECT_URN } from './action';
import { push } from 'connected-react-router';

function* conductRedirectURN(action) {
  try {
    let redirectURNStorage = (yield select((state) => {
      return state.get('redirectURNStorage');
    }));
    redirectURNStorage = redirectURNStorage.toJS ? redirectURNStorage.toJS().URN : '';

    let sessionURNStorage = sessionStorage.getItem('meshiRedirectURL');
    
    const toURN = redirectURNStorage || sessionURNStorage || '/';
    yield put(push(toURN));
  } catch (error) {
    console.log(error);
  }
}


export function* watchUseRedirectURN() {
  yield takeEvery(USE_REDIRECT_URN, conductRedirectURN);
}
