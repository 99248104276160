import React, { useState } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable'; 
import _ from 'lodash';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import EventDetail from '../EventDetail';
import PresidentCell from './PresidentCell';
import { Textarea, RadioBtn } from 'components/Form';
import { connect } from 'react-redux';
import EventCell from 'containers/Management/EventsManagement/EventList/EventCell';
import Stepper from 'components/Stepper';
import Button from 'components/Button';
import validate from './validate';

const applyOptions = [
  {
    text: '選考を希望する',
    value: true
  },
  {
    text: '選考を希望しない',
    value: false
  }
];

const stepDetail = [{
  key: 1,
  text: 'イベント主催者レビュー'
}, {
  key: 2,
  text: '今後の希望'
}, {
  key: 3,
  text: '運営へのコメント'
}];

const StudentReview = props => {
  const { eventInfo, presidentInfo, valid, votePresident } = props;
  const [step, setStep] = useState(1);
  const [openEventDetail, setOpenEventDetail] = useState(false);
  
  const reviewEventForAbsentStudent = async() => {
    await props.change('hasJoin', false);
    props.handleSubmit();
  };

  return (
    <form id='stud-review' onSubmit={props.handleSubmit} >
      { openEventDetail &&
        <div className='event-review-container'>
          <div className='event-info-header'>
            <img 
              onClick={() => setOpenEventDetail(false)} 
              className='back-icon' 
              src='images/back.svg' 
              alt='back' draggable='false'
            />
            <div className='event-name bold'>
              {eventInfo.name}
            </div>
          </div>
          <EventDetail event={eventInfo} />
        </div>
      }
      <div className={openEventDetail ? 'hidden' : 'event-review-container'}>
        <div className='header-container'>
          <Stepper stepCount={step} options={stepDetail} />
        </div>
        <div className='review-content'>
          { step === 1 && 
            <>
              <div className='text-note mb-4'>社長メシのご利用ありがとうございました。学生の出欠とレビューを登録してください </div>
              <div className='event-joined-container'>
                <div className='header'>
                  参加したイベント
                </div>
                <div
                  className='event-detail'
                  onClick={() => setOpenEventDetail(true)}
                >
                  { !_.isEmpty(eventInfo) && 
                    <EventCell event={eventInfo} />
                  }
                </div>
              </div>
              <div className='mt-40'>
                <PresidentCell presidentInfo={presidentInfo} votePresident={votePresident} />
              </div>
              <div className="w-100 mt-4">
                <Field 
                  name='questionAnswers.QUESTIONNAIRE_APPLY_COMPANY_FREE_TEXT'
                  component={Textarea} 
                  className='feedback'
                  placeholder='イベント主催者へのお礼メッセージを記入してください' 
                  rows={7} />
              </div>
              <div className='comment-note mb-5'>
                レビュー画面に表示されるので、個人情報の入力にお気をつけください。
              </div>
            </>
          }

          { step === 2 &&
            <>
              <div className='mb-4'>
                <PresidentCell presidentInfo={presidentInfo} votePresident={votePresident} isPreview />
              </div>
              <div className='text-note text-highlight'>今後の希望を選択してください</div>
              <div className='mt-4 mb-4'>
                <Field 
                  name="questionAnswers.QUESTIONNAIRE_HAS_APPLY_COMPANY" 
                  segment={true} 
                  component={RadioBtn} 
                  options={applyOptions} 
                />
              </div>
              <div className='mb-5'>                
                <Field 
                  name='questionAnswers.QUESTIONNAIRE_APPLY_COMPANY_FREE_COMMENT' 
                  component={Textarea} 
                  className='feedback'
                  placeholder='任意：コメントを書く' 
                  rows={7} 
                />
              </div>
            </>
          }

          { step === 3 && 
            <>
              <div className='mb-4'>                
                <Field 
                  name='feedback'
                  component={Textarea} 
                  className='feedback'
                  placeholder='任意：今後サービスの向上に向けて&#10;お気づきの点ありましたらご記入ください'
                  rows={7}
                />
              </div>
            </>
          }
          <div className='btn-wrapper'>
            { step === 1 && 
              <div className='btn-back mb-4' onClick={reviewEventForAbsentStudent}>
                この社長メシに参加していない
              </div>
            }
            { step === 3 ? 
              <Button 
                type='submit'
                className='btn-submit'
                disabled={!valid}
              >
                送信
              </Button> :
              <Button
                type='button'
                className='btn-submit'
                disabled={!valid} 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setStep(step + 1);
                }}
              >
                次へ
              </Button>
            }
            { step !== 1 && 
              <div className='btn-back mt-4' onClick={() => setStep(step - 1)}>
                戻る
              </div>
            }
          </div>
        </div>
      </div>
    </form>
  );
};

StudentReview.propTypes = {
  eventInfo: PropTypes.object,
  presidentInfo: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  votePresident: PropTypes.number,
  resetReview: PropTypes.func,
  valid: PropTypes.bool,
  hasJoin: PropTypes.bool
};

const selector = formValueSelector('studReview');

const mapStateToProps = state => {
  return {
    presidentInfo: state.get('review').get('president'),
    eventInfo: state.get('eventDetail').get('eventInfo'),
    votePresident: selector(state, 'votePresident'),
    hasJoin: selector(state, 'hasJoin')
  };
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'studReview',
    validate
  }),
  withImmutablePropsToJS
)(StudentReview);