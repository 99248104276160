import React, { Component } from 'react';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EditMyPage from 'components/EditMyPage';
import EditMyPagePresident from 'components/EditMyPagePresident';
import { studentCompleteProfile } from 'containers/StudentUpdateProfile/actions';
import { presidentUpdateProfile } from 'containers/PresidentUpdateProfile/actions';
import { getCurrentAccount } from 'containers/Account/actions';
import { uploadAvatar } from 'utilities/file';
import { formatDate } from 'utilities/date';
import { trimObjectList } from 'utilities/handleValues';
import { request } from 'utilities/Api';
import { getResources } from 'containers/Resources/actions';
import { setCommonPopup } from 'containers/Popup/actions';
import { formValueSelector } from 'redux-form/immutable';
import './style.scss';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { closeRequirePhoneModal, updateProfileSuccess } from '../RequirePhonePopup/action';
import _ from 'lodash';

class EditMyPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyTagsResources: [],
      tagsResource: [],
      presidentTag: []
    };
  }

  async componentDidMount() {
    await this.props.getCurrentAccount(this.props.sessionToken);

    const { currentUser } = this.props;
    if (currentUser.role === 'STUDENT') {
      this.props.getResources(['Industry', 'Tag', 'AvailableUniversity', 'Prefecture']);
    }
    if (currentUser.role === 'PRESIDENT') {
      this.props.getResources(['PresidentTag']);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.companyTagsResources) {
      const companyTagsResources = nextProps.companyTagsResources.map((value) => value.name);
      this.setState({
        companyTagsResources: companyTagsResources
      });
    }

    if (nextProps.presidentTag) {
      const presidentTag = nextProps.presidentTag.map((value) => value.name);
      this.setState({
        presidentTag: presidentTag
      });
    }
    if (this.props.showRequirePhone) {
      this.props.closeRequirePhonePopup();
    }
  }

  async handleUploadFile(file) {
    try {
      const image = await uploadAvatar(file);
      const { objectId } = await request('classes/Image', {
        file: { ...image, '__type': 'File' },
        type: 'AVATAR',
        name: image.name
      }, 'POST');
      return objectId || null;
    } catch (e) {
      return null;
    }
  }

  serializeObjectsToObjeciIdArray(objectList) {
    let serialized = [];

    if (objectList.length) {
      for (let i = 0; i < objectList.length; i++) {
        if (objectList[i] && objectList[i].objectId) {
          serialized.push(objectList[i].objectId);
        }
      }
    }

    return serialized;
  }

  submit = async(values) => {
    const data = values.toJS();
    data.photos = this.serializeObjectsToObjeciIdArray(data.photos);

    const { sessionToken, currentUser } = this.props;
    if (currentUser.role === 'STUDENT') {
      this.props.studentCompleteProfile(trimObjectList(data), sessionToken).then(() => {
        this.props.updateProfileSuccess();
        if (currentUser.phone) {
          this.props.history.push('/my-page');
        } else {
          this.props.history.push('/');
        }
      });
    } else {
      if (data.mediaImages && data.mediaImages.length > 0) {
        // just pass array of ids to server
        const mediaImageIds = data.mediaImages.map(img => img.objectId);
        // server still accept property mediaImages or mediaImageIds
        data.mediaImages = mediaImageIds;
      }
      
      this.props.presidentUpdateProfile(data, sessionToken).then(() => {
        this.props.history.push('/my-page');
      });
    }
  }

  handleOpenAlertPopup = () => {
    this.props.openAlertPopup({
      message: 'ファイルが大きすぎます。', 
      OKcallback: () => this.props.closeAlertPopup(),
      onHideCallback: () => this.props.closeAlertPopup()
    });
  }

  render() {
    const { currentUser } = this.props;
    
    if (!currentUser) {
      return (<Redirect to="/" />);
    }

    const initialValues = {
      photos: currentUser.photos || [],
      fullname: currentUser.fullname,
      nameHiragana: currentUser.nameHiragana,
      birthday: formatDate(currentUser.birthday 
        ? new Date(currentUser.birthday) 
        : new Date('1996-01-01'), 'YYYY-MM-DD'),
      gender: currentUser.gender || '',
      phone: currentUser.phone,
      email: currentUser.email,
      department: currentUser.department,
      tags: currentUser.tags
    };

    let companyLogo;
    if (currentUser.role === 'STUDENT') {
      const studentProfile = currentUser.studentProfile || {};
      initialValues.studentProfile = studentProfile;
      initialValues.studentProfile['graduationYear'] = studentProfile.graduationYear || new Date().getFullYear();
      initialValues.studentProfile['majorCategory'] = studentProfile.majorCategory || '文系';
      initialValues.studentProfile['universityId'] = _.get(studentProfile, 'universityObject._id');
      initialValues.studentProfile['district'] = _.get(studentProfile, 'district._id', '');
    } else {
      const presidentProfile = currentUser.presidentProfile;
      companyLogo = presidentProfile.companyLogo || {};
      presidentProfile.companyLogo = companyLogo.objectId;
      initialValues.presidentProfile = presidentProfile;
      initialValues.mediaImages = currentUser.mediaImages;
      initialValues.mediaArticles = currentUser.mediaArticles;
    }

    return (
      <div className="edit-my-page-container">
        <div className="title-container">
          <h1>プロフィール編集</h1>
        </div>
        <div className="edit-my-page">
          {
            currentUser.role === 'STUDENT' ? (
              <EditMyPage
                initialValues={initialValues}
                uploadFile={this.handleUploadFile}
                onSubmit={this.submit}
                currentUser={currentUser}
                tagsResource={this.props.tagsResource}
                availableUniversities={this.props.availableUniversities}
                availableDistrict={this.props.availableDistrict}
                companyTagsResources={this.state.companyTagsResources}
              />
            ) : (
              <EditMyPagePresident
                initialValues={initialValues}
                uploadFile={this.handleUploadFile}
                onSubmit={this.submit}
                currentUser={currentUser}
                presidentTag={this.state.presidentTag}
                companyLogo={companyLogo}
                openMediaPopup={this.props.openMediaPopup}
                mediaArticles={this.props.mediaArticles}
                mediaImages={this.props.mediaImages}
                showLoading={this.props.showLoading}
                hideLoading={this.props.hideLoading}
                handleOpenAlertPopup={this.handleOpenAlertPopup}
              />
            )
          }
        </div>
      </div>
    );
  }
}

EditMyPageContainer.propTypes = {
  history: PropTypes.object,
  getCurrentAccount: PropTypes.func,
  currentUser: PropTypes.object,
  studentCompleteProfile: PropTypes.func,
  presidentUpdateProfile: PropTypes.func,
  getResources: PropTypes.func,
  openMediaPopup: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  openAlertPopup: PropTypes.func,
  closeAlertPopup: PropTypes.func,
  tagsResource: PropTypes.array,
  companyTagsResources: PropTypes.array,
  presidentTagResource: PropTypes.array,
  mediaImages: PropTypes.array,
  mediaArticles: PropTypes.array,
  presidentTag: PropTypes.array,
  sessionToken: PropTypes.any,
  updateProfileSuccess: PropTypes.func,
  closeRequirePhonePopup: PropTypes.func,
  showRequirePhone: PropTypes.bool,
  availableDistrict: PropTypes.array,
  availableUniversities: PropTypes.array
};

const mapStateToProps = state => {
  const selector = formValueSelector('StudentProfileForm');
  let availableDistrict = state.get('resources').get('prefecture');
  let availableUniversities = state.get('resources').get('availableuniversity');
  if (!availableUniversities) {
    availableUniversities = [];
  }
  if (!availableDistrict) {
    availableDistrict = [];
  }
  availableUniversities = availableUniversities.map((element) => ({
    name: element.name,
    value: element.objectId,
    hasExtraInput: element.hasExtraInput
  }));
  availableDistrict = availableDistrict.map((item) => ({
    name: item.name,
    value: item.objectId
  }));
  return {
    currentUser: state.get('account').get('data'),
    sessionToken: state.get('account').get('token'),
    tagsResource: state.get('resources').get('tags'),
    companyTagsResources: state.get('resources').get('industries'),
    presidentTag: state.get('resources').get('presidenttag'),
    mediaImages: selector(state, 'mediaImages') || [],
    mediaArticles: selector(state, 'mediaArticles') || [],
    showRequirePhone: state.get('requirePhoneModal').get('show'),
    availableUniversities,
    availableDistrict
  };
};

const mapDispatchToProps = dispatch => ({
  getCurrentAccount: (token) => dispatch(getCurrentAccount(token)),
  getResources: (resourcenames) => dispatch(getResources(resourcenames)),
  studentCompleteProfile: (values, sessionToken) => 
    dispatch(studentCompleteProfile(values, sessionToken, 'STUDENT_UPDATE_PROFILE')),
  presidentUpdateProfile: (values, sessionToken) => dispatch(presidentUpdateProfile(values, sessionToken)),
  openMediaPopup: index => dispatch(setCommonPopup('media', { index: index })),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  openAlertPopup: show => dispatch(setCommonPopup('success', show)),
  closeAlertPopup: () => dispatch(setCommonPopup('success', false)),
  closeRequirePhonePopup: () => dispatch(closeRequirePhoneModal()),
  updateProfileSuccess: () => dispatch(updateProfileSuccess())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(EditMyPageContainer));