import { request } from './../../utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { receiveUserInfo, FETCH_USER_INFO_REQUEST } from './action';
import { push, goBack,replace } from 'connected-react-router';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { setCommonPopup } from 'containers/Popup/actions';

function* getUserInfo(action) {
  try {
    yield put(showLoading());
    let userId = action.userId;
    const data = yield call(request, 'functions/getUserInformation', { userId: userId }, 'POST');
    const {fromInternalLink = false } = action;

    const { status = 'ACTIVE', role} = data.result && data.result.infoUser;
    
    if(action.currentPage && action.currentPage === 'presidentDetail' && role !== 'PRESIDENT'){
      throw new Error('President does not exists')
    }
    if (role === 'PRESIDENT' && status === 'INACTIVE') {
      yield put(setCommonPopup('success', {
        message: 'この社長は掲載を終了しています',
        OKcallback: () => {
          fromInternalLink ? window.store.dispatch(goBack()) : window.store.dispatch(push('/'));
        },
        onHideCallback: () => {
          fromInternalLink ? window.store.dispatch(goBack()) : window.store.dispatch(push('/'));
        }
      }));
    } else {
      if (status !== 'ACTIVE') {
        yield put(replace('/unsubscribe'));
      } else {
        yield put(receiveUserInfo(data.result.infoUser));
      }
    }
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
    yield put(replace('/404-not-found'));
  }

}


export default function* watchGetUserInfo() {
  yield takeEvery(FETCH_USER_INFO_REQUEST, getUserInfo);
}