import { fromJS } from 'immutable';
import { SET_COMMON_POPUP } from './actions';

const initState = fromJS({
  default: false
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function commonPopup(state = initState, action) {
  switch (action.type) {
    case SET_COMMON_POPUP:
      return state.set(action.key, action.open);
    default:
      return state;
  }
}
