import _ from 'lodash';
import moment from 'moment';

// Only handle 2 layer objects
export const trimObjectList = (objectList) => _.mapValues(objectList, (value) => {
  if (typeof value === 'string') return _.trim(value);
  if (Array.isArray(value)) return value;
  if (typeof value === 'object')
    return _.mapValues(value, (item) => {
      if (typeof item === 'string') return _.trim(item);
      return item;
    });
  return value;
});

export function universityHasExtraInput(universityId) {
  const state = window.store.getState && window.store.getState();
  let availableUniversities = [];
  if (state) {
    availableUniversities = state.get('resources').get('availableuniversity');
    if (!availableUniversities) {
      availableUniversities = [];
    }
  }
  const university = _.find(availableUniversities, { objectId: universityId});
  return university && university.hasExtraInput;
}

export function getAgeFromISOString(dateString) {
  if (!dateString) {
    return undefined;
  }
  const sliceDate = dateString.slice(0, 10);
  const age = moment().diff(moment(sliceDate, 'YYYY-MM-DD'), 'years');
  return age;
}

export function getCurrentNationSelect() {
  return window.sessionStorage.getItem('select_nation_code');
}

export function getVerifyPopupPayload({ email }) {
  return {
    title: '認証メールを送信しました。',
    message: `${email}に認証メールを送信しました。\nメールを認証すると、サービスを利用することができます。`,
    btnConfirm: '確認',
    isAlert: true,
    subContent: 'もし届かなかった場合は、メールアドレスが\n間違えているか迷惑メールフォルダに\n格納されている可能性があります。'
  };
}