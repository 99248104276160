import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Answer extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.answerList.length === 0) {
      this.props.getResources();
    } else {
      this.props.input.onChange(this.props.answerList[0].objectId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //check geting resources
    if (this.props.answerList.length !== nextProps.answerList.length) {
      this.props.input.onChange(nextProps.answerList[0].objectId);
    }

    //check changing answer
    if (this.props.answer !== nextProps.answer) {
      const show = this.props.answerList.some((item) => {
        return item.objectId === nextProps.answer && item.type === 'TEXTBOX';
      });
      this.props.showExtraField(show);
    }
  }

  handleChange(event) {
    this.props.input.onChange(event.target.value);
  }

  render() {
    return (
      <div className={`custom-select-container ${!this.props.label && 'custom-input-no-label'} ${this.props.meta.error && this.props.meta.touched && 'error-form'}`}>
        { this.props.label ? <span>{ this.props.label }</span> : null }
        <select value={this.props.input.value} onChange={this.handleChange} className="form-control">
          {this.props.answerList.map((item, index) => {
            return <option value={item.objectId} key={index}>{item.name}</option>;
          })}
        </select>
      </div>
    );
  }
}

Answer.propTypes = {
  getResources: PropTypes.func,
  answerList: PropTypes.array,
  input: PropTypes.object,
  answer: PropTypes.string,
  showExtraField: PropTypes.func
};

export default Answer;
