import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

const prezProfileTabList = [
  {
    id: 'profile',
    name: 'プロフィール'
  }, 
  {
    id: 'review',
    name: 'レビュー'
  },
  {
    id: 'plans',
    name: 'オファープラン'
  }
];

class PresidentProfileTab extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.curPathname !== this.props.curPathname) {
      return true;
    }

    return false;
  }
  
  render() {
    /**
     * @property {string} curPathname same as url but maybe contain '/review'
     */
    const { url, curPathname, isShow } = this.props;

    return (
      <div id="prez-profile-tab-container">
        {prezProfileTabList.map(tab => {
          if (tab.id === 'plans' && isShow) return <></>;

          let pathname;
          switch (tab.id) {
            case 'review':
              pathname = url + '/review';
              break;
            case 'plans':
              pathname = url + '/plans';
              break;
            default:
              pathname = url;
          }
          
          return (
            <Link
              key={tab.id} 
              to={pathname}
              className={`prez-profile-tab ${curPathname === pathname ? 'active' : ''}`}
            >
              {tab.name}
            </Link>
          );
        })}
      </div>
    );
  }
}

PresidentProfileTab.propTypes = {
  url: PropTypes.string,
  curPathname: PropTypes.string,
  isShow: PropTypes.bool
};

export default PresidentProfileTab;