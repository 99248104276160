import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { getCurrentAccount } from 'containers/Account/actions';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import { setRedirectURN } from 'containers/LinkWithRedirect/action';
import { requestUserInfo, removeUserInfo } from '../PresidentDetail2/action';
import PresidentDetail from 'components/PresidentDetail2';
import { setCommonPopup } from 'containers/Popup/actions';
import {resetPresidentReviews} from 'containers/ReviewList/actions';
import {getSeoDataByUserIdRequest} from '../Seo/actions';
import { openModal } from 'containers/CustomModal/actions';
import { getResources } from 'containers/Resources/actions';
import RequestGochiModal from 'containers/GochiEvent/RequestGochiModal';
import { studentResendEmail } from 'containers/StudentUpdateProfile/actions';
import { getVerifyPopupPayload } from 'utilities/handleValues';

class PresidentDetail2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagResource: []
    };
  }

  async componentDidMount() {
    const { objectId } = this.props.match.params;
    this.props.getSeoDataByUserId(objectId);
    if (objectId) {
      const fromInternalLink = (this.props.location.state && this.props.location.state.fromInternalLink) || false;
      this.props.getPresident(objectId, fromInternalLink);
      this.props.getGochiResources();
    } else {
      await this.props.getCurrentAccount(this.props.token);
    }
  }

  componentWillUnmount() {
    this.props.removeUserInfo();
    this.props.resetPresidentReviews();
  }

  handleOpenGochiModal = () => {
    const { currentUser, showVerifyPopup, resendEmail } = this.props;
    if (!currentUser) {
      this.props.setRedirectURN(_.get(this.props, 'history.location.pathname', '/'));
      this.props.openPopupLogin();
    } else {
      if (!currentUser.emailVerified) {
        resendEmail().then(() => {
          showVerifyPopup({ 
            ...getVerifyPopupPayload({ email: currentUser.email }),
            onConfirm: () => resendEmail({ showSuccessToast: true })
          });
        });
      } else this.props.openGochiRequestModal();
    }
  }

  handleSubmit = (data) => {
    const { match } = this.props;
    const values = data.toJS ? data.toJS() : data;
    const { message, code, extraText } = values;
    this.props.sendGochiRequest({
      presidentId: _.get(match, 'params.objectId', ''), 
      message, 
      answer: { code, extraText } 
    });
  }

  render() {
    let currentUser = (this.props.match.params.objectId ? this.props.presidentDetail : this.props.currentUser) || {};
    !currentUser.objectId && (currentUser.objectId = this.props.match.params.objectId);
    const currentUserId = currentUser ? currentUser.objectId : '';
    const profileUserId = this.props.currentUser ? this.props.currentUser.objectId : '';
    const isShow = (
      (this.props.match && this.props.match.path === '/my-page') ||
      currentUserId === profileUserId
    ) ? false : true;
    const { seo, gochiResources, match } = this.props;

    return (
      <Fragment>
        {isShow && <Helmet>
          <title>{seo.title}</title>
          <meta name="title" content={seo.title} />
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keyword} />
          <meta name="twitter:image" content={seo.image} />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
        </Helmet>}
        
        {_.get(currentUser, 'role', '') && (
          <PresidentDetail 
            currentUser={currentUser}
            currentRole={_.get(this.props, 'currentUser.role', '')}
            isShow={isShow}
            isLogin={!!this.props.token} 
            presidentReviews={this.props.presidentReviews}
            url={this.props.match.url}
            pathname={this.props.location.pathname}
            openGochiRequestModal={this.handleOpenGochiModal}
          />
        )}

        <RequestGochiModal presidentId={_.get(match, 'params.objectId', '')} gochiResources={gochiResources}/>
      </Fragment>
    );
  }
}
PresidentDetail2.propTypes = {
  token: PropTypes.string,
  seo: PropTypes.object,
  currentUser: PropTypes.object,
  presidentReviews: PropTypes.object,
  getCurrentAccount: PropTypes.func,
  match: PropTypes.any,
  gochiResources: PropTypes.array,
  getPresident: PropTypes.func,
  removeUserInfo: PropTypes.func,
  getSeoDataByUserId: PropTypes.func,
  presidentDetail: PropTypes.any,
  location: PropTypes.any,
  history: PropTypes.object,
  resetPresidentReviews: PropTypes.func,
  openGochiRequestModal: PropTypes.func,
  openPopupLogin: PropTypes.func,
  setRedirectURN: PropTypes.func,
  sendGochiRequest: PropTypes.func,
  getGochiResources: PropTypes.func,
  showVerifyPopup: PropTypes.func,
  resendEmail: PropTypes.func
};

const mapStateToProps = state => {
  return {
    seo: state.get('seo'),
    token: state.get('account').get('token'),
    currentUser: state.get('account').get('data'),
    presidentDetail: state.get('userDetail'),
    presidentReviews: state.get('presidentReviews'),
    gochiResources: state.get('resources').get('gochioption')
  };
};

const mapDispatchToProps = dispatch => ({
  getSeoDataByUserId: (objectId) => dispatch(getSeoDataByUserIdRequest(objectId)),
  getCurrentAccount: (token) => dispatch(getCurrentAccount(token, 'mypage')),
  getPresident: 
    (objectId, fromInternalLink) => dispatch(requestUserInfo(objectId, fromInternalLink, 'presidentDetail')),
  removeUserInfo: () => dispatch(removeUserInfo()),
  resetPresidentReviews: () => dispatch(resetPresidentReviews()),
  openGochiRequestModal: () => dispatch(openModal({ key: 'requestGochi' })),
  openPopupLogin: () => dispatch(setCommonPopup('PopupLogin', true)),
  setRedirectURN: link => dispatch(setRedirectURN(link)),
  getGochiResources: () => dispatch(getResources(['GochiOption'])),
  showVerifyPopup: (payload) => dispatch(setCommonPopup('confirm', payload)),
  resendEmail: (payload) => dispatch(studentResendEmail(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PresidentDetail2));
