import React from 'react';
import PropTypes from 'prop-types';

const RadioBtn = props => {
  const { 
    className = '', 
    customCheckboxPath = '/images/circle-btn-checked.svg', 
    customUnCheckboxPath = '/images/circle-btn-uncheck.svg',
    options = [],
    input
  } = props;

  const handleChange = (value) => {
    input.onChange(value);
  };

  return (
    <div className={`radio-button-container ${className}`}>
      { options.map((data, index) => (
        <div key={index} className='radio-button'>
          <img 
            onClick={() => handleChange(data.value)} 
            src={input.value === data.value ? customCheckboxPath : customUnCheckboxPath}
            alt='radio-btn'
          />
          <div className='ml-2'>{data.text}</div>
        </div>
      ))}
    </div>
  );
};

RadioBtn.propTypes = {
  options: PropTypes.array,
  input: PropTypes.object,
  className: PropTypes.string,
  customCheckboxPath: PropTypes.string,
  customUnCheckboxPath: PropTypes.string
};

export default RadioBtn;
