export const FETCH_RELATE_EVENT_LIST_REQUEST = 'FETCH_RELATE_EVENT_LIST_REQUEST';
export const FETCH_RELATE_EVENT_LIST_SUCCESS = 'FETCH_RELATE_EVENT_LIST_SUCCESS';
export const REMOVE_RELATE_EVENT_LIST = 'REMOVE_RELATE_EVENT_LIST';
export const SET_FOLLOW_PRESIDENT = 'SET_FOLLOW_PRESIDENT';

export function requestRelateEventList(presidentId, eventId, page) {
  return { type: FETCH_RELATE_EVENT_LIST_REQUEST, presidentId, eventId, page };
}

export function receiveRelateEventList(eventList) {
  return { type: FETCH_RELATE_EVENT_LIST_SUCCESS, eventList };
}

export function removeRelateEventList() {
  return { type: REMOVE_RELATE_EVENT_LIST };
}

export function setFollowPresident(presidentId, action) {
  return {
    type: SET_FOLLOW_PRESIDENT,
    presidentId,
    action
  };
}