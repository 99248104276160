/* eslint-disable semi */
/* eslint-disable space-before-function-paren */
import {
  LOGIN_FIREBASE_SUCCESS,
  LOGOUT_FIREBASE_SUCCESS,
  UNREAD_MESSAGE_UPDATE,
  CHAT_GROUP_PREVIEW_UPDATE,
  CHAT_CONVERSATION_UPDATE,
  STOP_CHAT_CONVERSATION_UPDATE,
  LOADING_MORE_CHAT_CONVERSATION,
  START_CHAT_CONVERSATION_LISTENING,
  SET_CONVERSATION_DATA
} from './constants'

export function loginFirebaseSuccess () {
  return {
    type: LOGIN_FIREBASE_SUCCESS
  }
}

export function updateMessageUnread (data) {
  return {
    type: UNREAD_MESSAGE_UPDATE,
    data
  }
}

export function updateChatGroupPreview (data) {
  return {
    type: CHAT_GROUP_PREVIEW_UPDATE,
    data
  }
}

export function setConversationData (data) {
  return {
    type: SET_CONVERSATION_DATA,
    data
  }
}
export function updateConversation (data) {
  return {
    type: CHAT_CONVERSATION_UPDATE,
    data
  }
}
export function loadingMoreChatConversation (data) {
  return {
    type: LOADING_MORE_CHAT_CONVERSATION,
    data
  }
}

export function startListeningConversation () {
  return {
    type: START_CHAT_CONVERSATION_LISTENING
  }
}

export function stopListenConversationWithOperator () {
  return {
    type: STOP_CHAT_CONVERSATION_UPDATE
  }
}

export function logOutFirebaseSuccess () {
  return {
    type: LOGOUT_FIREBASE_SUCCESS
  }
}
