import { request } from './../../utilities/Api';
import { put, call, takeEvery } from 'redux-saga/effects';
import { 
  GET_PLANS_REQUEST,
  getPlansRequestFail,
  getPlansRequestSuccess
} from './actions';

function* handleGetPlans({ meta, payload }) {
  try {
    const data = yield call(request, 'functions/presidentGetPlans', { page: 1, limit: 100 }, 'POST');
    yield put(getPlansRequestSuccess(data.result.data, meta));
  } catch (error) {
    yield put(getPlansRequestFail({ error: error.error || error.message}, meta));
  }
}

export function* watchPlans() {
  yield takeEvery(GET_PLANS_REQUEST, handleGetPlans );
}