import { request } from './../../utilities/Api';
import { put, call, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import {
  receiveRelateEventList,
  FETCH_RELATE_EVENT_LIST_REQUEST
} from './actions';
import { setCommonPopup } from 'containers/Popup/actions';
import { replace } from 'connected-react-router';
function* getRelateEventList(action) {
  try {
    const data = yield call(request, 'functions/getRelateEventList', {
      presidentId: action.presidentId,
      limit: 10,
      page: action.page,
      eventId: action.eventId
    }, 'POST');
    yield put(receiveRelateEventList(data.result.eventList));
  } catch (error) {
    if (error && error.code === 8007) {
      yield put(setCommonPopup('success', {
        message: error.error, 
        OKcallback: () => window.store.dispatch(setCommonPopup('success', false)),
        onHideCallback: () => window.store.dispatch(setCommonPopup('success', false))
      }));
    } else {
      const page = yield select(state => state.get('eventListOfPresident').get('page'));
      page === 1 && (yield put(replace('/404-not-found')));
    }
  }
}

export default function* watchRelateEventList() {
  yield takeFirst(FETCH_RELATE_EVENT_LIST_REQUEST, getRelateEventList);
}