import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  initializeGochiEvent,
  acceptGochiRequest,
  getStudentAvailableSlotsRequest,
  getFollowerStudentListRequest,
  updateGochiStatusRequest
} from 'containers/GochiEvent/actions';
import {
  resetManagementList,
  getOfferEventListRequest,
  changeOfferStatusRequest
} from '../EventList/actions';
import FollowerCell from './FollowerCell';
import ListHeader from './ListHeader';
import { setCommonPopup } from 'containers/Popup/actions';
import {
  ID_ALL_COLLEGES,
  GOCHI_FILTER_OPTIONS,
  OFFER_FILTER_OPTIONS
} from 'utilities/constants';
import './style.scss';
import ReactPaginate from 'react-paginate';
import { Pagination } from 'react-bootstrap';

const filterOptions = {
  '/management-student': GOCHI_FILTER_OPTIONS,
  '/management-student/offer': OFFER_FILTER_OPTIONS
};

class FollowList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerFilter: [],
      gochiFilter: []
    };
    this.handleAcceptGochiRequest = this.handleAcceptGochiRequest.bind(this);
  }

  // Load more when scrolling to bottom
  loadMore = () => {
    if (
      Math.ceil(window.innerHeight + window.scrollY + 1) >=
      document.body.offsetHeight
    ) {
      const { location, gochiManagement } = this.props;

      if (
        gochiManagement.hasMore &&
        location.pathname === '/management-student'
      ) {
        this.props.getFollowerList({
          page: gochiManagement.page + 1,
          status: this.state.gochiFilter.length
            ? this.state.gochiFilter
            : undefined
        });
      }
    }
  };

  fetchData = () => {
    const { location } = this.props;
    if (location.pathname === '/management-student') {
      this.setState({
        gochiFilter: []
      });
      this.props.getFollowerList({
        page: 1
      });
    }

    if (location.pathname === '/management-student/offer') {
      this.setState({
        offerFilter: []
      });
      this.props.getOfferEventListRequest();
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.loadMore);
    this.fetchData();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const prevLocation = prevProps.location;
    if (location.pathname !== prevLocation.pathname) {
      this.fetchData();
    }
  }

  handleGetFilter = (value) => {
    const cleanedObject = _.pickBy(value, (v) => v || v !== undefined);
    const university = cleanedObject.university
      .map((item) => item.objectId)
      .join(',');

    this.setState({
      studentIds: [],
      selectAll: false,
      filter: {
        ...cleanedObject,
        university: university !== ID_ALL_COLLEGES ? university : undefined
      }
    });
  };

  async handleAcceptGochiRequest(gochiId, studentId) {
    const { hasSubcrised, availableSlots, offerEventId } =
      await this.props.getStudentAvailableSlots(studentId);
    if (!hasSubcrised) {
      this.props.openConfirmPopup({
        title: 'この機能はオファー機能ご契約企業のみ利用可能です。',
        isAlert: true,
        btnConfirm: 'OK'
      });
      return;
    }
    if (!offerEventId && availableSlots === 0) {
      this.props.openConfirmPopup({
        title: `オファー枠を確認してください。\n選択した学生： 1\n残りオファー枠： ${availableSlots}`,
        isAlert: true,
        btnConfirm: 'OK'
      });
      return;
    }
    if (offerEventId) {
      this.props.openConfirmPopup({
        title:
          'この学生とは既にオファー成立しているため\nオファー枠を消費せずこのまま\nメッセージ画面に遷移します。',
        isAlert: true,
        btnConfirm: 'OK',
        onConfirm: () => {
          this.props.acceptGochiRequest(gochiId, studentId).then(() => {
            this.props.history.push({
              pathname: `/messages/${offerEventId}`,
              state: {
                eventId: offerEventId
              }
            });
          });
        }
      });
      return;
    }
    this.props.openConfirmPopup({
      title: `オファー枠を消費して学生とメッセージを開始することができます。\n残りオファー枠： ${availableSlots}\n実行しますか？`,
      btnConfirm: 'はい',
      btnCancel: 'いいえ',
      onConfirm: () => {
        this.props
          .acceptGochiRequest(gochiId, studentId)
          .then(({ conversationId }) => {
            this.props.history.push({
              pathname: `/messages/${conversationId}`,
              state: {
                eventId: conversationId
              }
            });
          });
      }
    });
  }

  render() {
    const { location, offerEvents, gochiManagement } = this.props;
    let studentList = null;
    /**
     * @property {string} status Use for changing content under info & avatar component
     */

    if (location.pathname === '/management-student') {
      const { list, data } = gochiManagement;
      studentList = !_.isEmpty(list) ? (
        list.map((studentId) => (
          <FollowerCell
            key={studentId}
            userInfo={data[studentId]}
            status="follower"
            acceptGochiRequest={() =>
              this.handleAcceptGochiRequest(data[studentId]._id, studentId)
            }
            onChangeOfferStatus={(value) => {
              this.props.updateGochiStatus({
                gochiId: data[studentId]._id,
                status: value,
                studentId
              });
            }}
          />
        ))
      ) : (
        <div className="empty-offer">該当する学生はいませんでした。</div>
      );
    }

    if (location.pathname === '/management-student/offer') {
      const list = offerEvents.list;
      studentList = !_.isEmpty(list) ? (
        list.map((event) => {
          return (
            <FollowerCell
              key={event.objectId}
              userInfo={event.studentRequests[0]}
              status="offer"
              offerEventStatus={event.offerEventStatus}
              onChangeOfferStatus={(value) => {
                this.props
                  .changeOfferStatus({
                    eventId: event.objectId,
                    newStatus: value
                  })
                  .then(() => {
                    this.props.getOfferEventListRequest({
                      statuses: this.state.offerFilter,
                      page: offerEvents.page
                    });
                  });
              }}
              eventId={event.objectId}
            />
          );
        })
      ) : (
        <div className="empty-offer">該当する学生はいませんでした。</div>
      );
    }
    return (
      <div className="student-management-follow-list-container">
        <div className="student-management-follow-list">
          <ListHeader
            offerFilter={location.pathname === '/management-student'
              ? this.state.gochiFilter : this.state.offerFilter}
            filterOptions={filterOptions[location.pathname]}
            onCheck={(e) => {
              let newFilter =
                location.pathname === '/management-student'
                  ? this.state.gochiFilter
                  : this.state.offerFilter;
              if (e.target.checked) {
                newFilter = [...newFilter, e.target.value];
              } else {
                newFilter = newFilter.filter(
                  (option) => option !== e.target.value
                );
              }
              if (location.pathname === '/management-student') {
                this.setState({
                  gochiFilter: newFilter
                });
                this.props.getFollowerList({
                  status: newFilter.length ? newFilter : undefined,
                  page: 1
                });
              }
              if (location.pathname === '/management-student/offer') {
                this.setState({
                  offerFilter: newFilter
                });
                this.props.getOfferEventListRequest({ statuses: newFilter });
              }
            }}
          />
          {studentList}
        </div>

        {location.pathname === '/management-student/offer' && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactPaginate
              previousLabel={<Pagination.Prev />}
              nextLabel={<Pagination.Next />}
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={this.props.offerEvents.totalPages}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              onPageChange={(data) => {
                const page = data.selected + 1;
                this.props.getOfferEventListRequest({ page });
                window.scrollTo(0, 0);
              }}
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          </div>
        )}
      </div>
    );
  }
}

FollowList.propTypes = {
  resetFollowerList: PropTypes.func,
  getFollowerList: PropTypes.func,
  acceptGochiRequest: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  getOfferEventListRequest: PropTypes.func,
  resetManagementList: PropTypes.func,
  currentUser: PropTypes.object,
  offerEvents: PropTypes.object,
  changeOfferStatus: PropTypes.func,
  getStudentAvailableSlots: PropTypes.func,
  openConfirmPopup: PropTypes.func,
  updateGochiStatus: PropTypes.func,
  gochiManagement: PropTypes.object,
  totalPages: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.get('account').get('data'),
    gochiManagement: state.get('gochiEvent').get('gochiManagement'),
    offerEvents: state.get('studentManagement').get('offerEvents')
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFollowerList: (params) => dispatch(getFollowerStudentListRequest(params)),
  resetFollowerList: () => dispatch(initializeGochiEvent()),
  updateGochiStatus: ({ gochiId, status, studentId }) =>
    dispatch(updateGochiStatusRequest({ gochiId, status, studentId })),
  resetManagementList: () => dispatch(resetManagementList()),
  acceptGochiRequest: (gochiId, studentId) =>
    dispatch(acceptGochiRequest(gochiId, studentId)),
  getOfferEventListRequest: (params) =>
    dispatch(getOfferEventListRequest(params)),
  changeOfferStatus: ({ eventId, newStatus }) =>
    dispatch(changeOfferStatusRequest({ eventId, newStatus })),
  getStudentAvailableSlots: (studentId) =>
    dispatch(getStudentAvailableSlotsRequest({ studentId })),
  openConfirmPopup: (show) => dispatch(setCommonPopup('confirm', show))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(FollowList));
