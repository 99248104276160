import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class StarRating extends React.Component {
	createStar = () => {
		let starArr = [];
		const rating = this.props.input.value;
		for (let i = 0; i < 5; i++) {
			starArr.push(
        <div 
          key={i} 
          className={`a-rating-star ${i + 1 > rating ? 'g-star' : 'y-star'} ${this.props.size}-size`} 
          onClick={() => { this.starClick(i) }}>
				</div>
			)
		}
		return starArr;
  }
  
	starClick = (index) => {
		this.props.input.onChange(index + 1);
  }

  componentDidUpdate() {
		if (this.props.disable) {
			this.props.input.onChange(0);
		}
		if (!this.props.disable && this.props.input.value === 0) {
			this.props.input.onChange(0);
		}
		if (!this.props.disable && this.props.input.value === 0 && this.props.isPresidentReview) {
			this.props.input.onChange(0);
		}
	}
	
	render() {
    const disable = this.props.disable;

		return (
			<div className="star-rating-container">
				{
					!disable ? this.createStar().reverse() : (
						<Fragment>
							<div className={`disable-star ${this.props.size}-size`}></div>
							<div className={`disable-star ${this.props.size}-size`}></div>
							<div className={`disable-star ${this.props.size}-size`}></div>
							<div className={`disable-star ${this.props.size}-size`}></div>
							<div className={`disable-star ${this.props.size}-size`}></div>
						</Fragment>
					)
				}
			</div>
		);
	}
}

StarRating.propTypes = {
	input: PropTypes.any.isRequired,
	disable: PropTypes.bool,
	size: PropTypes.string,
	isPresidentReview: PropTypes.bool
};

StarRating.defaultProps = {
	disable: false,
	size: 'normal'
}

export default (StarRating);