import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ElementRenderer from './ElementRenderer';

class Button extends Component {
  render() {
    return (
      <ElementRenderer 
        {...this.props} 
        render={({ input, type, disabled, innerText }) => (
          <div className="text-center">
            <button {...input} 
              type={type} 
              disabled={disabled} 
              className="btn btn-primary btn-fw-96"
            >
              {innerText}
            </button>
          </div>
        )}/>
    );
  }
}

Button.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  innerText: PropTypes.string
};

export default Button;