import { fromJS } from 'immutable';

const initState = fromJS({
  show: false
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function requestReason(state = initState, action) {
  switch (action.type) {
    case 'CLOSE_REQUEST_EVENT_REASON_POPUP': {
      let newState = state.set('show', false);
      return newState;
    }
    case 'OPEN_REQUEST_EVENT_REASON_POPUP': {
      let newState = state.set('show', true);
      return newState;
    }
    default:
      return state;
  }
}
