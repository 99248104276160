export const FETCH_EVENT_DETAIL_REQUEST = 'FETCH_EVENT_DETAIL_REQUEST';
export const FETCH_EVENT_DETAIL_SUCCESS = 'FETCH_EVENT_DETAIL_SUCCESS';
export const FETCH_EVENT_DETAIL_FAILURE = 'FETCH_EVENT_DETAIL_FAILURE';
export const UNSET_EVENT_DETAIL = 'UNSET_EVENT_DETAIL';

export function requestEventDetail(eventId = '', actionType = 'get_detail_for_page') {
  return {type: FETCH_EVENT_DETAIL_REQUEST, eventId, actionType};
}

export function receiveEventDetail(payload) {
  return {type: FETCH_EVENT_DETAIL_SUCCESS, payload};
}

export function resetEventDetail() {
  return {type: FETCH_EVENT_DETAIL_FAILURE};
}

export function unsetEventDetail() {
  return {type: UNSET_EVENT_DETAIL};
}