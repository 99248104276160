import { fromJS } from 'immutable';

import {
  GET_OFFER_EVENT_DETAIL_SUCCESS
} from './actions';

const initState = fromJS({
  detail: {},
  presidentDetail: {}
});

export default function offerRequest(state = initState, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_OFFER_EVENT_DETAIL_SUCCESS:
      return state
        .set('detail', payload.detail)
        .set('presidentDetail', payload.presidentDetail);
    default:
      return state;
  }
}