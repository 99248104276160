import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { getValueByKey } from 'containers/Popup/selectors';
import PropTypes from 'prop-types';
import { setCommonPopup } from 'containers/Popup/actions';
import { requestStickers } from 'containers/PopupGreeting/actions';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import './style.scss';
import { greeting } from './actions';
import _ from 'lodash';

class PopupGreeting extends Component {
  state = {
    selected: 'first'
  }

  componentDidMount() {
    _.isEmpty(this.props.stickers) && this.props.requestStickers();
  }

  render() {
    return (
      <Modal id={'greeting'} show={this.props.show} onHide={this.props.closePopupGreeting} className="msg-popup">
        <div className="popup-close" onClick={this.props.closePopupGreeting} />
        <div className="title text-center">
          ゴチ確定！社長にお礼の
          <br />
          スタンプを送りましょう
        </div>
        <div className="greeting">
          {
            this.props.stickers.map((e, i) => 
              <img 
                alt=""
                key={i} 
                src={e.url}
                className={ this.state.selected === e.name ? 'focus-border-color' : null }
                onClick={() => { this.setState({ selected: e.name }) }}
              />
            )
          }
        </div>
        <div className="popup-btn" onClick={() => this.props.greeting(this.state.selected, this.props.eventId)}>送信</div>
      </Modal>
    );
  }
}

PopupGreeting.propTypes = {
  show: PropTypes.bool,
  closePopupGreeting: PropTypes.func,
  list: PropTypes.array,
  greeting: PropTypes.func,
  eventId: PropTypes.string,
  requestStickers: PropTypes.func
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'greeting'),
    stickers: state.get('greeting').get('list') || []
  };
};

const mapDispatchToProps = dispatch => ({
  closePopupGreeting: () => dispatch(setCommonPopup('greeting', false)),
  greeting: (sticker, eventId) => dispatch(greeting(sticker, eventId)),
  requestStickers: () => dispatch(requestStickers())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PopupGreeting));