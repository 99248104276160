import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

const SwitchInput = ({ checked, onChange }) => {
  return (
    <div className="switch-input">
      <label className='switch' >
        <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
        <span className="slider round" />
      </label>
    </div>
  );
};

SwitchInput.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default SwitchInput;