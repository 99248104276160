import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ReviewCell from './ReviewCell';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { requestPresidentReviews, resetPresidentReviews } from './actions';
import './styles.scss';

class ReviewList extends Component {

  componentDidMount() {
    setTimeout(() => this.props.requestPresidentReviews(this.props.currentUser.objectId, 1), 0);
  }

  loadMoreReviews() {
    this.props.requestPresidentReviews(this.props.currentUser.objectId, this.props.page);
  }
  renderLoadmore() {
    if (this.props.page > 1 && this.props.hasMore) {
      return (
        <div
          className='review-container-loadmore' 
          onClick={this.loadMoreReviews.bind(this)}
        >
          もっと見る
        </div>
      );
    }
  }
  render() {
    if (_.isEmpty(this.props.list) && !this.props.hasMore) {
      return (
        <div className='review-container' style={this.props.style}>
          <div className='empty-list'>まだレビューがありません。</div>
        </div>
      );
    }

    return (
      <div className='review-container' style={this.props.style}>
        {
          this.props.list.map((review) => {
            return (
              <ReviewCell isShow={this.props.isShow} review={review} key={`${review._id}-${review._created_at}`} />
            );
          })
        }
        {this.renderLoadmore()}
      </div>
    );
  }
}

ReviewList.propTypes = {
  requestPresidentReviews: PropTypes.func,
  currentUser: PropTypes.object,
  list: PropTypes.array,
  page: PropTypes.number,
  hasMore: PropTypes.bool,
  resetPresidentReviews: PropTypes.func,
  isShow: PropTypes.any,
  style: PropTypes.any
};

const mapStateToProps = (state) => ({
  list: state.get('presidentReviews').get('list'),
  page: state.get('presidentReviews').get('page'),
  hasMore: state.get('presidentReviews').get('hasMore')
});

const mapDispatchToProps = (dispatch) => ({
  requestPresidentReviews: (presidentId, page) => dispatch(requestPresidentReviews(presidentId, page)),
  resetPresidentReviews: () => dispatch(resetPresidentReviews())
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withImmutablePropsToJS,
  withRouter
)(ReviewList);