import { request } from './../../utilities/Api';
import { put, call, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { receiveEventsByTag, FETCH_EVENT_BY_TAG_REQUEST } from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* getEvents(action) {
  try {
    yield put(showLoading());

    const hasMore = yield select(state => state.get('eventsByTag').get('hasMore'));   
    const page = yield select(state => state.get('eventsByTag').get('page'));   

    if (hasMore) {
      const data = yield call(request, 'functions/getEventsByTag', { limit: 24, page: page, tag: action.tag }, 'POST');
      yield put(receiveEventsByTag(data.result.eventList, page));
    }

    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}


export default function* watchEventsByTag() {
  yield takeFirst(FETCH_EVENT_BY_TAG_REQUEST, getEvents);
}