import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './style.scss';

const Tags = ({ tags, selectedTags, addItem, removeItem, isOrdered, className }) => {
  if (Array.isArray(tags)) {
    if (isOrdered) {
      selectedTags = _.filter(selectedTags, (n) => tags.includes(n));
      tags = _.uniq([...selectedTags, ...tags]);
    }
    return tags.map((tag, index) => {
      if (selectedTags.includes(tag)) {
        return (
          <span 
            key={index} 
            className={`meshi-tag selected-tag ${className}`} 
            onClick={() => removeItem(tag)}
          >
            {tag}
          </span>
        );
      } else {
        return (
          <span className={`meshi-tag ${className}`} key={index} onClick={() => addItem(tag)}>{tag}</span>
        );
      }
    });
  }
  return (
    <div className={`meshi-tag ${className}`}>
      {tags}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.any,
  selectedTags: PropTypes.array,
  addItem: PropTypes.func,
  removeItem: PropTypes.func,
  className: PropTypes.string,
  isOrdered: PropTypes.bool
};

Tags.defaultProps = {
  tags: [],
  selectedTags: [],
  addItem: () => {},
  removeItem: () => {},
  className: '',
  isOrdered: false
};

export default Tags;