import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { 
  closeCancelRequestEventPopUp,
  CANCEL_REQUEST_JOIN_EVENT_REQUEST
} from './actions';
import { requestEventDetail } from 'containers/EventDetail/actions';

function* cancelRequest(action) {
  const eventId = action.payload.eventId;
  try {
    yield call(request, 'functions/cancelRequestJoinEvent', { eventId }, 'POST');
  } catch (e) {
    console.log(e.message);
  }
  yield put(closeCancelRequestEventPopUp());
  yield put(requestEventDetail(eventId));
}

export default function* watchCancelRequestJoin() {
  yield takeEvery(CANCEL_REQUEST_JOIN_EVENT_REQUEST, cancelRequest);
}

 