import { request } from './../../utilities/Api';
import { put, call, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { receiveEventsByDate, requestEventsByDateFail, FETCH_EVENT_BY_DATE_REQUEST } from './actions';
import { getEventsByDate } from './selectors';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* getEvents(action) {
  try {
    const oldState = yield select(getEventsByDate);
    let page = oldState.get('page');
    const hasMore = oldState.get('hasMore');
    if (!hasMore) {
      yield put(requestEventsByDateFail({hasMore}));
      return;
    }

    yield put(showLoading());
    if (action.more) {
      ++page;
    } else {
      page = 1;
    }
    const data = yield call(request, 'functions/getEventList', {
      limit: 12, 
      page: page, 
      orderBy: 'eventDate', 
      orderDirection: 'asc'
    }, 'POST');
    yield put(receiveEventsByDate(data.result.eventList, page));
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}


export default function* watchEventsByDate() {
  yield takeFirst(FETCH_EVENT_BY_DATE_REQUEST, getEvents);
}