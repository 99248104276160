import { REQUEST_EVENTS_BY_BANNER, receiveGetEventByBanner, receiveGetEventByBannerFail } from './action';
import { request } from './../../utilities/Api';
import { put, call } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* getBannerEvents(action) {
  try {
    yield put(showLoading());
    const data = yield call(request, 'functions/getEventsByIds', {events: action.events}, 'POST');
    yield put(receiveGetEventByBanner(data.result));
    yield put(hideLoading());
  } catch (error) {
    yield put(receiveGetEventByBannerFail());
    yield put(hideLoading());
  }
}
export default function* watchEventByBanner() {
  yield takeFirst(REQUEST_EVENTS_BY_BANNER, getBannerEvents);
}