import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import {
  CREATE_EVENT_REQUEST,
  createEventSuccess,
  createEventFail
} from './action';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { setCommonPopup } from 'containers/Popup/actions';
import { destroy } from 'redux-form';

function* handleCreateEvent(action) {
  try {
    yield put(showLoading());
    const eventInfo = action.payload.eventInfo || {};
    eventInfo.graduateYear =
      eventInfo.graduateYear && eventInfo.graduateYear.objectId;
    eventInfo.image = eventInfo.image && eventInfo.image.objectId;
    eventInfo.prefecture =
      eventInfo.prefecture && eventInfo.prefecture.objectId;
    const data = yield call(
      request,
      'functions/createEvent',
      eventInfo,
      'POST'
    );

    yield put(createEventSuccess(data, action.meta));
    yield put(
      setCommonPopup('createEvent', {
        status: 'success',
        message: '募集を開始しました！\n参加リクエストをお待ち下さい'
      })
    );
    yield put(destroy('createEvent'));
    yield put(hideLoading());
  } catch (e) {
    if (e /*&& e.error*/) {
      yield put(createEventFail(e, action.meta));
      yield put(
        setCommonPopup('createEvent', {
          status: 'error',
          message:
            e.error || '接続エラー、インターネット接続を確認してください。'
        })
      );
      yield put(hideLoading());
    }
  }
}

export function* watchCreateEvent() {
  yield takeEvery(CREATE_EVENT_REQUEST, handleCreateEvent);
}
