import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Field } from 'redux-form/immutable';
import get from 'lodash/get';
import InputPhoneNumber from '../../../components/Form/InputPhoneNumber';
import '../styles.scss';

const NumberPhoneVerification = ({
  valid,
  onSubmitPhoneNumber
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);
    
  useEffect(() => {
    if (!isLoading) {
      const infoAccount = 
        JSON.parse(window.sessionStorage.getItem('account_login')) || 
        JSON.parse(window.sessionStorage.getItem('account_register'));

      setTimeout(() => {
        if (document.getElementById('number-phone-sms')) {
          document.getElementById('number-phone-sms').focus();
          if (get(infoAccount, `${[4]}`))
            document.getElementById('number-phone-sms').value = atob(get(infoAccount, `${[4]}`));
        }
      }, 250);
    }
  }, [isLoading]);

  return (
    <>
      <h1>電話番号入力</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const form = new FormData(e.target);
          const formula = form.get('numberPhone');
          onSubmitPhoneNumber && onSubmitPhoneNumber(formula);
        }}
      >
        <div className="field-container">
          <Field
            component={InputPhoneNumber}
            className="input-form"
            name="numberPhone"
            placeholder="電話番号を入力してください。"
            autocomplete="off"
            id="number-phone-sms"
            maxLength={14}
          />
        </div>

        <Button
          type="submit"
          disabled={!valid}
          className={['btn-submit', valid ? 'btn-active' : ''].join(' ').trim()}
        >
          認証コードを受信する
        </Button>
      </form>
    </>
  );
};

NumberPhoneVerification.propTypes = {
  valid: PropTypes.bool,
  onSubmitPhoneNumber: PropTypes.func
};

export default NumberPhoneVerification;
