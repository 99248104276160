import { openAbsenceFromNotification } from '../containers/AbsenceWarning/action';
const webPush = {};

function newNotification(option = {}) {
  let linkTo = option.linkTo || '';
  linkTo = option.linkTo.split(':');
  const newNotic = new Notification(option.title, option);

  if (linkTo) {
    switch (linkTo[0]) {
      case 'MESSAGE_EVENT': newNotic.onclick = () => {window.ReactHistory.push(`/messages/${linkTo[1]}`);}; break;
      case 'EVENT': newNotic.onclick = () => {window.ReactHistory.push(`/event-detail/${linkTo[1]}`);}; break;
      case 'POPUP_ABSENCE_WARNING':
        newNotic.onclick = () => {window.store.dispatch(openAbsenceFromNotification(option));}; break;
      default: break;
    }
  }
}

function createNotification(option) {
  // first let's check browser support
  if (!('Notification' in window)) {
    return;
  }
  switch (Notification.permission) {
    case 'granted': {
      return newNotification(option);
    }
    case 'denied': {
      break;
    }
    case 'default': {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          return newNotification(option);
        }
      });
      break;
    }
    default: break;
  }
}

webPush.push = (parseNotificationObj = {}) => {
  const { message, iconUrl, linkTo, eventName, eventDate, eventId } = parseNotificationObj;
  const option = {
    title: message,
    icon: iconUrl,
    vibrate: [50, 100, 150],
    closeTimeout: 10000,
    linkTo,
    eventName,
    eventDate,
    eventId
  };

  createNotification(option);
};

webPush.requestPermission = () => {
  if ('Notification' in window) {
    Notification.requestPermission();
  }
};

export default webPush;