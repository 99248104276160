export const FETCH_BANNER_REQUEST = 'FETCH_BANNER_REQUEST';
export const FETCH_BANNER_SUCCESS = 'FETCH_BANNER_SUCCESS';
export const FETCH_BANNER_FAIL = 'FETCH_BANNER_FAIL';

export const fetchBannerRequest = () => {
  return { type: FETCH_BANNER_REQUEST };
};

export const fetchBannerSuccess = (data) => {
  return { 
    type: FETCH_BANNER_SUCCESS,
    banners: data
  };
};

export const fetchBannerFail = () => {
  return { type: FETCH_BANNER_FAIL };
};