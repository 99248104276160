import { put, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { 
  destroyAllFormSuccess,
  DESTROY_ALL_FORM_REQUEST
} from './action';
import { destroy } from 'redux-form';
import { getForms } from './selector';

function* destroyAllForm() {
  try {
    let forms = yield select(getForms);
    forms = forms.toJS ? forms.toJS() : forms;
    yield put(destroy(...Object.keys(forms)));
    yield put(destroyAllFormSuccess());
  } catch (error) {
    console.log(error);
  }
}

export function* watchDestroyAllForm() {
  yield takeFirst(DESTROY_ALL_FORM_REQUEST, destroyAllForm);
}