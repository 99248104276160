import { fromJS } from 'immutable';
import {
  GET_USER_LIST_TO_REVIEW_SUCCESS,
  RESET_REVIEW,
  STUDENT_DONE_REVIEW,
  REVIEW_STUDENTS_SUCCESS,
  RESET_REVIEW_INFORMATION,
  OPEN_REVIEW_SELECTED_STUDENT,
  CLOSE_REVIEW_SELECTED_STUDENT
} from './action';
import _ from 'lodash';

const initState = fromJS({
  hasReviewingEvent: false,
  eventId: '',
  reviewStudents: [],
  president: {},
  isSingle: false,
  showAbsencePopup: false,
  haveAbsence: false,
  reviewSuccess: false,
  locationBeforeReview: '/',
  selectedStudent: {}
});

const initReviewInformation = fromJS({
  eventId: '',
  reviewStudents: [],
  president: {},
  showAbsencePopup: false,
  haveAbsence: false,
  reviewSuccess: false,
  selectedStudent: {}
});

export default function message(state = initState, action) {
  switch (action.type) {
    case GET_USER_LIST_TO_REVIEW_SUCCESS: {
      const { eventId, result, hasReviewingEvent, isSingle, locationBeforeReview } = action;

      if (_.has(result, 'president')) {
        return state
          .set('hasReviewingEvent', hasReviewingEvent)
          .set('eventId', eventId)
          .set('isSingle', isSingle)
          .set('locationBeforeReview', locationBeforeReview)
          .set('president', result.president);
      } 

      if (_.has(result, 'students')) {
        const reviewStudents = result.students.map(e => fromJS({
          userId: e.objectId,
          star: 0,
          attendance: 'ATTENDANCE',
          avatarImage: e.avatarImage,
          displayName: e.displayName,
          birthday: e.birthday
        }));
        return state
          .set('hasReviewingEvent', hasReviewingEvent)
          .set('eventId', eventId)
          .set('isSingle', isSingle)
          .set('locationBeforeReview', locationBeforeReview)
          .set('reviewStudents', reviewStudents);
      }

      return state;
    }

    case REVIEW_STUDENTS_SUCCESS: {
      const { values: { reviewStudents } } = action;
      const absentStudent = _.find(reviewStudents, ['attendance', 'ABSENT']);
      return state
        .set('haveAbsence', absentStudent ? true : false)
        .set('reviewSuccess', true);
    }
    
    case STUDENT_DONE_REVIEW: {
      return state.set('reviewSuccess', true);
    }

    case OPEN_REVIEW_SELECTED_STUDENT:
      return state.set('selectedStudent', action.payload);
    case CLOSE_REVIEW_SELECTED_STUDENT:
      return state.set('selectedStudent', {});
    case RESET_REVIEW_INFORMATION: 
      return initReviewInformation;
    case RESET_REVIEW:
      return initState;
    default:
      return state;
  }
}