import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

class FirstTutorial extends Component {
  render() {
    return (
      <div id="signup-page-container">
        <div className="row">
          <div className="back-icon" onClick={this.props.history.goBack}>
            <img alt="" src="images/back.svg" /><span>戻る</span>
          </div>
          <section id="left-col-container" className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div id="left-col">
              <div className='tutorial-title'>アカウント登録の流れ</div>
              <div className='tutorial-img'>
                <img alt='tutorial' src='images/first-tutorial.svg'/>
              </div>
              <div className='tutorial-button-container'>
                <Link className='btn tutorial-button-content' to='/signUpPresident'>
                  次へ
                </Link>
              </div>
            </div>
          </section>
          <section
            id="right-col-container"
            style={{ backgroundImage: "url('images/background-login.png')" }}
            className="col-lg-5 hidden-md hidden-sm hidden-xs"
          >
            <div id="right-col">
              <img alt="welcome-img" src="images/logo-login.svg" className="logo" />
              <p className="meshi-slogan">
                就活の場を
                <br />
                「デスク」から「テーブル」へ
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

FirstTutorial.propTypes = {
  history: PropTypes.object
};

export default FirstTutorial;