import React, { Component } from 'react';
import { compose } from 'redux';
import './style.css';
// import MenuLeft from './../../components/MenuLeft';
import MenuRight from './../../components/MenuRight';
import MainMenu from '../MainMenu';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { setMenu } from './actions';
import { getValueByKey } from './selectors';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setCommonPopup } from './../Popup/actions';
import LogoutPopup from './../LogoutPopup';
import ApprovalNotification from './../ApprovalNotification';
import WelcomePresident from './../WelcomePresident';
import StudentSurvey from './../StudentSurvey';
import Notice from './../Notice';
import NotificationListForMenu from 'containers/Notification/NotificationListForMenu';
import NavigationBar from './../NavigationBar';
import LoginRoleSelectPopUp from './../Login/LoginRoleSelect';
import SignUpRoleSelectPopUp from './../SignUp/SignUpRoleSelect';
import StudentUpdateUniversity from './../StudentUpdateUniversity';


class Header extends Component {

  constructor(props) {
    super(props);
    this.handleHide = this.handleHide.bind(this);
    this.afterLogout = this.afterLogout.bind(this);
  }

  handleHide() {
    this.setState({ show: false });
  }

  afterLogout() {
    this.props.history.push('/');
  }

  icons() {
    if (this.props.currentUser) {
      return (
        <React.Fragment>
          <LogoutPopup />
          {/* <span className="navbar-brand pull-right link-menu" onClick={this.props.openLogout}>
            <i className="fa fa-sign-out"></i>
          </span>
          <span className="navbar-brand pull-right link-menu">
            <PrivateLink handleAction={() => this.props.controlNotificationMenu(1)}>
              <i className="fa fa-bell"></i>
            </PrivateLink>
          </span> */}
          <ApprovalNotification />
          <WelcomePresident />
          <StudentSurvey />
          <StudentUpdateUniversity/>
          <Notice />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {/* <span className="navbar-brand pull-right link-menu" onClick={() => this.props.controlUserMenu(1)}>
        <i className="fa fa-user"></i>
      </span> */}
      </React.Fragment>
    );
  }

  getBundles = () => {
    return (
      <React.Fragment>
        <LoginRoleSelectPopUp></LoginRoleSelectPopUp>
        <SignUpRoleSelectPopUp></SignUpRoleSelectPopUp>
        
        {this.icons()}

      </React.Fragment>
    );
  }

  render() {
    if (this.props.isLogoOnly) {
      return (
        <header>
          <NavigationBar isLogoOnly />
        </header>
      );
    }
    return (
      <header>
        {/* <MenuLeft active={this.props.userMenu} action={this.props.controlUserMenu}>
          <MainMenu />
        </MenuLeft> */}

        <NavigationBar></NavigationBar>
        {this.getBundles()}
        {/* <nav className="navbar main-nav">
          <div className="container-fluid">
            <div className="navbar-header text-center">
              <Link to="/" className="navbato=""r-brand link-menu mid-button">
                社長メシ
              </Link>
              <span className="navbar-brand link-menu" onClick={() => this.props.controlMainMenu(1)}>
                <i className="fa fa-bars"></i>
              </span>
              {this.icons()}
            </div>
          </div>
        </nav> */}
        {/* <TriggerLogout afterLogout={this.afterLogout} /> */}

        <MenuRight active={this.props.mainMenu} action={this.props.controlMainMenu} id="menu-right-main">
          {/* <UserMenu /> */}
          <MainMenu />
        </MenuRight>
        <div id="notification-menu-right">
          <MenuRight 
            active={this.props.notificationMenu} 
            action={this.props.controlNotificationMenu} 
            storeWatch="getNoticeList" 
            scrollableArea=".noti-list-display" 
            id="menu-right-notic"
          >
            <NotificationListForMenu />
          </MenuRight>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  mainMenu: PropTypes.number,
  userMenu: PropTypes.number,
  notificationMenu: PropTypes.number,
  currentUser: PropTypes.any,
  controlMainMenu: PropTypes.func,
  controlUserMenu: PropTypes.func,
  controlNotificationMenu: PropTypes.func,
  signOut: PropTypes.func,
  history: PropTypes.object,
  openLogout: PropTypes.func,
  isLogoOnly: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    mainMenu: getValueByKey(state, 'mainMenu'),
    userMenu: getValueByKey(state, 'userMenu'),
    notificationMenu: getValueByKey(state, 'notificationMenu'),
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  controlMainMenu: (open) => dispatch(setMenu('mainMenu', open)),
  controlUserMenu: (open) => dispatch(setMenu('userMenu', open)),
  controlNotificationMenu: (open) => dispatch(setMenu('notificationMenu', open)),
  openLogout: () => dispatch(setCommonPopup('logout', true))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(Header);