/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SMSVerification from '../../components/SMSVerification';
import { loginRequest } from '../Login/actions';
import { getCurrentAccount } from 'containers/Account/actions';
import { sendSmsNumberPhoneRequest, checkOtpRequest } from '../../components/SMSVerification/actions';
import { getCurrentNationSelect } from 'utilities/handleValues';

const LoginSMSVerification = ({
  onLogin,
  onSendSmsNumberPhone,
  onSubmitOtp,
  getCurrentAccount,
  ...props
}) => {
  const infoAccount = JSON.parse(window.sessionStorage.getItem('account_login')) || {};
  const info3rdAccount = JSON.parse(window.sessionStorage.getItem('account_login_3rd')) || {};
  const [isCheckedPhone, setCheckedPhone] = useState(false);
  const [currentNumberPhone, setCurrentNumber] = useState(null);

  const handleCheckPhoneNumber = (value) => {
    onSendSmsNumberPhone({
      phone: `+${getCurrentNationSelect()}${value}`
    }, (res) => {
      if (res.statusCode === 200) {
        setCheckedPhone(true);
        setCurrentNumber(`+${getCurrentNationSelect()}${value}`);
      } else setCheckedPhone(false);
    });
  };
  
  const handleSubmitOtp = (value, callback, errorCallback) => {
    if (value && currentNumberPhone) {
      onSubmitOtp({
        code: value,
        phone: currentNumberPhone
      }, (res) => {
        if (res.statusCode === 200) {
          callback(true);
        } else {
          errorCallback();
        }
      }, () => errorCallback());
    }
  };

  const handleSubmitConfirmOtp = () => {
    // Parse info login 3rd account to send request api
    if (!isEmpty(info3rdAccount)) {
      // Get Current User
      getCurrentAccount(get(info3rdAccount, `${[1]}`));
    } else {
      // Parse info login account to send request api
      const values = get(infoAccount, `${[0]}`) && get(infoAccount, `${[1]}`) && {
        email: atob(get(infoAccount, `${[0]}`)),
        password: atob(get(infoAccount, `${[1]}`))
      };

      const role = atob(get(infoAccount, `${[2]}`));

      if (!isEmpty(values) && !isEmpty(role)) onLogin(values, role);
    }
  };
  
  return (
    <>
      <SMSVerification
        {...props}
        infoAccount={isEmpty(infoAccount) ? info3rdAccount : infoAccount}
        isCheckedPhone={isCheckedPhone}
        onCheckPhoneNumber={handleCheckPhoneNumber}
        onSubmitOtp={handleSubmitOtp}
        onSubmitConfirmOtp={handleSubmitConfirmOtp}
      />
    </>
  );
};

LoginSMSVerification.protoType = {
  history: PropTypes.object,
  onLogin: PropTypes.func,
  onSendSmsNumberPhone: PropTypes.func,
  onSubmitOtp: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  onLogin: (value, role) => dispatch(loginRequest(value, role)),
  onSendSmsNumberPhone: (value, callback) => dispatch(sendSmsNumberPhoneRequest(value, callback)),
  onSubmitOtp: (value, callback, errorCallback) => dispatch(checkOtpRequest(value, callback, errorCallback)),
  getCurrentAccount: (token) => dispatch(getCurrentAccount(token))

});

export default compose(
  connect(null, mapDispatchToProps),
)(LoginSMSVerification);
