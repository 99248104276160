import { request } from 'utilities/Api';
import { takeEvery, call, put } from 'redux-saga/effects';
import { CHANGE_EMAIL_REQUEST, changeEmailSuccess, inChangeEmailRequest, changeEmailFailed } from './action';
import { stopSubmit, clearFields } from 'redux-form';

function* handleChangeEmailRequest(action) {
  try {
    yield put(inChangeEmailRequest());
    yield call(request, 'functions/requestChangeEmail', action.values, 'POST');
    yield put(changeEmailSuccess());
  } catch (err) {
    if (err.code === 143) {
      yield put(clearFields('emailChangeForm', true, true, ['password']));
      yield put(stopSubmit('emailChangeForm', {
        newEmail: 'change email failed',
        _error: err.code
      }));
      yield put(changeEmailFailed());
    } else if (err.code === 101) {
      yield put(stopSubmit('emailChangeForm', {
        password: 'wrong password',
        _error: err.code
      }));
      yield put(changeEmailFailed());
    }
  }
}

export default function* watchChangeEmail() {
  yield takeEvery(CHANGE_EMAIL_REQUEST, handleChangeEmailRequest);
}