export const SEND_JOIN_EVENT_REQUEST = 'SEND_JOIN_EVENT_REQUEST';
export const SEND_JOIN_EVENT_SUCCESS = 'SEND_JOIN_EVENT_SUCCESS';
export const SEND_JOIN_EVENT_FAILURE = 'SEND_JOIN_EVENT_FAILURE';
export const CLOSE_REQUEST_EVENT_REASON_POPUP = 'CLOSE_REQUEST_EVENT_REASON_POPUP';
export const OPEN_REQUEST_EVENT_REASON_POPUP = 'OPEN_REQUEST_EVENT_REASON_POPUP';
export const OPEN_REQUEST_EVENT_REASON_POPUP_REQUEST = 'OPEN_REQUEST_EVENT_REASON_POPUP_REQUEST';

export function sendJoinReason(payload) {
  return {type: SEND_JOIN_EVENT_REQUEST, payload};
}

export function sendJoinReasonSuccess(result) {
  return {type: SEND_JOIN_EVENT_SUCCESS, result: result};
}

export function closeRequestEventReasonPopUp() {
  return {type: CLOSE_REQUEST_EVENT_REASON_POPUP, payload: {}};
}

/* <!> do not use this to map to component state*/
export function openRequestEventReasonPopUp() {
  return {type: OPEN_REQUEST_EVENT_REASON_POPUP, payload: {}};
}

/* <i> use this to validate instead */
export function openRequestEventReasonPopUpRequest() {
  return {type: OPEN_REQUEST_EVENT_REASON_POPUP_REQUEST, payload: {}};
}