import { request } from 'utilities/Api';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import _ from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { 
  FETCH_NOTICE_LIST, 
  fetchNoticeListSuccess, 
  FETCH_UNREAD_NOTIFICATION, 
  fetchUnreadNotificationSuccess, 
  pushNewNotification 
} from './actions';
import { NEW_NOTIFICATION_COME } from 'utilities/LiveAdapter/modules/Notification/action';
function* fetchNoticeList(action) {
  try {
    if (!action.hasMore) {
      return;
    }
    if (action.loading) {
      yield put(showLoading());
    }
    const data = yield call(request, 'functions/getNotificationList', {
      limit: 13,
      page: action.page
    }, 'POST');
    if (action.update) {
      yield put(fetchNoticeListSuccess(data.result.notificationList));
    }
    yield* fetchUnreadNotification();
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    console.log(error);
  }
}
export function* watchGetNoticeList() {
  yield takeFirst(FETCH_NOTICE_LIST, fetchNoticeList);
}

/*Get Unread Notifications*/
function* fetchUnreadNotification() {
  try {
    const data = yield call(request, 'functions/getNotificationUnreadCount', {}, 'POST');
    yield put(fetchUnreadNotificationSuccess(data.result.unreadCount));
  } catch (error) {
    console.log(error);
  }
}
export function* watchGetUnreadNotificationList() {
  yield takeEvery(FETCH_UNREAD_NOTIFICATION, fetchUnreadNotification);
}

function* newNotificationCome(action) {
  try {
    const hasMore = yield select(state => state.get('getNoticeList').get('hasMore'));
    yield* fetchUnreadNotification();
    const noticeList = yield select(state => state.get('getNoticeList').get('noticeList'));
    if (_.isEmpty(noticeList.toJS ? noticeList.toJS() : noticeList) && hasMore) {
      return;
    } else yield put(pushNewNotification(action.notification));
  } catch (error) {
    console.log(error);
  }
}

export function* watchNewNotificationCome() {
  yield takeEvery(NEW_NOTIFICATION_COME, newNotificationCome);
}