import { fromJS } from 'immutable';
import {
  FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_SUCCESS,
  FETCH_DENIED_USERS_OF_EVENT_SUCCESS,
  FETCH_EVENT_REVIEWS_SUCCESS,
  RESET_MANAGEMENT_LIST,
  GET_STUDENTS_OF_EVENT_SUCCESS,
  GET_EVENTS_OF_STUDENT_SUCCESS,
  TOGGLE_EVENTS_OF_STUDENT_COLLAPSE,
  GET_OFFER_EVENT_LIST_SUCCESS
} from './actions';
import {
  STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE,
  STUDENT_MANAGEMENT_EVENT_PERPAGE
} from '../../../../utilities/constants';

const initState = fromJS({
  studentsOfEvent: {
    list: [],
    eventList: {},
    data: {},
    page: 1,
    hasMore: true
  },
  eventsOfStudent: {
    page: 1,
    hasMore: true,
    studentList: [],
    studentEvents: {}
  },
  offerEvents: {
    list: [],
    totalPages: 1,
    page: 1
  }
});

export default function studentManagement(state = initState, action) {
  switch (action.type) {
    case FETCH_DENIED_USERS_OF_EVENT_SUCCESS: {
      const { eventId, deniedUsers, page } = action;
      let list = [];

      if (deniedUsers.length === 0) {
        return state.setIn(['studentsOfEvent', 'eventList', eventId, 'hasMore'], false);
      }
      if (deniedUsers.length < STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE) {
        state = state.setIn(['studentsOfEvent', 'eventList', eventId, 'hasMore'], false);
      } else {
        state = state.setIn(['studentsOfEvent', 'eventList', eventId, 'hasMore'], true);
      }

      deniedUsers.forEach(student => {
        const studentId = student.objectId;
        list.push(studentId);
        state = state.setIn(['studentsOfEvent', 'data', eventId, studentId], student);
      });

      const curList = state.getIn(['studentsOfEvent', 'eventList', eventId, 'list']) || [];

      return state.setIn(['studentsOfEvent', 'eventList', eventId, 'list'], curList.concat(list))
        .setIn(['studentsOfEvent', 'eventList', eventId, 'page'], page + 1);
    }

    case FETCH_EVENT_REVIEWS_SUCCESS: {
      const { eventId, reviews, page } = action;
      let list = [];

      if (reviews.length === 0) {
        return state.setIn(['studentsOfEvent', 'eventList', eventId, 'hasMore'], false);
      }
      if (reviews.length < STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE) {
        state = state.setIn(['studentsOfEvent', 'eventList', eventId, 'hasMore'], false);
      } else {
        state = state.setIn(['studentsOfEvent', 'eventList', eventId, 'hasMore'], true);
      }

      reviews.forEach(review => {
        const reviewId = review._id;
        list.push(reviewId);
        state = state.setIn(['studentsOfEvent', 'data', eventId, reviewId], review);
      });

      const curList = state.getIn(['studentsOfEvent', 'eventList', eventId, 'list']) || [];

      return state.setIn(['studentsOfEvent', 'eventList', eventId, 'list'], curList.concat(list))
        .setIn(['studentsOfEvent', 'eventList', eventId, 'page'], page + 1);
    }

    case FETCH_HISTORY_EVENTS_HAVE_DENIED_USER_SUCCESS: {
      const { eventList } = action;
      const currentPage = state.getIn(['studentsOfEvent', 'page']) || 1;
      const currentList = state.getIn(['studentsOfEvent', 'list']) || [];

      if (eventList.length === 0) {
        return state.setIn(['studentsOfEvent', 'hasMore'], false);
      }
      state = state.setIn(['studentsOfEvent', 'hasMore'], eventList.length >= STUDENT_MANAGEMENT_EVENT_PERPAGE);

      return state
        .setIn(['studentsOfEvent', 'list'], currentList.concat(eventList))
        .setIn(['studentsOfEvent', 'page'], currentPage + 1);
    }

    case GET_STUDENTS_OF_EVENT_SUCCESS: {
      const { students = [] } = action.payload;
      const curList = state.getIn(['eventsOfStudent', 'studentList']) || [];
      const currentPage = state.getIn(['eventsOfStudent', 'page']) || 1;

      if (students.length === 0) {
        state = state.setIn(['eventsOfStudent', 'hasMore'], false);
      }

      students.forEach(student => {
        state = state.setIn(['eventsOfStudent', 'studentEvents', student._id], {
          page: 1, hasMore: true, events: [], isOpen: false
        });
      });

      state = state.setIn(['eventsOfStudent', 'hasMore'], students.length >= STUDENT_MANAGEMENT_EVENT_PERPAGE);

      return state
        .setIn(['eventsOfStudent', 'studentList'], curList.concat(students))
        .setIn(['eventsOfStudent', 'page'], currentPage + 1);
    }

    case GET_EVENTS_OF_STUDENT_SUCCESS: {
      const { studentId, events } = action.payload;

      const currentList = state.getIn(['eventsOfStudent', 'studentEvents', studentId, 'events']) || [];
      const currentPage = state.getIn(['eventsOfStudent', 'studentEvents', studentId, 'page']) || 1;

      state = state.setIn(
        ['eventsOfStudent', 'studentEvents', studentId, 'hasMore'],
        events.length >= STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE
      );

      return state
        .setIn(['eventsOfStudent', 'studentEvents', studentId, 'events'], currentList.concat(events))
        .setIn(['eventsOfStudent', 'studentEvents', studentId, 'page'], currentPage + 1);
    }

    case TOGGLE_EVENTS_OF_STUDENT_COLLAPSE: {
      const { studentId } = action.payload;
      const currentState = state.getIn(['eventsOfStudent', 'studentEvents', studentId, 'isOpen']);
      return state.setIn(['eventsOfStudent', 'studentEvents', studentId, 'isOpen'], !currentState);
    }

    case GET_OFFER_EVENT_LIST_SUCCESS: {
      return state
        .setIn(['offerEvents', 'list'], action.payload.eventList)
        .setIn(['offerEvents', 'totalPages'], action.payload.totalPages)
        .setIn(['offerEvents', 'page'], action.payload.currentPage);
    }

    case RESET_MANAGEMENT_LIST:
      return state
        .set('studentsOfEvent', fromJS({
          list: [],
          eventList: {},
          data: {},
          page: 1,
          hasMore: true
        }))
        .set('eventsOfStudent', fromJS({
          page: 1,
          hasMore: true,
          studentList: [],
          studentEvents: {}
        }))
        .set('offerEvents', {
          list: [],
          page: 1,
          totalPages: 1
        });

    default:
      return state;
  }
}