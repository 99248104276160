export const CREATE_OFFER_TEMPLATE_REQUEST = 'CREATE_OFFER_TEMPLATE_REQUEST';
export const CREATE_OFFER_TEMPLATE_SUCCESS = 'CREATE_OFFER_TEMPLATE_SUCCESS';
export const CREATE_OFFER_TEMPLATE_FAILURE = 'CREATE_OFFER_TEMPLATE_FAILURE';

export const FETCH_OFFER_TEMPLATE_REQUEST = 'FETCH_OFFER_TEMPLATE_REQUEST';
export const FETCH_OFFER_TEMPLATE_SUCCESS = 'FETCH_OFFER_TEMPLATE_SUCCESS';
export const FETCH_OFFER_TEMPLATE_FAILURE = 'FETCH_OFFER_TEMPLATE_FAILURE';

export const REMOVE_OFFER_TEMPLATE_REQUEST = 'REMOVE_OFFER_TEMPLATE_REQUEST';
export const REMOVE_OFFER_TEMPLATE_SUCCESS = 'REMOVE_OFFER_TEMPLATE_SUCCESS';
export const REMOVE_OFFER_TEMPLATE_FAILURE = 'REMOVE_OFFER_TEMPLATE_FAILURE';

export const CREATE_OFFER_EVENT_REQUEST = 'CREATE_OFFER_EVENT_REQUEST';
export const CREATE_OFFER_EVENT_SUCCESS = 'CREATE_OFFER_EVENT_SUCCESS';
export const CREATE_OFFER_EVENT_FAILURE = 'CREATE_OFFER_EVENT_FAILURE';

export const createOfferTemplateRequest = (payload) => ({
  type: CREATE_OFFER_TEMPLATE_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const createOfferTemplateSuccess = (payload, meta) => ({
  type: CREATE_OFFER_TEMPLATE_SUCCESS,
  payload,
  meta
});
export const createOfferTemplateFailure = (payload, meta) => ({
  type: CREATE_OFFER_TEMPLATE_FAILURE,
  payload,
  meta,
  error: true
});

export const fetchOfferTemplateRequest = () => ({
  type: FETCH_OFFER_TEMPLATE_REQUEST
});
export const fetchOfferTemplateSuccess = (payload) => ({
  type: FETCH_OFFER_TEMPLATE_SUCCESS,
  payload
});
export const fetchOfferTemplateFailure = () => ({
  type: FETCH_OFFER_TEMPLATE_FAILURE,
  error: true
});

export const removeOfferTemplateRequest = (payload) => ({
  type: REMOVE_OFFER_TEMPLATE_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const removeOfferTemplateSuccess = (payload, meta) => ({
  type: REMOVE_OFFER_TEMPLATE_SUCCESS,
  payload,
  meta
});
export const removeOfferTemplateFailure = (payload, meta) => ({
  type: REMOVE_OFFER_TEMPLATE_FAILURE,
  payload,
  meta,
  error: true
});

export const createOfferEventRequest = (payload) => ({
  type: CREATE_OFFER_EVENT_REQUEST,
  payload,
  meta: {
    thunk: true
  }
});
export const createOfferEventSuccess = (payload, meta) => ({
  type: CREATE_OFFER_EVENT_SUCCESS,
  payload,
  meta
});
export const createOfferEventFailure = (payload, meta) => ({
  type: CREATE_OFFER_EVENT_FAILURE,
  payload,
  meta,
  error: true
});