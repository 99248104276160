export const REQUEST_PRESIDENT_REVIEWS = 'REQUEST_PRESIDENT_REVIEWS';
export const RESET_PRESIDENT_REVIEWS = 'RESET_PRESIDENT_REVIEWS';
export const REQUEST_PRESIDENT_REVIEWS_SUCCESS = 'REQUEST_PRESIDENT_REVIEWS_SUCCESS';

export const requestPresidentReviews = (presidentId) => {
  return {
    type: REQUEST_PRESIDENT_REVIEWS,
    presidentId
  };
};

export const requestPresidentReviewsSuccess = (data, page) => {
  return {
    type: REQUEST_PRESIDENT_REVIEWS_SUCCESS,
    data,
    page
  };
};

export const resetPresidentReviews = () => {
  return {
    type: RESET_PRESIDENT_REVIEWS
  };
};