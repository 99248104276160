import { fromJS } from 'immutable';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { 
  FETCH_WAITING_STUDENT_OFFER_EVENT_SUCCESS, 
  RESET_WAITING_STUDENT_OFFER_EVENT
} from './actions';

import { OFFER_EVENT_STATUS_KEYS } from '../../utilities/constants';

let initState = fromJS({
  offerEvent: [],
  waitingEvent: [],
  accpectedEvent: [],
  rejectedEvent: []
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function newEvents(state = initState, action) {
  switch (action.type) {
    case FETCH_WAITING_STUDENT_OFFER_EVENT_SUCCESS: {
      const waitingEvent = filter(get(action, 'events', []), item => 
        OFFER_EVENT_STATUS_KEYS.WAITTING_FOR_ANSWER === item.offerEventStatus);
      const accpectedEvent = filter(get(action, 'events', []), item => 
        OFFER_EVENT_STATUS_KEYS.ACCEPTED_REQUEST === item.offerEventStatus);
      const rejectedEvent = filter(get(action, 'events', []), item => 
        OFFER_EVENT_STATUS_KEYS.REJECTED_REQUEST === item.offerEventStatus);
      const newData = fromJS({
        'offerEvent': action.events,
        'waitingEvent': waitingEvent,
        'accpectedEvent': accpectedEvent,
        'rejectedEvent': rejectedEvent
      });
      state = newData;
      return state;
    }
    case RESET_WAITING_STUDENT_OFFER_EVENT: {
      return initState;
    }
    default:
      return state;
  }
}