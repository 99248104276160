import React, { Component } from 'react';
import './style.scss';
import MessageList from '../MessageList';
import _ from 'lodash';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import MessageConversation from '../MessageConversation';
import { setMobileMessageView } from 'containers/Message/actions';
import { requestMessageList } from '../actions';
import { Redirect } from 'react-router-dom';
import MessageEmpty from '../MessageEmpty';
import { addTopMessageList } from 'containers/Message/actions';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { setCommonPopup } from 'containers/Popup/actions';

class MessagePage extends Component {
  constructor(props) {
    super(props);
    this.msgList = React.createRef();
  }

  handleScroll = () => {
    if (this.msgList.current.scrollTop + this.msgList.current.clientHeight >= this.msgList.current.scrollHeight) {
      this.props.requestMessageList();
    }
  }

  componentDidMount() {
    this.msgList.current && (
      (this.msgList.current.addEventListener('scroll', this.handleScroll)) ||
      (this.initedRef = true)
    );
    if (!window.isMobileDevice) {
      return;
    } else if (this.props.location.state && this.props.location.state.eventId) {
      this.props.turnToMessageView();
    } else if (this.props.location.state && this.props.location.state.chatWithOperatorId) {
      this.props.turnToMessageView();
    } else {
      this.props.returnToListView();
    }
  }

  componentWillUnmount() {
    this.props.returnToListView();
    this.msgList.current && this.msgList.current.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    const {location: {state}} = this.props;
    if (!this.initedRef && this.msgList.current) {
      this.msgList.current.addEventListener('scroll', this.handleScroll);
      this.initedRef = true;
    }
    if (!window.isMobileDevice) {
      return;
    }
    if (state !== undefined && (state.eventId || state.chatWithOperatorId)) {
      this.props.turnToMessageView();
    } else {
      this.props.returnToListView();
    }
  }

  renderMessageConversation = () => {
    const { mobileView, location, groupOperatorChatPreview } = this.props;
    const { state } = location;

    if (window.isMobileDevice && mobileView === 1) {
      return (
        <div id={'messenger-chat-container'} className='active'>
          <MessageConversation 
            eventId={(state && state.eventId)}
            chatWithOperatorId={state
              && !_.isEmpty(groupOperatorChatPreview)
              ? state.chatWithOperatorId
              : groupOperatorChatPreview.groupdId}/>
        </div>
      );
    } else if (!window.isMobileDevice) {
      return (
        <div id={'messenger-chat-container'}>
          <MessageConversation 
            eventId={(state && state.eventId)}
            chatWithOperatorId={state
              && !_.isEmpty(groupOperatorChatPreview)
              ? state.chatWithOperatorId
              : groupOperatorChatPreview.groupdId}/>
        </div>
      );
    }
  }

  render() {
    const user = this.props.currentUser;
    const offerEvent = _.get(this.props.event, 'offerEvent');
    if (user.role === 'STUDENT' && !user.isAcceptPolicy && offerEvent === false) {
      console.log(this.props.location);
      if (this.props.location.state && this.props.location.state.eventId && !this.props.location.state.acceptPolicy) {
        this.props.openAlertPopup();
      }
    }
    const {
      mobileView,
      location,
      match,
      messageList,
      groupOperatorChatPreview,
      unreadMessageFromOperator
    } = this.props;
    const { state } = location;
    const { eventId } = match.params;
    let msgStatusOperator = false;
    let thereIsMsgFromOperator = 0;
    if (unreadMessageFromOperator > 0) {
      msgStatusOperator = true;
      thereIsMsgFromOperator = 1;
    } else if (!_.isEmpty(groupOperatorChatPreview)) {
      thereIsMsgFromOperator = 1;
    }

    if (!state && eventId) return <Redirect to="/messages" />; // <!> prevent enter by link, fallback to below case
    
    if (!state && !eventId) {
      if (!window.isMobileDevice) { // <!> if at default messages screen => select first converstion(destop only)   
        if (!_.isEmpty(groupOperatorChatPreview)) {
          return <Redirect to={{
            pathname: `/messages/${groupOperatorChatPreview.groupId}`,
            state: {chatWithOperatorId: groupOperatorChatPreview.groupId}
          }}/>;
        } else if (messageList.length > 0) {
          return <Redirect to={{
            pathname: `/messages/${messageList[0]}`,
            state: {eventId: messageList[0]}
          }}/>;
        }
      }
    }
    
    if (state && state.eventId && !messageList.includes(state.eventId)) { // <!> con from event detail page
      this.props.addTopMessageList(state.eventId);
      this.props.turnToMessageView();
    }

    return (
      <div id="message-page">
        <div className="content">
          <div className="title">メッセージ</div>

          {((messageList.length + thereIsMsgFromOperator) > 0 || !_.isEmpty(groupOperatorChatPreview))
          && (<div id="messenger">
            <div 
              ref={this.msgList} 
              id={'messenger-list-container'} 
              className={`${mobileView === 0 && 'active'}`}
            >
              {!_.isEmpty(groupOperatorChatPreview) ?
                (<MessageList 
                  list={messageList} 
                  activeChatViewId={(state && state.eventId)}
                  messageOperator={true}
                  unread={msgStatusOperator}
                />) :
                (<MessageList 
                  list={messageList} 
                  activeChatViewId={(state && state.eventId)}
                  messageOperator={false}
                  unread={msgStatusOperator}
                />)
              }
            </div>
            {this.renderMessageConversation()}
          </div>)}

          {!state && !eventId && messageList.length === 0 && thereIsMsgFromOperator === 0 && (<MessageEmpty />)}
        </div>
      </div>
    );
  }
}

MessagePage.propTypes = {
  history: PropTypes.any,
  messageList: PropTypes.array,
  currentUser: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any,
  returnToListView: PropTypes.func,
  mobileView: PropTypes.number,
  requestMessageList: PropTypes.func,
  groupOperatorChatPreview: PropTypes.object,
  unreadMessageFromOperator: PropTypes.number,
  turnToMessageView: PropTypes.func,
  addTopMessageList: PropTypes.func,
  openAlertPopup: PropTypes.func,
  event: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  const eventId = _.get(ownProps, 'match.params.eventId');
  return {
    event: state.get('message').get('data').get(eventId),
    currentUser: state.get('account').get('data'),
    messageList: state.get('message').get('list') || [],
    mobileView: state.get('message').get('mobileView'),
    unreadMessageFromOperator: state.get('firebaseListener').get('unreadMessages'),
    groupOperatorChatPreview: state.get('firebaseListener').get('groupChatPreview')
  };
};

const mapDispatchToProps = dispatch => ({
  returnToListView: () => dispatch(setMobileMessageView(0)),
  turnToMessageView: () => dispatch(setMobileMessageView(1)),
  requestMessageList: () => dispatch(requestMessageList()),
  addTopMessageList: eventId => dispatch(addTopMessageList(eventId)),
  openAlertPopup: () => dispatch(setCommonPopup('policy', { goBack: true }))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withImmutablePropsToJS
)(MessagePage);