import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Fade } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { getResources } from 'containers/Resources/actions';
import Tags from 'components/Tags';
import PresidentCell from 'components/PresidentCell';
import { resetPresidentList, toggleFilter, requestPresident } from './action';
import { getSeoDataByCodeRequest } from '../Seo/actions';
import './style.scss';
import _ from 'lodash';

class PresidentList extends Component {
  doesHitBottom = () => {
    return Math.ceil(window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight;
  }

  loadMore = () => {
    if (this.doesHitBottom() && this.props.hasMore && !this.props.show.default && !this.props.isFirstPageLoading) {
      this.props.getPresidentList({ page: this.props.page, tags: this.props.selectedTags });
    }
  }

  componentDidMount() {
    this.props.getSeoDataByCode();
    this.props.getTagResources();      
    window.addEventListener('scroll', this.loadMore);

    if (this.props.page !== 1 && this.props.history && this.props.history.action === 'POP') {
      return;
    } else {
      this.props.resetFilter();
      this.props.getPresidentList({ page: 1, tags: this.props.selectedTags });
    }
  }
  
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.loadMore);
  }
  
  componentDidUpdate(prevProps) {
    if (!_.isEqual(_.get(prevProps, 'selectedTags'), _.get(this.props, 'selectedTags'))) {
      window.scrollTo(0, 0);
      this.props.reset();
      this.props.getPresidentList({ page: 1, tags: this.props.selectedTags });
    }
  }

  renderPresidentList = (list) => {
    if (_.isEmpty(list) && !this.props.show.default && !this.props.isFirstPageLoading) {
      return (
        <div className="empty-president">
          <img alt="" src="images/emptyTag.svg" />
          <div className="empty-text">
            検索結果は0人です
          </div>
          <div className="empty-description">
            ダグの選択数が多すぎる可能性があります
          </div>
        </div>
      );
    }
    return list.map((president) => (
      <PresidentCell
        key={president.id}
        presidentJSON={president}
        selectedTags={this.props.selectedTags}
        addFilter={(tag) => this.props.addFilter(tag)}
        removeFilter={(tag) => this.props.removeFilter(tag)}
      />
    ));
  }

  render() {
    const { seo, selectedTags, tagList = [], list = [], isFirstPageLoading } = this.props;
    const tags = tagList.map((tag) => tag.name);
    return (
      <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="title" content={seo.title} />
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keyword} />
        <meta name="twitter:image" content={seo.image} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
      </Helmet>
      <div className="tags-list">
        <Tags 
          tags={tags}
          selectedTags={selectedTags}
          addItem={(tag) => this.props.addFilter(tag)}
          removeItem={(tag) => this.props.removeFilter(tag)}
          isOrdered
        />
      </div>
      <div className="tags-list-refresh" onClick={this.props.resetFilter}>
        選択したすべてのタグをクリア
      </div>
      <Fade in={!isFirstPageLoading}>
        <div className="president-list">
          {this.renderPresidentList(list)}
        </div>
      </Fade>
      </>
    );
  }
}

PresidentList.propTypes = {
  list: PropTypes.array,
  selectedTags: PropTypes.array,
  tagList: PropTypes.array,
  page: PropTypes.number,
  isFirstPageLoading: PropTypes.object,
  seo: PropTypes.object,
  history: PropTypes.object,
  show: PropTypes.object,
  hasMore: PropTypes.bool,
  getSeoDataByCode: PropTypes.func,
  getPresidentList: PropTypes.func,
  reset: PropTypes.func,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  resetFilter: PropTypes.func,
  getTagResources: PropTypes.func
};

const mapStateToProps = state => {
  return {
    seo: state.get('seo'),
    tagList: state.get('resources').get('presidenttag'),
    list: state.get('presidentList').get('list'),
    page: state.get('presidentList').get('page'),
    hasMore: state.get('presidentList').get('hasMore'),
    selectedTags: state.get('presidentList').get('selectedTags'),
    isFirstPageLoading: state.get('presidentList').get('isFirstPageLoading'),
    show: state.get('loadingBar')
  };
};

const mapDispatchToProps = dispatch => ({
  getSeoDataByCode: () => dispatch(getSeoDataByCodeRequest('president_list')),
  getPresidentList: ({ page, tags }) => dispatch(requestPresident({ page, tags })),
  reset: () => dispatch(resetPresidentList()),
  addFilter: (tag) => dispatch(toggleFilter({ tag, type: 'add' })),
  removeFilter: (tag) => dispatch(toggleFilter({ tag, type: 'remove' })),
  resetFilter: () => dispatch(toggleFilter({})),
  getTagResources: () => dispatch(getResources(['PresidentTag']))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PresidentList));
