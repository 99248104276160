import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from 'components/Button';
import './styles.scss';

const StudentCell = props => {
  const { stud, studInfo, openReviewSelectedStudent, openConfirmPopup, onChangeField } = props;
  const handleAbsenceCheckbox = () => {
    if (studInfo.attendance === 'ABSENT') onChangeField(`${stud}.attendance`, 'ATTENDANCE');
    else onChangeField(stud, {
      ...studInfo,
      attendance: 'ABSENT',
      communicateRating: 0,
      honestnessRating: 0,
      smartnessRating: 0,
      isInvited: undefined
    });
  };
  const isReviewed = () => {
    return !(studInfo.communicateRating === undefined || studInfo.communicateRating === 0) &&
      !(studInfo.honestnessRating === undefined || studInfo.honestnessRating === 0) &&
      !(studInfo.smartnessRating === undefined || studInfo.smartnessRating === 0) &&
      !(studInfo.isInvited === undefined);
  }; 
  const absencePopup = () => {
    if (studInfo.attendance === 'ABSENT') handleAbsenceCheckbox();
    else {
      openConfirmPopup({
        message: isReviewed() ? '欠席登録をするとレビュー内容は無効になります' : 'この学生は欠席者として登録しますか？',
        btnConfirm: isReviewed() ? '変更' : 'はい',
        btnCancel: isReviewed() ? 'キャンセル' : 'いいえ',
        onConfirm: handleAbsenceCheckbox
      });
    }
  };

  return (
    <>
      <div className='student-cell'>
        <div className='avatar-container'>
          <img 
            src={_.get(studInfo, 'avatarImage.thumbSmall', '/images/default-avatar.png')} 
            alt='avatar'
            draggable='false'
          />
        </div>
        <div className='student-content'>
          <div className='student-name'>{studInfo.displayName}</div>
          <div className='review-student-navigator'>
            <div className='to-review-student'>
              <Button
                type='button'
                onClick={openReviewSelectedStudent}
                className={isReviewed() && 'btn-ghost'}
                disabled={studInfo.attendance === 'ABSENT'}
                block
              >
                {isReviewed() ? '編集' : 'レビュー'}
              </Button>
            </div>
            <div className='student-absence'>
              <img 
                src={studInfo.attendance === 'ABSENT' ? '/images/checked.svg' : '/images/uncheck.svg'} 
                alt='checkbox'
                draggable='false'
                onClick={absencePopup}
              />
              <div className={`student-absence-text ${studInfo.attendance === 'ABSENT' && 'absence-checked'}`}>欠席</div>
            </div>
          </div>
        </div>
      </div>
      <hr className='line-break' />
    </>
  );
};

StudentCell.propTypes = {
  stud: PropTypes.any,
  studInfo: PropTypes.object,
  openReviewSelectedStudent: PropTypes.func,
  openConfirmPopup: PropTypes.func,
  onChangeField: PropTypes.func
};

export default StudentCell;