import React, { Component } from 'react';
import './style.scss';
class NotificationEmpty extends Component {
  render() {
    return (
      <div id="empty-noti">
        <img alt="empty" src="/images/empty-notification.svg" />
        <div id="noti" className="text-center">ここには応募した社長メシに関する通知や運営からのお知らせが表示されます</div>
      </div>
    );
  }
}

export default NotificationEmpty;