import { fromJS } from 'immutable';
import { SET_LOADING_RESOURCES, FETCH_RESOURCES_SUCCESS, FETCH_RESOURCES_FAILURE } from './actions';
import _ from 'lodash';

const initState = fromJS({
  loading: false,
  message: null
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function resources(state = initState, action) {
  switch (action.type) {
    case FETCH_RESOURCES_SUCCESS: {
      let newState = state.set('key', fromJS([]));
      _.forEach(action.resources, function(value, key) {
        newState = newState.set(key, value);
      });
      const StateForLoading = newState.set('loading', false);
      return StateForLoading.set('message', null);
    }
    case FETCH_RESOURCES_FAILURE: {
      const unsetLoading = state.set('loading', false);
      return unsetLoading.set('message', action.message);
    }
    case SET_LOADING_RESOURCES: {
      const unsetMessage = state.set('message', null);
      return unsetMessage.set('loading', action.value);
    }
    default:
      return state;
  }
}
