import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';

export default class SystemConversationCell extends Component {
  render() {
    const { item = {} } = this.props;
    
    return (
      <div className="system-cvs-cell">{item.message}</div>
    );
  }
}

SystemConversationCell.propTypes = {
  item: PropTypes.object
};