import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';

import { StarRating, Textarea } from 'components/Form';

const StepThree = ({
  reviewEvent
}) => {
  return (
    <div className="step-three-wrapper">
      <div className='text-note text-highlight'>今回のイベントはいかがでしたか？</div>
      <div className="mt-3 star-container w-100">
        <Field name="reviewEvent.star" component={StarRating} size='normal'/>
        <div className='rating fs-32 bold'>{reviewEvent.star > 0 ? reviewEvent.star : 0}</div>
      </div>
      <div className="rating-note mt-2">★をタップ</div>
      <div className="w-100 mt-4 mb-4">
        <Field 
          name="reviewEvent.freeComment" 
          component={Textarea}
          className="feedback"
          placeholder="任意：次回に向けて、改善点があればご記載ください" rows={6} />
      </div>
    </div>
  );
};

StepThree.propTypes = {
  reviewEvent: PropTypes.object
};

export default StepThree;
