import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';

import { request } from 'utilities/Api';
import { closeModal } from 'containers/CustomModal/actions';
import { setCommonPopup } from 'containers/Popup/actions';
import {
  SEND_GOCHI_REQUEST,
  ACCEPT_GOCHI_REQUEST,
  GET_STUDENT_GOCHI_REQUEST,
  sendGochiSuccess,
  acceptGochiSuccess,
  acceptGochiFailure,
  getStudentGochiSuccess,
  GET_FOLLOWING_PRESIDENT_LIST_REQUEST,
  getFollowingPresidentListSuccess,
  getFollowingPresidentListFailure,
  SET_PRESIDENT_NOTIFY_REQUEST,
  setPresidentNotifySuccess,
  setPresidentNotifyFailure,
  GET_STUDENT_AVAILABLE_SLOTS_REQUEST,
  getStudentAvailableSlotsSuccess,
  getStudentAvailableSlotsFaiure,
  GET_FOLLOWER_STUDENT_LIST_REQUEST,
  getFollowerStudentListSuccess,
  getFollowerStudentListFailure,
  UPDATE_GOCHI_STATUS_REQUEST,
  updateGochiStatusSuccess,
  updateGochiStatusFailure,
  initializeGochiEvent
} from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// STUDENT ROLE
function* sendGochiRequest(action) {
  try {
    const { payload } = action;
    yield call(request, 'functions/sendGochiRequest', payload, 'POST');
    yield put(closeModal());
    yield put(sendGochiSuccess());
    yield put(
      setCommonPopup('success', {
        message:
          'ごちリクエストを受付けました！\nイベント開催の連絡を\nお待ちください',
        OKcallback: () =>
          window.store.dispatch(setCommonPopup('success', false)),
        onHideCallback: () =>
          window.store.dispatch(setCommonPopup('success', false))
      })
    );
  } catch (e) {
    yield put(closeModal());
    yield put(initializeGochiEvent());
  }
}

// PRESIDENT ROLE
function* acceptGochiRequest(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      'functions/acceptGochiRequest',
      { status: 'ACCEPTED', gochiId: payload.gochiId },
      'POST'
    );
    yield put(acceptGochiSuccess({ ...payload, ...result }, meta));
    // yield put(setCommonPopup('success', {
    //   message: '学生のスケジュールを確認して\n運営より担当者様にご連絡差し上げます。',
    //   OKcallback: () => window.store.dispatch(setCommonPopup('success', false)),
    //   onHideCallback: () => window.store.dispatch(setCommonPopup('success', false))
    // }));
  } catch (e) {
    yield put(acceptGochiFailure(e, meta));
  }
}

// PRESIDENT ROLE
function* getStudentGochiRequest(action) {
  try {
    const { payload } = action;
    const { result } = yield call(
      request,
      'functions/getGochiRequest',
      payload,
      'POST'
    );
    yield put(getStudentGochiSuccess(result));
  } catch (e) {
    console.log(e);
  }
}

// STUDENT ROLE
function* getFollowingPresidentList(action) {
  const { payload, meta } = action;
  const { page = 1, limit = 10, status = 'NEW' } = payload;
  try {
    const { result } = yield call(
      request,
      'functions/getFollowedUsers',
      { limit, page, status },
      'POST'
    );
    yield put(
      getFollowingPresidentListSuccess(
        { status, page, limit, list: result.followedUsers },
        meta
      )
    );
  } catch (e) {
    yield put(getFollowingPresidentListFailure({ status, error: e }, meta));
  }
}

// STUDENT ROLE
function* setPresidentNotify(action) {
  const { payload, meta } = action;
  try {
    yield put(showLoading());
    yield call(
      request,
      'functions/setNotificationStatus',
      {
        presidentId: payload.presidentId,
        status: payload.hasReceiveNotification ? 'OFF' : 'ON'
      },
      'POST'
    );
    yield put(
      setPresidentNotifySuccess(
        {
          presidentId: payload.presidentId,
          hasReceiveNotification: !payload.hasReceiveNotification
        },
        meta
      )
    );
    yield put(hideLoading());
  } catch (e) {
    yield put(setPresidentNotifyFailure(e, meta));
    yield put(hideLoading());
  }
}

// PRESIDENT ROLE
function* getStudentAvailableSlots(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      'functions/getAvailableSlotsAndOfferEvent',
      payload,
      'POST'
    );
    yield put(getStudentAvailableSlotsSuccess(result, meta));
  } catch (e) {
    yield put(getStudentAvailableSlotsFaiure({}, meta));
  }
}

// PRESIDENT ROLE
function* getFollowerStudentList(action) {
  const { payload, meta } = action;
  const { page = 1, limit = 10, status } = payload;
  try {
    yield put(showLoading());
    const { result } = yield call(request, 'functions/getFollowerUsers', { limit, page, status }, 'POST');
    yield put(getFollowerStudentListSuccess({ status, page, limit, list: result.followerUsers }, meta));
    yield put(hideLoading());
  } catch (e) {
    yield put(getFollowerStudentListFailure({ status, error: e }, meta));
    yield put(hideLoading());
  }
}

// PRESIDENT ROLE
function* updateGochiStatus(action) {
  const { payload, meta } = action;
  const { gochiId, status, studentId } = payload;
  try {
    yield put(showLoading());
    yield call(request, 'functions/presidentUpdateGochiStatus', { status, gochiId }, 'POST');
    yield put(updateGochiStatusSuccess({ status, gochiId, studentId }, meta));
    yield put(hideLoading());
  } catch (e) {
    yield put(setCommonPopup('success', {
      message: e.error
    }));
    yield put(updateGochiStatusFailure({ error: e }, meta));
    yield put(hideLoading());
  }
}

export default function* watchGochiEvent() {
  yield takeLatest(SEND_GOCHI_REQUEST, sendGochiRequest);
  yield takeLatest(ACCEPT_GOCHI_REQUEST, acceptGochiRequest);
  yield takeLatest(GET_STUDENT_GOCHI_REQUEST, getStudentGochiRequest);
  yield takeEvery(
    GET_FOLLOWING_PRESIDENT_LIST_REQUEST,
    getFollowingPresidentList
  );
  yield takeLatest(SET_PRESIDENT_NOTIFY_REQUEST, setPresidentNotify);
  yield takeLatest(GET_STUDENT_AVAILABLE_SLOTS_REQUEST, getStudentAvailableSlots);
  yield takeEvery(GET_FOLLOWER_STUDENT_LIST_REQUEST, getFollowerStudentList);
  yield takeLatest(UPDATE_GOCHI_STATUS_REQUEST, updateGochiStatus);
}
