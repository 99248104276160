/* eslint-disable semi */
export const LOGIN_FIREBASE_SUCCESS = 'LOGIN_FIREBASE_SUCCESS'
export const LOGOUT_FIREBASE_SUCCESS = 'LOGOUT_FIREBASE_SUCCESS'
export const UNREAD_MESSAGE_UPDATE = 'UNREAD_MESSAGE_UPDATE'
export const CHAT_GROUP_PREVIEW_UPDATE = 'CHAT_GROUP_PREVIEW_UPDATE'
export const CHAT_CONVERSATION_UPDATE = 'CHAT_CONVERSATION_UPDATE'
export const LOADING_MORE_CHAT_CONVERSATION = 'LOADING_MORE_CHAT_CONVERSATION'
export const STOP_CHAT_CONVERSATION_UPDATE = 'STOP_CHAT_CONVERSATION_UPDATE'
export const START_CHAT_CONVERSATION_LISTENING = 'START_CHAT_CONVERSATION_LISTENING'
export const SET_CONVERSATION_DATA = 'SET_CONVERSATION_DATA'
