import { fromJS } from 'immutable';
import { OPEN_REQUIRE_PHONE_MODAL, CLOSE_REQUIRE_PHONE_MODAL, UPDATE_PROFILE_SUCCESS } from './action';

const initialState = fromJS({
  show: false,
  updateProfileSuccess: false
});

const requirePhoneModal = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_REQUIRE_PHONE_MODAL:
      return state.set('show', true);
    case CLOSE_REQUIRE_PHONE_MODAL:
      return state.set('show', false);
    case UPDATE_PROFILE_SUCCESS:
      return state.set('updateProfileSuccess', true);
    default:
      return state;
  }
};

export default requirePhoneModal;