import { request } from './../../utilities/Api';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { PRESIDENT_VIEW_APPROVAL_NOTIFICATION } from './actions';
import { getCurrentAccount } from './../Account/actions';
import { getAccount } from './../Account/selectors';

function* viewNotification() {
  try {
    const account = yield select(getAccount);
    yield call(request, 'functions/presidentViewApprovalNotification', {}, 'POST', {
      headers: { 'X-Parse-Session-Token': account.get('token') }
    });
    yield put(getCurrentAccount(account.get('token'), 'PRESIDENT_VIEW_APPROVAL_NOTIFICATION'));
  } catch (error) {
    console.log(error);
  }
}


export default function* watchPresidentViewApprovalNotification() {
  yield takeEvery(PRESIDENT_VIEW_APPROVAL_NOTIFICATION, viewNotification);
}