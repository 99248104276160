import { request } from './../../utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';

function* incrementAsync() {
  try {
    const data = yield call(request, 'functions/getAllPresidentListByPage', {limit: 10}, 'POST');
    console.log(data);
    yield put({ type: 'INCREMENT' });
  } catch (error) {
    console.log(error);
  }
}


export default function* watchIncrementAsync() {
  yield takeEvery('INCREMENT_ASYNC', incrementAsync);
}