import React, {Component} from 'react';
import {connect} from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { studentUpdateUniversity } from './../Account/actions'
//Component
import UpdateUniversityForm from './UpdateUniversityForm';
import { setCommonPopup } from '../Popup/actions';
import { toast } from 'react-toastify';
//Style
import './style.scss';

class StudentUpdateUniversity extends Component {

  handleSubmit = async (values) => {
    const data = values.toJS();
    try{
      await this.props.studentUpdateUniversity({
        universityId: data.answer,
        university: data.extraAnswer
      });
      this.props.setPopup(false)
    }catch(error){
      toast.error(error.error || error.message)
    }
    
  }

  render() {
    return (
      <UpdateUniversityForm onSubmit={this.handleSubmit} />
    );
  }
}

StudentUpdateUniversity.propTypes = {
  show: PropTypes.bool,
  setPopupWelcome: PropTypes.func,
  getResources: PropTypes.func,
  answerList: PropTypes.array,
  answer: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  studentUpdateUniversity: (values) => dispatch(studentUpdateUniversity(values)),
  setPopup: (open) => dispatch(setCommonPopup('studentUpdateuniversity', open)),
});


export default compose(
  connect(null, mapDispatchToProps),
  withImmutablePropsToJS
)(StudentUpdateUniversity);


