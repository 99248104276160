import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { requestDeniedUsersOfEvent, requestEventReviews } from '../actions';
import ListInEventSection from 'components/EventListSmall';
import { STUDENT_MANAGEMENT_FILTERS } from 'utilities/constants';
import './style.scss';

class EventSection extends Component {
  fetchCellData = (page) => {
    const { eventId, activeFilter } = this.props;
    
    if (activeFilter === STUDENT_MANAGEMENT_FILTERS.APPROVED) {
      this.props.getEventReviews(eventId, page);
    } else {
      this.props.getDeniedUsersOfEvent(eventId, page);
    }
  }

  componentDidMount() {
    // First section will automatically get data with page = 1 & limit = 5 when this section mounted
    if (this.props.firstSection) {
      this.fetchCellData(1);
    }
  }

  render() {
    return (
      /**
       * Render list of review or denied student 
       */
      <div className="event-section">
        <ListInEventSection 
          {...this.props} 
          fetchCellData={this.fetchCellData} 
          hasMore={this.props.event.hasMore} 
          events={this.props.event.list} 
          activeFilter={this.props.activeFilter}
        />
      </div>
    );
  }
}

EventSection.propTypes = {
  firstSection: PropTypes.bool,
  path: PropTypes.string,
  eventId: PropTypes.string,
  activeFilter: PropTypes.string,
  match: PropTypes.object,
  event: PropTypes.object,
  getEventReviews: PropTypes.func,
  getDeniedUsersOfEvent: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    event: state.get('studentManagement').get('studentsOfEvent').get('eventList').get(ownProps.eventId) || {},
    data: state.get('studentManagement').get('studentsOfEvent').get('data').get(ownProps.eventId) || {}
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDeniedUsersOfEvent: (eventId, page) => dispatch(requestDeniedUsersOfEvent(eventId, page)),
  getEventReviews: (eventId, page) => dispatch(requestEventReviews(eventId, page))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(EventSection));