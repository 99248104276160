import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Field, reduxForm, getFormSubmitErrors } from 'redux-form/immutable';
import TextField from '../../../components/Inputs/TextField';
import { changeEmailRequest } from '../action';
import validate from './validate';
import _ from 'lodash';

class EmailChangeForm extends Component {
  
  componentDidUpdate(prevProps) {
    if (!_.isEmpty(prevProps.submitErrors) && _.isEmpty(this.props.submitErrors)) {
      this.props.clearSubmitErrors();
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="field-container">
          <Field
            component={TextField}
            className="input-form"
            label="新しいメールアドレス"
            name="newEmail"
            placeholder="メールアドレス"
            type="email"
            autocomplete='username'
          />
          {_.get(this.props.formSubmitErrors, 'newEmail') && 
          (
            <span className='change-email-error'>
              <img src='images/alert.svg' alt='change-email-error'/>
              このメールアドレスは既に登録されています。別のアドレスを入力してください。
            </span>
          )
          }
        </div>
        {/* Password */}
        { !_.get(this.props.currentUser, 'loginBySocialNetworkAccount') &&
          <div className="field-container">
            <Field
              component={TextField}
              className="input-form"
              label="現在のパスワード"
              name="password"
              placeholder="パスワード"
              type="password"
              showEye={true}
              autocomplete='current-password'
            />
            {_.get(this.props.formSubmitErrors, 'password') && 
            (
              <span className='change-email-error'>
                <img src='images/alert.svg' alt='change-email-error'/>
                このメールアドレスは既に登録されています。別のアドレスを入力してください。
              </span>
            )
            }
          </div>
        }
        <div className="action-container">
          <button
            className={`btn btn-submit ${(this.props.valid && !this.props.inSubmitRequest) && 'btn-active'}`}
            disabled={!this.props.valid}
            name="submit"
            type="submit">
            変更
          </button>
        </div>
      </form>
    );
  }
}

EmailChangeForm.propTypes = ({
  valid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  clearSubmitErrors: PropTypes.func,
  submitErrors: PropTypes.object,
  formSubmitErrors: PropTypes.object,
  inSubmitRequest: PropTypes.bool,
  currentUser: PropTypes.object
});

const mapStateToProps = (state) => {
  return {
    submitErrors: getFormSubmitErrors('emailChangeForm')(state),
    inSubmitRequest: state.get('emailChange').get('inChangeEmailRequest'),
    formSubmitErrors: getFormSubmitErrors('emailChangeForm')(state),
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch(changeEmailRequest(values))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'emailChangeForm',
    validate
  }),   
  withImmutablePropsToJS
)(EmailChangeForm);