import React, { Component } from 'react';
import { Route, Switch, NavLink, Redirect } from 'react-router-dom';
import NewEvent from './../../containers/NewEvent/Loadable';
import EventByDate from './../../containers/EventByDate/Loadable';
import './style.css';

class HomeEvent extends Component {

  activeNewEvent(match, location) {
    if (location.pathname === '/') {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div id="event-list-container">
        <div id="action-button-container">
          <NavLink 
            to="/" 
            className="btn-navlink" 
            activeClassName="btn-active" 
            isActive={this.activeNewEvent}
          >
            新着順
          </NavLink>
          <NavLink 
            to="/event" 
            className="btn-navlink" 
            activeClassName="btn-active"
          >
            日付順
          </NavLink>
        </div>
        <Switch>
          <Route exact path="/event" component={EventByDate} />
          <Route exact path="/" component={NewEvent} />
          <Redirect to="/"/>
        </Switch>
      </div>
    );
  }

}

export default HomeEvent;
