import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { setCommonPopup } from 'containers/Popup/actions';
import { unsetCurrentAccount } from 'containers/Account/actions';
import './styles.scss';

class BlockStudentPopup extends Component {
  render() {
    /**
     * @constant {string} message make popup show smoothly
     */
    const { currentUser, message } = this.props;

    if (
      currentUser &&
      currentUser.role === 'PRESIDENT' &&
      currentUser.status === 'INACTIVE'
    ) {
      this.props.openAlertPopup({
        message: 'セッションが切れました',
        OKcallback: () => this.props.unsetAccount(),
        onHideCallback: () => this.props.unsetAccount(),
        btnLabel: 'はい'
      });
    }

    return (
      <Modal
        show={
          message &&
          currentUser &&
          currentUser.role === 'STUDENT' &&
          currentUser.status === 'INACTIVE'
        }
        id="block-student-popup"
      >
        <div className="title">サービス利用停止中</div>
        <div className="image">
          <img src="images/blocked.svg" alt="blocked" />
        </div>
        <div className="message">
          あなたのアカウントは利用規約違反により
          <br />
          利用停止中です。
          <br />
          詳細についてのお問い合わせや
          <br />
          利用再開を希望する場合は
          <br />
          運営にお問い合わせください。
        </div>
        <a
          href="https://line.me/R/ti/p/%40mhs7705k"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="button">LINEサポート</div>
        </a>
      </Modal>
    );
  }
}

BlockStudentPopup.propTypes = {
  message: PropTypes.string,
  currentUser: PropTypes.object,
  openAlertPopup: PropTypes.func,
  unsetAccount: PropTypes.func
};

const mapStateToProps = (state) => ({
  message: state.get('account').get('message'),
  currentUser: state.get('account').get('data')
});

const mapDispatchToProps = (dispatch) => ({
  openAlertPopup: (show) => dispatch(setCommonPopup('success', show)),
  unsetAccount: () => dispatch(unsetCurrentAccount())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(BlockStudentPopup);
