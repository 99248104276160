import React, { Component } from 'react';
import EventItem from './EventItem';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './style.scss';
import BannerCarousel from '../BannerCarousel';

class EventList extends Component {

  doesHitBottom = () => {
    return Math.ceil(window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight;
  }

  loadMore = () => {
    if (this.doesHitBottom()) {
      this.props.getMore(1);
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.loadMore);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.loadMore);
  }

  Banner = () => {
    if (this.props.showBanner) {
      return <BannerCarousel/>;
    }
  }

  Collections = () => {
    const events = this.props.events;
    if (this.props.showBanner && !_.isEmpty(this.props.banners) && this.props.hasMore) {
      events.pop();
      events.pop();
    }

    return events.map((item, index) => {
      return <EventItem key={index} data={item}/>;
    });
  }

  render() {
    return (
      <div id="event-list-detail-container" className="row">
        {this.Banner()}
        {this.Collections()}
      </div>
    );
  }
}

EventList.propTypes = {
  getMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  events: PropTypes.array.isRequired,
  showBanner: PropTypes.bool,
  banners: PropTypes.array
};

EventList.defaultProps = {
  events: [],
  hasMore: true,
  showBanner: true
};

const mapStateToProps = state => ({
  banners: state.get('banners')
});

export default connect(
  mapStateToProps,
  null
)(withImmutablePropsToJS(EventList));