
const validate = values => {
  const errors = {};
  if (values) {
    if (values.has('reviewStudents')) {
      const reviewStudents = values.get('reviewStudents').toJS();
      for (let i = 0; i < reviewStudents.length; i++) {
        let reviewedStudent = reviewStudents[i];
        const { attendance, communicateRating, honestnessRating, smartnessRating, isInvited } = reviewedStudent;
        if (attendance !== 'ABSENT') {
          if (communicateRating === undefined || communicateRating === 0) 
            errors.reviewStudents = { 0: { communicateRating: 'Please rating' }};
          if (honestnessRating === undefined || honestnessRating === 0) 
            errors.reviewStudents = { 0: { honestnessRating: 'Please rating' }};
          if (smartnessRating === undefined || smartnessRating === 0) 
            errors.reviewStudents = { 0: { smartnessRating: 'Please rating' }};
          if (isInvited === undefined) {
            errors.reviewStudents = { 0: { isInvited: 'Please choose' }};
          }
        }
      }
    }
    if (values.has('reviewEvent') && values.get('reviewEvent').get('star') === 0) {
      errors.reviewEvent = {star: 'Please rating'};
    }
  }
  return errors;
};

export default validate;