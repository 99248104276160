export const CLOSE_PROFILE_REQUEST_EVENT_POPUP = 'CLOSE_PROFILE_REQUEST_EVENT_POPUP';
export const OPEN_PROFILE_REQUEST_EVENT_POPUP = 'OPEN_PROFILE_REQUEST_EVENT_POPUP';
export const COMPLETE_PROFILE_REQUEST_EVENT_POPUP = 'COMPLETE_PROFILE_REQUEST_EVENT_POPUP';

export function closeCompleteProfilePopUp() {
  return {type: CLOSE_PROFILE_REQUEST_EVENT_POPUP, payload: {}};
}

export function openCompleteProfilePopUp() {
  return {type: OPEN_PROFILE_REQUEST_EVENT_POPUP, payload: {}};
}

export function completeProfilePopUp() {
  return {type: COMPLETE_PROFILE_REQUEST_EVENT_POPUP, payload: {}};
}