import { fromJS } from 'immutable';
import {
  REQUEST_GET_EVENTS_BY_STATUS_SUCCESS,
  RESET_EVENTS_BY_STATUS,
  GET_PRESIDENT_EVENTS_REQUEST,
  GET_PRESIDENT_EVENTS_SUCCESS,
  GET_PRESIDENT_EVENTS_FAILURE
} from './actions';
import _ from 'lodash';
const initialState = fromJS({
  list: [],
  page: 1,
  hasMore: true,
  status: '',
  presidentEvents: {
    list: [],
    page: 1,
    hasMore: true,
    isLoading: false
  }
});

const eventsManagementByStatus = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_EVENTS_BY_STATUS_SUCCESS: {
      const tempList = action.payload.eventList;
      if (_.isEmpty(tempList)) {
        return state.set('hasMore', false).set('status', action.status);
      }
      if (action.status === state.get('status')) {
        state = state.update('list', (oldList) => {
          oldList = oldList.toJS ? oldList.toJS() : oldList;
          return fromJS([...new Set(oldList.concat(tempList))]);
        });
      } else {
        state = state.set('list', tempList);
      }
      return state
        .update('page', (page) => page + 1)
        .set('status', action.status);
    }

    case GET_PRESIDENT_EVENTS_REQUEST:
      return state.setIn(['presidentEvents', 'isLoading'], true);
    case GET_PRESIDENT_EVENTS_SUCCESS: {
      const { eventList = [], page } = action.payload;
      const hasMore = eventList.length < 10 ? false : true;
      const prevList = state.getIn(['presidentEvents', 'list']);
      const newList = page <= 1 ? eventList : prevList.concat(eventList);
      const newState = {
        list: newList,
        hasMore: hasMore,
        page: page + 1,
        isLoading: false
      };
      return state.set('presidentEvents', fromJS(newState));
    }
    case GET_PRESIDENT_EVENTS_FAILURE:
      return state.setIn(['presidentEvents', 'isLoading'], false);

    case RESET_EVENTS_BY_STATUS:
      return initialState;
    default:
      return state;
  }
};

export default eventsManagementByStatus;
