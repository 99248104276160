import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import MessageInput from './MessageInput';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { 
  requestConversationLoadmore, 
  requestConversationSwitch, 
  requestEventDetailForMessage 
} from './actions';
import { setCommonPopup } from 'containers/Popup/actions';
import PopupGreeting from 'containers/PopupGreeting';
import Popup24hConfirm from 'containers/Popup24hConfirm';
import ChatTitle from './ChatTitle';
import ChatSection from './ChatSection';

class MessageConversation extends Component {
  constructor(props) {
    super(props);
    this.chatSection = React.createRef();
  }

  handleScroll = () => {
    const {eventId} = this.props;
    if (eventId) {
      if (this.chatSection.scrollTop === 0) {
        this.props.requestConversationLoadmore(this.props.eventId);
      }
    }
  }

  componentDidMount() {
    const {eventId} = this.props;
    if (eventId) {
      this.props.requestConversationSwitch(this.props.eventId);
      this.props.requestEventDetailForMessage(this.props.eventId);
    }
  }

  componentDidUpdate(prevProps) {
    const { eventId, eventIdLoaded, conversation } = this.props;
    if (eventId && eventId !== prevProps.eventId) {
      this.props.requestConversationSwitch(this.props.eventId);
      this.props.requestEventDetailForMessage(this.props.eventId);
    }
    if (eventIdLoaded && eventIdLoaded !== prevProps.eventIdLoaded && eventId === eventIdLoaded) {
      if (!conversation.attendance) {
        const msToEvent = moment(conversation.eventDate).diff(moment());
        if (0 < msToEvent && msToEvent < 86400000 && !conversation.offerEvent) {
          this.props.openPopup24hConfirm();
        }
      } 
      if (!this.props.conversation.greeting) {
        this.props.openPopupGreeting();
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const {eventId, eventIdLoaded, groupOperatorChatPreview, chatWithOperatorId} = this.props;
    if (eventId !== nextProps.eventId || groupOperatorChatPreview !== nextProps.groupOperatorChatPreview) {
      return true;
    } else if (chatWithOperatorId !== nextProps.chatWithOperatorId) {
      return true;
    } else if (eventIdLoaded !== nextProps.eventIdLoaded) {
      return true;
    }
    return false;
  }

  render() {
    const { eventId, chatWithOperatorId } = this.props;
    const fromOperator = chatWithOperatorId ? true : false;

    if (!eventId && !chatWithOperatorId) {
      return null;
    }

    const currentUserId = this.props.currentUser.objectId;

    return (
      <div className="chat-view">
        <ChatTitle eventId={eventId} chatWithOperatorId={chatWithOperatorId} fromOperator={fromOperator}/>
        <ChatSection
          eventId={eventId}
          chatWithOperatorId={chatWithOperatorId}
          currentUserId={currentUserId}
          fromOperator={fromOperator}
        />
        <MessageInput eventId={eventId} chatWithOperatorId={chatWithOperatorId} fromOperator={fromOperator}/>
        {eventId &&
          <>
            <PopupGreeting eventId={eventId}/>
            <Popup24hConfirm eventId={eventId}/>
          </>
        }
      </div>
    );
  }
}

MessageConversation.defaultProps = {
  conversation: {}
};

MessageConversation.propTypes = {
  currentUser: PropTypes.any,
  eventId: PropTypes.string,
  eventIdLoaded: PropTypes.string,
  chatWithOperatorId: PropTypes.string,
  requestConversationSwitch: PropTypes.func,
  requestConversationLoadmore: PropTypes.func,
  openPopupGreeting: PropTypes.func,
  openPopup24hConfirm: PropTypes.func,
  requestEventDetailForMessage: PropTypes.func,
  groupOperatorChatPreview: PropTypes.object,
  conversation: PropTypes.object,
  data: PropTypes.object,
  conversationData: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.get('account').get('data'),
    eventIdLoaded: state.get('conversation').get('eventId'),
    groupOperatorChatPreview: state.get('firebaseListener').get('groupChatPreview'),
    conversation: state.get('conversation').get('conversationsInfo').get(ownProps.eventId) || {},
    data: state.get('conversation').get('chatMessagesData')
  };
};

const mapDispatchToProps = dispatch => ({
  requestConversationLoadmore: (eventId) => dispatch(requestConversationLoadmore(eventId)),
  requestConversationSwitch: (eventId) => dispatch(requestConversationSwitch(eventId)),
  requestEventDetailForMessage: (eventId) => dispatch(requestEventDetailForMessage(eventId)),
  openPopupGreeting: () => dispatch(setCommonPopup('greeting', true)),
  openPopup24hConfirm: () => dispatch(setCommonPopup('24hConfirm', true))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(MessageConversation));