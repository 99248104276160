import React, { useEffect } from 'react';
import './style.scss';
import SwitchInput from 'components/SwitchInput';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationSettingRequest, updateNotificationSettingRequest } from './actions';
import { get } from 'lodash';

const NotificationSetting = () => {
  const dispatch = useDispatch();
  const notificationSetting = useSelector(state => state.get('notificationSetting').get('data'));
  const emailOfferReceiveSetting = get(notificationSetting, 'email.setting[0]') || {};
  const emailOfferReceiveSettingValue = get(emailOfferReceiveSetting, 'value');

  useEffect(() => {
    dispatch(fetchNotificationSettingRequest());
  }, []);

  const handleUpdateEmailSetting = (value) => {
    dispatch(updateNotificationSettingRequest({ email: [{ id: emailOfferReceiveSetting.id, value: value ? 1 : 0 }] }));
  };

  return (
   
    <div className='notification-setting-page'>
      <div className='notification-setting-container'>
        <h1>設定</h1>
        <div className='content-container'>
          <h2>通知設定</h2>
          <div className='divider' />
          <h3>メール通知</h3>
          <div className='switch-container' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>オファー受信</p>
            <SwitchInput checked={!!emailOfferReceiveSettingValue} onChange={handleUpdateEmailSetting} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSetting;