import { fromJS } from 'immutable';
import { FETCH_USER_INFO_SUCCESS } from '../../PresidentDetail2/action';

const initState = fromJS({
  data: {}
});

export default function studentProfile(state = initState, action) {
  switch (action.type) {
    case FETCH_USER_INFO_SUCCESS: {
      return state.setIn(['data', action.infoUser.objectId], action.infoUser);
    }

    default:
      return state;
  }
}