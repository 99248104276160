import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.scss';

class MagnementMenuCell extends Component {
  render() {
    const { item = {}, currentPath } = this.props;

    return (
      <div className={`management-menu ${item.children && 'with-sub'}`}>
        <NavLink
          to={item.path}
          isActive={(match, location) => {
            if (item.children && item.childrenPath.includes(location.pathname)) {
              return true;
            }

            return !!match;
          }}
          activeClassName="active"
          exact
        >
          <span className="management-menu-image">
            <img src={item.icon} alt="management" />
          </span>
          <span>{item.title}</span>
        </NavLink>
        {item.children && (
          <div className={`management-sub-menu ${item.childrenPath.includes(currentPath) && 'active-sub-menu'}`}>
            {item.children.map((subMenu) => (
              <NavLink
                to={subMenu.path}
                activeClassName="active"
                exact
                key={subMenu.path}
              >
                <span className="management-menu-image">
                  <img src={subMenu.icon} alt="management" />
                </span>
                <span>{subMenu.title}</span>
              </NavLink>
            ))}
          </div>
        )}
      </div>
    );
  }
}

MagnementMenuCell.propTypes = {
  item: PropTypes.object,
  currentPath: PropTypes.string
};

export default MagnementMenuCell;
