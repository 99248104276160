import { put, takeEvery } from 'redux-saga/effects';
import { UNSET_ACCOUNT, FETCH_CURRENT_ACCOUNT_SUCCESS } from '../../containers/Account/actions';
import { 
  initLiveAdapterSuccess,
  initLiveAdapterFail,
  destroyLiveAdapterSuccess,
  destroyLiveAdapterFail
} from './action';
import LiveAdapter from './index';

function* initLiveAdapter(action) {
  try {
    yield LiveAdapter.initSDK();
    yield LiveAdapter.subscribe();
    yield put(initLiveAdapterSuccess());
  } catch (err) {
    yield put(initLiveAdapterFail(err));
  }
}

export function* _adapter_watchLogin() {
  yield takeEvery(FETCH_CURRENT_ACCOUNT_SUCCESS, initLiveAdapter);
}

function* destroyLiveAdapter(action) {
  try {
    if (LiveAdapter.terminate()) {
      yield put(destroyLiveAdapterSuccess());
    }
  } catch (err) {
    yield put(destroyLiveAdapterFail(err));
  }
  
}


export function* _adapter_watchUnsetAccount() {
  yield takeEvery(UNSET_ACCOUNT, destroyLiveAdapter);
}