import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { getValueByKey } from 'containers/Popup/selectors';
import { setCommonPopup } from 'containers/Popup/actions';
import './style.scss';

class Lightbox extends Component {
  handleClose = () => {
    this.props.closeLightbox();
    this.props.showLightboxControl && this.props.closeLightboxControl();
  }

  render() {
    return (
      <Modal id="lightbox" show={!!this.props.showLightbox}>
        <div className="close-btn" onClick={this.handleClose}>
          <img alt="" src="/images/lightbox-close-btn.svg" />
        </div>
        <img alt="" src={this.props.showLightbox.url} />
      </Modal>
    );
  }
}

Lightbox.propTypes = {
  showLightbox: PropTypes.any,
  showLightboxControl: PropTypes.any,
  closeLightbox: PropTypes.func,
  closeLightboxControl: PropTypes.func
};

const mapStateToProps = state => {
  return {
    showLightbox: getValueByKey(state, 'lightbox'),
    showLightboxControl: getValueByKey(state, 'lightboxControl')
  };
};

const mapDispatchToProps = dispatch => ({
  closeLightbox: () => dispatch(setCommonPopup('lightbox', false)),
  closeLightboxControl: () => dispatch(setCommonPopup('lightboxControl', false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(Lightbox));