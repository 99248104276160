import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { acceptPolicyRequest } from './actions';
import { getValueByKey } from 'containers/Popup/selectors';
import { setCommonPopup } from 'containers/Popup/actions';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import './style.scss';

class PopupPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  closePopup = () => {
    this.props.closePopupPolicy();
    if (this.props.show && this.props.show.goBack) {
      this.props.history.goBack();
    }
  }

  render() {
    const { pathname } = this.props.history.location;
    const eventId = pathname.substring(pathname.lastIndexOf('/') + 1);
    return (
      <Modal show={!!this.props.show} onHide={this.closePopup} className="policy-popup" id="acceptPolicy-popup">
        <div className="popup-close" onClick={this.closePopup} />
        <div className="title text-center">参加確定おめでとうございます！</div>
        <div className="text-center policy-msg">
          チャットで主催者に挨拶しましょう。<br />
          ＜挨拶例＞○○大学の社長メシ太郎です！<br />
          友達からは「おかわり君」と呼ばれてます。<br />
          当日はよろしくお願いします！<br />
          なお、当日無断で欠席された場合はサービスのご利用を停止させて<br />
          いただく可能性がございますので、予めご了承ください。<br />
          社長メシの利用にあたっては社長メシ利用規約をご確認ください。<br />
        </div>
        <div className="checkbox">
          <label className='policy-check'>
            <div className='policy-checkbox'>
              <input type="checkbox" name="terms" value="true" checked={this.state.checked} onChange={() =>
                this.setState({checked: !this.state.checked})
              }/>
              <span className='checkmark'></span>
            </div>
            <a href="https://careertrip.jp/shachomeshi-agreement/" rel="noopener noreferrer" target="_blank" className="text-primary"> 利用規約</a> に同意する
          </label>
        </div>
        <div className={`${this.state.checked ? 'popup-btn' : 'popup-btn-disable'}`} onClick={() => {
          if (this.state.checked) {
            this.props.acceptPolicyRequest({eventId: eventId, token: this.props.sessionToken});
            this.props.closePopupPolicy();
          } 
        }}>OK</div>
      </Modal>
    );
  }
}

PopupPolicy.propTypes = {
  history: PropTypes.any,
  eventId: PropTypes.string,
  sessionToken: PropTypes.string,
  show: PropTypes.object,
  acceptPolicyRequest: PropTypes.func,
  closePopupPolicy: PropTypes.func
};

const mapStateToProps = state => {
  return {
    eventId: state.get('eventDetail').get('eventInfo').objectId,
    sessionToken: state.get('account').get('token'),
    show: getValueByKey(state, 'policy')
  };
};

const mapDispatchToProps = dispatch => ({
  acceptPolicyRequest: (eventId, sessionToken) => dispatch(acceptPolicyRequest(eventId, sessionToken)),
  closePopupPolicy: () => dispatch(setCommonPopup('policy', false))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withImmutablePropsToJS
)(PopupPolicy);
