import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { 
  receiveStudentDataCSV,
  failStudentDataCSV,
  GET_EVENT_DETAIL_STUDENT_CSV_REQUEST
} from './action';

function* getEventDetailStudentDataCSV(action) {
  yield put(showLoading());
  const { meta, eventId } = action;
  try {
    const data = yield call(request, 'functions/getStudentDataByEvent', { eventId }, 'POST');
    yield put(receiveStudentDataCSV(meta, data.result));
  } catch (e) {
    yield put(failStudentDataCSV(meta, e));
  }
  yield put(hideLoading());
}

export function* watchEventDetailStudentDataCSV() {
  yield takeEvery(GET_EVENT_DETAIL_STUDENT_CSV_REQUEST, getEventDetailStudentDataCSV);
}