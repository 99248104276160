import { request } from '../../../../utilities/Api';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { toast } from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import _ from 'lodash';
import {
  GET_SEARCHED_STUDENTS_REQUEST,
  getSearchedStudentsSuccess,
  getSearchedStudentsFailed,
  COUNT_AVAILABLE_STUDENTS_REQUEST,
  countAvailableStudentsRequest,
  countAvailableStudentsSuccess,
  countAvailableStudentsFailed
} from './actions';
import { setCommonPopup } from '../../../Popup/actions';

function* getSearchedStudents(action) {
  try {
    yield put(showLoading());
    let { payload } = action;

    let filter = yield select((state) =>
      state.getIn(['form', 'searchStudents', 'values'])
    );
    if (filter) {
      filter = { ...filter.toJS() };
      if (
        filter.university &&
        !_.find(filter.university, { objectId: 'u000000196' })
      ) {
        const universities = filter.university.map((item) => item.objectId);
        filter.university = universities.join(',');
      } else {
        filter.university = '';
      }
      payload = _.pickBy({ ...payload, ...filter }, _.identity);
    }

    let page = yield select((state) =>
      state.getIn(['searchStudentsManagement', 'page'])
    );
    if (payload.page) {
      page = payload.page;
    }
    if (page === 1) {
      yield put(countAvailableStudentsRequest(payload));
    }

    const { result } = yield call(
      request,
      '/functions/getStudentListForPresident',
      {
        page,
        limit: 12,
        ...payload
      },
      'POST'
    );
    yield put(getSearchedStudentsSuccess({ page, result }, action.meta));
    yield put(hideLoading());
  } catch (e) {
    yield put(
      setCommonPopup('confirm', {
        title: e.error,
        isAlert: true,
        btnLabel: 'OK'
      })
    );
    yield put(hideLoading());
    yield put(getSearchedStudentsFailed({}, action.meta));
  }
}

function* countAvailableStudents(action) {
  try {
    const { result } = yield call(
      request,
      '/functions/countAvailableStudentForOfferEvent',
      action.payload,
      'POST'
    );
    yield put(countAvailableStudentsSuccess(_.get(result, 'totalStudent', 0), action.meta));
  } catch (e) {
    toast.error(e.error);
    yield put(countAvailableStudentsFailed({}, action.meta));
  }
}

export default function* watchSearchStudentsManagement() {
  yield takeFirst(GET_SEARCHED_STUDENTS_REQUEST, getSearchedStudents);
  yield takeLatest(COUNT_AVAILABLE_STUDENTS_REQUEST, countAvailableStudents);
}
