import isAsciiEmail from 'sane-email-validation';

const validate = values => {
  const errors = {};
  
  if (!values.has('email')) {
    errors.email = 'この値は必須です。';
  } else if (!isAsciiEmail(values.get('email'))) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

export default validate;