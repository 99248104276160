export const validateInviteCode = (value) => /^[A-Z0-9]{6}$/.test(value);

const validate = values => {
  const errors = {};
  const inviteCode = values.get('inviteCode');
  if (inviteCode && inviteCode.trim() && !validateInviteCode(inviteCode.trim().toUpperCase())) {
    errors.inviteCode = 'この値は必須です。';
  } 
  return errors;
};

export default validate;