export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';

export function createEvent(payload) {
  return {
    type: CREATE_EVENT_REQUEST,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function createEventSuccess(payload, meta) {
  return {
    type: CREATE_EVENT_SUCCESS,
    payload,
    meta
  };
}

export function createEventFail(payload, meta) {
  return {
    type: CREATE_EVENT_FAIL,
    payload,
    error: true,
    meta
  };
}