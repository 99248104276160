export const FETCH_FOLLOW_LIST_REQUEST = 'FETCH_FOLLOW_LIST_REQUEST';
export const FETCH_FOLLOW_LIST_SUCCESS = 'FETCH_FOLLOW_LIST_SUCCESS';
export const REMOVE_FOLLOW_LIST = 'REMOVE_FOLLOW_LIST';

export const SET_FOLLOW_USER_REQUEST = 'SET_FOLLOW_USER_REQUEST';
export const SET_FOLLOW_USER_SUCCESS = 'SET_FOLLOW_USER_SUCCESS';

export const SET_NOTIFY_STATUS_REQUEST = 'SET_NOTIFY_STATUS_REQUEST';
export const SET_NOTIFY_STATUS_SUCCESS = 'SET_NOTIFY_STATUS_SUCCESS';

export function requestFollowList() {
  return {type: FETCH_FOLLOW_LIST_REQUEST};
}

export function receiveFollowList(followList) {
  return {type: FETCH_FOLLOW_LIST_SUCCESS, followList};
}

export function removeFollowList() {
  return {type: REMOVE_FOLLOW_LIST};
}

export function setFollowUser(userId, action) {
  return {
    type: SET_FOLLOW_USER_REQUEST,
    userId,
    action,
    meta: {
      thunk: true
    }};
}
export function setFollowUserSuccess(meta, data = {}) {
  return {type: SET_FOLLOW_USER_SUCCESS, data, meta};
}

export function setNotificationStatus(presidentId, status = 'ON') {
  return {
    type: SET_NOTIFY_STATUS_REQUEST,
    presidentId,
    status,
    meta: {
      thunk: true
    }};
}
export function setNotificationStatusSuccess(meta, data = {}) {
  return {type: SET_NOTIFY_STATUS_SUCCESS, payload: data, meta};
}