
import * as firebase from 'firebase/app';
import { request } from 'utilities/Api';
import _ from 'lodash';
import 'firebase/auth';
import 'firebase/firestore';
import {
  updateMessageUnread,
  updateChatGroupPreview,
  updateConversation,
  setConversationData,
  loadingMoreChatConversation,
  startListeningConversation
} from '../containers/FirebaseListener/actions';

export function initFirebase() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
  };

  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}

export const getChatGroupPreview = async groupId => {
  // If chat group Op/Student does not exist, run this listener
  const group = await firebase.firestore()
    .doc(`groups/${groupId}`)        
    .get();
  if (group.exists && !group.metadata.hasPendingWrites) {
    window.store.dispatch(updateChatGroupPreview({...group.data(), groupId}));
  }
};

export const listenUnreadMsgs = async(uid, groupId) => {
  if (window.unsubscribeCheckForGroup) {
    window.unsubscribeCheckForGroup(); // Stop listener for the creation of the group chat with Operator when this is created. 
    window.unsubscribeCheckForGroup = null;
  }
  window.unsubscribeUnreadMessages = await firebase.firestore()
    .doc(`users/${uid}/groups/${groupId}`)        
    .onSnapshot(doc => {
      if (doc) {
        const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server';
        const chatActive = window.store.getState().get('firebaseListener').get('listeningConversationWithOperator');

        // in Local, when a msg is received, is always readed and 
        // unreadMsg always will be zero so it won't modify chat-room-cell UI when chat is active
        if (source === 'Local' && chatActive) {
          window.store.dispatch(updateMessageUnread(doc.data()));
        }
        // in Server, unreadMsg may change, and chat-room-cell UI will be updated when chat is not active
        if (source === 'Server') {
          if (!chatActive) {
            window.store.dispatch(updateMessageUnread(doc.data()));
          }
          getChatGroupPreview(groupId);
        }
      }
    });
};

// Listen to check when chat group is created
export const listenForGroup = (uid) => {
  const getUserChatGroups = firebase.firestore().collection(`/users/${uid}/groups`);
  window.unsubscribeCheckForGroup = getUserChatGroups   
    .onSnapshot(snapshot => {
      if (snapshot) {
        if (snapshot.docs.length === 1) {
          listenUnreadMsgs(uid, snapshot.docs[0].id);
        }
      }
    });
};

//ADD USER ID TO readedUser Field, FOR EVERY MSG LOADED
const addUserIdToReadedUsersArr = (messages, userId, groupId) => {
  const unreadMessages = messages.filter(msg => !msg.readedUsers.includes(userId));
  if (unreadMessages.length > 0) {
    const batch = firebase.firestore().batch();
    const userRef = firebase.firestore().doc(`users/${userId}`);
    const userGroupRef = firebase.firestore().doc(`users/${userId}/groups/${groupId}`);
    const fieldValue = firebase.firestore.FieldValue;

    unreadMessages.forEach(msg => {
      const msgRef = firebase.firestore().doc(`messages/${groupId}/messages/${msg.id}`);
      batch.update(msgRef, {
        readedUsers: fieldValue.arrayUnion(userId)
      });
    });
    batch.update(userRef, {
      'lastRead': fieldValue.serverTimestamp()
    });
    batch.update(userGroupRef, {
      'unreadMessages': 0
    });
    batch.commit();
  }
};

let lastVisibleMessage = null; // FOR PAGINATION REFERENCE
export const loadConversation = (groupId, firstLoad, userId) => { //firstLoad: BOOLEAN
  const fieldValue = firebase.firestore.FieldValue;
  const userRef = firebase.firestore().doc(`users/${userId}/groups/${groupId}`);
  const messagesRef = firebase.firestore().collection(`messages/${groupId}/messages`);

  //FIRST LOAD
  if (firstLoad) {
    messagesRef
      .orderBy('createdAt', 'desc')
      .limit(20)
      .get()
      .then(documents => {
        if (documents) {
          let messages = [];
          if (documents.docs.length > 0) {
            lastVisibleMessage = documents.docs[documents.docs.length - 1];
          }
          documents.forEach(doc => messages.push({...doc.data(), id: doc.id}));
          if (messages.length > 0) {
            addUserIdToReadedUsersArr(messages, userId, groupId);
            window.store.dispatch(setConversationData(messages));
            return firebase.firestore().runTransaction(transaction => {
              return transaction
                .get(userRef)
                .then(doc => {
                  if (doc.exists) {
                    const data = doc.data();
                    if (data.unreadMessages > 0) {
                      transaction.update(userRef, {
                        'unreadMessages': 0,
                        'updatedAt': fieldValue.serverTimestamp()
                      });
                    }
                  }
                });
            }).then()
              .catch(e => console.log('transaction failed. Error: ', e));
          } else {
            lastVisibleMessage = null;
          }
        }
      })
      .catch(e => console.log('Error Loading Messages. ERROR:', e));
  } else {
    // LOAD 20 MORE
    messagesRef
      .orderBy('createdAt', 'desc')
      .startAfter(lastVisibleMessage)
      .limit(20)
      .get()
      .then(documents => {
        if (documents) {
          if (documents.docs.length > 0) {
            lastVisibleMessage = documents.docs[documents.docs.length - 1];
          }
          let messages = [];
          documents.forEach(doc => messages.push({...doc.data(), id: doc.id}));
          if (messages.length > 0) {
            addUserIdToReadedUsersArr(messages, userId, groupId);
            window.store.dispatch(setConversationData(messages));
          } else {
            lastVisibleMessage = null;
          }
        } else {
          window.store.dispatch(loadingMoreChatConversation(false));
          console.log('doc does not exist');
        }
      })
      .catch(e => {
        window.store.dispatch(loadingMoreChatConversation(false));
        console.log('ERROR:', e);
      });
  }
};

export const listenConversation = (groupId, userId) => {
  if (window.unsubscribeCheckForGroup) {
    window.unsubscribeCheckForGroup();
  }
  const messageCollRef = firebase.firestore().collection(`messages/${groupId}/messages`);
  //GET MESSAGE UPDATES
  window.unsubscribeConversationWithOperator = messageCollRef
    .orderBy('createdAt', 'desc')
    .limit(20)
    .onSnapshot(documents => {
      const conversation = window.store.getState().get('firebaseListener').get('conversationWithOperator');
      const chatActive = window.store.getState().get('firebaseListener').get('listeningConversationWithOperator');
      if (documents) {
        if (!chatActive) {
          window.store.dispatch(startListeningConversation());
        }
        let messages = [];
        documents.docChanges().forEach(newDoc => {
          if (newDoc.type === 'added') {
            const messageInConversation = conversation.filter(msg => msg.id === newDoc.doc.id);
            if (messageInConversation.length === 0) {
              const data = newDoc.doc.data();
              if (data.createdAt === null) {
                const localTimestamp = Math.floor(Date.now() / 1000);
                messages.push({...data, createdAt: {seconds: localTimestamp}, id: newDoc.doc.id});
              } else {
                messages.push({...newDoc.doc.data(), id: newDoc.doc.id});
              }
            }
          }
        });
        if (messages.length > 0) {
          addUserIdToReadedUsersArr(messages, userId, groupId);
          window.store.dispatch(updateConversation(messages));
        } 
      } else {
        console.log('doc does not exist');
      }
    });
};

export const sendMessageFirestore = (operatorId, groupId, userId, msg, msgType) => {
  const batch = firebase.firestore().batch();
  const fieldValue = firebase.firestore.FieldValue;

  // REFERENCES
  const operatorRef = firebase.firestore().doc(`users/${operatorId}/groups/${groupId}`);
  const userRef = firebase.firestore().doc(`users/${userId}/groups/${groupId}`);
  const groupPreviewRef = firebase.firestore().doc(`groups/${groupId}`);
  const newMessageRef = firebase.firestore().collection('messages').doc(groupId).collection('messages').doc();

  // Step 1: Update number of unread message of Operator
  batch.update(operatorRef, {
    'unreadMessages': fieldValue.increment(1),
    'updatedAt': fieldValue.serverTimestamp()
  });

  batch.update(userRef, {
    'updatedAt': fieldValue.serverTimestamp()
  });

  // Step 2: Update last message type of Group Preview
  batch.update(groupPreviewRef, {
    'lastMessage': msg,
    'lastMessageType': msgType,
    'updatedAt': fieldValue.serverTimestamp(),
    'senderId': userId // Me
  });

  // Step 2: Create message
  batch.set(newMessageRef, {
    'content': msg,
    'contentType': msgType,
    'updatedAt': fieldValue.serverTimestamp(),
    'createdAt': fieldValue.serverTimestamp(),
    'readedUsers': [userId],
    'senderId': userId // Me
  });
  batch.commit();
};

export const loginFirebase = () => new Promise(async(resolve, reject) => {
  try {
    firebase.auth().onAuthStateChanged(async user => {
      let uid;
      if (!user) {
        // GET TOKEN FROM SERVER
        const customToken = await request('/functions/createFireBaseToken', {}, 'POST');
        // SIGNIN FIREBASE
        const signInUser = await firebase.auth().signInWithCustomToken(_.get(customToken, 'result.token'));
        // GET USER DOCUMENT INFO
        uid = _.get(signInUser, 'user.uid');
      } else {
        uid = user.uid;
      }
      const getUserChatGroups = await firebase.firestore()
        .collection(`/users/${uid}/groups`)  
        .get();
      let groupPreview = [];
      let groupId = '';
      if (!getUserChatGroups.empty) {
        getUserChatGroups.forEach(group => groupPreview.push({id: group.id, data: group.data()}));
        if (groupPreview.length === 1) {
          groupId = groupPreview[0].id;
        }
      }
      return resolve({uid, groupId});
    });
  } catch (err) {
    console.log('error login firebase catched');
    return reject(err);
  }
});

export const logoutFirebase = () => new Promise(async(resolve, reject) => {
  try {
    await firebase.auth().signOut();
    if (window.unsubscribeCheckForGroup) {
      window.unsubscribeCheckForGroup();
      window.unsubscribeCheckForGroup = null;
    }
    window.unsubscribeUnreadMessages();
    return resolve();
  } catch (err) {
    return reject(err);
  }
});

