import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ElementRenderer from './ElementRenderer';

class Errors extends Component {
  render() {
    const { error } = this.props;
    return (
      <ElementRenderer
        {...this.props}
        render={() => (
          (error 
            ? <div className="alert alert-danger">
              <strong>Error!</strong> {error}
            </div>
            : null
          )
        )}/>
    );
  }
}

Errors.propTypes = {
  error: PropTypes.string
};

export default Errors;