/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isCheckLogin3RDPartyRequest } from 'containers/Login/actions';

class Auth extends PureComponent {

  constructor(props) {
    super(props);
    this.finishCreateAccount = this.finishCreateAccount.bind(this);
    this.state = {
      show: false
    };
  }

  finishCreateAccount() {
    //this.props.history.push('/');
  }

  componentDidMount() {
    const authCode = queryString.parse(this.props.location.search);
    if (authCode.loginWith === 'apple') {
      this.props.isCheckLogin3RDPartyRequest(authCode.token);
    } else {
      if (authCode.code) {
        this.props.loginWithLine(authCode.code, authCode.state).then(() => {
          this.finishCreateAccount();
        }).catch((err) => {
          this.setState({show: true});
        });
      }
    }
  }

  render() {
    return (
      <LoadingOverlay 
        text={'読み込み中です'} 
        active={true} 
        spinner 
      >
        <div style={{height: 'calc(100vh - 70px)'}}>
          {/* <TriggerSaveAccount endSaveAccount={this.finishCreateAccount}><div>12</div></TriggerSaveAccount> */}
        </div>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>ユーザーを認証できません!</Modal.Body>
          <Modal.Footer>
            <Link to='/'>
              <Button className='btn-warning' onClick={this.handleClose}>
                ホームページへ戻る
              </Button>
            </Link>
          </Modal.Footer>
        </Modal>
      </LoadingOverlay>
    );
  }
}

Auth.propTypes = {
  isCheckLogin3RDPartyRequest: PropTypes.func,
  loginWithLine: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
  isCheckLogin3RDPartyRequest: (token) => dispatch(isCheckLogin3RDPartyRequest(token)),
  loginWithLine: (code, state) => dispatch({ 
    type: 'LOGIN_LINE_REQUEST',
    code: code,
    state: state,
    meta: {thunk: true}
  })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(Auth));