import { fromJS } from 'immutable';
import { union } from 'lodash';

import {
  SEND_GOCHI_REQUEST,
  SEND_GOCHI_SUCCESS,
  ACCEPT_GOCHI_REQUEST,
  ACCEPT_GOCHI_SUCCESS,
  GET_STUDENT_GOCHI_REQUEST,
  GET_STUDENT_GOCHI_SUCCESS,
  GET_FOLLOWING_PRESIDENT_LIST_REQUEST,
  GET_FOLLOWING_PRESIDENT_LIST_SUCCESS,
  GET_FOLLOWING_PRESIDENT_LIST_FAILURE,
  SET_PRESIDENT_NOTIFY_SUCCESS,
  GET_FOLLOWER_STUDENT_LIST_REQUEST,
  GET_FOLLOWER_STUDENT_LIST_SUCCESS,
  GET_FOLLOWER_STUDENT_LIST_FAILURE,
  UPDATE_GOCHI_STATUS_SUCCESS,
  INITIALIZE_GOCHI
} from './actions';

const initialState = fromJS({
  isLoading: false,
  currentStudent: {},
  acceptedGochi: {
    page: 1,
    hasMore: true,
    list: [],
    data: {},
    isLoading: false
  },
  newGochi: {
    page: 1,
    hasMore: true,
    list: [],
    data: {},
    isLoading: false
  },
  gochiManagement: {
    data: {},
    list: [],
    page: 1,
    hasMore: true,
    isLoading: false
  }
});

export default function gochiEvent(state = initialState, action) {
  switch (action.type) {
    case SEND_GOCHI_REQUEST:
      return state.set('isLoading', true);
    case SEND_GOCHI_SUCCESS:
      return state.set('isLoading', false);
    case ACCEPT_GOCHI_REQUEST:
      return state.set('isLoading', true);
    case ACCEPT_GOCHI_SUCCESS:
      return state
        .set('isLoading', false)
        .setIn(['currentStudent', 'status'], 'ACCEPTED');
    case GET_STUDENT_GOCHI_REQUEST:
      return state.set('isLoading', true);
    case GET_STUDENT_GOCHI_SUCCESS:
      return state
        .set('isLoading', false)
        .set('currentStudent', action.payload);
    case GET_FOLLOWING_PRESIDENT_LIST_REQUEST: {
      const { status } = action.payload;
      const gochiType = status === 'NEW' ? 'newGochi' : 'acceptedGochi';
      return state.setIn([gochiType, 'isLoading'], true);
    }
    case GET_FOLLOWING_PRESIDENT_LIST_SUCCESS: {
      const { status, page, limit, list } = action.payload;
      const gochiType = status === 'NEW' ? 'newGochi' : 'acceptedGochi';

      const tempList = list.map((item) => {
        state = state.setIn([gochiType, 'data', item.objectId], fromJS(item));
        return item.objectId;
      });

      state = state.updateIn([gochiType, 'list'], (prevList) => {
        const oldList = prevList.toJS ? prevList.toJS() : prevList;
        return fromJS(union(oldList, tempList));
      });

      return state
        .setIn([gochiType, 'page'], page)
        .setIn([gochiType, 'isLoading'], false)
        .setIn([gochiType, 'hasMore'], list.length === limit);
    }
    case GET_FOLLOWING_PRESIDENT_LIST_FAILURE: {
      const { status } = action.payload;
      const gochiType = status === 'NEW' ? 'newGochi' : 'acceptedGochi';
      return state.setIn([gochiType, 'isLoading'], false);
    }
    case SET_PRESIDENT_NOTIFY_SUCCESS: {
      const { hasReceiveNotification, presidentId } = action.payload;
      return state.updateIn(
        ['acceptedGochi', 'data', presidentId],
        (prevData) => {
          const oldData = prevData.toJS ? prevData.toJS() : prevData;
          return fromJS({
            ...oldData,
            hasReceiveNotification
          });
        }
      );
    }

    case GET_FOLLOWER_STUDENT_LIST_REQUEST: {
      return state.setIn(['gochiManagement', 'isLoading'], true);
    }
    case GET_FOLLOWER_STUDENT_LIST_SUCCESS: {
      const { page, limit, list } = action.payload;

      const tempList = list.map((item) => {
        state = state.setIn(
          ['gochiManagement', 'data', item.objectId],
          fromJS(item)
        );
        return item.objectId;
      });

      state = state.updateIn(['gochiManagement', 'list'], (prevList) => {
        const oldList = prevList.toJS ? prevList.toJS() : prevList;
        const result = page > 1 ? union(oldList, tempList) : tempList;
        return fromJS(result);
      });

      return state
        .setIn(['gochiManagement', 'page'], page)
        .setIn(['gochiManagement', 'isLoading'], false)
        .setIn(['gochiManagement', 'hasMore'], list.length === limit);
    }
    case GET_FOLLOWER_STUDENT_LIST_FAILURE: {
      return state.setIn(['gochiManagement', 'isLoading'], false);
    }
    case UPDATE_GOCHI_STATUS_SUCCESS: {
      const { status, studentId } = action.payload;
      return state.updateIn(
        ['gochiManagement', 'data', studentId],
        (prevData) => {
          console.log('🚀 ~ file: reducer.js:143 ~ gochiEvent ~ prevData:', prevData);
          const oldData = prevData.toJS ? prevData.toJS() : prevData;
          return fromJS({
            ...oldData,
            presidentStatus: status
          });
        }
      );
    }

    case INITIALIZE_GOCHI:
      return initialState;
    default:
      return state;
  }
}
