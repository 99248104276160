import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import { closeModal } from './actions';
import './styles.scss';

const CustomModal = ({
  modalName, activeModal, closeModal, className = '', children, closeButton = false, ...props 
}) => (
  <Modal 
    show={activeModal === modalName} 
    onHide={closeModal} 
    className={`custom-modal-container ${className}`}
    {...props}
  >
    {closeButton && (
      <div className="close-modal" onClick={closeModal}>
        <img alt="" src="./images/Quit.svg" />
      </div>
    )}
    {children}
  </Modal>
);

CustomModal.propTypes = {
  closeButton: PropTypes.bool,
  closeModal: PropTypes.func,
  modalName: PropTypes.string,
  activeModal: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    activeModal: state.get('customModal').get('activeModal')
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(CustomModal));