import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { 
  SEND_SMS_NUMBER_PHONE_REQUEST,
  CHECK_OTP_REQUEST
} from './actions';
import get from 'lodash/get';
import { setCommonPopup } from 'containers/Popup/actions';
import { push } from 'connected-react-router';

function* handleSendSmsToNumberPhone(action) {
  try {
    const data = yield call(request, 'functions/sendVerificationToken', action.values, 'POST');
    action.callback(data);
  } catch (e) {
    yield put(setCommonPopup('success', {
      message: e.error || '問題が発生しました',
      btnLabel: '確認'
    }));
  }
}

function* handleCheckOtpRequest(action) {
  try {
    const infoAccount = JSON.parse(window.sessionStorage.getItem('account_login'));
    const infoRegisterAccount = JSON.parse(window.sessionStorage.getItem('account_register'));
    const infoAccount3rd = JSON.parse(window.sessionStorage.getItem('account_login_3rd'));
    const sessionToken = 
      get(infoAccount3rd, `${[1]}`) || atob(get(infoAccount, `${[3]}`) || get(infoRegisterAccount, `${[3]}`));
    const data = yield call(request, 'functions/checkVerificationToken', action.values, 'POST', {
      headers: { 'X-Parse-Session-Token': sessionToken }
    });

    action.callback(data);
  } catch (e) {
    if (e.code === 209) {
      if (sessionStorage.getItem('account_login_3rd')) sessionStorage.removeItem('account_login_3rd');
      if (sessionStorage.getItem('account_login')) sessionStorage.removeItem('account_login');
      if (sessionStorage.getItem('account_register')) sessionStorage.removeItem('account_register');
      if (sessionStorage.getItem('account_login') ||
        sessionStorage.getItem('account_register') ||
        sessionStorage.removeItem('account_login_3rd')) yield put(push('/'));
    }

    yield put(setCommonPopup('success', {
      message: e.error || '問題が発生しました',
      btnLabel: '確認',
      OKcallback: () => action.errorCallback(),
      backdrop: 'static',
      keyboard: false
    }));
  }
}

export default function* watchSMSVerification() {
  yield takeEvery(SEND_SMS_NUMBER_PHONE_REQUEST, handleSendSmsToNumberPhone);
  yield takeEvery(CHECK_OTP_REQUEST, handleCheckOtpRequest);
}

 