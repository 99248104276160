import { UNSET_ACCOUNT } from 'containers/Account/actions';
import { 
  GET_CURRENT_CAMPAIGN_FAILURE, GET_CURRENT_CAMPAIGN_REQUEST, GET_CURRENT_CAMPAIGN_SUCCESS,
  GET_POINT_HISTORY_REQUEST, GET_POINT_HISTORY_SUCCESS, GET_POINT_HISTORY_FAILURE
} from './action';
import { fromJS } from 'immutable';
import get from 'lodash/get';

const initialState = fromJS({
  loading: true,
  error: null,
  data: {},
  pointHistory: {
    loading: false,
    error: null,
    page: 1,
    limit: 5,
    data: {}
  }
});

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_CAMPAIGN_REQUEST:
      return state.set('loading', true).set('error', null);
    case GET_CURRENT_CAMPAIGN_SUCCESS:
      return state.set('data', action.payload).set('loading', false).set('error', null);
    case GET_CURRENT_CAMPAIGN_FAILURE:
      return state.set('loading', false).set('error', action.error);

    case GET_POINT_HISTORY_REQUEST: {
      const dataToJS = state.toJS();

      return state.set('pointHistory', {
        ...dataToJS.pointHistory,
        loading: true,
        error: null
      });
    }
    case GET_POINT_HISTORY_SUCCESS: {
      const dataToJS = state.toJS();
      return state.set('pointHistory', {
        ...dataToJS.pointHistory,
        data: {
          ...dataToJS.pointHistory.data,
          ...action.payload,
          dataHistoryList: [
            ...get(dataToJS, 'pointHistory.data.dataHistoryList', []),
            ...get(action, 'payload.dataHistoryList', [])
          ]
        },
        page: get(action, 'payload.dataPage.current_page', 1) + 1,
        loading: false,
        error: null
      });
    }
    case GET_POINT_HISTORY_FAILURE: {
      const dataToJS = state.toJS();
      return state.set('pointHistory', {
        ...dataToJS.pointHistory,
        data: {},
        loading: false,
        error: action.error
      });
    }
    case UNSET_ACCOUNT: {
      return state.set('data', {}); 
    }
    default:
      return state;
  }
};

export default campaignReducer;