import { fromJS, isImmutable } from 'immutable';
import _get from 'lodash/get';
import { 
  FETCH_MESSAGE_LIST_SUCCESS, 
  REMOVE_MESSAGE_LIST, 
  FETCH_UNREAD_MESSAGE_NUMBER_SUCCESS,
  SET_MOBILE_MSG_VIEW,
  FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_SUCCESS,
  ADD_TOP_EVENT_KEY_TO_MESSAGES_LIST
} from './actions';
import {
  READ_MESSAGES_SUCCESS
} from './MessageConversation/actions';

const initState = fromJS({
  mobileView: 0, //view = 0: messages list || view = 1: chat view
  list: [],
  badgeNumber: 0,
  page: 1,
  data: {
  },
  hasMore: true
});

export default function message(state = initState, action) {
  switch (action.type) {
    case FETCH_MESSAGE_LIST_SUCCESS: {
      let { list = [], page } = action;
      if (list.length === 0) {
        return state.set('hasMore', false);
      }
      list = list.map(cell => {
        state = state.setIn(['data', cell.objectId], cell);
        return cell.objectId;
      });
      state = state.update('list', oldList => {
        if (page === 1) {
          return fromJS(list);
        }
        
        oldList = oldList.toJS ? oldList.toJS() : oldList;
        return fromJS([...new Set(oldList.concat(list))]);
      });
      return state.set('page', page + 1);
    }
    case REMOVE_MESSAGE_LIST:
      return initState;
    case FETCH_UNREAD_MESSAGE_NUMBER_SUCCESS:
      return state.set('badgeNumber', action.number);
    case SET_MOBILE_MSG_VIEW:
      return state.set('mobileView', action.viewNumber);
    case READ_MESSAGES_SUCCESS:
      return state.setIn(['data', action.eventId, 'unreadMessage'], '0');
    case 'NEW_MESSAGE_COME': {
      const { message } = action;
      const { eventId } = message;
      state = state.update('list', oldList => {
        oldList = oldList.toJS ? oldList.toJS() : oldList;
        oldList.unshift(eventId);
        return fromJS([...new Set(oldList)]);
      });
      state = state.setIn(['data', eventId, 'objectId'], eventId);
      state = state.setIn(['data', eventId, 'lastActionTime'], new Date());
      state = state.setIn(['data', eventId, 'lastMessage'], _get(message, 'message', ''));
      if (!message.isMine) {
        state = state.setIn(['data', eventId, 'unreadMessage'], '1');
      }
      return state;
    }
    case FETCH_EVENT_DETAIL_FOR_NEW_CONVERSATION_SUCCESS: {
      const { event } = action;
      state = state.update('list', oldList => {
        oldList = oldList.toJS ? oldList.toJS() : oldList;
        if (oldList.includes(event.objectId)) {
          return fromJS(oldList);
        }

        oldList.unshift(event.objectId);
        return fromJS([...new Set(oldList)]);
      });
      let oldData = state.getIn(['data', event.objectId]);
      oldData = isImmutable(oldData) ? oldData.toJS() : oldData;
      state = state.setIn(['data', event.objectId], {...oldData, ...event});
      state = state.setIn(['data', event.objectId, 'unreadMessage'], '1');
      return state;
    }
    case ADD_TOP_EVENT_KEY_TO_MESSAGES_LIST: {
      const { eventId } = action;
      state = state.update('list', oldList => {
        oldList = oldList.toJS ? oldList.toJS() : oldList;
        oldList.unshift(eventId);
        return fromJS([...new Set(oldList)]);
      });

      return state;
    }
    default:
      return state;
  }
}