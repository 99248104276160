const validate = values => {
  const errors = {};

  if (values.get('votePresident') < 1 && values.get('hasJoin') === true) {
    errors.votePresident = 'Please Rating';
  }

  if (values.has('questionAnswers')) {
    if (!values.get('questionAnswers').has('QUESTIONNAIRE_HAS_APPLY_COMPANY')) 
      errors.questionAnswers = { QUESTIONNAIRE_HAS_APPLY_COMPANY: 'Please choose' };
  }
  return errors;
};

export default validate;