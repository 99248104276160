import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { setMobileMessageView } from 'containers/Message/actions';

class ChatTitle extends Component {
  render() {
    const { event = {}, currentUser, fromOperator, returnToListView } = this.props;
    let titleName = event && event.jCreatedBy && (event.jCreatedBy.displayName + '　社長');
  
    if (currentUser && currentUser.role === 'PRESIDENT') {
      titleName = event.eventTitle;
    }
    return (
      <>
        <div className="chat-title">
          <Link to='/messages'>
            <div className="chat-goback" onClick={returnToListView}>
              <img alt="arrow-go-back" src="/images/back.svg"/>
            </div>
          </Link>
          {fromOperator ?
            <span>社長メシ運営事務局</span>
            :
            <span onClick={() => this.props.history.push(`/event-detail/${event.objectId}`)}>
              {titleName}
            </span>
          }
        </div>
        <hr />
      </>
    );
  }
}

ChatTitle.propTypes = {
  event: PropTypes.object,
  fromOperator: PropTypes.bool,
  returnToListView: PropTypes.func,
  currentUser: PropTypes.object,
  history: PropTypes.any
};

const mapStateToProps = (state, ownProps) => {
  return {
    event: state.get('message').get('data').get(ownProps.eventId),
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  returnToListView: () => dispatch(setMobileMessageView(0))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withImmutablePropsToJS
)(ChatTitle);