import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { change } from 'redux-form/immutable';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import { getSearchedStudentsRequest, setSelectedStudents } from '../SearchStudents/actions';
import SearchStudentsForm from './SearchStudentsForm';
import { setCommonPopup } from 'containers/Popup/actions';
import { StudentManagementFilter, STUDENT_MANAGEMENT_FILTERS } from 'utilities/constants';
import Dropdown from 'components/Dropdown';
import './styles.scss';

const ManagementFilter = ({
  className = '',
  history,
  openUniversitySelector,
  change,
  getSearchedStudentsRequest,
  formValues,
  availableSearchOptions,
  totalPlanSlots,
  openConfirmPopup,
  industries,
  setSelectedStudents
}) => {
  const { location } = history;
  const query = queryString.parse(location.search);
  const initialValues = {
    graduationYear: _.get(query, 'graduationYear'),
    gender: _.get(query, 'gender'),
    university: [
      {
        name: '全ての大学',
        sort: '196',
        hidenInput: true,
        status: 'ACTIVE',
        hasExtraInput: false,
        objectId: 'u000000196'
      }
    ],
    hasJoinedEvent: false,
    hasAcceptedOfferEvent: false,
    justLogin: false
  };

  const handleOnClickDropdown = (keyName, value) => {
    query[keyName] = value;
    history.replace({
      pathname: location.pathname,
      search: `?${queryString.stringify(query)}`
    });
  };

  const handleOpenUniversitySelector = () => {
    openUniversitySelector({
      selectedUniversities: formValues.university,
      onConfirm: (values) => {
        change('university', values);
        openUniversitySelector({});
      }
    });
  };

  const renderFilterContent = () => {
    if (location.pathname === '/management-student/search') {
      return (
        <SearchStudentsForm
          initialValues={initialValues}
          onSubmit={() => {
            if (!totalPlanSlots) {
              openConfirmPopup({
                title: 'この機能はオファー機能ご契約企業のみ利用可能です。',
                isAlert: true,
                btnConfirm: 'OK'
              });
              return;
            }
            getSearchedStudentsRequest({ page: 1 });
            setSelectedStudents({
              selectedStudentIds: [],
              selectedAll: false
            });
          }}
          formValues={formValues}
          handleOpenUniversitySelector={handleOpenUniversitySelector}
          availableSearchOptions={availableSearchOptions}
          industries={industries}
        />
      );
    }
    return (
      <Dropdown
        id="students-filter"
        activeKey={_.get(query, 'status', STUDENT_MANAGEMENT_FILTERS.APPROVED)}
        onClickDropdown={(key) => handleOnClickDropdown('status', key)}
        options={StudentManagementFilter}
        className="filter-dropdown"
      />
    );
  };

  return (
    <div className={`management-filter-container ${className}`}>
      {renderFilterContent()}
    </div>
  );
};

ManagementFilter.propTypes = {
  className: PropTypes.string,
  activeFilter: PropTypes.string,
  history: PropTypes.object,
  formValues: PropTypes.object,
  openUniversitySelector: PropTypes.func,
  change: PropTypes.func,
  getSearchedStudentsRequest: PropTypes.func,
  availableSearchOptions: PropTypes.array,
  totalPlanSlots: PropTypes.number,
  openConfirmPopup: PropTypes.func,
  industries: PropTypes.array,
  setSelectedStudents: PropTypes.func
};

const mapStateToProps = (state) => {
  const planList = state.get('plans').get('list') || [];
  const availableSearchOptions = _.get(planList, '[0].availableOptions', []);
  return {
    formValues: state.getIn(['form', 'searchStudents', 'values']) || {},
    availableSearchOptions: availableSearchOptions
      .map((option) => option.replace('MESHI_', '')),
    industries: state.get('resources').get('industries')
  };
};

const mapDispatchToProps = (dispatch) => ({
  openUniversitySelector: (data) => dispatch(setCommonPopup('universitySelectorModal', data)),
  change: (field, value) => dispatch(change('searchStudents', field, value)),
  getSearchedStudentsRequest: (params) => dispatch(getSearchedStudentsRequest(params)),
  openConfirmPopup: (show) => dispatch(setCommonPopup('confirm', show)),
  setSelectedStudents: (params) => dispatch(setSelectedStudents(params))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS
)(ManagementFilter);
