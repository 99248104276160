import React, { Component } from 'react';
import './style.scss';

export default class MessageEmpty extends Component {
  render() {
    return (
      <div id="empty-msg">
        <img alt="" src="/images/empty-message.svg" />
        <div id="msg" className="text-center">ここには参加が確定した社長メシのグループチャットが表示されます</div>
      </div>
    );
  }
}
