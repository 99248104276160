import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { getMediaInfoByUrlRequest } from 'components/SocialMedia/actions';
import Image from 'components/Image';
import { setCommonPopup } from 'containers/Popup/actions';
import { requestNewSender } from 'containers/Message/MessageConversation/actions';
import { Link } from 'react-router-dom';
import { containsURL } from 'utilities/constants';
import _ from 'lodash';
import Linkify from 'react-linkify';
class ConversationCell extends Component {
  state = {
    linkData: {},
    isMounted: true
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (
      !_.isEqual(this.props.sender, nextProps.sender) ||
      !_.isEqual(this.props.item, nextProps.item)
    ) {
      return true;
    }
    if (!_.isEqual(this.state.linkData, nextState.linkData)) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    const { item } = this.props;
    if (item.id && item.contentType !== 'image') {
      const msgHasLink = item.content.match(containsURL);
      if (msgHasLink && msgHasLink[0]) {
        this.props
          .getMediaInfoByUrl(msgHasLink[0])
          .then((linkData) => {
            if (this.state.isMounted) {
              this.setState({ linkData });
            }
          })
          .catch(() => {
            this.setState({ linkData: { url: msgHasLink[0] } });
          });
      }
    }
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  avatarImg = () => {
    const { item = {}, sender = {}, currentUser } = this.props;
    const viewPressident = sender.role === 'PRESIDENT';
    const isPresident = currentUser.role === 'PRESIDENT';

    if ((viewPressident || isPresident) && item.senderId) {
      return (
        <Link
          to={{
            pathname: `/${
              sender.role === 'PRESIDENT' ? 'presidentDetail' : 'studentDetail'
            }/${item.senderId}`,
            state: {
              fromInternalLink: true
            }
          }}
        >
          <img
            alt=""
            src={
              (sender.avatarImage && sender.avatarImage.thumbSmall) ||
              '/images/avatar-default-complete.svg'
            }
          />
        </Link>
      );
    }
    return (
      <img
        alt=""
        src={
          (sender.avatarImage && sender.avatarImage.thumbSmall) ||
          '/images/avatar-default-complete.svg'
        }
      />
    );
  };

  renderMessageFromOperator = (
    item,
    openLightbox,
    msgIsOnlyLink,
    msgHasLink
  ) => {
    const createdTime = moment.unix(item.createdAt.seconds).toISOString();
    if (item.contentType === 'image') {
      return (
        <>
          <div className="img-container">
            <img
              alt=""
              className="img-msg"
              src={item.content}
              onClick={() => openLightbox(item.content)}
            />
          </div>
          <div className="time text-right">
            {moment(createdTime).format('HH:mm')}
          </div>
        </>
      );
    } else {
      if (!msgHasLink) {
        return (
          <>
            <div className="msg">{item.content}</div>
            <div className="time text-right">
              {moment(createdTime).format('HH:mm')}
            </div>
          </>
        );
      } else {
        if (msgIsOnlyLink) {
          return (
            <>
              {this.renderLinkPreview()}
              <div className="time text-right">
                {moment(createdTime).format('HH:mm')}
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="text-and-link-container">
                <div className="msg">{item.content}</div>
                {this.renderLinkPreview()}
              </div>
              <div className="time text-right">
                {moment(createdTime).format('HH:mm')}
              </div>
            </>
          );
        }
      }
    }
  };

  renderLinkPreview = () => {
    const { linkData } = this.state;
    return (
      <a href={linkData.url} target="_blank" rel="noopener noreferrer">
        <div className="link-media">
          <Image
            alt=""
            src={linkData.image}
            defaultSrc="/images/default-event.svg"
          />
          <div className="link-media__info">
            <div className="link-media__info__host-name">
              {linkData.host_name || linkData.url}
            </div>
            <div className="link-media__info__title ellipsis">
              <div>{linkData.title}</div>
            </div>
          </div>
        </div>
      </a>
    );
  };

  render() {
    const { item = {}, sender = {}, openLightbox } = this.props;
    if (item.id) {
      let msgIsOnlyLink = false;
      let msgHasLinkAndText = false;
      const msgHasLink = item.content.match(containsURL);
      if (msgHasLink && msgHasLink[0] === item.content) {
        msgIsOnlyLink = true;
      }
      if (msgHasLink && msgHasLink.length > 0 && !msgIsOnlyLink) {
        msgHasLinkAndText = true;
      }
      return (
        <div className={`cvs-cell ${msgHasLinkAndText && 'with-link'}`}>
          <div className="name">社長メシ運営事務局</div>
          <div className="detail">
            <img
              alt=""
              className="operator-avatar"
              src="/images/shacho-meshi-operator.svg"
            />
            {this.renderMessageFromOperator(
              item,
              openLightbox,
              msgIsOnlyLink,
              msgHasLink
            )}
          </div>
        </div>
      );
    }
    if (!sender.displayName) {
      this.props.requestNewSender(item.senderId);
    }
    return (
      <div className="cvs-cell">
        <div className="name">{sender.displayName}</div>
        <div className="detail">
          {this.avatarImg()}
          {item.media ? (
            <div className="img-container">
              <img
                alt=""
                className="img-msg"
                src={item.media}
                onClick={() => openLightbox(item.media)}
              />
            </div>
          ) : (
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  href={decoratedHref}
                  key={key}
                  target="_blank"
                  rel="noreferrer"
                >
                  {decoratedText}
                </a>
              )}
            >
              <div className="msg">{item.message}</div>
            </Linkify>
          )}
          <div className="time">{moment(item.createdAt).format('HH:mm')}</div>
        </div>
      </div>
    );
  }
}

ConversationCell.propTypes = {
  currentUser: PropTypes.object,
  item: PropTypes.object,
  sender: PropTypes.object,
  openLightbox: PropTypes.func,
  requestNewSender: PropTypes.func,
  getMediaInfoByUrl: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.get('account').get('data'),
    sender: state
      .get('conversation')
      .get('sendersData')
      .get(ownProps.item.senderId)
  };
};

const mapDispatchToProps = (dispatch) => ({
  openLightbox: (url) => dispatch(setCommonPopup('lightbox', { url })),
  getMediaInfoByUrl: (url) => dispatch(getMediaInfoByUrlRequest(url)),
  requestNewSender: (senderId) => dispatch(requestNewSender(senderId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(ConversationCell));
