import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import Map from 'components/EventDetail/Map';
import EventInfo from 'components/EventDetail/EventInfo';
import './styles.scss';

const EventDetail = ({ event }) => {
  const eventImage = _.get(event, 'image.thumbLarge', '/images/default-event.svg');
  const date = moment(event.eventDate).format('YYYY/MM/DD (ddd)');
  const timestart = moment(event.eventDate).local().format('HH:mm');
  const timeend = moment(event.eventDate).add(event.duration, 'minutes').local().format('HH:mm');

  return (
    <div className='event-detail-container'>
      <div className='details-card'>
        <div className='date-time-loc'>
          <div className='row-detail'>
            <div className='icon'>
              <img src='/images/icon-grey-copy-2.svg' alt='calendar' className='info-items-icon' />
            </div>
            <div className='info-items-text'> {date} </div>
          </div>
          <div className='row-detail'>
            <div className='icon'>
              <img src='/images/combined-shape.svg' alt='clock' className='info-items-icon' />
            </div>
            <div className='info-items-text'> {timestart + '~' + timeend} </div>
          </div>
          <div className='row-detail'>
            <div className='icon'>
              <img src='/images/shape.svg' alt='pin' className='info-items-icon' />
            </div>
            <div title={event.location} className='info-items-text'><span>{event.location}</span> </div>
          </div>
        </div>
        <div className='map-container'>
          <Map eventInfo={event} />
        </div>
      </div>

      <div className="event-image-container">
        <div className="graduate-title">
          {_.get(event, 'graduateYear.name', '')}
        </div>

        <div className="event-thumbnail">
          <div 
            style={{
              backgroundImage: 
                `linear-gradient(to right, rgba(0, 0, 0, 0.1), rgb(0, 0, 0), rgba(0, 0, 0, 0.1)), url("${eventImage}")`
            }}
          />
          <img
            src={eventImage}
            alt="社長メシ"
          />
        </div>
      </div>

      <div className="event-info-container">
        <EventInfo eventInfo={{
          capacity: event.capacity,
          explanation: event.explanation,
          newDescription: event.newDescription,
          graduateYear: event.graduateYear && event.graduateYear.name,
          prefecture: event.prefecture && event.prefecture.name,
          offerEvent: event.offerEvent && event.offerEvent
        }} />
      </div>
    </div>
  );
};

EventDetail.propTypes = {
  event: PropTypes.object
};

export default EventDetail;
