export const FETCH_EVENT_BY_TAG_REQUEST = 'FETCH_EVENT_BY_TAG_REQUEST';
export const FETCH_EVENT_BY_TAG_SUCCESS = 'FETCH_EVENT_BY_TAG_SUCCESS';
export const FETCH_EVENT_BY_TAG_FAILURE = 'FETCH_EVENT_BY_TAG_FAILURE';
export const RESET_EVENT_BY_TAG = 'RESET_EVENT_BY_TAG';

export function requestEventsByTag(tag, payload) {
  return {type: FETCH_EVENT_BY_TAG_REQUEST, tag, payload};
}

export function receiveEventsByTag(events, page) {
  return {type: FETCH_EVENT_BY_TAG_SUCCESS, events: events, page: page};
}

export function resetEventsByTag() {
  return { 
    type: RESET_EVENT_BY_TAG
  };
}