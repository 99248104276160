import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { PRESIDENT_UPDATE_PROFILE, presidentUpdateProfileSuccess, presidentUpdateProfileFail} from './actions';
import { getCurrentAccount } from './../Account/actions';
import { stopSubmit } from 'redux-form';

function* handlePresidentUpdateProfile(action) {
  const { meta, message } = action; 
  try {
    if (action.isRequestJoinEvent) {
      yield call(request, 'functions/updateUserInformation', action.values, 'POST', {
        headers: { 'X-Parse-Session-Token': action.sessionToken }
      });
      //yield put(getCurrentAccount(action.sessionToken, 'PRESIDENT_Update_PROFILE'));
    } else {
      yield call(request, 'functions/updateUserInformation', action.values, 'POST', {
        headers: { 'X-Parse-Session-Token': action.sessionToken }
      });
      yield put(getCurrentAccount(action.sessionToken, message || 'PRESIDENT_UPDATE_PROFILE'));
      yield put(presidentUpdateProfileSuccess(meta));
    }
  } catch (e) {
    yield put(stopSubmit('PresidentProfileForm', { _error: e.error }));
    yield put(presidentUpdateProfileFail(meta));
  }
}

export function* watchPresidentUpdateProfile() {
  yield takeEvery(PRESIDENT_UPDATE_PROFILE, handlePresidentUpdateProfile);
}

 