import React, {Component} from 'react';
import {connect} from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import {  presidentViewApprovalNotification } from './actions';

class ApprovalNotification extends Component {

  constructor(props) {
    super(props);
    this.handleAction = this.handleAction.bind(this);
  }

  handleAction() {
    this.props.presidentViewApprovalNotification();
  }

  render() {
    if (
      this.props.currentUser &&
      this.props.currentUser.role === 'PRESIDENT' &&
      this.props.currentUser.approvalNotification
    ) {
      return (
        <Modal show={true} onHide={this.handleAction}>
          <Modal.Header closeButton>
            <Modal.Title >
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            アカウントが承認されました。これよりすべての機能がご利用いただけます。
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleAction} className="btn btn-warning"> ok </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return null;

  }
}

ApprovalNotification.propTypes = {
  currentUser: PropTypes.any,
  presidentViewApprovalNotification: PropTypes.func
};

ApprovalNotification.defaultProps = {
  setPopupNotice: () => null
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  presidentViewApprovalNotification: () => dispatch(presidentViewApprovalNotification())
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withImmutablePropsToJS(ApprovalNotification));
