import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { getValueByKey } from 'containers/Popup/selectors';
import { setCommonPopup } from 'containers/Popup/actions';
import './style.scss';

class LightboxControl extends Component {
  componentWillReceiveProps(nextProps) {
    const { show } = nextProps;
    show && this.props.openLightbox(show.gallery[show.index]);
  }

  render() {
    const { show, showLightboxControl } = this.props;
    const { gallery, index } = show;

    if (!show || (show && gallery.length === 1)) return null;

    return (
      <div id="lightbox-control">
        <div 
          id="left-lightbox-control" 
          onClick={() => {
            const prevIndex = index === 0 ? gallery.length - 1 : index - 1;
            showLightboxControl(gallery, prevIndex);
          }}
        >
          <img alt="" src="/images/in-active-copy.svg" />
        </div>

        <div 
          id="right-lightbox-control" 
          onClick={() => {
            const nextIndex = index === gallery.length - 1 ? 0 : index + 1;
            showLightboxControl(gallery, nextIndex);
          }}
        >
          <img alt="" src="/images/active-copy.svg" />
        </div>
      </div>
    );
  }
}

LightboxControl.propTypes = {
  show: PropTypes.any,
  openLightbox: PropTypes.func,
  showLightboxControl: PropTypes.func
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, 'lightboxControl')
  };
};

const mapDispatchToProps = dispatch => ({
  openLightbox: url => dispatch(setCommonPopup('lightbox', { url })),
  showLightboxControl: (gallery, index) => dispatch(setCommonPopup('lightboxControl', { gallery, index }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(LightboxControl));