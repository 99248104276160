import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { setCommonPopup } from 'containers/Popup/actions';
import { getUserCurrentLocation } from '../../utilities/locationHelpers';
import { connect } from 'react-redux';
import _ from 'lodash';
import './style.scss';

class MapPinMarker extends Component {
  constructor(props) {
    super(props);
    this.mapRef = null;
    this.searchBox = null;
    this.libraries = ['places'];
    this.locationFailMessage = {
      title: 'Error',
      message: '住所が見つかりません'
    };
    this.defaultLocation = {
      lat: 35.658253,
      lng: 139.783997,
      address: 'とうきょう',
      checkMapload: false
    }; // default location in case cant get any location
    this.geocoder = null;
    this.state = {
      ...this.defaultLocation
    };
  }

  handleClickOnMap = async e => {
    const { latLng } = e;
    if (this.geocoder) {
      this.geocoder.geocode(
        {
          location: {
            lat: latLng.lat(),
            lng: latLng.lng()
          }
        },
        (result, status) => {
          if (status === 'OK') {
            this.setState({
              lat: latLng.lat(),
              lng: latLng.lng(),
              address: result[0].formatted_address
            });
          } else {
            this.setState({
              lat: latLng.lat(),
              lng: latLng.lng(),
              address: ''
            });
          }
        }
      );
    } else {
      this.setState({
        lat: latLng.lat(),
        lng: latLng.lng(),
        address: ''
      });
    }
  };

  onDragEnd = async() => {
    // Can't get the current center of the map when drag without using ref
    const center = this.mapRef.getCenter();
    if (this.geocoder) {
      this.geocoder.geocode(
        {
          location: {
            lat: center.lat(),
            lng: center.lng()
          }
        },
        (result, status) => {
          if (status === 'OK') {
            this.setState({
              lat: center.lat(),
              lng: center.lng(),
              address: result[0].formatted_address
            });
          } else {
            this.setState({
              lat: center.lat(),
              lng: center.lng(),
              address: ''
            });
          }
        });
    } else {
      this.setState({
        lat: center.lat(),
        lng: center.lng(),
        address: ''
      });
    }
  }

  onLoad = autocomplete => {
    this.searchBox = autocomplete;
  };

  onPlaceChanged = () => {
    if (this.searchBox !== null) {
      const places = this.searchBox.getPlaces();
      const location = _.get(places[0], 'geometry.location');
      if (!_.isEmpty(location)) {
        this.setState({
          lat: location.lat(),
          lng: location.lng(),
          address: _.get(places[0], 'formatted_address')
        });
      }
    }
  }
  
  componentDidUpdate(prevProp, prevState) {
    const {
      location,
      isOpenMapModal,
      popUpFailToSearch,
      longitude,
      latitude
    } = this.props;
    const longitudeValue = _.get(longitude, 'input.value');
    const latitudeValue = _.get(latitude, 'input.value');
    if (isOpenMapModal && !prevProp.isOpenMapModal) {
      // Compare current location with current position of the marker when open the modal
      // If they are not the same, set the current marker position same as the current location
      if (longitudeValue && latitudeValue) {
        this.setState({
          lat: Number(latitudeValue),
          lng: Number(longitudeValue)
        });
      }
    }
    // Only find the the place when open the the modal and havent save any location yet
    if (
      isOpenMapModal &&
      (!prevProp.isOpenMapModal || (!prevState.checkMapload && this.state.checkMapload)) &&
      !longitudeValue &&
      !latitudeValue &&
      this.geocoder
    ) {
      this.geocoder.geocode(
        { address: location },
        async(result, status) => {
          try {
            if (status === 'OK') {
              const address = _.get(result[0], 'geometry.location');
              this.setState({
                lat: address.lat(),
                lng: address.lng(),
                address: result[0].formatted_address
              });
            } else {
              if (location) {
                popUpFailToSearch();
              }
              throw new Error();      
            }
          } catch (err) {
            // Need update msg in case the user doesn't allow the browser to get current location of the user
            this.handleGetUserCurrentLocation();
          }
        }  
      );
    }
  }

  handleSaveLocation = () => {
    const {latitude, longitude} = this.props;
    const longitudeValue = _.get(longitude, 'input.value');
    const latitudeValue = _.get(latitude, 'input.value');
    if (!longitudeValue && !latitudeValue) {
      this.props.changeLocation('location', this.state.address);
    } // update address to field when save the location first time
    // Only update the input of latitude and longitude when user decide to save
    latitude.input.onChange(this.state.lat);
    longitude.input.onChange(this.state.lng);
    this.props.closeMapModal();
  }

  handleGetUserCurrentLocation = async() => {
    try {
      const currentUserLocation = await getUserCurrentLocation();
      this.geocoder.geocode(
        {
          location: {
            lat: currentUserLocation.latitude,
            lng: currentUserLocation.longitude
          }
        },
        (result, status) => {
          if (status === 'OK') {
            this.setState({
              lat: currentUserLocation.latitude,
              lng: currentUserLocation.longitude,
              address: result[0].formatted_address
            });
          } else {
            throw new Error();
          }
        }
      );
    } catch {
      // Need update msg in case the user doesn't allow the browser to get current location of the user
      this.setState({
        ...this.defaultLocation
      });
    }
  }

  render() {
    const {latitude, longitude} = this.props;
    const longitudeValue = _.get(longitude, 'input.value');
    const latitudeValue = _.get(latitude, 'input.value');
    return (
      <React.Fragment>
        <Modal
          className='map-input-modal'
          show={this.props.isOpenMapModal}>
          <Modal.Header>
            <div
              className='map-input-modal-close'
              onClick={this.props.closeMapModal}
            >
              <img src='images/Quit.svg' alt='currentLocation' />
            </div>
            <div className='map-input-modal-title'>
              {
                !(longitudeValue && latitudeValue) ? '住所を選択' : '住所を編集' 
              }
            </div>
          </Modal.Header>
          <Modal.Body>
            <LoadScript
              id='script-loader'
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_PLACE_API_KEY}
              libraries={this.libraries}
              language='ja'
              onLoad={() => {
                this.geocoder = new window.google.maps.Geocoder();
                this.setState({ checkMapload: true});
              }}
            >
              <StandaloneSearchBox
                onLoad={this.onLoad}
                onPlacesChanged={this.onPlaceChanged}
              >
                <input
                  type="text"
                  placeholder="検索場所"
                  className='map-input-autocomplete'
                />
              </StandaloneSearchBox>
              <GoogleMap
                ref={this.map}
                onLoad={map => this.mapRef = map }
                zoom={16}
                center={this.state}
                mapContainerClassName={
                  `map-container
                  ${(longitudeValue && latitudeValue) && 'map-container-address-hide'}`
                }
                onCenterChanged={this.onCenterChanged}
                onClick={this.handleClickOnMap}
                onDragEnd={this.onDragEnd}
                options={{
                  streetViewControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false
                }}
              >
                <Marker
                  position={this.state}
                  icon='images/pin.svg'
                />
                <div className='map-input-current-location-button'>
                  <button
                    onClick={this.handleGetUserCurrentLocation}
                  >
                    <img src='images/currentLocation.png' alt='currentLocation' />
                  </button>
                </div>
              </GoogleMap>
            </LoadScript>
          </Modal.Body>
          <Modal.Footer>
            { !(longitudeValue && latitudeValue) &&
            <div className='map-input-address-display'>
              {this.state.address}    
            </div>
            }
            <button
              className='btn btn-block map-container-input-save'
              onClick={this.handleSaveLocation}  
            >
              保存
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

MapPinMarker.propTypes = {
  isOpenMapModal: PropTypes.bool.isRequired,
  closeMapModal: PropTypes.func.isRequired,
  location: PropTypes.string,
  latitude: PropTypes.object,
  longitude: PropTypes.object,
  changeLocation: PropTypes.func,
  popUpFailToSearch: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  popUpFailToSearch: () => {
    dispatch(setCommonPopup('success', {
      title: 'エラー',
      message: '住所が見つかりません'
    }
    ));
  }
});

export default connect(null, mapDispatchToProps)(MapPinMarker);