import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { getMediaInfoByUrlRequest } from 'components/SocialMedia/actions';
import Image from 'components/Image';
import { setCommonPopup } from 'containers/Popup/actions';
import { containsURL } from 'utilities/constants';
import _ from 'lodash';
import Linkify from 'react-linkify';

class MyConversationCell extends Component {
  state = {
    linkData: {},
    isMounted: true
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(this.props.item, nextProps.item)) {
      return true;
    }
    if (!_.isEqual(this.state.linkData, nextState.linkData)) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    const { item } = this.props;
    if (item.id && item.contentType !== 'image') {
      const msgHasLink = item.content.match(containsURL);
      if (msgHasLink && msgHasLink[0]) {
        this.props
          .getMediaInfoByUrl(msgHasLink[0])
          .then((linkData) => {
            if (this.state.isMounted) {
              this.setState({ linkData });
            }
          })
          .catch(() => {
            this.setState({ linkData: { url: msgHasLink[0] } });
          });
      }
    }
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  renderMessageSentToOperator = (item, openLightbox) => {
    let msgIsOnlyLink = false;
    const msgHasLink = item.content.match(containsURL);
    if (msgHasLink && msgHasLink[0] === item.content) {
      msgIsOnlyLink = true;
    }
    const createdTime = moment.unix(item.createdAt.seconds).toISOString();

    if (item.contentType === 'image') {
      return (
        <div className="my-cvs-cell">
          <div className="time text-right">
            {moment(createdTime).format('HH:mm')}
          </div>
          <div className="img-container">
            <img
              alt=""
              className="img-msg"
              src={item.content}
              onClick={() => openLightbox(item.content)}
            />
          </div>
        </div>
      );
    } else {
      if (!msgHasLink) {
        return (
          <div className="my-cvs-cell">
            <div className="time text-right">
              {moment(createdTime).format('HH:mm')}
            </div>
            <div className="msg">{item.content}</div>
          </div>
        );
      } else {
        if (msgIsOnlyLink) {
          return (
            <div className="my-cvs-cell">
              <div className="time text-right">
                {moment(createdTime).format('HH:mm')}
              </div>
              {this.renderLinkPreview()}
            </div>
          );
        } else {
          return (
            <>
              <div className="my-cvs-cell with-link">
                <div className="time text-right">
                  {moment(createdTime).format('HH:mm')}
                </div>
                <div className="text-and-link-container">
                  <div className="msg">{item.content}</div>
                  {this.renderLinkPreview()}
                </div>
              </div>
            </>
          );
        }
      }
    }
  };

  renderLinkPreview = () => {
    const { linkData } = this.state;
    return (
      <a href={linkData.url} target="_blank" rel="noopener noreferrer">
        <div className="link-media">
          <Image
            alt=""
            src={linkData.image}
            defaultSrc="/images/default-event.svg"
          />
          <div className="link-media__info">
            <div className="link-media__info__host-name">
              {linkData.host_name || linkData.url}
            </div>
            <div className="link-media__info__title ellipsis">
              <div>{linkData.title}</div>
            </div>
          </div>
        </div>
      </a>
    );
  };

  render() {
    const { item, openLightbox } = this.props;
    if (item.id) {
      return <>{this.renderMessageSentToOperator(item, openLightbox)}</>;
    }
    return (
      <div className="my-cvs-cell">
        <div className="time text-right">
          {moment(item.createdAt).format('HH:mm')}
        </div>
        {item.media ? (
          <div className="img-container">
            <img
              alt=""
              className="img-msg"
              src={item.media}
              onClick={() => openLightbox(item.media)}
            />
          </div>
        ) : (
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                href={decoratedHref}
                key={key}
                target="_blank"
                rel="noreferrer"
              >
                {decoratedText}
              </a>
            )}
          >
            <div className="msg">{item.message}</div>
          </Linkify>
        )}
      </div>
    );
  }
}

MyConversationCell.propTypes = {
  item: PropTypes.object,
  openLightbox: PropTypes.func,
  getMediaInfoByUrl: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  openLightbox: (url) => dispatch(setCommonPopup('lightbox', { url })),
  getMediaInfoByUrl: (url) => dispatch(getMediaInfoByUrlRequest(url))
});

export default connect(
  null,
  mapDispatchToProps
)(withImmutablePropsToJS(MyConversationCell));
