import { fromJS } from 'immutable';
import { FETCH_NEW_EVENTS_SUCCESS, RESET_NEW_EVENTS } from './actions';
import _ from 'lodash';

const initState = fromJS({
  events: [],
  page: 1,
  hasMore: true,
  lastCreatedAt: new Date()
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function newEvents(state = initState, action) {
  switch (action.type) {
    case FETCH_NEW_EVENTS_SUCCESS: {
      let newState = state.update('events', v => {
        const result = action.page > 1 ? v.concat(fromJS(action.events)) : fromJS(action.events);
        // remove duplicates
        return fromJS(_.unionBy(result.toJS(), 'objectId'));
      });

      if (_.isEmpty(action.events)) {
        newState = newState.set('hasMore', false);
      }
      return newState.set('page', action.page).set('lastCreatedAt', _.get(action.events.pop(), 'createdAt'));
    }
    case RESET_NEW_EVENTS: {
      return initState;
    }
    default:
      return state;
  }
}
