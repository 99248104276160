import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PhotoSelector from 'containers/CreateEvent/PhotoSelector';
import { fromJS } from 'immutable';
import './style.scss';

class EventPhoto extends Component {
  constructor(props) {
    super(props);
    let { input } = props;
    input = input.value || {};
    input = input.toJS ? input.toJS() : [];
    this.state = {
      images: input || {}
    };
  }

  componentWillReceiveProps(newProps) {
    let { input } = newProps;
    input = input.value || {};
    input = input.toJS ? input.toJS() : [];

    let newstate = {
      images: input || {}
    };
    if (!_.isEqual(newstate.images, this.state.images)) {
      this.setState(newstate);
    }
  }

  addItem = (value) => {
    let images = this.state.images;
    images = {
      objectId: value.imageId,
      imageUrl: value.imageUrl
    };
    this.setState({
      images
    });
    this.props.input.onChange(fromJS(images));
  };

  removeItem = () => {
    let images = this.state.images;
    images = {
      objectId: null,
      imageUrl: null
    };
    this.setState({
      images
    });

    this.props.input.onChange(fromJS(images));
  };
  render() {
    let images = this.state.images;
    return (
      <Fragment>
        <div className="row">
          <div id="event-photo" className="photo-selector-container">
            <PhotoSelector
              imageId={images.objectId}
              imageUrl={images.imageUrl}
              addItem={(value) => {
                this.addItem(value);
              }}
              removeItem={() => {
                this.removeItem();
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

EventPhoto.propTypes = {
  input: PropTypes.any.isRequired
};

export default EventPhoto;