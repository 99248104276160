import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown as BootstrapDropdown, MenuItem } from 'react-bootstrap';
import _get from 'lodash/get';

import './styles.scss';

const Dropdown = ({
  activeKey,
  options,
  placeholder = '',
  onClickDropdown,
  className = '',
  hiddenActive = false,
  ...props
}) => {
  const activeMenu = options.find((option) => activeKey === option.value);

  return (
    <BootstrapDropdown className={`dropdown-container ${className}`} {...props}>
      <BootstrapDropdown.Toggle className="dropdown-toggle">
        <div className="menu-name">
          {_get(activeMenu, 'icon', '') && (
            <img src={activeMenu.icon} alt="icon" className="menu-icon" />
          )}
          {_get(activeMenu, 'name', placeholder)}
        </div>
      </BootstrapDropdown.Toggle>
      <BootstrapDropdown.Menu className="dropdown-list">
        {options.map((option) => {
          if (hiddenActive && activeKey === option.value) return null;
          return (
            <MenuItem
              active={activeKey === option.value}
              disabled={activeKey === option.value}
              key={option.value}
              className={`dropdown-item ${option.value === '' && 'item-placeholder'}`}
              onClick={() => onClickDropdown(option.value)}
              {...option}
            >
              {_get(option, 'icon', '') && (
                <img src={option.icon} alt="icon" className="item-icon" />
              )}
              {_get(option, 'name', '')}
            </MenuItem>
          );
        })}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  );
};

Dropdown.propTypes = {
  activeKey: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  hiddenActive: PropTypes.bool,
  onClickDropdown: PropTypes.func
};

export default Dropdown;
