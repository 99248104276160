import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ja';
import _get from 'lodash/get';

class EventListSmallcell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const eventInfo = this.props.eventInfo || {};
    const presidentAvatar = 
    _get(eventInfo, 'jCreatedBy.avatarImage.thumbLarge', 'images/shacho-meshi-operator.svg');
    let avatarURL = eventInfo.image ? eventInfo.image.thumbLarge : presidentAvatar;
    const { status, statusText } = eventInfo.eventStateInfo || {};
    let btnClass = '';
    if (status === 'LOCKED') {
      btnClass = 'status-label lb-locked';
    }
    if (status === 'REQUESTED' || status === 'WANTED') {
      btnClass = 'status-label lb-req-join';
    }
    if (status === 'EVENT_FINISHED' || status === 'WANTED_EXIT' || status === 'EVENT_CANCELED') {
      btnClass = 'status-label lb-finish';
    }

    return (
      <Link to={'/event-detail/' + eventInfo.objectId}>
        <div className="event-cell">
          <div className="event-cell-row">
            <div className="event-info-wrapper">
              <div>
                <img 
                  className={`event-img-sm-container ${this.props.type ? 'br-2' : 'br-50' }`} 
                  src={avatarURL} 
                  alt="社長メシ"
                />
              </div>

              <div className={`event-cell-base-info ${!this.props.type && 'h-48'}`}>
                <div className="ellip-overflow event-title">
                  {eventInfo.name}
                </div>

                {eventInfo.eventDate && (
                  <div className="ellip-overflow time">
                    <div className="calendar" />
                    <div className="text" >
                      {moment(eventInfo.eventDate).format('YYYY/MM/DD (ddd)')}
                    </div>
                  </div>
                )}

                {this.props.type && eventInfo.eventDate && (
                  <div className="ellip-overflow time">
                    <div className="clock" />
                    <div className="text" >
                      {moment(eventInfo.eventDate).format('HH:mm')}~
                      {moment(eventInfo.eventDate).add(eventInfo.duration, 'm').format('HH:mm')}
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <div className="status-lable-container text-center">
              <div className={btnClass}>{statusText}</div>
            </div>
            
          </div>
        </div>
      </Link>
    );
  }
}

EventListSmallcell.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  role: PropTypes.string,
  type: PropTypes.string
};

export default EventListSmallcell;