/* eslint-disable no-prototype-builtins */
import isEmail from 'sane-email-validation';
import _ from 'lodash';
import { universityHasExtraInput } from 'utilities/handleValues';
import { isValidDate } from 'utilities/date';
import moment from 'moment-timezone';
import { PHONE_REGEX } from 'utilities/constants';

function telephoneCheck(str) {
  return !!PHONE_REGEX.test(str);
}

const validate = values => {
  const errors = {};  
  // fullname
  const fullname = values.get('fullname');
  if (!fullname || _.isEmpty(_.trim(fullname))) {
    errors.fullname = 'Required';
  } else if (fullname.length > 15) {
    errors.fullname = 'Max 15 character length';
  }
  // nameHiragana
  const nameHiragana = values.get('nameHiragana');
  if (!nameHiragana || _.isEmpty(_.trim(nameHiragana))) {
    errors.nameHiragana = 'Required';
  } else if (nameHiragana.length > 15) {
    errors.nameHiragana = 'Max 15 character length';
  }
  // birthday
  const birthday = values.get('birthday');
  if (!birthday) {
    errors.birthday = 'Required';
  } else if (birthday.length !== 10 || !isValidDate(birthday)) {
    errors.birthday = 'Invalid date';
  }

  if (!errors.birthday && moment().diff(birthday, 'years') < 15) {
    errors.birthday = '誕生日は15歳以上である必要があります';
  }
  // gender
  const gender = values.get('gender');
  if (!gender) {
    errors.gender = 'この値は必須です。';
  }
  //phone
  // const phone = values.get('phone');
  // if (!phone) {
  //   errors.phone = 'Required';
  // } else if (!telephoneCheck(phone)) {
  //   errors.phone = 'Invalid Phone Number';
  // }
  // email
  const email = values.get('email');
  if (!email) {
    errors.email = 'Required';
  } else if (!isEmail(email)) {
    errors.email = 'Invalid email';
  }
  //
  if (!errors.hasOwnProperty('studentProfile')) {
    errors.studentProfile = {};
  }
  //
  // club
  const club = values.get('studentProfile') && values.get('studentProfile').get('club');
  if (!club || _.isEmpty(_.trim(club))) {
    if (!errors.hasOwnProperty('studentProfile')) {
      errors.studentProfile = {};
    }
    errors.studentProfile.club = 'Required';
  }
  // intro
  const introduction = values.get('studentProfile') && values.get('studentProfile').get('introduction');
  if (!introduction || _.isEmpty(_.trim(introduction))) {
    errors.studentProfile.introduction = 'Required';
  }
  
  //companytag
  const companyTags = values.get('studentProfile') && values.get('studentProfile').get('companyTags');
  if (!companyTags || _.isEmpty(companyTags)) {
    errors.studentProfile.companyTags = 'Required';
  }
  // university
  const universityId = values.get('studentProfile') && values.get('studentProfile').get('universityId');
  if (!universityId || _.isEmpty(_.trim(universityId))) {
    if (!errors.hasOwnProperty('studentProfile')) {
      errors.studentProfile = {};
    }
    errors.studentProfile.universityId = 'この値は必須です。';
  } else {
    const hasExtraInput = universityHasExtraInput(universityId);
    if (hasExtraInput) {
      const university = values.get('studentProfile') && values.get('studentProfile').get('university');
      if (!university) {
        if (!errors.hasOwnProperty('studentProfile')) {
          errors.studentProfile = {};
        }
        errors.studentProfile.university = 'この値は必須です。';
      }
    }
  }
  // major
  const major = values.get('studentProfile') && values.get('studentProfile').get('major');
  if (!major || _.isEmpty(_.trim(major))) {
    errors.studentProfile.major = 'Required';
  } else if (major.length > 40) {
    errors.studentProfile.major = 'Max 40 character length';
  }
  // majorCategory
  const majorCategory = values.get('studentProfile') && values.get('studentProfile').get('majorCategory');
  if (!majorCategory) {
    errors.studentProfile.majorCategory = 'Required';
  }
  // graduationYear
  const graduationYear = values.get('studentProfile') && values.get('studentProfile').get('graduationYear');
  if (!graduationYear) {
    errors.studentProfile.graduationYear = 'Required';
  }
  return errors;
};

export default validate;