import { request } from 'utilities/Api';
import { call, takeEvery, put, select } from 'redux-saga/effects';
import { 
  SEND_MESSAGE
} from './actions';
import { replace } from 'connected-react-router';
import _get from 'lodash/get';
import firebase from 'firebase/app';

function* sendMessage(action) {
  try {
    let response;
    if (action.message) {
      response = yield call(request, 'functions/sendMessage', { 
        eventId: action.eventId,
        senderId: action.senderId,
        message: action.message
      }, 'POST');
    }
    if (action.media) {
      response = yield call(request, 'functions/sendMessage', { 
        eventId: action.eventId,
        senderId: action.senderId,
        media: action.media
      }, 'POST');
    }

    const conversationDetail = yield select(state => 
      state.get('message').get('data').get(action.eventId)
    );
    const usersHaveSendMessage = _get(conversationDetail, 'usersHaveSendMessage', []);
    const isOfferEvent = _get(conversationDetail, 'offerEvent', false);
    if (!usersHaveSendMessage.includes(action.senderId)) {
      firebase.analytics().logEvent('send_first_message', isOfferEvent ? {
        type: 'offer',
        offer_id: action.eventId,
        messageID: _get(response, 'result.status.objectId')
      } : {
        type: 'event',
        event_id: action.eventId,
        messageID: _get(response, 'result.status.objectId')
      });
    }
  } catch (error) {
    if (error.code === 9001) {
      yield put(replace('/'));
    }
  }
}

export function* watchSendMessage() {
  yield takeEvery(SEND_MESSAGE, sendMessage);
}