export const FETCH_MEDIA_INFO_REQUEST = 'FETCH_MEDIA_INFO_REQUEST';
export const FETCH_MEDIA_INFO_SUCCESS = 'FETCH_MEDIA_INFO_SUCCESS';
export const FETCH_MEDIA_INFO_FAILURE = 'FETCH_MEDIA_INFO_FAILURE';

export function getMediaInfoByUrlRequest(url, json = 0) {
  return {type: FETCH_MEDIA_INFO_REQUEST, url, json, meta: { thunk: true }};
}

// saga thunk must use "payload"
export function getMediaInfoByUrlSuccess(payload, meta) {
  return {type: FETCH_MEDIA_INFO_SUCCESS, payload, meta};
}

export function getMediaInfoByUrlFailure(payload, meta) {
  return {type: FETCH_MEDIA_INFO_FAILURE, payload, meta, error: true};
}