import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Stepper = props => {
  const { stepCount, options, className = '' } = props;

  const renderStep = (key) => {
    let image = stepCount === key ? '/images/circle-check.svg' : '/images/circle-uncheck.svg';
    if (stepCount > key) image = '/images/circle-completed.svg';
    
    return (
      <img
        className='step-btn' 
        src={image}
        alt='step-checkbox'
        draggable='false'
      />
    );
  };

  return (
    <div className={`stepper-content ${className}`}>
      {options.map(({key, text}) => (
        <div key={key} className={`step ${stepCount === key ? 'step-active' : 'step-inactive'}`}>
          {renderStep(key)}
          {text}
        </div>
      )
      )}
    </div>
  );
};

Stepper.propTypes = {
  stepCount: PropTypes.number,
  className: PropTypes.string,
  options: PropTypes.array // { key: number, text: string}
};

export default Stepper;