import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import './styles.scss';
import _ from 'lodash';
import StarRatings from 'react-star-ratings';
class ReviewCell extends Component {
  state = {
    isLong: false, // true when comment line over 3
    isClicked: false // true when show full button clicked
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(this.props.review, nextProps.review) 
      && _.isEqual(this.state, nextState) 
      && this.props.location.pathname === nextProps.location.pathname
    ) {
      return false;
    }
    
    return true;
  }

  checkCommentElementHeight = () => {
    const commentElement = document.getElementById(this.props.review._id);

    if (commentElement && commentElement.offsetHeight > 54) {
      this.setState({ isLong: true });
    }
  }

  componentDidMount() {
    this.checkCommentElementHeight();
  }

  componentDidUpdate() {
    // just show less reason when switching tab
    // not trigger when clicking show full button
    if (!this.state.isClicked) {
      this.checkCommentElementHeight();
    }
  }

  render() {
    const { review = {}, isShow } = this.props;
    const { isLong, isClicked } = this.state;
    const { reviewer = {} } = review;
    const image = reviewer.avatarImage ? reviewer.avatarImage.thumbSmall : '/images/default-avatar.png';
    let comment = review.questionAnswers.QUESTIONNAIRE_APPLY_COMPANY_FREE_TEXT || '';
    comment = comment === '' 
      ? (<span className="no-comment">コメントはありません</span>) 
      : (<div className={(isLong && !isClicked) ? 'ellipsises' : ''}><div>{comment}</div></div>);
    
    const reviewCell = (
      <div className='review-cell-container'>
        <div className='review-cell-container-date'>{moment(review._created_at).format('YYYY/MM/DD')}</div>
        <div className='review-cell-container-comment' id={review._id}>{comment}</div>

        <div className='review-cell-container-reviewer'>
          <div className='review-cell-container-reviewer-image'><img src={image} alt='thumbsmall' /></div>
          
          {isShow && reviewer.graduationYear && (
            <div className='review-cell-container-reviewer-graduation'>{reviewer.graduationYear}年卒</div>
          )}

          {!isShow && (<div className='review-cell-container-reviewer-name'>{reviewer.displayName}</div>)}

          <div className='review-cell-container-reviewer-rating'>
            <div className='review-cell-container-reviewer-rating-star'>
              <StarRatings 
                starRatedColor='#FF8C00' 
                starDimension='13px' 
                svgIconViewBox='0 0 35 32'
                // eslint-disable-next-line max-len
                svgIconPath='M17.5 25.718L28.315 32l-2.87-11.84L35 12.194l-12.582-1.028L17.5 0l-4.918 11.166L0 12.194l9.555 7.966L6.685 32z' 
                starEmptyColor='#C8C8C8'
                starSpacing='2px'
                rating={Number(review.votePresident)} 
                numberOfStars={5} />
            </div>
            <div className='review-cell-container-reviewer-rating-number'>{review.votePresident}</div>
          </div>
        </div>
      </div>
    );
    
    return (
      <div className='review-cell'>
        {isShow ? reviewCell : (<Link to={`/event-detail/${this.props.review.eventId}`}>{reviewCell}</Link>)}
        {isLong && !isClicked && (
          <div className="hidden-show-full-reason-btn" onClick={() => this.setState({ isClicked: true })} />
        )}
      </div>
    );
  }
}

ReviewCell.propTypes = {
  review: PropTypes.object,
  isShow: PropTypes.any,
  location: PropTypes.any
};

export default withRouter(ReviewCell);