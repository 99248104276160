export const FETCH_CURRENT_ACCOUNT_REQUEST = 'FETCH_CURRENT_ACCOUNT_REQUEST';
export const FETCH_CURRENT_ACCOUNT_SUCCESS = 'FETCH_CURRENT_ACCOUNT_SUCCESS';
export const UNSET_ACCOUNT = 'UNSET_ACCOUNT';
export const SET_LOADING = 'SET_LOADING';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const STUDENT_UPDATE_UNIVERSITY = 'STUDENT_UPDATE_UNIVERSITY' 
export const STUDENT_UPDATE_UNIVERSITY_SUCCESS = 'STUDENT_UPDATE_UNIVERSITY_SUCCESS' 
export const STUDENT_UPDATE_UNIVERSITY_FAIL = 'STUDENT_UPDATE_UNIVERSITY_FAIL' 

export function getCurrentAccount(token, message = '') {
  return {
    type: FETCH_CURRENT_ACCOUNT_REQUEST,
    token, message, 
    meta: {
      thunk: true
    }
  };
}

export function receiveCurrentAccount(data, token, message, meta) {
  return { type: FETCH_CURRENT_ACCOUNT_SUCCESS, data, token, message, meta, payload: data };
}

export function setCurrentAccount(data, token) {
  return {type: SET_ACCOUNT, data, token};
}

export function unsetCurrentAccount(meta) {
  return {type: UNSET_ACCOUNT, meta, error: true};
}

export function setLoading(value) {
  return {type: SET_LOADING, value};
}

export function studentUpdateUniversity(payload) {
  return {
    type: STUDENT_UPDATE_UNIVERSITY, 
    payload,
    meta: {
      thunk: true
    }
  };
}

export function studentUpdateUniversityFail(payload, meta) {
  return {
    type: STUDENT_UPDATE_UNIVERSITY_FAIL, 
    payload,
    error: true,
    meta
  };
}

export function studentUpdateUniversitySuccess(payload, meta) {
  return {
    type: STUDENT_UPDATE_UNIVERSITY_SUCCESS, 
    payload,
    meta
  };
}