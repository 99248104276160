import { fromJS } from 'immutable';
import { FETCH_BANNER_SUCCESS } from './actions';
const initState = fromJS(null);
/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function banners(state = initState, action) {
  switch (action.type) {
    case FETCH_BANNER_SUCCESS: {
      return fromJS(action.banners || []);
    }
    default:
      return state;
  }
}