const validate = values => {
  const errors = {};
  if (values) {
    if (!values.get('communicateRating') || values.get('communicateRating') === 0)
      errors.communicateRating = 'Please rating';
    if (!values.get('honestnessRating') || values.get('honestnessRating') === 0)
      errors.honestnessRating = 'Please rating';
    if (!values.get('smartnessRating') || values.get('smartnessRating') === 0)
      errors.smartnessRating = 'Please rating';
    if (!values.has('isInvited') || values.get('isInvited') === undefined) errors.isInvited = 'Please choose';
  }
  return errors;
};

export default validate;