export const GET_EVENT_DETAIL_STUDENT_CSV_REQUEST = 'GET_EVENT_DETAIL_STUDENT_CSV_REQUEST';
export const GET_EVENT_DETAIL_STUDENT_CSV_SUCCESS = 'GET_EVENT_DETAIL_STUDENT_CSV_SUCCESS';
export const GET_EVENT_DETAIL_STUDENT_CSV_FAILURE = 'GET_EVENT_DETAIL_STUDENT_CSV_FAILURE';

export function requestStudentDataCSV(eventId) {
  return {
    type: GET_EVENT_DETAIL_STUDENT_CSV_REQUEST,
    eventId, 
    meta: {
      thunk: true
    }
  };
}

export function receiveStudentDataCSV(meta, payload) {
  return {
    type: GET_EVENT_DETAIL_STUDENT_CSV_SUCCESS,
    payload,
    meta
  };
}

export function failStudentDataCSV(meta, payload) {
  return {
    type: GET_EVENT_DETAIL_STUDENT_CSV_FAILURE,
    payload,
    meta,
    error: true
  };
}